import { classNames } from '@guibil/components';
import { commonCSS } from '@guibil/styles';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { IDefaultFormElementProps, IFormElementValueType } from '../../hocs/withGuiFormItem';
import { IGuiDropdownProps } from '../GuiDropdown';
import { GuiText } from '../GuiText';

type IProps = IGuiDropdownProps & IDefaultFormElementProps;

export const GuiDropdownAutocomplete: React.FC<IProps> = (props) => {
  const {
    disabled, multipleSelection, showClearOption, fieldValue,
    fieldError, fieldOnChange, field, label, fieldRequired,
    selections, autocomplete, maskNormalizer
  } = props;

  const handleAutocompleteChange = useCallback((event: any, value: IFormElementValueType) => {
    fieldOnChange(value || "");
  }, [fieldOnChange])

  if (!autocomplete || !selections) { return null; }

  const options = Array.isArray(selections) ? selections : Object.keys(selections);

  let freeSoloProps = {}

  if (autocomplete.freesolo) {
    freeSoloProps = {
      inputValue: fieldValue,
      onInputChange: handleAutocompleteChange,
      freeSolo: true,
    }
  }

  // multiple causes bug, for @material-ui/lab v4.0.0-alpha.57
  return (
    <Autocomplete
      value={fieldValue ? fieldValue : (multipleSelection ? "" : null)}
      onChange={handleAutocompleteChange}
      options={options}
      multiple={multipleSelection}
      disabled={disabled}
      className={classNames(commonCSS.autoComplateLabel, fieldRequired && commonCSS.requiredLabel)}
      classes={{ inputRoot: commonCSS.autoCompleteInput, endAdornment: commonCSS.autoCompleteEndorment }}
      getOptionLabel={(option) => option.toString()}
      renderInput={(params) => (
        <GuiText
          controlled
          field={`${field}_autocomplete`}
          label={label}
          fieldError={fieldError}
          clearable={showClearOption}
          maskNormalizer={maskNormalizer}
          {...params}
        />
      )}
      forcePopupIcon // to always show down arrow
      {...freeSoloProps}
    />
  )
}
