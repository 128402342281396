export const play_store_developers_tr = {
  title: "Yapımcılar",

  developers: {
    title: "Yapımcılar",
    object_order: "Sıra",
    name: "Adı",
    rate: "Puan",
    last_release_date: "Son Yayın Tarihi",
    follow: "Takip",
    showTags: "Etiketler",
    tags: {
      title: "Yapımcı Etiketleri",
      createButton: "Ekle",
      deleteConfirmMessage: "Seçili etiketi silmek istediğinizden emin misiniz?",
      deleteSuccessMessage: "Etiket başarı ile silindi",
      tag: "Etiket",
      actions: " ",
      create: {
        title: "Etiket Ekle",
        tag: "Yeni Etiket",
      },
    },
  },

  developerFilter: {
    filter: "Filtrele",
    name: "Ad",
    min_last_release_date: "Min Son Yayın Tarihi",
    max_last_release_date: "Max Son Yayın Tarihi",
    rate: "Puan",
    tag: "Etiket",
    executive: "Üst Yapımcı",
  },

  newDeveloperDialog: {
    add: "Ekle",
    title: "Yeni Yapımcı Ekle",
    url: "Yapımcı veya Oyun Linki",
    preview: "Önizle",
    save: "Sisteme Kaydet",
    developerExist: "Yapımcı zaten kayıtlı",
    saveSuccessMessage: "Yapımcı sisteme kayıt edildi",

    accepedFormats: {
      accepedFormats: "Kabul edilen formatlar",
      developer: "Yapımcı",
      game: "Oyun",
      or: "veya"
    },

    developer: {
      developer: "Yapımcı",
      id: "Id:",
      name: "Adı:",
      url: "Url:",
    },

    games: {
      url: "Url",
      icon: " ",
      appId: "Id",
    },
  },
};
