import { ILang, useLang } from '@guibil/app';
import { useMemo } from 'react';

export type ISwitchText = | 'on-off' | "registered-not-registered" | 'enabled-disabled' | 'active-passive' | 'yes-no' | "exist-not-exist" | "defined-not-defined";

const SWITCH_TEXT_CONFIG: {
  [key in ISwitchText]: {
    on: string,
    off: string
  }
} = {
  'on-off': {
    on: 'guibil:components.switchText.on',
    off: 'guibil:components.switchText.off',
  },
  'enabled-disabled': {
    on: 'guibil:components.switchText.enabled',
    off: 'guibil:components.switchText.disabled',
  },
  'active-passive': {
    on: 'guibil:components.switchText.active',
    off: 'guibil:components.switchText.passive',
  },
  'yes-no': {
    on: 'guibil:components.switchText.yes',
    off: 'guibil:components.switchText.no',
  },
  "exist-not-exist": {
    on: 'guibil:components.switchText.exists',
    off: 'guibil:components.switchText.notExists',
  },
  "registered-not-registered": {
    on: 'guibil:components.switchText.registered',
    off: 'guibil:components.switchText.notRegistered',
  },
  "defined-not-defined": {
    on: 'guibil:components.switchText.defined',
    off: 'guibil:components.switchText.notDefined',
  },

};
interface IConfig {
  activeText?: string,
  passiveText?: string,
}

export const useSwitchText = (value: boolean, switchText: ISwitchText = "on-off", config: IConfig = {}) => {
  const lang = useLang();

  const text = useMemo(() => {
    if (value === true) {
      return lang(config.activeText || SWITCH_TEXT_CONFIG[switchText].on);
    }
    if (value === false) {
      return lang(config.passiveText || SWITCH_TEXT_CONFIG[switchText].off);
    }

    return lang('guibil:components.switchText.invalid');
  }, [value, switchText, config, lang]);

  return text;
};

export const getTranslatedSwitchText = (switchText: ISwitchText = "on-off", lang: ILang, config: IConfig = {}) => {
  return {
    true: lang(config.activeText || SWITCH_TEXT_CONFIG[switchText].on),
    false: lang(config.passiveText || SWITCH_TEXT_CONFIG[switchText].off),
  }
}