import { IEnumBuilder } from '@guibil/app';
import { AppEnumTypes, app_enum } from './app/app-enum';
import { AuthEnumTypes, auth_enum } from './auth/auth-enum';

export type EnumsCollectionType =
  | AuthEnumTypes
  | AppEnumTypes;

export const enumsCollection: IEnumBuilder<EnumsCollectionType> = {
  ...auth_enum,
  ...app_enum,
};
