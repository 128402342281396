import { CircularProgress, makeStyles } from '@material-ui/core';

export function LoadingComponent() {
  const classes = useClasses();

  return (
    <div className={classes.loadingIndicator}>
      <CircularProgress disableShrink />
    </div>
  );
}

const useClasses = makeStyles({
  loadingIndicator: {
    zIndex: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    "& svg": {
      color: "var(--titleColor) !important"
    }
  },
})