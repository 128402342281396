import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface IProps {
  hide?: boolean,
  title?:  string | React.ElementType
  children: JSX.Element
  placement?: | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
}

export const GuiTooltip: React.FC<IProps> = (props) => {
  const {
    placement, title, children, hide,
  } = props;

  if (hide || !title) { return children; }

  return (
    <Tooltip
      placement={placement || 'bottom'}
      title={title || ''}
    >
      { children}
    </Tooltip>
  );
};
