import { GuiDropdown } from '@guibil/form';
import { IFieldOnChangeCallback } from '@guibil/form/form-elements/hocs/withGuiFormItem';
import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

interface IGuiTimeIntervalDropdownProps {
  intervals?: number[],
  onRefreshTimeChange?: Function,
  defaultInterval?: number,
  disabled?: boolean,
}

export function GuiTimeIntervalDropdown(props: IGuiTimeIntervalDropdownProps) {
  const { onRefreshTimeChange, defaultInterval, disabled } = props;
  const [activeInterval, setActiveInterval] = useState(defaultInterval || 10);
  const classes = useStyles();

  const intervals = props.intervals || [2, 5, 10, 15, 20, 25, 30, 60, 300];

  if (defaultInterval && !intervals.includes(defaultInterval)) {
    intervals.push(defaultInterval);
    intervals.sort((a, b) => a - b);
    // Ascending Order
  }

  const handleChange: IFieldOnChangeCallback = (event: any) => {
    setActiveInterval(event.target.value as number);
    if (onRefreshTimeChange) { onRefreshTimeChange(event.target.value as number * 1000); }
  };

  return (
    <GuiDropdown className={classes.refreshDropdown} controlled field="" label="guibil:components.timeInterval.title" selections={intervals} value={activeInterval} onChange={handleChange} disabled={disabled} />
  );
}

const useStyles = makeStyles({
  refreshDropdown: {
    width: 200,
    marginLeft: 10,
    "& label": {
      top: -21
    },
    "& > div": {
      marginTop: -6
    }
  }
});
