/* eslint-disable unused-imports/no-unused-vars */
import React, { FunctionComponent } from 'react';
import { isNotNullOrUndefined, guiIsEqual } from '@guibil/helpers';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import './styles/GuiDateTimePicker.css';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import { classNames, GuiButton } from '@guibil/components';
import { commonCSS } from '@guibil/styles';
import { useLang } from '@guibil/app';
import { GuiText } from './GuiText';
import { withGuiFormItem, IGuiFormElementCommonProps, IDefaultFormElementProps } from '../hocs/withGuiFormItem';
interface IElementSpecificProps {
  options?: any,
  measurementLabel?: string,
  clearable?: boolean,
  disableTime?: boolean,
  onOpen?: () => void;
  onClose?: () => void;
}
type IProps = IElementSpecificProps & IDefaultFormElementProps;

const flatpickrDateTimeFormat = 'Y-m-dTH:i:S';
const flatpickrHumanDateTimeFormat = 'd-m-Y H:i:S';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_HUMAN_FORMAT = 'DD-MM-YYYY HH:mm:ss';

const flatpickrDateFormat = 'Y-m-d';
const flatpickrHumanDateFormat = 'd-m-Y';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_HUMAN_FORMAT = 'DD-MM-YYYY';


const GuiDateTimePicker: FunctionComponent<IProps> = (props) => {
  const lang = useLang();
  const {
    disabled, fieldValue, fieldEditMode, fieldError, fieldRequired,
    fieldOnChange, children, field, measurementLabel, clearable, onOpen, onClose,
    label, tooltip, controlled, options, disableTime, ...otherProps
  } = props;

  if (fieldEditMode) {
    return (
      <FormControl
        variant="standard"
        margin="normal"
        error={isNotNullOrUndefined(fieldError)}
        fullWidth
        className={commonCSS.formItem}
      >
        <div className={commonCSS.editableDrop}>
          <label className={classNames(fieldRequired && commonCSS.requiredLabel, commonCSS.dateTimePickerLabel)}>{label}</label>
          <div className={classNames(commonCSS.dateTimePickerOutline, disabled && commonCSS.dateTimePickerOutlineDisabled)}>
            <Flatpickr
              disabled={disabled}
              onOpen={onOpen}
              options={{
                // static: true,
                enableTime: !disableTime,
                dateFormat: disableTime ? flatpickrDateFormat : flatpickrDateTimeFormat,
                time_24hr: true,
                altInput: true,
                altFormat: disableTime ? flatpickrHumanDateFormat : flatpickrHumanDateTimeFormat,
                minuteIncrement: 1,
                allowInput: true,
                disableMobile: true,
                plugins: [confirmDatePlugin({
                  confirmText: lang('guibil:form.dateTime.confirmText'),
                  showAlways: true,
                  confirmIcon: '',
                  theme: 'light',
                })],
                // parseDate: (datestring:string)=>{ moment(fieldValue).format(this.dateTimeFormat) },
                // clickOpens: false,
                ...options,
              }}

              data-enable-time
              value={fieldValue}
              onChange={(date: any, datestring: string, instance: any) => {
                fieldOnChange(moment(date[0]).format(disableTime ? DATE_FORMAT : DATE_TIME_FORMAT));
              }}
              onClose={(date: any, datestring: string, instance: any) => {
                if (onClose) onClose();

                let format = disableTime ? flatpickrHumanDateFormat : flatpickrHumanDateTimeFormat;
                if (isNotNullOrUndefined(options)) {
                  if (options.altFormat) {
                    format = options.altFormat;
                  }
                }
                if (instance.altInput && instance.altInput.value) {
                  instance.setDate(instance.altInput.value, true, format);
                }
              }}
              {...otherProps}
            />
            {(fieldValue.length > 0 && clearable) && <GuiButton nativeProps={{ classes: { root: commonCSS.clearDateTime } }} icon="times" onClick={() => fieldOnChange("")} />}
          </div>
        </div>
        <FormHelperText className={commonCSS.formHelper}>{fieldError}</FormHelperText>
      </FormControl>
    );
  }

  let activeValue: any = '';
  if (!moment(fieldValue).isValid()) { activeValue = fieldValue; } else { activeValue = moment(fieldValue).format(disableTime ? DATE_HUMAN_FORMAT : DATE_TIME_HUMAN_FORMAT); }

  return (
    <GuiText
      controlled
      field={field}
      measurementLabel={measurementLabel}
      value={activeValue}
      label={label}
      disabled
      editMode={false}
    />
  );
};

const GuiDateTimePickerComponent = withGuiFormItem<IElementSpecificProps & IGuiFormElementCommonProps>(
  React.memo(GuiDateTimePicker, (prevProps, nextProps) => guiIsEqual(prevProps, nextProps)),
);

export { GuiDateTimePickerComponent as GuiDateTimePicker };
