export const log_user_management_tr = {
  breadcrumbTitle: "Log Kullanıcı Yönetimi",
  pageTitle: "Log Kullanıcı Yönetimi",
  title: "Log Kullanıcı Yönetimi",
  menuTitle: "Log Kullanıcı Yönetimi",

  addUser: "Ekle",
  deleteConfirmTitle: "\"{{ name }}\" log kullanıcısını silenecek!",
  deleteConfirmMessage: "\"{{ name }}\" log kullanıcısı silinirse bu kullanıcı üzerinden gelen \"İndirilme Verileri\" silinecektir, devam etmek istediğinizden emin misiniz?",
  deleteSuccessMessage: "Log kullanıcısı başarılı bir şekilde silindi",

  username: "Kullanıcı Adı",
  basic_auth: "Token",
  is_active: "Aktiflik Durumu",
  is_test_user: "Test Hesabı",
  actions: " ",

  create: {
    title: "Log Kullanıcısı Ekle",
    username: "Kullanıcı Adı",
    password: "Şifre",
    password_confirm: "Şifre Tekrar",
    successMessage: "Log kullanıcısı başarılı bir şekilde oluşturuldu",
    randomPassword: "Rastgele Parola Üret"
  },

  update: {
    title: "Log Kullanıcısı Güncelle",
    username: "Kullanıcı Adı",
    is_active: "Aktiflik Durumu",
    is_test_user: "Test Hesabı",
    successMessage: "Log kullanıcısı başarılı bir şekilde güncellendi",
  },

  updatePassword: {
    title: "Log Kullanıcısı Şifresi Güncelle",
    username: "Kullanıcı Adı",
    password: "Şifre",
    password_confirm: "Şifre Tekrar",
    successMessage: "Log kullanıcısı şifresi başarılı bir şekilde güncellendi",
    randomPassword: "Rastgele Parola Üret"
  },
};
