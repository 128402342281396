import { useRequest } from "@guibil/api"
import { useLang } from "@guibil/app";
import { classNames, GuiButton, guiNotifier } from "@guibil/components";
import { GuiGrid, useGuiGridHook } from "@guibil/grid"
import GuiGridRowButton from "@guibil/grid/components/buttons/GuiGridRowButton"
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import UserCreate from "../components/create/UserCreate";
import UserPasswordUpdate from "../components/update-password/UserPasswordUpdate";
import UserUpdate from "../components/update/UserUpdate";

const LogUserManagement = () => {
	const lang = useLang();
	const req = useRequest();
	const classes = useClasses();
	const [gridHook, setGridHook] = useGuiGridHook();
	const [showAddDialog, setShowAddDialog] = useState(false);
	const [editRowId, setEditRowId] = useState<any>(null);
	const [editPasswordRowData, setEditPasswordRowData] = useState<any>(null);

	const Action = (row: any) => {
		const handleDelete = async () => {
			await req.delete("users/log_user/" + row.row.id).then(() => {
				guiNotifier().success("gameTracker.logUserManagement.deleteSuccessMessage");
				gridHook?.fetch();
			});
		};

		const onDeleteClick = () => guiNotifier().confirm({
			title: lang("gameTracker.logUserManagement.deleteConfirmTitle", { name: row.row.username }),
			description: lang("gameTracker.logUserManagement.deleteConfirmMessage", { name: row.row.username })
		}, handleDelete)

		return (
			<>
				<GuiGridRowButton onClick={() => setEditRowId(row.row.id)} type="update" />
				<GuiButton
					nativeProps={{ classes: { root: classNames(classes.button, classes.lock) } }}
					variant="cancel" onClick={() => setEditPasswordRowData({ id: row.row.id, username: row.row.username })}
					icon="lock"
				/>
				<GuiGridRowButton onClick={onDeleteClick} type="delete" />
			</>
		)
	}


	return (
		<>
			<GuiGrid
				fetchData="users/log_users"
				columns={["username", "basic_auth", "is_active", "is_test_user", "actions"]}
				title="gameTracker.logUserManagement.title"
				labelPath="gameTracker.logUserManagement"
				setHook={setGridHook}
				booleanFields={[
					{ targetField: "is_active", switchText: "active-passive" },
					{ targetField: "is_test_user", switchText: "yes-no" },
				]}
				customCells={[
					{
						targetFields: ["actions"], component: Action,
					},
				]}
				columnExtensions={[
					{ columnName: "actions", width: 140 },
				]}
				extraButtons={[{
					onClick: () => setShowAddDialog(true),
					text: "gameTracker.logUserManagement.addUser",
					icon: "plus-circle",
					variant: "success"
				}]}
			/>
			{showAddDialog && <UserCreate onClose={() => setShowAddDialog(false)} onSuccess={() => { setShowAddDialog(false); gridHook?.fetch(); }} />}
			{editRowId && <UserUpdate id={editRowId} onClose={() => setEditRowId(null)} onSuccess={() => { setEditRowId(null); gridHook?.fetch(); }} />}
			{editPasswordRowData && <UserPasswordUpdate username={editPasswordRowData.username} id={editPasswordRowData.id} onClose={() => setEditPasswordRowData(null)} onSuccess={() => { setEditPasswordRowData(null); gridHook?.fetch(); }} />}
		</>
	)
}

const useClasses = makeStyles({
	button: {
		margin: "4px",
		minWidth: "unset",
		width: "32px !important",
		height: "32px !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "7px !important",
		float: "left",
		marginRight: "5px !important",
		"& span": {
			margin: 0,
		},
		"& div": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		"& svg": {
			position: "static !important",
			width: "14px !important",
			height: "14px !important",
		}
	},
	lock: {
		"& .MuiCircularProgress-root": {
			left: "6.5px !important"
		},
		"& svg": {
			color: "var(--primaryBlue) !important",
			position: "static !important",
		}
	}
})

export default LogUserManagement
