import { useRequest } from "@guibil/api"
import { useLang } from "@guibil/app";
import { GuiPage, useGuiPageData } from "@guibil/page"
import { makeStyles } from '@material-ui/core';
import React from "react";

interface ItemProps {
	title: string,
	value: number,
}

const Info = () => {
	const lang = useLang();
	const req = useRequest();
	const classes = useStyle();
	const [data, setData] = useGuiPageData();

	const fetchData = async () => {
		const res = await req.get("ps_developers/info");
		return { ...res }
	}

	const Item: React.FC<ItemProps> = ({ title, value }) => {
		return (
			<div className={classes.itemContainer}>
				<span className={classes.itemTitle}>{title}</span>
				<span className={classes.itemValue}>{value}</span>
			</div>
		)
	}

	return (
		<GuiPage
			fetchData={fetchData}
			setData={setData}
			title="gameTracker.dashboard.playStore.info.title"
		>
			<div className={classes.container}>
				<Item title={lang("gameTracker.dashboard.playStore.info.developer")} value={data?.developer} />
				<div className={classes.divider} />
				<Item title={lang("gameTracker.dashboard.playStore.info.game")} value={data?.game} />
				<div className={classes.divider} />
				<Item title={lang("gameTracker.dashboard.playStore.info.following_developer")} value={data?.following_developer} />
				<div className={classes.divider} />
				<Item title={lang("gameTracker.dashboard.playStore.info.following_game")} value={data?.following_game} />
			</div>
		</GuiPage>
	)
}

const useStyle = makeStyles({
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	divider: {
		padding: 5,
	},
	itemContainer: {
		padding: 10,
		marginBottom: 5,
		background: "var(--bgColor)",
		borderRadius: "2px"
	},
	itemTitle: {
		opacity: "0.75"
	},
	itemValue: {
		fontWeight: 900,
		opacity: "0.875",
		marginLeft: 7,
	},
});

export default Info
