import { useLang } from '@guibil/app';
import { Grid } from '@material-ui/core'
import { useRequest } from '@guibil/api';
import validationSchema from "./validation";
import React, { useEffect, useState } from 'react'
import { GuiButton, GuiGenericDialog, guiNotifier } from '@guibil/components'
import { makeStyles } from '@material-ui/core/styles';
import Tagger, { TagProps } from 'pages/components/Tagger';
import { GuiDropdown, GuiForm, GuiText, useGuiFormData } from '@guibil/form'
import ValuesList, { ValuesProps } from 'pages/components/valuesList/ValuesList';
import GenresDialog from '../genres/GenresDialog';
import Base64Images, { Base64ImageProps } from 'pages/components/base64Images/Base64Images';
import { GidStatus } from 'app/enums/app/general/gid-status/enum_type';
import { GuiRichText } from '@guibil/form/form-elements/elements/GuiRichText';
import FilesUpload, { FileUploadProps } from 'pages/components/filesUpload/FilesUpload';

interface AddGidDialogProps {
  onClose: () => void,
  onSuccess: () => void,
}

const AddGidDialog: React.FC<AddGidDialogProps> = ({ onClose, onSuccess }) => {
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();

  const [data, setData] = useGuiFormData();
  const [availableDevelopers, setAvailableDevelopers] = useState<TagProps[]>([]);
  const [availableDevelopersLoading, setAvailableDevelopersLoading] = useState<boolean>(false);
  const [availableArtists, setAvailableArtists] = useState<TagProps[]>([]);
  const [availableArtistsLoading, setAvailableArtistsLoading] = useState<boolean>(false);
  const [genres, setGenres] = useState<string[]>([]);
  const [genresLoading, setGenresLoading] = useState<boolean>(false);
  const [displayGenres, setDisplayGenres] = useState(false);
  const [selectedDevUsers, setSelectedDevUsers] = useState<TagProps[]>([]);
  const [selectedArtUsers, setSelectedArtUsers] = useState<TagProps[]>([]);
  const [coreLoops, setCoreLoops] = useState<ValuesProps[]>([]);
  const [hasCoreLoopsError, setHasCoreLoopsError] = useState<boolean>(false);
  const [mechanicCores, setMechanicCores] = useState<ValuesProps[]>([]);
  const [hasMechanicCoresError, setHasMechanicCoresError] = useState<boolean>(false);
  const [mechanicSubs, setMechanicSubs] = useState<ValuesProps[]>([]);
  const [hasMechanicSubsError, setHasMechanicSubsError] = useState<boolean>(false);
  const [b64encodeImages, setB64encodeImages] = useState<Base64ImageProps[]>([]);
  const [files, setFiles] = useState<FileUploadProps[]>([]);
  const [percentage, setPercentage] = useState<Number | null>(null);

  useEffect(() => {
    async function get() {
      await getGenres();
      await getAvailableDevelopers();
      await getAvailableArtists();
    }
    get();
  }, [])

  useEffect(() => {
    data?.values && data?.setFieldValue("core_loops", coreLoops);
    data?.values && data?.setFieldValue("planned_developers", selectedDevUsers);
    data?.values && data?.setFieldValue("planned_designers", selectedArtUsers);
    data?.values && data?.setFieldValue("mechanic.cores", mechanicCores);
    data?.values && data?.setFieldValue("mechanic.subs", mechanicSubs);
    data?.values && data?.setFieldValue("design.b64encode_images", b64encodeImages);
    data?.values && data?.setFieldValue("design.files", files);
  }, [coreLoops, selectedDevUsers, selectedArtUsers, mechanicCores, mechanicSubs, b64encodeImages, files])


  const getAvailableDevelopers = async () => {
    setAvailableDevelopersLoading(true);
    const res = await req.get("gids/gid/available_developers");
    setAvailableDevelopers(res);
    setAvailableDevelopersLoading(false);
  }

  const getAvailableArtists = async () => {
    setAvailableArtistsLoading(true);
    const res = await req.get("gids/gid/available_designers");
    setAvailableArtists(res);
    setAvailableArtistsLoading(false);
  }

  const getGenres = async () => {
    setGenresLoading(true);
    const res = await req.get("gids/genre/genres");
    setGenres(res.result.map((item: any) => item.genre));
    setGenresLoading(false);
  }

  const onUploadProgress = (progress: any) => {
    setPercentage(Math.round((progress.loaded * 100) / progress.total))
  }

  const uploadFiles = async () => {
    try {
      setPercentage(0);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const item = files[i];
        if (item.file) formData.append("design_files", item.file);
      }
      return await req.upload("gids/gid/add_design_files", formData, onUploadProgress);
    } catch (err) {
      guiNotifier().handleError(err);
      return null;
    } finally {
      setPercentage(null);
    }
  }

  const submitHandler = async (data: any) => {
    let values = { ...data }
    const uploadedFiles = await uploadFiles();
    if (uploadedFiles) {
      values["design"]["files"] = uploadedFiles;
      return await req.post("gids/gid/add", values);
    }
  }

  const fetchData = () => {
    return {
      title: "",
      status: GidStatus.IDEA,
      popularity: null,
      art_difficulty: null,
      dev_difficulty: null,
      mantra: "",
      core_loops: [],
      planned_developers: [],
      planned_designers: [],
      dynamic: {
        genre: "",
        progress: "<p><br></p>",
        goal: "<p><br></p>",
        challenge: "<p><br></p>"
      },
      mechanic: {
        cores: [],
        subs: []
      },
      design: {
        description: "<p><br></p>",
        notes: "<p><br></p>",
        b64encode_images: [],
        files: [],
      }
    }
  }

  return <GuiGenericDialog
    isOpen
    onClose={onClose}
    title="gameTracker.development.gids.addGid.title"
    size="xl"
  >
    <div className={classes.root}>
      <GuiForm
        type="new-form"
        fetchData={fetchData}
        setData={setData}
        submitHandler={submitHandler}
        validationSchema={validationSchema}
        successListener={onSuccess}
        labelPath="gameTracker.development.gids"
        isDialogForm
        mainButtonsConfig={{
          dialogSaveText: percentage ? lang("gameTracker.development.gids.addGid.uploading") + " " + percentage : lang("gameTracker.development.gids.addGid.save")
        }}
        disableSubmitButton={hasCoreLoopsError || hasMechanicCoresError || hasMechanicSubsError}
      >
        {/* General */}
        <div>
          <div className={classes.title}>
            {lang("gameTracker.development.gids.sections.info")}
          </div>
          <div className={classes.container}>
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <GuiText
                  field="title"
                  label="gameTracker.development.gids.general.title"
                  placeholder="gameTracker.development.gids.placeHolders.title"
                />
              </Grid>
              <Grid item xs={12}>
                <GuiText
                  field="mantra"
                  multiline
                  label="gameTracker.development.gids.general.mantra"
                  placeholder="gameTracker.development.gids.placeHolders.mantra"
                />
              </Grid>


              <Grid item xs={12} md={6} lg={3}>
                <GuiDropdown
                  field="status"
                  editMode={false}
                  label="gameTracker.development.gids.general.status"
                  selectionsEnum="GidStatus"
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <GuiDropdown
                  field="popularity"
                  label="gameTracker.development.gids.general.popularity"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <GuiDropdown
                  field="art_difficulty"
                  label="gameTracker.development.gids.general.art_difficulty"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <GuiDropdown
                  field="dev_difficulty"
                  label="gameTracker.development.gids.general.dev_difficulty"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Tagger
                  label="gameTracker.development.gids.general.planned_developers"
                  isLoading={availableDevelopersLoading}
                  availables={availableDevelopers}
                  selecteds={selectedDevUsers}
                  handleSelectedTags={setSelectedDevUsers}
                  handleSingleSelectedTag={() => null}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Tagger
                  label="gameTracker.development.gids.general.planned_designers"
                  isLoading={availableArtistsLoading}
                  availables={availableArtists}
                  selecteds={selectedArtUsers}
                  handleSelectedTags={setSelectedArtUsers}
                  handleSingleSelectedTag={() => null}
                />
              </Grid>
              <Grid item xs={12}>
                <ValuesList
                  label={lang("gameTracker.development.gids.general.core_loops")}
                  placeholder="gameTracker.development.gids.placeHolders.core_loop"
                  values={coreLoops}
                  setValues={setCoreLoops}
                  setHasError={setHasCoreLoopsError}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Dynamics */}
        <div>
          <div className={classes.title}>
            {lang("gameTracker.development.gids.sections.dynamic")}
          </div>
          <div className={classes.container}>
            <Grid container spacing={1} >
              <Grid container item xs={12}>
                <Grid item xs={7} md={8} lg={9}>
                  <GuiDropdown
                    field="dynamic.genre"
                    selections={genres}
                    isLoading={genresLoading}
                  />
                  {
                    displayGenres &&
                    <GenresDialog
                      onClose={() => setDisplayGenres(false)}
                      onSuccess={async () => {
                        await getGenres();
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={5} md={4} lg={3}>
                  <GuiButton
                    variant="cancel"
                    onClick={() => setDisplayGenres(true)}
                    text="gameTracker.development.gids.genres.edit"
                    icon="pen"
                    nativeProps={{ classes: { root: classes.displayGenres } }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <GuiRichText
                  field="dynamic.progress"
                  placeholder="gameTracker.development.gids.placeHolders.progress"
                />
              </Grid>
              <Grid item xs={12}>
                <GuiRichText
                  field="dynamic.goal"
                  placeholder="gameTracker.development.gids.placeHolders.goal"
                />
              </Grid>
              <Grid item xs={12}>
                <GuiRichText
                  field="dynamic.challenge"
                  placeholder="gameTracker.development.gids.placeHolders.challenge"
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Mechanics */}
        <div>
          <div className={classes.title}>
            {lang("gameTracker.development.gids.sections.mechanic")}
          </div>
          <div className={classes.container}>
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <ValuesList
                  label={lang("gameTracker.development.gids.mechanic.cores")}
                  placeholder="gameTracker.development.gids.placeHolders.core_mechanic"
                  values={mechanicCores}
                  setValues={setMechanicCores}
                  setHasError={setHasMechanicCoresError}
                />
              </Grid>
              <Grid item xs={12}>
                <ValuesList
                  label={lang("gameTracker.development.gids.mechanic.subs")}
                  placeholder="gameTracker.development.gids.placeHolders.sub_mechanic"
                  values={mechanicSubs}
                  setValues={setMechanicSubs}
                  setHasError={setHasMechanicSubsError}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Design */}
        <div>
          <div className={classes.title}>
            {lang("gameTracker.development.gids.sections.design")}
          </div>
          <div className={classes.container}>
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <GuiRichText
                  field="design.description"
                  placeholder="gameTracker.development.gids.placeHolders.description"
                />
              </Grid>
              <Grid item xs={12}>
                <GuiRichText
                  field="design.notes"
                  placeholder="gameTracker.development.gids.placeHolders.notes"
                />
              </Grid>
              <Grid item xs={12}>
                <Base64Images
                  label="gameTracker.development.gids.design.b64encodeImages"
                  b64encodeImages={b64encodeImages}
                  setB64encodeImages={setB64encodeImages}
                />
              </Grid>
              <Grid item xs={12}>
                <FilesUpload
                  label="gameTracker.development.gids.design.files"
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </GuiForm>
    </div>
  </GuiGenericDialog>
}

const useStyles = makeStyles(() => ({
  root: {
    "& form > div": {
      padding: "8px !important",
      background: "transparent !important"
    }
  },
  container: {
    padding: "10px 0px 30px 0px",
    background: "var(--bgPrimary)"
  },
  title: {
    fontWeight: 800,
    fontSize: 22,
    marginLeft: 7,
    marginBottom: 5,
    color: "var(--titleColor)"
  },
  displayGenres: {
    marginLeft: 15,
    marginTop: 28,
    width: "-webkit-fill-available",
    "& svg": {
      left: 8,
      width: "18px !important",
      height: "18px !important",
    },
  },
}));

export default AddGidDialog
