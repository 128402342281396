import React from 'react';
import { GuiGenericDialog } from '@guibil/components';
import { GuiForm, GuiSwitch, GuiText } from '@guibil/form';
import { Grid } from '@material-ui/core';
import validationSchema from './validation';

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
  id: string;
}

const UserUpdate: React.FC<IProps> = (props) => {
  const { onClose, onSuccess, id } = props;

  return (
    <GuiGenericDialog isOpen title="gameTracker.logUserManagement.update.title" onClose={onClose}>
      <GuiForm
        fetchData={'users/log_user/' + id}
        type="new-form"
        isDialogForm
        config={{
          submitDataApi: 'users/log_user/' + id,
          successMessage: "gameTracker.logUserManagement.update.successMessage",
          submitType: "put"
        }}
        labelPath="gameTracker.logUserManagement.update"
        validationSchema={validationSchema}
        successListener={onSuccess}
        onCancel={onClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GuiText field="username" />
          </Grid>
          <Grid item xs={6}>
            <GuiSwitch field="is_active" switchText="active-passive" />
          </Grid>
          <Grid item xs={6}>
            <GuiSwitch field="is_test_user" switchText="on-off" />
          </Grid>
        </Grid>
      </GuiForm>
    </GuiGenericDialog>
  );
};

export default UserUpdate;
