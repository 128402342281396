import { commonCSS } from '@guibil/styles';
import React from 'react';
import { GuiImage } from '../GuiImage';

interface IProps {
  src: string,
}
export const GuiImageViewer: React.FC<IProps> = (props) => {
  const { src } = props;

  return (
    <GuiImage src={src} className={commonCSS.viewer} />
  );
};
