export const installations_tr = {
  breadcrumbTitle: "Yayınlanmış Oyunlar",
  pageTitle: "Yayınlanmış Oyunlar",
  title: "Yayınlanmış Oyunlar",
  menuTitle: "Yayınlanmış Oyunlar",

  installedGames: {
    title: "Yayınlanmış Oyunlar",
    follow: "Takip",
    register_date: "Kayıt Tarihi",
    game: {
      logo_image_url: "Logo",
      name: "Adı",
      artist: "Yapımcı",
      last_release_date: "Son Yayın Tarihi",
    },

    play_detail: {
      player_count: "Oyuncu Sayısı",
      total_playtime_in_ms: "Oynanma Süresi",
      average_playtimes_in_ms: "Ortalama Oynanma Süresi"
    },
  },
};
