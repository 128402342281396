import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import { Grid } from '@material-ui/core';
import { DATE_FORMAT, GuiDateTimePicker, GuiForm, GuiSwitch, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form';
import { GuiButton, useWindowSize } from '@guibil/components';
import { useEffect, useState } from 'react';
import validation from './validation';
import moment from 'moment';
import { commonCSS } from '@guibil/styles';


const NewGames = () => {
	const { width } = useWindowSize();
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [gridHook, setGridHook] = useGuiGridHook();
	const [data, setData] = useGuiFormData();
	const [filterHook, setFilterHook] = useGuiFormHook();
	const filterParams = {
		name: "",
		date_switch: false,
		created_datetime: moment().startOf("day").format(DATE_FORMAT),
		min_created_datetime: "",
		max_created_datetime: "",
	};

	useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

	const handleSetFilter = () => {
		gridHook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
		gridHook?.setQueryParams({
			name: data?.values?.name,
			created_datetime: data?.values?.date_switch ? "" : data?.values?.created_datetime,
			min_created_datetime: data?.values?.date_switch ? data?.values?.min_created_datetime : "",
			max_created_datetime: data?.values?.date_switch ? data?.values?.max_created_datetime : "",
		})
	}

	const handleResetFilter = () => {
		gridHook?.setQueryParams({
			name: "",
			created_datetime: moment().startOf("day").format(DATE_FORMAT),
			min_created_datetime: "",
			max_created_datetime: "",
		});
		filterHook?.fetch();
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<GuiForm
					validationSchema={validation}
					fetchData={() => filterParams}
					setHook={setFilterHook}
					setData={setData}
					showForm={!showFilter}
					title="gameTracker.newGames.appStore.filter.filter"
					labelPath="gameTracker.newGames.appStore.filter"
					disableLeaveFormPrompt
					headerComponent={
						<div>
							<GuiButton
								onClick={() => setShowFilter(!showFilter)}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon={showFilter ? "eye-slash" : "eye"}
							/>
							<GuiButton
								onClick={handleResetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon="undo-alt"
							/>
							<GuiButton
								onClick={handleSetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="success"
								icon="filter"
							/>
						</div>
					}
					hideButtons
					type="writer"
					chainedFieldsAr={[
						{
							targetField: "date_switch",
							onValue: false,
							fieldsToHide: ["min_created_datetime", "max_created_datetime"]
						},
						{
							targetField: "date_switch",
							onValue: true,
							fieldsToHide: ["created_datetime"]
						}
					]}
				>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} lg={3}>
							<GuiText
								field="name"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiSwitch
								field="date_switch"
								passiveText="gameTracker.newGames.appStore.filter.date_switch_date"
								activeText="gameTracker.newGames.appStore.filter.date_switch_between"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="created_datetime"
								disableTime
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<GuiDateTimePicker
								field="min_created_datetime"
								disableTime
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<GuiDateTimePicker
								field="max_created_datetime"
								disableTime
							/>
						</Grid>
					</Grid>
				</GuiForm>
			</Grid>

			<Grid item xs={12}>
				<GuiGrid
					fetchData="games/new_free_games"
					title="gameTracker.newGames.appStore.title"
					labelPath="gameTracker.newGames.appStore"
					setHook={setGridHook}
					queryParams={filterParams}
					columnExtensions={[
						{ columnName: "object_order", width: 66 },
						{ columnName: "game.name", wordWrapEnabled: true },
						{ columnName: "game.artist", wordWrapEnabled: true },
						{ columnName: "game.logo_image_url", width: 76 },
						{ columnName: "game.rate", width: 60 },
						{ columnName: "game.store_rate", width: 100 },
						{ columnName: "game.release_date", width: 140 },
						{ columnName: "game.last_release_date", width: 140 },
						{ columnName: "follow", width: 140 },
					]}
					columns={[
						"object_order",
						"game.logo_image_url",
						"game.name",
						"game.artist",
						"game.rate",
						"game.store_rate",
						"game.release_date",
						"game.last_release_date",
						"follow",
					]}
					hiddenSortFields={["follow"]}
					dateTimeFields={[
						{ targetField: "game.release_date" },
						{ targetField: "game.last_release_date" },
					]}
					customCells={[
						{
							targetFields: ["game.name"], component: (row: any) =>
								<Name
									linkTo={`/games/game/${row.row?.game.id}`}
									name={row.row?.game.name}
									url={row.row?.game.url}
								/>
						},
						{
							targetFields: ["game.artist"], component: (row: any) =>
								<Name
									linkTo={`/makers/maker/${row.row?.game.artist?.id}`}
									name={row.row?.game.artist?.name}
									url={row.row?.game.artist?.url}
								/>
						},
						{
							targetFields: ["game.logo_image_url"], component: (row: any) =>
								<GameLogo logo_image_url={row.row?.game.logo_image_url} />
						},
						{
							targetFields: ["follow"], component: (row: any) =>
								<FollowingStatus
									key={row.row?.game.id}
									isFollowing={row.row?.game.following}
									followUrl={`games/follow_game/${row.row?.game.id}`}
								/>
						},
					]}
				/>
			</Grid>
			<Grid item xs={12} />
		</Grid >
	)
}

export default NewGames
