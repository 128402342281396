import { IEnumBuilder } from '@guibil/app';
import { app_active_passive_enum } from './general/active-passive/enum';
import { app_store_chart_enum } from './general/app-store-chart/enum';
import { date_filter_enum } from './general/date-filter/enum';
import { app_enabled_disabled_enum } from './general/enabled-disabled/enum';
import { app_exists_not_exists_enum } from './general/exist-nonexists/enum';
import { app_store_genre_enum } from './general/app-store-genre/enum';
import { app_language_enum } from './general/language/enum';
import { notification_type_enum } from './general/notification-type/enum';
import { app_on_off_enum } from './general/on-off/enum';
import { play_store_chart_enum } from './general/play-store-chart/enum';
import { play_store_genre_enum } from './general/play-store-genre/enum';
import { app_registered_not_registered_enum } from './general/registered-not-registered/enum';
import { app_yes_no_enum } from './general/yes-no/enum';
import { os_type_enum } from './general/os-type/enum';
import { gid_status_enum } from './general/gid-status/enum';

export type AppEnumTypes =
  | 'AppLanguage'
  | 'AppYesNo'
  | 'AppOnOff'
  | 'AppActivePassive'
  | 'AppEnabledDisabled'
  | 'AppExistsNotExists'
  | "AppRegisteredNotRegistered"
  | "NotificationType"
  | "DateFilter"
  | "OsType"
  | "PlayStoreChart"
  | "PlayStoreGenre"
  | "AppStoreChart"
  | "AppStoreGenre"
  | "GidStatus"


export const app_enum: IEnumBuilder<AppEnumTypes> = {
  AppLanguage: app_language_enum,
  AppYesNo: app_yes_no_enum,
  AppEnabledDisabled: app_enabled_disabled_enum,
  AppOnOff: app_on_off_enum,
  AppActivePassive: app_active_passive_enum,
  AppExistsNotExists: app_exists_not_exists_enum,
  AppRegisteredNotRegistered: app_registered_not_registered_enum,
  NotificationType: notification_type_enum,
  DateFilter: date_filter_enum,
  OsType: os_type_enum,
  PlayStoreChart: play_store_chart_enum,
  PlayStoreGenre: play_store_genre_enum,
  AppStoreChart: app_store_chart_enum,
  AppStoreGenre: app_store_genre_enum,
  GidStatus: gid_status_enum,
};
