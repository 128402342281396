import * as Yup from "yup";
import i18n from "i18next";

export default () =>
  Yup.object().shape({
    username: Yup.string()
      .required()
      .min(6)
      .max(30)
      .matches(/^\w+$/, i18n.t("guibil:form.validation.onlyLettersAndDigits")),
    password: Yup.string().min(8),
    password_confirm: Yup.string()
      .min(8)
      .required()
      .oneOf(
        [Yup.ref("password"), null],
        i18n.t("guibil:form.validation.passwordsMustMatch")
      ),
  });
