// To Find Other properties of theme visit -> https://material-ui.com/customization/default-theme/

const defaultTheme = {
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.

  },
  palette: {
    primary: {
      main: '#10131f',
      contrastText: '#ffffff',
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      light: '#25283a',
      main: '#192734',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#e0dccc',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      paper: '#edeff5',
      default: '#fefefe',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
        button: {
          cursor: 'pointer',
        },
      },
    },
  },
};

export { defaultTheme };
