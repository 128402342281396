import { GuiGrid, useGuiGridData, useGuiGridHook } from '@guibil/grid'
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';
import { Grid } from '@material-ui/core';
import { GuiDateTimePicker, GuiDropdown, GuiForm, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form';
import { GuiButton, guiNotifier, useWindowSize } from '@guibil/components';
import { useEffect, useState } from 'react';
import { useRequest } from '@guibil/api';
import GuiGridRowButton from '@guibil/grid/components/buttons/GuiGridRowButton';
import { commonCSS } from '@guibil/styles';


const Notification = () => {
	const req = useRequest();
	const { width } = useWindowSize();
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [gridHook, setGridHook] = useGuiGridHook();
	const [gridData, setGridData] = useGuiGridData();
	const [data, setData] = useGuiFormData();
	const [filterHook, setFilterHook] = useGuiFormHook();
	const [filterParams, setFilterParams] = useState<any | undefined>({
		game_name: "",
		min_created_datetime: "",
		max_created_datetime: "",
		notification_type: "",
	});

	useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

	const handleSetFilter = () => {
		gridHook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
		gridHook?.setQueryParams({
			game_name: data?.values?.game_name,
			min_created_datetime: data?.values?.min_created_datetime,
			max_created_datetime: data?.values?.max_created_datetime,
			notification_type: data?.values?.notification_type,
		})
	}

	const handleResetFilter = () => {
		setFilterParams(undefined);
		gridHook?.setQueryParams({});
		filterHook?.fetch();
	}

	const Delete = (row: any) => {
		const handleDelete = async () => {
			await req.delete("ps_games/game_notification/" + row.row.id).then(() => {
				gridHook?.fetch();
				guiNotifier().success("gameTracker.notifications.notification.playStore.deleteSuccessMessage");
			});
		};

		const onDeleteClick = () => guiNotifier().confirm({ description: "gameTracker.notifications.notification.playStore.deleteConfirmMessage" }, handleDelete)

		return <GuiGridRowButton onClick={onDeleteClick} type="delete" />
	}

	const handleDeleteAll = async () => {
		await req.delete("ps_games/delete_game_notifications").then(() => {
			gridHook?.fetch();
			guiNotifier().success("gameTracker.notifications.notification.playStore.deleteAllSuccessMessage");
		});
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<GuiForm
					fetchData={() => filterParams}
					setHook={setFilterHook}
					showForm={!showFilter}
					setData={setData}
					title="gameTracker.notifications.notification.playStore.filter.filter"
					labelPath="gameTracker.notifications.notification.playStore.filter"
					disableLeaveFormPrompt
					headerComponent={
						<>
							<GuiButton
								onClick={() => setShowFilter(!showFilter)}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon={showFilter ? "eye-slash" : "eye"}
							/>
							<GuiButton
								onClick={handleResetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon="undo-alt"
							/>
							<GuiButton
								onClick={handleSetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="success"
								icon="filter"
							/>
						</>
					}
					hideButtons
					type="writer"
				>
					{showFilter
						?
						<Grid container spacing={1}>
							<Grid item xs={12} md={6} lg={3}>
								<GuiText
									field="game_name"
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={3}>
								<GuiDropdown
									field="notification_type"
									selectionsEnum="NotificationType"
									showClearOption
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={3}>
								<GuiDateTimePicker
									field="min_created_datetime"
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={3}>
								<GuiDateTimePicker
									field="max_created_datetime"
								/>
							</Grid>
						</Grid>
						:
						null
					}


				</GuiForm>
			</Grid>

			<Grid item xs={12}>
				<GuiGrid
					setData={setGridData}
					fetchData="ps_games/game_notifications"
					title="gameTracker.notifications.notification.playStore.title"
					labelPath="gameTracker.notifications.notification.playStore"
					headerComponent={
						<GuiButton
							onClick={handleDeleteAll}
							confirm="gameTracker.notifications.notification.playStore.deleteAllConfirmMessage"
							disabled={gridData?.list.length === 0}
							text="gameTracker.notifications.notification.playStore.deleteAll"
							variant="cancel"
							icon="trash-alt"
						/>
					}
					setHook={setGridHook}
					columnExtensions={[
						{ columnName: "object_order", width: 66 },
						{ columnName: "type", width: 150 },
						{ columnName: "created_datetime", width: 140 },
						{ columnName: "game.logo_image_url", width: 76 },
						{ columnName: "game.name", wordWrapEnabled: true },
						{ columnName: "game.developer", wordWrapEnabled: true },
						{ columnName: "game.rate", width: 60 },
						{ columnName: "game.store_rate", width: 110 },
						{ columnName: "game.release_date", width: 140 },
						{ columnName: "game.last_release_date", width: 140 },
						{ columnName: "actions", width: 60 },
					]}
					hiddenSortFields={[
						"game.logo_image_url",
						"actions",
					]}
					columns={[
						"object_order",
						"type",
						"created_datetime",
						"game.logo_image_url",
						"game.name",
						"game.developer",
						"game.rate",
						"game.store_rate",
						"game.release_date",
						"game.last_release_date",
						"actions",
					]}
					enumCells={[
						{ targetField: "type", enumObject: "NotificationType" }
					]}
					dateTimeFields={[
						{ targetField: "created_datetime" },
						{ targetField: "game.release_date" },
						{ targetField: "game.last_release_date" },
					]}
					customCells={[
						{
							targetFields: ["game.name"], component: (row: any) =>
								<Name
									linkTo={`/games/game/${row.row?.game?.id}`}
									name={row.row?.game?.name}
									url={row.row?.game?.url}
								/>
						},
						{
							targetFields: ["game.developer"], component: (row: any) =>
								<Name
									linkTo={`/makers/maker/${row.row?.game?.developer?.id}`}
									name={row.row?.game?.developer?.name}
									url={row.row?.game?.developer?.url}
								/>
						},
						{
							targetFields: ["game.logo_image_url"], component: (row: any) =>
								<GameLogo logo_image_url={row.row?.game?.logo_image_url} />
						},
						{
							targetFields: ["actions"], component: Delete
						},
					]}
				/>
			</Grid>
		</Grid >
	)
}

export default Notification
