import React, { useContext } from 'react';
import { IChainedFieldsToHide, IChainedFieldsToDisable } from '../form/features/chained-fields/GuiChainedFields';
import { IFieldOnChangeCallback } from '../form-elements/hocs/withGuiFormItem';
import { IGuiFormType } from '../types';

export interface IGuiFormElementContext {
  labelPath?: string,
  formState: | 'monitoring' | 'updating',
  fieldsToHide: IChainedFieldsToHide,
  fieldsToDisable: IChainedFieldsToDisable,
  roles: IGuiApp.IRole,
  isSubmitting: boolean,
  formElementOnChange: IFieldOnChangeCallback,
  formType: IGuiFormType,
  requiredFields: string[],
}

export const GuiFormElementContext = React.createContext<IGuiFormElementContext | undefined>(undefined);

const emptyValue: IGuiFormElementContext = {
  roles: {},
  isSubmitting: false,
  formState: 'monitoring',
  formElementOnChange: () => { },
  fieldsToHide: {},
  fieldsToDisable: {},
  formType: 'update',
  requiredFields: [],
};

/**
 * if formElement is controlled, then it must not require any form context
 *
 * @param controlled
 */
export const useGuiFormElementContext = (controlled?: boolean) => {
  const context = useContext(GuiFormElementContext);
  if (typeof (context) === 'undefined') {
    if (controlled) {
      return emptyValue;
    }

    throw new Error('Please wrap all form elements with GuiForm');
  }

  return context;
};
