import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IconProp } from '@fortawesome//fontawesome-svg-core';
import { ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GuiTimeIntervalDropdown } from './GuiTimeIntervalDropdown';
import { GuiButton } from './GuiButton';
import { commonCSS } from '@guibil/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 10,
    },
  }
}));

export interface IGuiRefreshButtonProps {
  interval?: number;
  intervals?: number[],
  onBeforeRefresh?: Function;
  onRefresh: Function;
  onAfterRefresh?: Function;
  hideIntervalOptions?: boolean;
  stop?: boolean;
  disabled?: boolean;
  autoRefresh?: boolean;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  hide?: boolean;
  icon?: IconProp;
  refreshKey?: any;
  pause?: boolean;
  nativeProps?: ButtonProps,
  isRefreshing?: boolean
}

function GuiRefreshButton(props: IGuiRefreshButtonProps) {
  const classes = useStyles();

  const {
    label,
    icon,
    hide,
    pause,
    onRefresh,
    onBeforeRefresh,
    onAfterRefresh,
    autoRefresh,
    interval,
    intervals,
    size,
    disabled,
    hideIntervalOptions,
    nativeProps,
    isRefreshing
  } = props;

  const [completedPercent, setCompletedPercent] = useState(0);
  const [reload, setReload] = useState(false);
  const [count, setCount] = useState(0);
  const [refreshInterval, setRefreshInterval] = useState(interval || 10000);
  const [refreshing, setRefreshing] = useState(false);

  const [stp, setStp] = useState(!!pause);
  const [lastTime, setLastTime] = useState(0);

  const TIMER_INTERVAL = 400;

  useEffect(() => {
    setCompletedPercent(0);
    setCount(0);
  }, [onRefresh])

  useEffect(() => {
    if (props.pause) {
      setStp(true);
      setReload(!reload);
      setLastTime(refreshInterval - count * TIMER_INTERVAL);
    } else {
      setStp(false);
      setCount(count + 1);
      setReload(!reload);
    }
  }, [props.pause]);

  // refreshKey
  useEffect(() => {
    (async () => {
      if (
        !refreshing
        && !stp
        && !disabled
        && props.refreshKey
      ) {
        if (onBeforeRefresh) await onBeforeRefresh();
        setCompletedPercent(100);
        setCount(refreshInterval / TIMER_INTERVAL);
        setRefreshing(true);
        await onRefresh({});
        setCount(0);
        setReload(!reload);
        setRefreshing(false);
        if (onAfterRefresh) await onAfterRefresh();
      }
    })();
  }, [props.refreshKey]);

  // Timer for Render
  useEffect(() => {
    if (refreshInterval > 0 && autoRefresh && !stp) {
      setLastTime(0);
      const timer = setTimeout(() => {
        const delta = 0.12 * (10000 / refreshInterval);

        setCount(count + 1 + delta);
        setCompletedPercent(
          Math.min(((count * TIMER_INTERVAL) / refreshInterval) * 100, 100),
        );
      }, TIMER_INTERVAL);

      return () => {
        clearTimeout(timer);
      };
    }
    return () => { };
  }, [count, onRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  // Timer for Refresh
  useEffect(() => {
    if (refreshInterval > 0 && autoRefresh && !stp) {
      const timer = setTimeout(
        async () => {
          setCompletedPercent(100);
          (async () => {
            if (onRefresh) {
              if (onBeforeRefresh) await onBeforeRefresh();

              setRefreshing(true);
              await onRefresh({});
              setCount(0);
              setReload(!reload);
              setRefreshing(false);
              if (onAfterRefresh) await onAfterRefresh();
            }
          })();
        },
        lastTime !== 0 ? lastTime : refreshInterval,
      );

      return () => {
        clearTimeout(timer);
      };
    }
    return () => { };
  }, [reload, onRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  if (hide) return null;

  return (
    <div className={classes.root}>
      <GuiButton
        onClick={() => {
          if (!refreshing && !stp) {
            (async () => {
              if (onRefresh) {
                if (onBeforeRefresh) await onBeforeRefresh();
                setCompletedPercent(100);
                setCount(refreshInterval / TIMER_INTERVAL);
                setRefreshing(true);
                await onRefresh({});
                setCount(0);
                setReload(!reload);
                setRefreshing(false);
                if (onAfterRefresh) await onAfterRefresh();
              }
            })();
          }
        }}
        size={size}
        disabled={!!disabled}
        isLoading={refreshing || isRefreshing}
        icon={icon}
        text={label || 'guibil:components.guiRefreshButton.text'}
        nativeProps={{
          // variant: "contained",
          color: 'secondary',
          classes: {
            label: commonCSS.progressContainer,
            root: commonCSS.refreshButton
          },
          ...nativeProps,
        }}
      >
        {refreshInterval >= 1000 && autoRefresh && (
          <LinearProgress
            variant="determinate"
            value={completedPercent}
            color="primary"
          />
        )}
      </GuiButton>

      {
        autoRefresh
        && refreshInterval >= 1000
        && !hideIntervalOptions && (
          <GuiTimeIntervalDropdown
            intervals={intervals}
            defaultInterval={refreshInterval ? refreshInterval / 1000 : interval ? Math.round(interval / 1000) : 10}
            disabled={disabled}
            onRefreshTimeChange={(t: number) => {
              setRefreshInterval(t);
              setCount(0);
              setCompletedPercent(0);
              setReload(!reload);
            }}
          />
        )
      }
    </div>
  );
}

export { GuiRefreshButton };
