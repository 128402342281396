export const play_store_genre_tr = {
  "GAME": "Oyunlar",
  "GAME_ARCADE": "Arcade",
  "GAME_PUZZLE": "Bulmaca",
  "GAME_CARD": "Kart",
  "GAME_CASUAL": "Gündelik",
  "GAME_RACING": "Yarış",
  "GAME_SPORTS": "Spor",
  "GAME_ACTION": "Aksiyon",
  "GAME_ADVENTURE": "Macera",
  "GAME_BOARD": "Skor",
  "GAME_CASINO": "Kumar",
  "GAME_EDUCATIONAL": "Eğitsel",
  "GAME_MUSIC": "Müzik",
  "GAME_ROLE_PLAYING": "Rol",
  "GAME_SIMULATION": "Simülasyon",
  "GAME_STRATEGY": "Strateji",
  "GAME_TRIVIA": "Trivia",
  "GAME_WORD": "Kelime",
};
