import { GuiEnums, useLang } from '@guibil/app';
import { classNames } from '@guibil/components';
import { isNotNullOrUndefined, mapArrayOrObject } from '@guibil/helpers';
import { commonCSS } from '@guibil/styles';
import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import React, { useCallback } from 'react';
import { IDefaultFormElementProps } from '../../hocs/withGuiFormItem';
import { IGuiDropdownProps } from '../GuiDropdown';

type IProps = IGuiDropdownProps & IDefaultFormElementProps;

export const GuiDropdownDefault: React.FC<IProps> = (props) => {
  const {
    disabled, className, showClearOption, fieldValue, fieldRequired, helperMessages, disabledValues,
    // eslint-disable-next-line unused-imports/no-unused-vars
    fieldError, fieldOnChange, field, label, selections, selectionsEnum, fieldEditMode, ...otherProps
  } = props;
  const lang = useLang();
  const classes = useStyles();

  const handleFieldChange = useCallback((e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    fieldOnChange(e.target.value as string);
  }, [fieldOnChange]);

  const dropdownOptionsContainer = mapArrayOrObject(selections, (optionValue, key, type) => {
    let value = key;

    if (type === 'array') { value = optionValue; }
    if (typeof (selectionsEnum) !== 'undefined') { optionValue = GuiEnums.get(selectionsEnum, value); }

    const disabled = disabledValues?.length ? disabledValues.includes(optionValue) : false;

    return (
      <MenuItem key={value} value={value} disabled={disabled}>
        {optionValue}
      </MenuItem>
    );
  });

  let clearDropdownOption = null;
  if (showClearOption) {
    clearDropdownOption = (
      <MenuItem className={commonCSS.dropdownClearItem} value="">
        {lang('guibil:form.dropdown.clear')}
        {' '}
      </MenuItem>
    );
  }


  return (
    <FormControl
      // variant="outlined"
      margin="normal"
      className={classNames(commonCSS.guiDropDown, disabled && commonCSS.guiDropDownDisabled, className)}
      error={isNotNullOrUndefined(fieldError)}
    >
      <InputLabel className={classNames(commonCSS.guiDropDownLabel, fieldRequired && [commonCSS.requiredLabel, commonCSS.requiredDropdownLabel])} shrink>{label}</InputLabel>
      <Select
        variant="outlined"
        fullWidth
        input={<OutlinedInput labelWidth={100} name={field} />}
        value={isNotNullOrUndefined(fieldValue) ? fieldValue : ''}
        onChange={handleFieldChange}
        disabled={disabled}
        {...otherProps}
      >
        {clearDropdownOption}
        {dropdownOptionsContainer}
      </Select>
      <FormHelperText className={commonCSS.formHelper}>{fieldError}</FormHelperText>
      {helperMessages?.map(({ text, variant }) => {
        return <FormHelperText
          error={variant === "danger"}
          key={text}
          className={classNames(commonCSS.formHelper, variant === "success" && classes.success, variant === "warning" && classes.warning)}
        >
          {lang(text)}
        </FormHelperText>
      })}
    </FormControl>
  );
}

const useStyles = makeStyles({
  success: {
    color: "var(--primarySuccess)"
  },
  warning: {
    color: "var(--primaryWarning)"
  }
});

