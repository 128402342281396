import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { guiIsEqual } from '@guibil/helpers';
import { withGuiFormItem, IGuiFormElementCommonProps, IDefaultFormElementProps } from '../hocs/withGuiFormItem';
import { GuiText } from './GuiText';
import { GuiDropdown } from './GuiDropdown';

interface IElementSpecificProps {
  hemisphereSelection: { [key: string]: string | number },
}
type IProps = IElementSpecificProps & IDefaultFormElementProps;

const GuiLongLatField: FunctionComponent<IProps> = (props) => {
  const {
    controlled, hemisphereSelection, fieldEditMode,
    label, tooltip, field, className,
    fieldValue, ...otherProps
  } = props;

  if (fieldEditMode) {
    return (
      <>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Field
              name={`${field}.degree`}
              render={({ field }: any) => (
                <GuiText
                  field={field.name}
                  controlled={controlled}
                  tooltip={tooltip}
                  type="number"
                  measurementLabel="°"
                  {...otherProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Field
              name={`${field}.minute`}
              render={({ field }: any) => (
                <GuiText
                  field={field.name}
                  controlled={controlled}
                  tooltip={tooltip}
                  type="number"
                  measurementLabel="'"
                  {...otherProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Field
              name={`${field}.second`}
              render={({ field }: any) => (
                <GuiText
                  field={field.name}
                  controlled={controlled}
                  tooltip={tooltip}
                  type="number"
                  measurementLabel="''"
                  {...otherProps}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Field
            name={`${field}.hemisphere`}
            render={({ field }: any) => (
              <GuiDropdown
                field={field.name}
                controlled={controlled}
                tooltip={tooltip}
                selections={hemisphereSelection}
                {...otherProps}
              />
            )}
          />
        </Grid>
      </>
    );
  }

  let textToDisplay = `${fieldValue.degree}° ${fieldValue.minute}' ${fieldValue.second}'' `;
  if (typeof (hemisphereSelection) !== 'undefined') {
    textToDisplay += hemisphereSelection[fieldValue.hemisphere];
  } else {
    textToDisplay += fieldValue.hemisphere;
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <GuiText
        controlled
        disabled
        field={field}
        label={label}
        tooltip={tooltip}
        value={textToDisplay}
        editMode={false}
        className={className}
      />
    </Grid>
  );
};

const GuiLongLatFieldComponent = withGuiFormItem<IElementSpecificProps & IGuiFormElementCommonProps>(
  React.memo(GuiLongLatField, (prevProps, nextProps) => guiIsEqual(prevProps, nextProps)),
);

export { GuiLongLatFieldComponent as GuiLongLatField };
