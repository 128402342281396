import { IModuleRoutes } from "@guibil/router/types";
import Dashboard from "pages/dashboard/Dashboard";
import Makers from "pages/makers/maker/Maker";
import MakerDetails from "pages/makers/maker-details/MakerDetails";
import Games from "pages/games/game/Game";
import GameDetails from "pages/games/game-details/GameDetails";
import NewGames from "pages/games/new-games/NewGames";
import TopGames from "pages/games/top-games/TopGames";
import RandomGames from "pages/games/random-games/RandomGames";
import Profile from "pages/profile/views/Profile";
import UserManagement from "pages/user-management/views/UserManagement";

import QueueIcon from '@material-ui/icons/Queue';
import DashboardIcon from "@material-ui/icons/Gamepad";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import CodeIcon from '@material-ui/icons/Code';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import FlareIcon from '@material-ui/icons/Flare';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import LogUserManagement from "pages/log-user-management/views/LogUserManagement";
import { notifications } from "pages/notifications/router";
import { development } from "pages/development/router";


export const authRouterConfig: IModuleRoutes = [
  {
    url: "/",
    title: "gameTracker.dashboard.menuTitle",
    component: Dashboard,
    icon: DashboardIcon,
    exact: true,
  },
  {
    url: "notifications",
    title: "gameTracker.notifications.menuTitle",
    children: notifications,
    icon: NotificationsIcon,
  },
  {
    url: "development",
    title: "gameTracker.development.menuTitle",
    children: development,
    icon: DeveloperModeIcon,
    forbiddenRoles: ["VIEWER"],
  },
  {
    url: "makers",
    title: "gameTracker.makers.menuTitle",
    component: Makers,
    icon: CodeIcon,
  },
  {
    url: "makers/maker/:id",
    component: MakerDetails,
    hidden: true
  },
  {
    url: "games",
    title: "gameTracker.games.menuTitle",
    component: Games,
    icon: SportsEsportsIcon,
  },
  {
    url: "games/game/:id",
    component: GameDetails,
    hidden: true
  },
  {
    url: "new_games",
    title: "gameTracker.newGames.menuTitle",
    component: NewGames,
    icon: QueueIcon,
  },
  {
    url: "top_games",
    title: "gameTracker.topGames.menuTitle",
    component: TopGames,
    icon: FlareIcon,
  },
  {
    url: "random_games",
    title: "gameTracker.randomGames.menuTitle",
    component: RandomGames,
    icon: CallMergeIcon,
  },
  {
    url: "profile",
    title: "gameTracker.profile.menuTitle",
    component: Profile,
    hidden: true,
  },
  {
    url: "user_management",
    title: "gameTracker.userManagement.menuTitle",
    component: UserManagement,
    hidden: true,
  },
  {
    url: "log_user_management",
    title: "gameTracker.logUserManagement.menuTitle",
    component: LogUserManagement,
    hidden: true,
  },
];
