import { IAlarmSummary } from '@guibil/app/shared-types';
import { DATE_TIME_HUMAN_FORMAT } from '@guibil/form';
import moment from 'moment';
import { IModuleRoutes, IModuleRouteItem } from '../types';

export function convertGroupIntoOneLevel(config: IModuleRoutes, curPath: string, oneLevel: IModuleRouteItem[] = []): IModuleRouteItem[] {
  config.forEach((configItem) => {
    const { url } = configItem;
    const joinedPath = joinPath(curPath, url);
    const {
      module, params, children, ...other
    } = configItem;

    oneLevel.push({
      params,
      module,
      path: joinedPath,
      ...other,
      children: undefined,
    });

    if (Array.isArray(children)) {
      // pass parameters from parent to all children routes
      const newChildren = [...children];
      newChildren.forEach((item, key) => {
        newChildren[key].params = newChildren[key].params || params;
        newChildren[key].module = newChildren[key].module || module;
      });
      convertGroupIntoOneLevel(newChildren, joinedPath, oneLevel);
    }
  });

  return oneLevel;
}

function joinPath(original: string, addPath: string) {
  let result = original;
  result += addPath ? (`/${addPath}`) : '';

  result = result.replace(/\/\//, '/'); // replace `//` to `/`

  return result;
}

export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find(key => object[key] === value);
}

export function arrayToString(target: Array<string> | string | undefined, defaultStr = '') {
  if (Array.isArray(target)) {
    return target[0] || defaultStr;
  }
  return target || defaultStr;
}

export const findHighestAlarmSeverity = (summary: IAlarmSummary) => {
  if (summary.criticalActiveAlarmCount > 0) return "Critical";
  else if (summary.majorActiveAlarmCount > 0) return "Major";
  else if (summary.minorActiveAlarmCount > 0) return "Minor";
  else if (summary.warningActiveAlarmCount > 0) return "Warning";
  else if (summary.informationActiveAlarmCount > 0) return "Information";
}


export const formatDateTime = (datetimeStr: string) => {
  const momentValue = moment(datetimeStr);
  return momentValue.isValid() ? momentValue.format(DATE_TIME_HUMAN_FORMAT) : "-";
}