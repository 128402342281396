import React from 'react'

const Account = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.857" height="20" viewBox="0 0 22.857 20">
      <path id="Icon_awesome-window-maximize" data-name="Icon awesome-window-maximize" d="M20.714,2.25H2.143A2.143,2.143,0,0,0,0,4.393V20.107A2.143,2.143,0,0,0,2.143,22.25H20.714a2.143,2.143,0,0,0,2.143-2.143V4.393A2.143,2.143,0,0,0,20.714,2.25ZM20,9.393H2.857V5.643a.537.537,0,0,1,.536-.536H19.464A.537.537,0,0,1,20,5.643Z" transform="translate(0 -2.25)" fill="currentColor" />
    </svg>

  )
}

export default Account
