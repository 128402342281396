import React, { useContext } from 'react';

export type ILang = (message: string, params?: { [key: string]: any }) => string;

const LanguageContext = React.createContext<ILang | undefined>(undefined);

const useLang = (): ILang => {
  const context = useContext(LanguageContext);
  if (!context) {
    return (message: string) => message;
  }

  return context;
};

export { LanguageContext, useLang };
