import { classNames } from '@guibil/components';
import { useGuiFormStateContext } from '@guibil/form/contexts/GuiFormStateContext';
import { GuiFormTitle } from '@guibil/form/form-elements/elements/GuiFormTitle';
import { commonCSS } from '@guibil/styles';
import { makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { useGuiFormPropsContext } from '../../contexts/GuiFormPropsContext';
import { GuiFormConfiguration } from '../behaviors/configuration/GuiFormConfiguration';
import { GuiFormDefault } from '../behaviors/default/GuiFormDefault';
import GuiFormBehaviorListener from '../behaviors/event-listener/GuiFormBehaviorListener';
import { GuiFormKeyboardEvents } from '../behaviors/event-listener/GuiFormKeyboardEvents';
import { GuiFormUserConfigsListener } from '../behaviors/event-listener/GuiFormUserConfigsListener';
import GuiFormPromptLeave from '../behaviors/prompt-leave/GuiFormPromptLeave';
import { GuiFormRequest } from '../behaviors/requests/GuiFormRequest';
import { GuiFormStateSubscribers } from '../behaviors/state-subscribers/GuiFormStateSubscribers';
import { GuiFormButtons } from '../components/GuiFormButtons';
import GuiFormChildrenOrLoaderRenderer from '../components/GuiFormChildrenOrLoaderRenderer';
import GuiFormSaveRequestError from '../components/GuiFormSaveRequestError';

interface IProps { }

const GuiFormCoreContainer: FC<IProps> = (props) => {
  const classes = useStyles();
  const {
    title, extraButtonsPosition, showForm, isDialogForm, titlePosition, paperHeight, headerComponent, grouped
  } = useGuiFormPropsContext();

  const { loadingState } = useGuiFormStateContext();

  const isLoaded = loadingState === 'idle' || loadingState === 'refreshing';
  const verticalPosition = extraButtonsPosition?.includes("bottom") ? "bottom" : "top";
  const horizontalPosition = extraButtonsPosition?.includes("left") ? "left" : "right";

  return (
    <div className={commonCSS.guiFormWrapper} >
      <GuiFormRequest />
      <GuiFormConfiguration />
      <GuiFormDefault />
      <GuiFormKeyboardEvents />
      <GuiFormUserConfigsListener />
      <GuiFormBehaviorListener />
      <GuiFormStateSubscribers />

      {/* WHEN IT'S A DIALOG FORM WE WILL USE DIALOG TITLE AS A FORM TITLE */}
      {(!isDialogForm && title)
        && (
          <div className={classNames(classes.formBar, /*titlePosition === 'center' && classes.center,*/ horizontalPosition === "right" && commonCSS.actionBarRight)}>
            {/* <GuiFormSaveRequestError /> */}
            <GuiFormTitle title={title} />
            {verticalPosition === 'top' && <GuiFormButtons />}
            {headerComponent}
          </div>
        )}

      <div className={classNames(commonCSS.guiFormOutline, (verticalPosition === 'bottom') ? classes.bottomFormOutline : classes.topFormOutline)}>
        <GuiFormPromptLeave />

        {(title && isDialogForm) && <span style={{ margin: 30 }}><GuiFormTitle title={title} /></span>}
        <Paper
          className={classNames(
            commonCSS.formPaper,
            showForm ? commonCSS.hiddenFormPaper : null,
            grouped && classes.groupedPaper, isDialogForm && classNames(commonCSS.noShadow),
            isDialogForm ? commonCSS.formPaperDialog : null,
          )}
          style={{ minHeight: paperHeight }}
        >
          <GuiFormChildrenOrLoaderRenderer isLoaded={isLoaded}>
            {props.children}
          </GuiFormChildrenOrLoaderRenderer>

          <GuiFormSaveRequestError />

          {isDialogForm && <div className={classNames(classes.actions, isDialogForm && classes.dialogActions)} style={paperHeight ? { marginTop: 'auto' } : {}}>
            <GuiFormButtons />
            {headerComponent}
          </div>}
        </Paper>

        {/* BOTTOM BUTTONS */}
        {(verticalPosition === 'bottom' && !isDialogForm)
          && (
            <div className={classNames(classes.formBar, titlePosition === 'center' && classes.center)}>
              <GuiFormButtons />
            </div>
          )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  groupedPaper: {
    padding: "10px",
  },
  formBar: {
    display: 'flex',
    alignItems: "center",
    fontWeight: "bold",
    color: 'var(--titleColor)',
    '& button': {
      color: 'var(--titleColor)',
      transition: "none"
    },
  },
  center: {
    justifyContent: 'center',
  },
  bottomFormOutline: {
    '& > .MuiPaper-root': {
      // paddingBottom: 40,
    },
    '& > div:nth-child(2)': {
      marginTop: -15,
    },
  },
  topFormOutline: {
    '& > .MuiPaper-root': {
    },
    "& > div:nth-child(1)": {
    }
  },
  title: {
    margin: 0,
    padding: '1rem 0.6rem',
    fontSize: 24,
    color: 'inherit',
    position: 'absolute',
    top: 0,
  },
  actions: {
    marginTop: 25,
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
  },
}));

export { GuiFormCoreContainer };
