import { useRequest } from '@guibil/api'
import { guiNotifier } from '@guibil/components'
import { GuiForm } from '@guibil/form'
import { GuiRichText } from '@guibil/form/form-elements/elements/GuiRichText'
import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import GuiGridRowButton from '@guibil/grid/components/buttons/GuiGridRowButton'
import { makeStyles } from '@material-ui/styles'
import FollowingStatus from 'pages/components/FollowingStatus'
import GameLogo from 'pages/components/GameLogo'
import Name from 'pages/components/Name'
import { useState } from 'react'
import AddEvaluation from './components/AddEvaluation'
import EditEvaluation from './components/EditEvaluation'

const Evaluation = () => {
  const req = useRequest();
  const classes = useStyles();
  const [hook, setHook] = useGuiGridHook();
  const [addDialog, setAddDialog] = useState<boolean>(false);
  const [editDialog, setEditDialog] = useState<{ game_id: string, comment: string } | null>(null);

  return <GuiGrid
    fetchData="games/evaluations"
    setHook={setHook}
    title="gameTracker.development.evaluation.title"
    labelPath="gameTracker.development.evaluation"
    extraButtons={[
      {
        onClick: () => setAddDialog(true),
        text: "gameTracker.development.evaluation.add.new",
        icon: "plus-circle",
        variant: "success"
      }
    ]}
    columnExtensions={[
      { columnName: "object_order", width: 66 },
      { columnName: "game.logo_image_url", width: 76 },
      { columnName: "game.name", width: 240 },
      { columnName: "created_datetime", width: 150 },
      { columnName: "comment", wordWrapEnabled: true },
      { columnName: "follow", width: 120 },
      { columnName: "action", width: 86 },
    ]}
    columns={[
      "object_order",
      "game.logo_image_url",
      "game.name",
      "created_datetime",
      "comment",
      "follow",
      "action",
    ]}
    hiddenSortFields={[
      "game.logo_image_url",
      "follow",
      "comment",
      "action",
    ]}
    dateTimeFields={[
      { targetField: "created_datetime" },
    ]}
    customCells={[
      {
        targetFields: ["game.logo_image_url"], component: (row: any) =>
          row.row?.game ? <GameLogo logo_image_url={row.row?.game?.logo_image_url} /> : null
      },
      {
        targetFields: ["game.name"], component: (row: any) =>
          row.row?.game ? <Name
            linkTo={`/games/game/${row.row?.game?.id}`}
            name={row.row?.game?.name}
            url={row.row?.game?.url}
          /> : row.row?.game_id
      },
      {
        targetFields: ["follow"], component: (row: any) =>
          row.row?.game ? <FollowingStatus
            key={row.row?.game?.id}
            isFollowing={row.row?.game?.following}
            followUrl={`games/follow_game/${row.row?.game?.id}`}
          /> : null
      },
      {
        targetFields: ["action"], component: (row: any) =>
          <>
            <GuiGridRowButton
              onClick={() => setEditDialog({
                game_id: row.row?.game_id,
                comment: row.row?.comment
              })}
              type="update"
            />
            <GuiGridRowButton
              onClick={() => {
                guiNotifier().confirm({ description: "gameTracker.development.evaluation.deleteConfirmMessage" }, () => {
                  req.delete("games/delete_evaluation/" + row.row?.id)
                    .then(() => hook?.fetch())
                    .catch(err => guiNotifier().handleError(err))
                })
              }}
              type="delete"
            />
          </>
      },
      {
        targetFields: ["comment"], component: (row: any) =>
          <GuiForm
            type="reader"
            fetchData={() => {
              return {
                comment: row.row.comment
              }
            }}
          >
            <GuiRichText className={classes.richText} field="comment" editMode={false} />
          </GuiForm>
      },
    ]}
  >
    <AddEvaluation
      isOpen={addDialog}
      onClose={() => setAddDialog(false)}
      onSuccess={() => { setAddDialog(false); hook?.fetch(); }}
    />
    {
      editDialog && <EditEvaluation
        game_id={editDialog.game_id}
        comment={editDialog.comment}
        onClose={() => setEditDialog(null)}
        onSuccess={() => { setEditDialog(null); hook?.fetch(); }}
      />
    }
  </GuiGrid>
}

const useStyles = makeStyles(() => ({
  richText: {
    backgroundColor: "transparent !important",
    opacity: "1 !important",
    "& > div:nth-child(2)": {
      minHeight: "0px !important",
      fontFamily: "inherit",
    }
  },
}));

export default Evaluation
