import { useAutocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { useLang } from '@guibil/app';
import { LoadingComponent } from '@guibil/components';

export interface TagProps {
  tag: string,
  slug: string
}

interface TaggerProps {
  selecteds: TagProps[],
  availables: TagProps[],
  label: string,
  handleSelectedTags?: (tags: TagProps[]) => void,
  handleSingleSelectedTag?: (tags: TagProps) => void,
  disabled?: boolean;
  isLoading?: boolean;
}

const Tagger: React.FC<TaggerProps> = ({
  selecteds,
  availables,
  label,
  handleSelectedTags,
  handleSingleSelectedTag,
  disabled,
  isLoading
}) => {
  const lang = useLang();
  const classes = useStyles();

  const [selectedTags, setSelectedTags] = useState<TagProps[]>(selecteds);
  const [availableTags, setAvailableTags] = useState<TagProps[]>(availables);

  useEffect(() => { setSelectedTags(selecteds) }, [selecteds]);
  useEffect(() => { setAvailableTags(availables) }, [availables]);

  const handleOnChange = (_: any, newSelectedTags: TagProps[]) => {
    if (handleSelectedTags) {
      handleSelectedTags(newSelectedTags);
    }

    if (handleSingleSelectedTag) {
      const added = newSelectedTags.filter(newTag => !selectedTags.some(oldTag => (oldTag.slug === newTag.slug)));
      const removed = selectedTags.filter(newTag => !newSelectedTags.some(oldTag => (oldTag.slug === newTag.slug)));
      if (added?.length > 0) {
        handleSingleSelectedTag(added[0]);
      } else if (removed?.length > 0) {
        const hasAny = availableTags.filter(tag => !removed.some(removedTag => (tag.slug !== removedTag.slug)));
        if (hasAny?.length === 0) setAvailableTags(availableTags.concat(removed[0]));
        handleSingleSelectedTag(removed[0]);
      }
    };

    setSelectedTags(newSelectedTags);
  }

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'tagger',
    value: selectedTags,
    defaultValue: selectedTags,
    multiple: true,
    options: availableTags,
    onChange: handleOnChange,
    filterSelectedOptions: true,
    getOptionLabel: (tag) => tag.tag,
  });


  const Tag = (props: any) => {
    const { label, onDelete, ...other } = props;
    return (
      <div className={classes.tag} {...other}>
        <span>{label}</span>
        {!disabled && <CloseIcon onClick={onDelete} />}
      </div>
    );
  }

  return (
    <div style={{ flex: 1 }} >
      <div className={classes.label}>{lang(label)}</div>
      <div className={classNames(classes.root, disabled ? classes.disabled : undefined)}>
        <div {...getRootProps()}>
          <div ref={setAnchorEl} className={classNames(classes.inputWrapper, focused ? 'focused' : '')}>
            {isLoading && <div className={classes.loadingStyle}>
              <LoadingComponent />
            </div>}
            {
              value.map((option, index) => (
                <Tag key={index} label={option.tag} {...getTagProps({ index })} />
              ))
            }
            {!isLoading && <input disabled={disabled} placeholder={disabled ? "" : "..."} {...getInputProps()} />}
          </div>
          {groupedOptions.length > 0 ? (
            <ul className={classes.listBox} {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li key={index} {...getOptionProps({ option, index })}>
                  <span>{option.tag}</span>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  loadingStyle: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0px !important",
    marginTop: "-1.5px !important",
    marginBottom: "-1.5px !important",

    "& .MuiCircularProgress-svg": {
      transform: "scale(0.75)"
    }
  },
  root: {
    marginTop: "6px",
    position: "relative",
    backgroundColor: "var(--bgColor) !important",
    borderRadius: "2px !important",
    fontSize: "12px",
    width: "100%",
    color: "var(--defaultTextColor)",
  },
  disabled: {
    opacity: 0.70,
  },
  label: {
    paddingTop: "4px",
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
  },
  inputWrapper: {
    borderRadius: "2px",
    padding: "0px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    alignItems: "center",
    color: "var(--defaultTextColor) !important",
    background: "transparent !important",

    "& input": {
      color: "var(--defaultTextColor) !important",
      background: "transparent !important",
      caretColor: "var(--defaultTextColor)",
      border: "0px",
      borderRadius: "2px",
      height: "36px",
      fontWeight: 800,
      paddingLeft: "5px",
      paddingRight: "5px",
      flexGrow: 1,
      margin: "0px 2.5px",
      outline: "0",
    },
  },
  tag: {
    marginLeft: "4px",
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    minHeight: "28px",
    margin: "2px",
    lineHeight: "22px",
    backgroundColor: "var(--bgPrimary)",
    borderRadius: "2px",
    boxSizing: "content-box",
    padding: "0 10px 0 10px",
    outline: "0",
    overflow: "hidden",

    "& svg": {
      fontSize: "16px",
      cursor: "pointer",
      paddingLeft: "10px",
    }
  },
  listBox: {
    position: "absolute",
    zIndex: 2,
    fontSize: "15px",
    fontWeight: 600,
    padding: "8px 0px",
    width: "100%",
    listStyle: "none",
    backgroundColor: "var(--bgColor)",
    overflow: "auto",
    maxHeight: "250px",
    borderRadius: "2px",
    boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    "& li": {
      padding: "10px 20px",
    },

    "& li[data-focus='true']": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      color: "var(--titleColor) !important",
    }
  },
});

export default Tagger
