import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import { Grid } from '@material-ui/core';
import { DATE_FORMAT, GuiDateTimePicker, GuiDropdown, GuiForm, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form';
import { GuiButton, useWindowSize } from '@guibil/components';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { commonCSS } from '@guibil/styles';
import { AppStoreGenre } from 'app/enums/app/general/app-store-genre/enum_type';
import GameScreenshots from 'pages/games/game-details/app-store/components/game-screenshots/GameScreenshots';
import { AppStoreChart } from 'app/enums/app/general/app-store-chart/enum_type';

const TopGames = () => {
	const { width } = useWindowSize();
	const [showFilter, setShowFilter] = useState<boolean>(false);

	const [gridHook, setGridHook] = useGuiGridHook();
	const [data, setData] = useGuiFormData();
	const [filterHook, setFilterHook] = useGuiFormHook();
	const [expandAll, setExpandAll] = useState(false);

	const filterParams = {
		name: "",
		chart_type: AppStoreChart.FREE,
		chart_genre: AppStoreGenre[6014],
		previous_datetime: moment().startOf("day").format(DATE_FORMAT),
	};

	useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

	const handleSetFilter = () => {
		gridHook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
		gridHook?.setQueryParams({
			name: data?.values?.name,
			chart_type: data?.values?.chart_type,
			chart_genre: data?.values?.chart_genre,
			previous_datetime: data?.values?.previous_datetime,
		})
	}

	const handleResetFilter = () => {
		gridHook?.setQueryParams({
			chart_type: AppStoreChart.FREE,
			chart_genre: AppStoreGenre[6014],
			previous_datetime: moment().startOf("day").format(DATE_FORMAT),
		});
		filterHook?.fetch();
	}

	const ChangeOfOrder = (row: any) => {
		const { change_of_order } = row.row;
		if (change_of_order === 0) {
			return <div>-</div>
		} else if (change_of_order > 0) {
			return <div style={{ color: "var(--primarySuccess)" }}>+{change_of_order}</div>
		} else {
			return <div style={{ color: "var(--primaryError)" }}>{change_of_order}</div>
		}
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<GuiForm
					fetchData={() => filterParams}
					setHook={setFilterHook}
					setData={setData}
					showForm={!showFilter}
					title="gameTracker.topGames.appStore.filter.filter"
					labelPath="gameTracker.topGames.appStore.filter"
					disableLeaveFormPrompt
					headerComponent={
						<div>
							<GuiButton
								onClick={() => setShowFilter(!showFilter)}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon={showFilter ? "eye-slash" : "eye"}
							/>
							<GuiButton
								onClick={handleResetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon="undo-alt"
							/>
							<GuiButton
								onClick={handleSetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="success"
								icon="filter"
							/>
						</div>
					}
					hideButtons
					type="writer"
				>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} lg={3}>
							<GuiText
								field="name"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="chart_genre"
								selectionsEnum="AppStoreGenre"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="chart_type"
								selectionsEnum="AppStoreChart"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="previous_datetime"
								disableTime
							/>
						</Grid>
					</Grid>
				</GuiForm>
			</Grid>

			<Grid item xs={12}>
				<GuiGrid
					fetchData="games/top_free_games"
					title="gameTracker.topGames.appStore.topFreeGame.title"
					labelPath="gameTracker.topGames.appStore.topFreeGame"
					rowDetailComponent={(row: any) => <GameScreenshots screenshots={row?.row?.screenshots} showVideos={false} />}
					expandAll={expandAll}
					setHook={setGridHook}
					queryParams={filterParams}
					columnExtensions={[
						{ columnName: "order", width: 50 },
						{ columnName: "game.name", wordWrapEnabled: true },
						{ columnName: "game.artist", wordWrapEnabled: true },
						{ columnName: "game.logo_image_url", width: 76 },
						{ columnName: "game.rate", width: 60 },
						{ columnName: "game.store_rate", width: 110 },
						{ columnName: "game.release_date", width: 140 },
						{ columnName: "game.last_release_date", width: 140 },
						{ columnName: "follow", width: 140 },
					]}
					hiddenSortFields={["follow"]}
					columns={[
						"order",
						"game.logo_image_url",
						"game.name",
						"game.artist",
						"game.rate",
						"game.store_rate",
						"game.release_date",
						"game.last_release_date",
						"change_of_order",
						"follow",
					]}
					extraButtons={[
						{
							onClick: () => setExpandAll(!expandAll),
							text: expandAll ? "gameTracker.topGames.appStore.topFreeGame.hideScreenshots" : "gameTracker.topGames.appStore.topFreeGame.showScreenshots",
							icon: "file-image",
							variant: "cancel"
						}
					]}
					dateTimeFields={[
						{ targetField: "game.release_date" },
						{ targetField: "game.last_release_date" },
					]}
					customCells={[
						{
							targetFields: ["order"], component: (row: any) => row?.row?.order != null ? row?.row?.order : "-"
						},
						{
							targetFields: ["game.name"], component: (row: any) =>
								<Name
									linkTo={`/games/game/${row.row?.game?.id}`}
									name={row.row?.game?.name}
									url={row.row?.game?.url}
								/>
						},
						{
							targetFields: ["game.artist"], component: (row: any) =>
								<Name
									linkTo={`/makers/maker/${row.row?.game?.artist?.id}`}
									name={row.row?.game?.artist?.name}
									url={row.row?.game?.artist?.url}
								/>
						},
						{
							targetFields: ["game.logo_image_url"], component: (row: any) =>
								<GameLogo logo_image_url={row.row?.game?.logo_image_url} />
						},
						{
							targetFields: ["change_of_order"], component: ChangeOfOrder
						},
						{
							targetFields: ["follow"], component: (row: any) =>
								<FollowingStatus
									key={row.row?.id}
									isFollowing={row.row?.following}
									followUrl={`games/follow_game/${row.row?.game.id}`}
								/>
						},
					]}
				/>
			</Grid>
			<Grid item xs={12} />
		</Grid >
	)
}

export default TopGames
