import React, { useEffect, useState } from 'react';
import { useSnackbar, OptionsObject, VariantType } from 'notistack';
import { useLang } from '@guibil/app';
import { GuiConfirmationDialog, IGuiConfirmationDialogConfig } from '../GuiConfirmationDialog';
import { GuiGenericLoadingDialog, IGuiGenericLoadingDialogConfig } from '../GuiGenericLoadingDialog';

interface IConfirmPopupState {
  id: symbol,
  config: IGuiConfirmationDialogConfig,
  onConfirm?: () => void,
  onCancel?: () => void,
}

interface ILoaderState {
  id: symbol,
  config: IGuiGenericLoadingDialogConfig,
  onComplete?: () => void,
  type: | 'infinite' | 'finite'
}

interface IProps { }
const GuiNotificationProvider: React.FC<IProps> = (props) => {
  /**
   * confirmPopups - is array because,
   * since users may request more than 1 confirm,
   * before closing previous, we need to be able to show all of them
   */
  const [confirmPopups, setConfirmPopups] = useState<IConfirmPopupState[]>([]);
  const [loaders, setLoaders] = useState<ILoaderState[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const lang = useLang();

  const closeConfirm = (id: symbol) => {
    setConfirmPopups((popups) => popups.filter((popup) => popup.id !== id));
  };

  const closeLoader = (id: symbol) => {
    setLoaders((loaders) => loaders.filter((loader) => loader.id !== id));
  };

  useEffect(() => {
    window.guiNotification = window.guiNotification || {};
  }, []);

  useEffect(() => {
    window.guiNotification.toast = enqueueSnackbar;

    // Sets all other toasts
    const all: VariantType[] = ['success', 'error', 'warning', 'info'];
    all.forEach((variant) => {
      // @ts-ignore
      window.guiNotification[variant] = (message: React.ReactNode, options?: OptionsObject | undefined) => {
        message = typeof (message) === 'string' ? lang(message) : message;
        enqueueSnackbar(message, { variant, ...options });
      };
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    const guiConfirm = (config: IGuiConfirmationDialogConfig, onConfirm?: () => void, onCancel?: () => void) => {
      const id = Symbol();
      const newConfirm: IConfirmPopupState = {
        id,
        config,
        onConfirm: () => { closeConfirm(id); onConfirm?.(); },
        onCancel: () => { closeConfirm(id); onCancel?.(); },
      };
      setConfirmPopups((popups) => [...popups, newConfirm]);
    };

    window.guiNotification.confirm = guiConfirm;
  }, []);

  useEffect(() => {
    const guiLoader = (config: IGuiGenericLoadingDialogConfig, onComplete?: () => void) => {
      const id = Symbol();
      const newLoader: ILoaderState = {
        id,
        config,
        onComplete: () => { closeLoader(id); onComplete?.(); },
        type: 'finite',
      };
      setLoaders((loaders) => [...loaders, newLoader]);
    };
    const guiInfiniteLoader = (config: IGuiGenericLoadingDialogConfig) => {
      const id = Symbol();
      const newLoader: ILoaderState = {
        id,
        config,
        type: 'infinite',
      };
      setLoaders((loaders) => [...loaders, newLoader]);

      return () => { closeLoader(id); };
    };

    window.guiNotification.loader = guiLoader;
    window.guiNotification.infiniteLoader = guiInfiniteLoader;
  }, []);

  const confirmPopupsContainer = confirmPopups.map(({
    onCancel, onConfirm, config, id,
  }) => (
    <GuiConfirmationDialog
      key={id.toString()}
      onConfirm={onConfirm}
      onCancel={onCancel}
      {...config}
    />
  ));

  const loadersContainer = loaders.map(({
    config, id, type, onComplete,
  }) => (
    <GuiGenericLoadingDialog
      key={id.toString()}
      isOpen
      {...config}
      type={type}
      onLoad={onComplete}
    />
  ));

  return (
    <>
      {props.children}
      {confirmPopupsContainer}
      {loadersContainer}
    </>
  );
};

export { GuiNotificationProvider };
