import React, { ReactElement, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Draggable from 'react-draggable';
import { Button } from '@material-ui/core';

import { GuiTooltip } from '@guibil/components/components/GuiTooltip';
import { useLang } from '@guibil/app';
import { commonCSS } from '@guibil/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { GuiButtonsRenderer, IGuiButtonsRenderer } from './GuiButtonsRenderer';
import { guiNotifier } from './notification/GuiNotificationConsumer';
import { LoadingComponent } from './LoadingComponent';

interface IProps {
  isOpen?: boolean,
  children?: ReactElement,
  title?: string,
  description?: string,
  size?: | 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  onClose?: Function,
  onOpen?: Function,
  drag?: boolean,
  fullWidth?: boolean,
  dismissible?: boolean,
  hideCloseButton?: boolean,
  buttons?: IGuiButtonsRenderer,
  confirmBeforeClose?: boolean,
  className?: string;
  isLoading?: boolean;
  extraButtons?: IGuiButtonsRenderer,
}

export const GuiGenericDialog: React.FC<IProps> = (props) => {
  const lang = useLang();
  const {
    isOpen, title, description, buttons, size, onClose, drag, children, fullWidth, dismissible, hideCloseButton, className, confirmBeforeClose, isLoading, extraButtons
  } = props;

  const [open, setOpen] = useState(!!isOpen);

  useEffect(() => {
    setOpen(!!props.isOpen);
  }, [props]);

  const onDialogClose = () => {
    if (confirmBeforeClose) {
      guiNotifier().confirm({ description: "guibil:form.confirmation.leavingUnsaved" }, () => {
        if (onClose) onClose();
        setOpen(false);
      })
    } else {
      if (onClose) onClose();
      setOpen(false);
    }
  }

  return (
    <Draggable axis={drag ? "both" : "none"} handle={"#draggable-dialog-title" + lang(title?.split(".")[0] || "").split(" ")[0]}>
      <Dialog
        open={open}
        onClose={onDialogClose}
        disableEscapeKeyDown={!dismissible}
        disableBackdropClick={!dismissible}
        fullWidth={!fullWidth}
        className={className}
        classes={{ paper: commonCSS.dialogPaper, root: commonCSS.dialogRoot }}
        maxWidth={size || 'sm'}
      >
        <div className={commonCSS.dialogHeader}>
          <div className={commonCSS.dialogClose}>
            {!hideCloseButton && <GuiTooltip title={lang('guibil:components.genericDialog.close')}><Button color="primary" className="close-button" onClick={onDialogClose}><HighlightOffIcon /></Button></GuiTooltip>}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DialogTitle id={"draggable-dialog-title" + lang(title || "").split(" ")[0]} style={drag ? { cursor: "move" } : {}} classes={{ root: commonCSS.dialogTitle }}>{title && lang(title)}</DialogTitle>
            <div style={{ marginTop: "0.7rem" }}>
              <GuiButtonsRenderer buttons={extraButtons} />
            </div>
          </div>
        </div>

        <DialogContent>
          {isLoading ? <LoadingComponent /> :
            <div style={{ overflow: "hidden!important", padding: 10 }}>
              {description && <DialogContentText color="initial" variant="body1" classes={{ root: commonCSS.dialogContent }}>{lang(description)}</DialogContentText>}
              {children}
            </div>
          }
        </DialogContent>

        {buttons
          && (
            <>
              <DialogActions classes={{ root: commonCSS.dialogActions }}>
                <GuiButtonsRenderer buttons={buttons} />
              </DialogActions>
            </>
          )}
      </Dialog>
    </Draggable>
  )
};

GuiGenericDialog.defaultProps = {
  isOpen: true,
};
