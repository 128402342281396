import { Swiper, SwiperSlide } from 'swiper/react';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';

import 'swiper/swiper.scss'
import ZoomDialog from 'pages/components/base64Images/ZoomDialog';
import { useLang } from '@guibil/app';
import classNames from 'classnames';

export interface B64ImageProps {
  id: number,
  value: string,
}

interface GameImagesProps {
  label?: string,
  disabled?: boolean,
  b64encode_images: B64ImageProps[],
}

const GameImages: React.FC<GameImagesProps> = ({ label, disabled, b64encode_images }) => {
  const lang = useLang();
  const classes = useStyles();
  const [zoomImage, setZoomImage] = useState<string | null>(null);

  return <div>
    <div className={classes.label}>
      {label && lang(label)}
    </div>
    {
      <div className={classNames(classes.container, disabled ? classes.disabled : undefined)}>
        <Swiper
          freeMode={false}
          slidesPerView={"auto"}
          spaceBetween={10}
          pagination={{ clickable: true }}
          className={classes.swiperRoot}
        >
          {
            b64encode_images.map((image: B64ImageProps, index: number) => {
              return (
                <SwiperSlide key={index} >
                  <img
                    onClick={() => setZoomImage(image.value)}
                    src={image.value}
                  />

                </SwiperSlide>
              )
            })
          }
        </Swiper>
        {zoomImage && <ZoomDialog image={zoomImage} onClose={() => setZoomImage(null)} />}
      </div>
    }
  </div>
}

const useStyles = makeStyles({
  label: {
    paddingTop: "4px",
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
  },
  container: {
    paddingTop: "5px",
    paddingLeft: "6px",
    marginTop: "5.5px",
    borderRadius: "2px",
    minHeight: "180px",
    backgroundColor: "var(--bgColor)",
  },
  disabled: {
    backgroundColor: "var(--overlayColor)",
  },
  swiperRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    cursor: "grab",
    opacity: "1.0 !important",
    "& .swiper-slide": {
      height: "auto",
      maxHeight: "300px",
      opacity: "1.0 !important",
      display: "flex",
      alignItems: "center",
      width: "auto",
    },

    "& .swiper-slide img": {
      opacity: "1.0 !important",
      maxWidth: "300px",
      maxHeight: "300px"
    }
  },
});

export default GameImages
