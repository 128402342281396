export const profile_tr = {
  breadcrumbTitle: "Profilim",
  pageTitle: "Profilim",
  title: "Profilim",
  menuTitle: "Profilim",
  passwordTitle: "Şifre",

  email: "E-mail",
  first_name: "İsim",
  last_name: "Soyisim",
  password: "Şifre",
  password_confirm: "Şifre Tekrar"
};
