import { GuiGrid } from '@guibil/grid'
import Datetime from 'pages/components/Datetime'
import React from 'react'

interface DailyPlayTimesProps {
  player_id: string
}

const DailyPlayTimes: React.FC<DailyPlayTimesProps> = ({ player_id }) => {
  return (
    <GuiGrid
      fetchData={`installations/player_daily_play_times/${player_id}`}
      labelPath="gameTracker.development.installationDetails.players.playerPlayDetailsDialog.dailyPlayTimes"
      title="gameTracker.development.installationDetails.players.playerPlayDetailsDialog.dailyPlayTimes.title"
      columns={
        [
          "day",
          "playtimes_in_ms",
          "created_datetime",
        ]}
      columnExtensions={
        [
          { columnName: "day", width: 66 },
          { columnName: "playtimes_in_ms", wordWrapEnabled: true },
          { columnName: "created_datetime", width: 180 },
        ]}
      getCellValues={
        [
          {
            targetFields: ["playtimes_formatted"],
            function: (row: any) => row.row?.playtimes_in_ms
          },
        ]}
      dateTimeFields={
        [
          { targetField: "created_datetime" },
        ]}
      customCells={
        [
          {
            targetFields: ["playtimes_in_ms"],
            component: (row: any) => <span>{row.row?.playtimes_formatted}</span>
          },
          {
            targetFields: ["created_datetime"],
            component: (row: any) => <Datetime datetimeString={row?.row?.created_datetime} timeDisabled />
          }
        ]}
    />
  )
}

export default DailyPlayTimes
