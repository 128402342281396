/* eslint no-template-curly-in-string: "off" */
export const guibil_form_tr = {
  confirmation: {
    title: 'Onay',
    submit: 'Değişiklikleri kaydetmek istediğinizden emin misiniz?',
    leavingUnsaved: 'Sayfadan ayrılırsanız kaydetmediğiniz değişiklikler kaybolacaktır!',
  },
  successMessage: "İşleminiz başarılı bir şekilde gerçekleşti",
  successUpdateMessageWithTitle: "'{{title}}' başarı ile güncellendi",
  successCreateMessageWithTitle: "'{{title}}' başarı ile oluşturuldu",
  errorMessage: "İşleminiz gerçekleştirilirken hata oluştu",
  button: {
    save: 'Kaydet',
    close: 'Kapat',
    update: 'Düzenle',
    dialogSave: 'Kaydet',
    reset: "Varsayılanları Getir",
  },
  dateTime: {
    confirmText: 'Tamam',
  },
  dropdown: {
    clear: 'Temizle',
  },
  validation: {
    mixed: {
      default: "'{{element}}' geçerli değildir",
      required: "'{{element}}' alanı boş bırakılamaz",
      oneOf: "'{{element}}' şu değerlerden biri olmalıdır: ${values}",
      notOneOf: "'{{element}}' şu değerlerden biri olmamalıdır: ${values}",
      notType: "'{{element}}' alanı gereklidir",
    },
    string: {
      required: "'{{element}}' alanı boş bırakılamaz",
      length: "'{{element}}' ${length} karakter uzunluğunda olmalıdır",
      min: "'{{element}}' en az ${min} karakter uzunluğunda olmalıdır",
      max: "'{{element}}' en fazla ${max} karakter uzunluğunda olmalıdır",
      matches: "'{{element}}' şunlarla eşleşmelidir: '${regex}'",
      email: "'{{element}}' geçerli bir e-mail olmalıdır",
      url: "'{{element}}' geçerli bir URL olmalıdır",
      trim: "'{{element}}' için fazla boşluklar kaldırılmalıdır",
      lowercase: "'{{element}}' küçük harflerden oluşmalıdır",
      uppercase: "'{{element}}' büyük harflerden oluşmalıdır",
    },
    number: {
      min: "'{{element}}' ${min} değerinden büyük veya eşit olmalıdır",
      max: "'{{element}}' ${max} değerinden küçük veya eşit olmalıdır",
      lessThan: "'{{element}}' ${less} değerinden az olmalıdır",
      moreThan: "'{{element}}' ${more} değerinden büyük olmalıdır",
      notEqual: "'{{element}}' ${notEqual} değerine eşit olmamalıdır",
      positive: "'{{element}}' pozitif bir sayı olmalıdır",
      negative: "'{{element}}' negatif bir sayı olmalıdır",
      integer: "'{{element}}' bir tam sayı olmalıdır",
    },
    date: {
      min: "'{{element}}' alanı ${min} tarihinden sonra olmalıdır",
      max: "'{{element}}' alanı ${max} tarihinden önce olmalıdır",
    },
    object: {
      noUnknown: "'{{element}}' alanı nesne biçiminde belirtilmemiş anahtarlara sahip olamaz",
    },
    array: {
      min: "'{{element}}' alanı en az ${min} öğeye sahip olmalıdır",
      max: "'{{element}}' alanı ${max} veya daha az öğeye sahip olmalıdır",
    },
    onlyLetters: 'Sadece harflerden oluşmalıdır',
    onlyLettersAndDigits: 'Sadece harfler ve sayılardan oluşmalıdır',
    atLeastOneUpperCase: 'En az bir büyük harf içermelidir',
    atLeastOneLowerCase: 'En az bir küçük harf içermelidir',
    atLeastOneDigit: 'En az bir sayı içermelidir',
    atLeastOneSpecial: 'En az bir özel karakter içermelidir',
    passwordsMustMatch: 'Şifreler eşleşmiyor',
    invalidPhoneNumber: 'Telefon numarası geçersiz',
  },

  validIpMessage: 'Geçerli bir IP adresi giriniz',
  validHexMessage: 'Geçerli bir HEX değeri giriniz',
  validMacMessage: 'Geçerli bir MAC adresi giriniz',
  validSubnetMaskMessage: 'Geçerli bir alt ağ adresi giriniz',
  validIpOrDomainMessage: 'Geçerli bir IP adresi veya domain giriniz',
  uniqueIpMessage: 'Benzersiz bir IP adresi girin',

  defaultValue: "Varsayılan Değer '{{defaultValue}}'"

};
