export const gids_en = {
  breadcrumbTitle: "Game Ideas",
  pageTitle: "Game Ideas",
  title: "Game Ideas",
  menuTitle: "Game Ideas",

  addGid: {
    add: "New",
    title: "New Game Implementation Defination",
    save: "Save",
    uploading: "Files Uploading...",
  },

  updateGid: {
    title: "Update Game Implementation Defination",
    uploading: "Files Uploading...",
    update: "Save Changes",
  },

  filterGid: {
    filter: "Filter",
    title: "Title",
    created_by: "Created By",
    genre: "Genre",
    rate: "Rate",
    min_created_datetime: "Min Created Datetime",
    max_created_datetime: "Max Created Datetime",
    min_updated_datetime: "Min Updated Datetime",
    max_updated_datetime: "Max Updated Datetime",
  },

  listGid: {
    title: "Name",
    rate: "Rate",
    created_by: "Created By",
    created_datetime: "Created Date",
    updated_by: "Last Updated By",
    updated_datetime: "Last Updated Date",
    action: " ",
    deleteConfirmMessage: "Are you sure you want to delete this game?",
    deleteSuccessMessage: "Game deleted successfully",
  },

  genres: {
    title: "Genres",
    add: "New",
    edit: "Genres",
    deleteConfirmMessage: "Games belonging to this genre will be deleted! Are you sure you want to delete the selected genre?",
    deleteSuccessMessage: "Genre deleted successfully",
    genre: "Genre",
    actions: " ",
    addGenre: {
      title: "Add New Genre",
      genre: "New Genre Name",
    },
  },

  sections: {
    info: "Information",
    dynamic: "Dynamics",
    mechanic: "Mechanics",
    design: "Design",
  },

  placeHolders: {
    title: "Game name",
    mantra: "One sentence introduction to the game",
    progress: "How do we progress in the game?",
    goal: "What is your goal in the game?",
    challenge: "What challenges are there in the game?",
    core_loop: "What are the core loops of the game?",
    core_mechanic: "What should be the main mechanics of the game?",
    sub_mechanic: "What should be the sub mechanics of the game?",
    description: "How should we design the game?",
    notes: "What are the design notes of the game?",
  },

  validations: {
    progress: "'Progress' field is required",
    goal: "'Goal' field is required",
    challenge: "'Challenge' field is required",
  },

  general: {
    title: "Name",
    status: "Status",
    popularity: "Popularity",
    art_difficulty: "Artistry Difficulty",
    dev_difficulty: "Development Difficulty",
    mantra: "Mantra",
    core_loops: "Core Loops",
    planned_developers: "Planned Developers",
    planned_designers: "Planned Artists",
  },

  dynamic: {
    genre: "Genre",
    progress: "Progress",
    goal: "Goal",
    challenge: "Challenge",
  },

  mechanic: {
    cores: "Core Mechanics",
    subs: "Sub Mechanics",
  },

  design: {
    description: "Descriptions",
    notes: "Notes",
    b64encodeImages: "Design Images",
    files: "Design Files",
  },
};
