import { IModuleRoutes } from '@guibil/router/types';
import Login from 'pages/login/views/Login';

export const guestRouterConfig: IModuleRoutes = [
  {
    url: 'login',
    title: '',
    component: Login
  },
];
