import { useLang } from '@guibil/app';
import Grid from '@material-ui/core/Grid';
import { avaiableThemes, useTheme } from '@guibil/styles/useTheme';
import { Card, CardContent, CardMedia, makeStyles } from '@material-ui/core';
import { GuiButton } from '@guibil/components';
import darkThemeImage from 'images/dark-theme.png'
import lightThemeImage from 'images/light-theme.png'

const Theme = () => {
  const { theme, setTheme } = useTheme();
  const lang = useLang();
  const handleThemeChange = (theme: string) => setTheme(theme);
  const classes = useStyles();

  return (
    <>
      <h2 className={classes.title}>{lang("app.theme.title")}</h2>
      <Grid container spacing={2}>
        {avaiableThemes.map(item =>
          <Grid item xs={12} key={item}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={item === "dark" ? darkThemeImage : lightThemeImage}
                title="Contemplative Reptile"
              />
              <CardContent className={classes.content}>
                <span>
                  {lang(`app.theme.${item}`) + " " + lang("app.theme.theme")}
                </span>
                <GuiButton
                  icon="check-circle"
                  text={item === theme ? lang("app.theme.using") : lang("app.theme.use")}
                  onClick={() => handleThemeChange(item)}
                  variant="cancel"
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  )
}

const useStyles = makeStyles({
  title: {
    fontSize: "0.8rem !important",
    color: "var(--titleColor) !important",
    marginLeft: "1.5rem",
    marginBottom: "0.5rem",
    marginTop: -15,
    fontWeight: 600,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 152,
  },
  card: {
    backgroundColor: "transparent !important",
    borderRadius: "none !important",
    boxShadow: "none !important",

  },
  content: {
    display: "flex",
    backgroundColor: "var(--bgPrimary)",
    borderRadius: "2px",
    justifyContent: "space-between",
    alignItems: "center",
    color: "var(--defaultTextColor)",
    padding: "14px !important",
  },
});

export default Theme
