import { useLang } from '@guibil/app';
import { GuiButton } from '@guibil/components';
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import HistoriesDialog, { HistoryProps } from './HistoriesDialog'
import moment from 'moment';
import { DATE_TIME_HUMAN_FORMAT } from '@guibil/form';

interface WithLastUpdateProps {
  title: string,
  histories: HistoryProps[],
  fullWidth?: boolean,
  richText?: boolean,
}

const WithLastUpdate: React.FC<WithLastUpdateProps> = ({ children, title, histories, fullWidth, richText }) => {
  const lang = useLang();
  const classes = useStyles();
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);

  const display = histories?.length > 1;
  const lastHistory = display && histories[histories.length - 1];
  const lastCreatedBy = lastHistory && lastHistory.created_by;
  const lastCreatedDatetime = lastHistory && moment(lastHistory.created_datetime).format(DATE_TIME_HUMAN_FORMAT);

  return <div>
    <div className={classes.container}>
      <div style={{ width: "100%" }}>
        {children}
      </div>
      <div>
        {
          display &&
          <GuiButton
            onClick={setHistoryOpen}
            icon="history"
            variant="success"
            nativeProps={{ classes: { root: classes.history } }}
          />
        }
      </div>
    </div>
    {
      display &&
      <div className={classes.infoContainer}>
        <div>
          {lang("gameTracker.development.gidDisplay.history.updated_by") + ":"}
          <span className={classes.updatedBy}>{lastCreatedBy}</span>
        </div>
        <div>
          {lastCreatedDatetime}
        </div>
      </div>
    }
    {
      historyOpen && <HistoriesDialog
        title={title}
        histories={histories}
        fullWidth={fullWidth}
        richText={richText}
        onClose={() => setHistoryOpen(false)}
      />
    }
  </div>
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
  },
  history: {
    top: 28,
    width: "34px",
    height: "34px",
    padding: "0px !important",
    margin: "0px 0px 0px 4px !important",
    minWidth: "0px !important",
    backgroundColor: "var(--accordionColor)",
    "& svg": {
      left: 8,
      width: "16px !important",
      height: "16px !important",
      color: "var(--titleColor)",
    },
  },
  infoContainer: {
    opacity: 0.85,
    marginTop: "4px",
    color: "var(--titleColor)",
    fontSize: "12px",
    display: "flex",
    justifyContent: "space-between",
  },
  updatedBy: {
    marginLeft: 4,
    fontWeight: 600,
  },
}));

export default WithLastUpdate
