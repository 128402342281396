import { useLang } from "@guibil/app";
import { RowInput } from "jspdf-autotable";
import { useCallback, useRef } from "react";
import { exportPdf } from "../behaviors/pdf/GuiGridPdfExporter";
import { useGuiGridPropsContext } from "../contexts/GuiGridPropsContext";
import GuiGridFetchContainer from "./GuiGridFetchContainer";


interface IProps { }


const GuiGridActionsContainer: React.FC<IProps> = (props) => {
  const lang = useLang();
  const { pdfExporterProps, columns, labelPath } = useGuiGridPropsContext();

  const exportRef = useRef<any>(null);

  const startExcelExport = useCallback(() => {
    const r = exportRef.current;

    r.exportGrid();
  }, []);


  const startPdfExport = useCallback(() => {
    let body: RowInput[] = [];

    const r = exportRef.current;
    const head = [(pdfExporterProps?.columnNames ? columns.filter((column: any) => pdfExporterProps.columnNames?.includes(column)) : columns).map(text => lang(`${labelPath}.${text}`))];

    r.props.rows.forEach((row: any) => {
      let values: any = [];

      if (pdfExporterProps?.columnNames) {
        pdfExporterProps.columnNames.forEach((columnName: string) => {
          values.push(row[columnName]);
        })

      } else {
        columns.forEach((columnName: any) => {
          values.push(row[columnName]);
        })
      }

      body.push(values);
    })

    exportPdf(head, body, lang(pdfExporterProps?.fileName || document.title));
  }, []);

  return <GuiGridFetchContainer performExcelExport={startExcelExport} performPdfExport={startPdfExport} exportRef={exportRef}>
    {props.children}
  </GuiGridFetchContainer>


};

export { GuiGridActionsContainer };
