import { login_en } from "pages/login/locales/en_US";
import { dashboard_en } from "pages/dashboard/locales/en_US";
import { notifications_en } from "pages/notifications/locales/en_US";
import { makers_en } from "pages/makers/maker/locales/en_US";
import { games_en } from "pages/games/game/locales/en_US";
import { new_games_en } from "pages/games/new-games/locales/en_US";
import { top_games_en } from "pages/games/top-games/locales/en_US";
import { random_games_en } from "pages/games/random-games/locales/en_US";
import { profile_en } from "pages/profile/locales/en_US";
import { user_management_en } from "pages/user-management/locales/en_US";
import { maker_details_en } from "pages/makers/maker-details/locales/en_US";
import { components_en } from "pages/components/locales/en_US";
import { game_details_en } from "pages/games/game-details/locales/en_US";
import { log_user_management_en } from "pages/log-user-management/locales/en_US";
import { development_en } from "pages/development/locales/en_US";

export const game_tracker_en = {
  components: components_en,
  login: login_en,
  dashboard: dashboard_en,
  notifications: notifications_en,
  development: development_en,
  makers: makers_en,
  makerDetails: maker_details_en,
  games: games_en,
  gameDetails: game_details_en,
  newGames: new_games_en,
  topGames: top_games_en,
  randomGames: random_games_en,
  profile: profile_en,
  userManagement: user_management_en,
  logUserManagement: log_user_management_en,
};
