import { IEnumLangBuilder } from "@guibil/app";
import { AppEnumTypes } from "../app-enum";
import { app_active_passive_tr } from "../general/active-passive/locales/tr_TR";
import { app_store_chart_tr } from "../general/app-store-chart/locales/tr_TR";
import { date_filter_tr } from "../general/date-filter/locales/tr_TR";
import { app_enabled_disabled_tr } from "../general/enabled-disabled/locales/tr_TR";
import { app_exists_not_exists_tr } from "../general/exist-nonexists/locales/tr_TR";
import { app_store_genre_tr } from "../general/app-store-genre/locales/tr_TR";
import { app_language_tr } from "../general/language/locales/tr_TR";
import { notification_type_tr } from "../general/notification-type/locales/tr_TR";
import { app_on_off_tr } from "../general/on-off/locales/tr_TR";
import { play_store_chart_tr } from "../general/play-store-chart/locales/tr_TR";
import { play_store_genre_tr } from "../general/play-store-genre/locales/tr_TR";
import { app_registered_not_registere_tr } from "../general/registered-not-registered/locales/tr_TR";
import { app_yes_no_tr } from "../general/yes-no/locales/tr_TR";
import { os_type_tr } from "../general/os-type/locales/tr_TR";
import { gid_status_tr } from "../general/gid-status/locales/tr_TR";

export const app_enum_tr: IEnumLangBuilder<AppEnumTypes> = {
  AppLanguage: app_language_tr,
  AppYesNo: app_yes_no_tr,
  AppEnabledDisabled: app_enabled_disabled_tr,
  AppActivePassive: app_active_passive_tr,
  AppOnOff: app_on_off_tr,
  AppExistsNotExists: app_exists_not_exists_tr,
  AppRegisteredNotRegistered: app_registered_not_registere_tr,
  NotificationType: notification_type_tr,
  DateFilter: date_filter_tr,
  OsType: os_type_tr,
  PlayStoreChart: play_store_chart_tr,
  PlayStoreGenre: play_store_genre_tr,
  AppStoreChart: app_store_chart_tr,
  AppStoreGenre: app_store_genre_tr,
  GidStatus: gid_status_tr,
};
