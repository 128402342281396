import { useRequest } from '@guibil/api';
import { useLang } from '@guibil/app';
import { GuiButton, guiNotifier } from '@guibil/components';
import { GuiDropdown } from '@guibil/form';
import { GuiGrid, useGuiGridHook } from '@guibil/grid';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PlayerLevelEventsDialog from './player-play-details/player-level-events-dialog/PlayerLevelEventsDialog';
import PlayerPlayDetailsDialog from './player-play-details/player-play-detail-dialog/PlayerPlayDetailsDialog';

const Players = () => {
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [hook, setHook] = useGuiGridHook();
  const [playerIdForLevelEvents, setPlayerIdForLevelEvents] = useState<any>(null);
  const [playerIdForDetails, setPlayerIdForDetails] = useState<any>(null);

  const [osType, setOsType] = useState<string>("");
  const [gameVersion, setGameVersion] = useState<string>("");
  const [gameVersions, setGameVersions] = useState<string[]>([]);

  useEffect(() => fetchGameVersions(), [])

  const fetchGameVersions = () => {
    req.get(`installations/game_versions/${id}`)
      .then(res => setGameVersions(res))
      .catch(err => guiNotifier().handleError(err));
  }

  const Events = (row: any) => {
    const { id } = row?.row;

    return (
      <GuiButton
        hide={row?.row?.level === 0}
        onClick={() => setPlayerIdForLevelEvents(id)}
        text="gameTracker.development.installationDetails.players.eventsButton"
        variant="success"
        icon="check-double"
      />
    )
  }

  const Details = (row: any) => {
    const { id } = row?.row;
    return (
      <GuiButton
        onClick={() => setPlayerIdForDetails(id)}
        text="gameTracker.development.installationDetails.players.detailsButton"
        variant="cancel"
        icon="info-circle"
      />
    )
  }

  const downloadAsCsv = async () => {
    try {
      await req.download(`installations/export_as_csv_players/${id}?os=${osType}&game_version=${gameVersion}`, `${id}_players_version_${gameVersion}_os_${osType}.csv`)
    } catch (err) {
      guiNotifier().handleError(err);
    }
  }

  useEffect(() => handleSetFilter(), [osType, gameVersion])

  const handleSetFilter = () => {
    hook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
    hook?.setQueryParams({
      os: osType,
      game_version: gameVersion
    })
  }

  return (
    <>
      <GuiGrid
        fetchData={`installations/players/${id}`}
        labelPath="gameTracker.development.installationDetails.players"
        title="gameTracker.development.installationDetails.players.title"
        setHook={setHook}
        queryParams={{
          os: osType,
          game_version: gameVersion
        }}
        columns={[
          "install_datetime",
          "play_count",
          "playtimes_in_ms",
          "average_playtimes_in_ms",
          "level",
          "events",
          "details",
        ]}
        hiddenSortFields={["events", "details"]}
        headerComponent={
          <div className={classes.headerComponent}>
            <div className={classes.filterComponent}>
              <div>{lang("gameTracker.development.installationDetails.players.os")}</div>
              < div className={classes.osType} >
                <GuiDropdown
                  field=""
                  controlled
                  onChange={(_: any, e: any,) => setOsType(e?.props?.value)}
                  value={osType}
                  showClearOption
                  selectionsEnum="OsType"
                />
              </div>
            </div>
            <div className={classes.filterComponent}>
              <div>{lang("gameTracker.development.installationDetails.players.version")}</div>
              < div className={classes.gameVersions} >
                <GuiDropdown
                  field=""
                  controlled
                  onChange={(_: any, e: any,) => setGameVersion(e?.props?.value ? e?.props?.value : "")}
                  value={gameVersion}
                  showClearOption
                  selections={gameVersions}
                />
              </div>
            </div>
            <div>
              <GuiButton
                onClick={downloadAsCsv}
                text="gameTracker.development.installationDetails.players.download"
                variant="success"
                icon={"download"}
              />
            </div>
          </div>
        }
        dateTimeFields={[
          { targetField: "install_datetime" }
        ]}
        columnExtensions={[
          { columnName: "play_count", width: 84 },
          { columnName: "playtimes_in_ms", width: 180 },
          { columnName: "average_playtimes_in_ms", width: 180 },
          { columnName: "events", width: 130 },
          { columnName: "details", width: 130 },
        ]}
        getCellValues={[
          {
            targetFields: ["playtimes_formatted"],
            function: (row: any) => row.row?.playtimes_in_ms
          },
          {
            targetFields: ["average_playtimes_formatted"],
            function: (row: any) => row.row?.average_playtimes_in_ms
          },
        ]}
        customCells={[
          {
            targetFields: ["playtimes_in_ms"],
            component: (row: any) => <span>{row.row?.playtimes_formatted}</span>
          },
          {
            targetFields: ["average_playtimes_in_ms"],
            component: (row: any) => <span>{row.row?.average_playtimes_formatted}</span>
          },
          {
            targetFields: ["events"],
            component: Events
          },
          {
            targetFields: ["details"],
            component: Details
          },
          {
            targetFields: ["level"],
            component: (row: any) => <span>{row?.row?.level === 0 ? "-" : row?.row?.level}</span>
          }
        ]}
      />
      {playerIdForDetails && <PlayerPlayDetailsDialog
        player_id={playerIdForDetails}
        onClose={() => setPlayerIdForDetails(null)} />
      }
      {playerIdForLevelEvents && <PlayerLevelEventsDialog
        player_id={playerIdForLevelEvents}
        onClose={() => setPlayerIdForLevelEvents(null)}
      />}
    </>
  )
}

const useStyles = makeStyles({
  headerComponent: {
    marginLeft: "-24px",
    display: "flex",
    alignItems: "center"
  },
  filterComponent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "-19px"
  },
  osType: {
    marginRight: "6px",
    marginLeft: "8px",
    "& .MuiInputBase-root": {
      marginTop: "-7px !important",
    }
  },
  gameVersions: {
    marginRight: "10px",
    marginLeft: "4px",
    "& .MuiInputBase-root": {
      marginTop: "-7px !important",
    }
  },
});

export default Players
