import React, { FC, useMemo, useState } from 'react';
import { useRequestErrors } from '@guibil/api';
import { GuiFormStateContext } from '../../contexts/GuiFormStateContext';
import { IGuiFormLoadingState, IGuiFormState } from '../../types';
import { GuiEventSystem } from '@guibil/app';

interface IProps { }

const GuiFormStateContainer: FC<IProps> = (props) => {
  const [data, setData] = useState<any>({});
  const [formState, setFormState] = useState<IGuiFormState>('monitoring');
  const [loadingState, setLoadingState] = useState<IGuiFormLoadingState>('waiting');
  const [fieldsToDismiss, setFieldsToDismiss] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { requestErrors, onRequestError, removeRequestError } = useRequestErrors();
  const guiEventSystem = useMemo(() => new GuiEventSystem(), []);

  const guiFormStateContext = {
    data,
    setData,
    formState,
    setFormState,
    loadingState,
    setLoadingState,
    requestErrors,
    onRequestError,
    removeRequestError,
    fieldsToDismiss,
    setFieldsToDismiss,
    isSubmitting,
    setIsSubmitting,
    guiEventSystem
  };

  return (
    <GuiFormStateContext.Provider value={guiFormStateContext}>
      {props.children}
    </GuiFormStateContext.Provider>
  );
};

export { GuiFormStateContainer };
