import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core';
import { SortableElement } from 'react-sortable-hoc';
import { useGuiGridStateContext } from '@guibil/grid/contexts/GuiGridStateContext';
import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';

const RowComponent = (props: Table.DataRowProps) => {
  const classes = useStyles();
  const { dragging } = useGuiGridPropsContext();
  const { row } = props;
  const { data } = useGuiGridStateContext();
  const TableRow = dragging?.enable ? SortableElement(Table.Row) : Table.Row;

  return (
    <TableRow
      {...props}
      className={classes.rowComponent}
      index={data.list.indexOf(row)}
    />
  );
};

const useStyles = makeStyles(() => ({
  rowComponent: {

  },
}));

export { RowComponent };
