import { GuiButton, guiNotifier, useWindowSize } from '@guibil/components';
import { GuiDateTimePicker, GuiDropdown, GuiForm, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form';
import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useRequest } from '@guibil/api';
import Name from 'pages/components/Name';
import Tagger, { TagProps } from 'pages/components/Tagger';
import FollowingStatus from 'pages/components/FollowingStatus';
import { useUser } from '@guibil/app';
import TagsDialog from '../components/tags/TagsDialog';
import NewArtistDialog from '../components/new-artist-dialog/NewArtistDialog';
import { commonCSS } from '@guibil/styles';

const Artists = () => {
	const req = useRequest();
	const { width } = useWindowSize();
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [selectedTags, setSelectedTags] = useState<TagProps[]>([]);
	const [availableTags, setAvailableTags] = useState<TagProps[] | "loading">([]);
	const [gridHook, setGridHook] = useGuiGridHook();
	const classes = useClasses();
	const user = useUser();
	const [showTagsDialog, setShowTagsDialog] = useState(false);
	const [showNewArtistDialog, setShowNewArtistDialog] = useState(false);
	const [executives, setExecutives] = useState([])
	const [filterData, setFilterData] = useGuiFormData();
	const [filterHook, setFilterHook] = useGuiFormHook();
	const [filterParams, setFilterParams] = useState<any | undefined>({
		name: "",
		min_last_release_date: "",
		max_last_release_date: "",
		rate: "",
		tag: "",
		executive: "",
	});

	useEffect(() => { fetchTags(); fetchExecutives(); }, [])
	useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

	const fetchTags = async () => {
		try {
			const res = await req.get("artists/artist_tags");
			setAvailableTags("loading");
			setAvailableTags(res?.result);
		} catch (err: any) {
			setAvailableTags([]);
			guiNotifier().handleError(err);
		}
	}

	const fetchExecutives = async () => {
		try {
			const res = await req.get("artists/executives");
			const executives = res?.result.map((executive: any) => { return executive?.name })
			setExecutives(executives);
		} catch (err: any) {
			guiNotifier().handleError(err);
		}
	}

	const handleSetFilter = () => {
		gridHook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
		gridHook?.setQueryParams({
			name: filterData?.values?.name,
			min_last_release_date: filterData?.values?.min_last_release_date,
			max_last_release_date: filterData?.values?.max_last_release_date,
			rate: filterData?.values?.rate,
			tag: filterData?.values?.tag,
			executive: filterData?.values?.executive
		})
	}

	const handleResetFilter = () => {
		setFilterParams(undefined);
		setSelectedTags([]);
		gridHook?.setQueryParams({});
		filterHook?.fetch();
	}

	const handleSelectedTags = (tags: TagProps[]) => {
		const tagAsCommaSeperated = tags.map((tag: TagProps) => tag.slug).join();
		filterData?.setValues({ ...filterData?.values, tag: tagAsCommaSeperated });
		setSelectedTags(tags);
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<GuiForm
					fetchData={() => filterParams}
					setHook={setFilterHook}
					setData={setFilterData}
					showForm={!showFilter}
					title="gameTracker.makers.appStore.artistFilter.filter"
					labelPath="gameTracker.makers.appStore.artistFilter"
					disableLeaveFormPrompt
					headerComponent={
						<div>
							<GuiButton
								onClick={() => setShowFilter(!showFilter)}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon={showFilter ? "eye-slash" : "eye"}
							/>
							<GuiButton
								onClick={handleResetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon="undo-alt"
							/>
							<GuiButton
								onClick={handleSetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="success"
								icon="filter"
							/>
						</div>
					}
					hideButtons
					type="writer"
				>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} lg={3}>
							<GuiText
								field="name"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="min_last_release_date"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="max_last_release_date"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="rate"
								selections={[1, 2, 3, 4, 5]}
								showClearOption
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<Grid container item spacing={0}>
								<Grid item xs={8} md={8} lg={8}>
									<Tagger
										label="gameTracker.makers.appStore.artistFilter.tag"
										selecteds={selectedTags}
										availables={availableTags === "loading" ? [] : availableTags}
										isLoading={availableTags === "loading"}
										handleSelectedTags={handleSelectedTags}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<GuiButton
										nativeProps={{ classes: { root: classes.showBtn } }}
										variant="cancel"
										onClick={() => setShowTagsDialog(true)}
										text="gameTracker.makers.appStore.artists.showTags"
										icon="pen"
										disabled={user.role === "VIEWER"}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="executive"
								selections={executives}
								showClearOption
							/>
						</Grid>
					</Grid>
				</GuiForm>
			</Grid>

			<Grid item xs={12}>
				<GuiGrid
					fetchData="artists/artists"
					title="gameTracker.makers.appStore.artists.title"
					labelPath="gameTracker.makers.appStore.artists"
					setHook={setGridHook}
					columnExtensions={[
						{ columnName: "object_order", width: 100 },
						{ columnName: "name", wordWrapEnabled: true },
						{ columnName: "follow", width: 150 },
					]}
					extraButtons={[{
						onClick: () => setShowNewArtistDialog(true),
						text: "gameTracker.makers.appStore.newArtistDialog.add",
						variant: "success",
						icon: "plus-circle",
					}]}
					columns={[
						"object_order",
						"name",
						"rate",
						"last_release_date",
						"follow",
					]}
					hiddenSortFields={["follow"]}
					dateTimeFields={[
						{ targetField: "last_release_date" },
					]}
					customCells={[
						{
							targetFields: ["name"], component: (row: any) =>
								<Name
									linkTo={`makers/maker/${row.row?.id}`}
									name={row.row?.name}
									url={row.row?.url}
								/>
						},
						{
							targetFields: ["follow"], component: (row: any) =>
								<FollowingStatus
									isFollowing={row.row?.following}
									followUrl={`artists/follow_artist/${row.row?.id}`}
								/>
						},
					]}
				/>
			</Grid>
			<Grid item xs={12} />
			{
				showTagsDialog && <TagsDialog onClose={() => setShowTagsDialog(false)} onSuccess={async () => {
					setShowTagsDialog(false);
					setSelectedTags([]);
					await fetchTags();
				}} />
			}
			{
				showNewArtistDialog && <NewArtistDialog
					onClose={() => setShowNewArtistDialog(false)}
					onSuccess={async () => {
						setShowNewArtistDialog(false);
						await gridHook?.fetch();
					}}
				/>
			}
		</Grid>
	)
}

const useClasses = makeStyles({
	showBtn: {
		height: "35px !important",
		marginLeft: 15,
		marginTop: 28,
		width: "-webkit-fill-available",
		"& svg": {
			left: 8,
			width: "18px !important",
			height: "18px !important",
		},
	}
})

export default Artists
