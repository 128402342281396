import Logo from 'images/logo.png';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { classNames } from '@guibil/components';

interface Props {
  displayLogo?: boolean;
  disableClick?: boolean;
}

const Brand: React.FC<Props> = ({ displayLogo, disableClick }) => {
  const classes = useStyles();
  const history = useHistory();

  return <div
    className={classes.titleBox}
    onClick={() => !disableClick && history.push('/')}
    style={{ cursor: disableClick ? "default" : "pointer" }}
  >
    {displayLogo && <img className={classes.logo} src={Logo}></img>}
    <div>
      <span className={classes.title}>MARKER</span>
      <span className={classNames(classes.title, classes.subTitle)}>GAMES</span>
    </div>
  </div>
};

const useStyles = makeStyles(() => ({
  logo: {
    width: 60,
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginLeft: 8,
    letterSpacing: -0.75,
    fontSize: "29px !important",
    fontWeight: 500,
    color: "var(--titleColor) !important",
    fontFamily: "EurostileTBla",
  },
  subTitle: {
    fontWeight: 600,
  },
}));

export default Brand;
