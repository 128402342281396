export const app_store_genre_enum = [
  "6014",
  "7001",
  "7002",
  "7003",
  "7004",
  "7005",
  "7006",
  "7007",
  "7008",
  "7009",
  "7011",
  "7012",
  "7013",
  "7014",
  "7015",
  "7016",
  "7017",
  "7018",
  "7019",
];
