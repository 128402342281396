import { useEffect, useState } from "react";

const avaiableThemes = ["light", "dark"];

const useTheme = () => {
  const htmlElement = document.documentElement;
  const localTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState<string>(localTheme || "light");

  useEffect(() => {
    htmlElement.setAttribute("theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme])

  return { theme, setTheme }
}

export { useTheme, avaiableThemes }
