import { isNullOrUndefined, getFieldByName } from '@guibil/helpers';

/**
 * generate all possibilities of field, from right to left
 *
 * ex-1:
 * field = "longitude.data.minute"
 * All Possibilities:
 * 1) "longitude.data.minute"
 * 2) "longitude.data"
 * 3) "longitude"
 *
 * ex-2:
 * _feild = "frequency"
 * All Possibilties:
 * 1) "frequency"
*/
type IFormPermission = | 'R' | 'RW' | 'I';
export function getUserPermissionForForm(formRoles: IGuiApp.IRole, userRole: IGuiApp.IAppUserRole, field: string, editMode: boolean): IFormPermission {
  let permission: IFormPermission = 'RW';

  try {
    const action: | 'update' | 'monitor' = editMode ? 'update' : 'monitor';

    const parts = field.split('.');
    for (let ind = parts.length - 1; ind >= 0; ind--) {
      try {
        const newParts = parts.slice(0, ind + 1);
        field = newParts.join('.');

        const newField = getFieldByName(formRoles[action], field);

        if (!newField) {
          continue;
        }

        // @ts-ignore
        permission = formRoles[action][newField][userRole];
        break;
      } catch (error) { }
    }
  } catch (error) {
    // console.log("getUserPermissionForForm error", action, field, userRole, "does not exist in formRoles, returning Read for those fields");
    return 'R';
  }

  return permission;
}

export function preprocessYupValidationErr(validationErrs: any) {
  const resultErrors: any = {};
  try {
    validationErrs.inner.forEach((err: any) => {
      const { path, message } = err;

      if (isNullOrUndefined(resultErrors[path])) {
        resultErrors[path] = message;
      }
    });
  } catch (err) { }
  return resultErrors;
}
