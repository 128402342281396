export const app_store_dashboard_en = {
  info: {
    title: "General",
    artist: "Total Artist Count:",
    game: "Total Game Count:",
    following_artist: "Following Artist Count:",
    following_game: "Following Game Count:",
  },

  followingArtists: {
    title: "Following Artists",
    object_order: "Index",
    name: "Name",
    rate: "Rate",
    last_release_date: "Last Release Date",
    following: "Follow",
  },

  followingGames: {
    title: "Following Games",
    object_order: "Index",
    logo_image_url: "Logo",
    name: "Name",
    artist: "Artist",
    rate: "Rate",
    store_rate: "Store Rate",
    release_date: "Release Date",
    last_release_date: "Last Release Date",
    following: "Follow",
  },
};
