export const app_store_game_artist_history_en = {
  title: "Artist Changes",

  filter: {
    filter: "Filter",
    previous_datetime: "Date",
  },

  game: {
    name: "Name",
    logo_image_url: "Logo",
    last_release_date: "Last Release Date",
  },
  old_artist: "Old Artist",
  new_artist: "New Artist",
};
