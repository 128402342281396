import { Rating } from '@material-ui/lab'
import React, { useState } from 'react'
import { useRequest } from '@guibil/api';
import { classNames, guiNotifier } from '@guibil/components';
import { makeStyles } from '@material-ui/styles';
import { useLang } from '@guibil/app';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from '@material-ui/icons/Star';

interface RateProps {
  rate: number | null,
  label?: string,
  rateUrl?: string,
  isStoreRate?: boolean,
  isFixedRate?: boolean,
  fixedRate?: number,
  disabled?: boolean,
  withoutBg?: boolean,
}

const Rate: React.FC<RateProps> = ({ rate, label, rateUrl, isStoreRate, isFixedRate, fixedRate, disabled, withoutBg }) => {
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();

  const [isRating, setIsRating] = useState(false);
  const [currentRate, setCurrentRate] = useState(rate);

  const onChange = async (_: any, value: number | null) => {
    if (rateUrl && value) {
      try {
        const res = await req.post(rateUrl, { rate: value });
        setCurrentRate(res.data);
      } catch (err: any) {
        guiNotifier().handleError(err);
      }
    }
  }


  return <div>
    <div className={classes.label}>
      {label && lang(label)}
    </div>
    <div className={classNames(
      classes.container,
      withoutBg ? classes.withoutBackground : undefined,
      disabled ? classes.disabled : undefined
    )}>
      <div>
        <Rating
          name="rating"
          value={currentRate}
          readOnly={disabled || isStoreRate}
          precision={isStoreRate ? 0.1 : isRating ? 1 : 0.1}
          onChange={onChange}
          className={classNames(isStoreRate ? classes['store-rate-icon'] : classes['rate-icon'])}
          classes={{}}
          icon={isStoreRate ? <StarIcon /> : <FavoriteIcon />}
          size="large"
          onMouseOver={() => setIsRating(true)}
          onMouseLeave={() => setIsRating(false)}
        />
      </div>
      <div className={classes.rate}>
        <span>{isFixedRate ? fixedRate : currentRate ? currentRate : lang("gameTracker.components.rate_null")}</span>
      </div>
    </div>
  </div>
}

const useStyles = makeStyles({
  label: {
    paddingTop: "4px",
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
  },
  container: {
    paddingTop: "5px",
    paddingLeft: "6px",
    display: "flex",
    marginTop: "5.5px",
    height: "34.5px",
    borderRadius: "2px",
    backgroundColor: "var(--bgColor)",
  },
  withoutBackground: {
    background: "none !important",
    border: "none !important",
  },
  disabled: {
    opacity: 0.70,
  },
  rate: {
    color: "var(--defaultTextColor)",
    fontSize: "14px !important",
    fontWeight: 800,
    marginLeft: "6px",
    marginTop: "3px"
  },
  'rate-icon': {
    color: 'var(--primaryError)',
    "& .MuiRating-iconEmpty": {
      color: 'var(--primaryGray)',
    },
  },
  'store-rate-icon': {
    "& .MuiRating-iconEmpty": {
      color: 'var(--primaryGray)',
    },
  },
});

export default Rate
