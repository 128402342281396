import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import { GuiButton } from '@guibil/components';
import ZoomDialog from './ZoomDialog';

interface ImageProps {
  value: string,
  position: number,
  handleDelete: (position: number) => void,
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void,
}

const Image: React.FC<ImageProps> = ({ position, value, handleDelete, handleDrop }) => {
  const classes = useStyles();
  const [image, setImage] = useState<Blob | null>(null);
  const [zoomOpen, setZoomOpen] = useState<boolean>(false);

  useEffect(() => {
    fetch(value)
      .then(res => res.blob())
      .then(setImage)
  }, [value]);

  return <div>
    {
      image && <div
        id={position.toString()}
        onDragStart={(e: any) => e.dataTransfer.setData('position', e.currentTarget.id)}
        onDragOver={(e: any) => e.preventDefault()}
        onDrop={handleDrop}
        onClick={() => setZoomOpen(!zoomOpen)}
        className={classes.container}
        draggable
      >
        <img
          className={classes.image}
          src={URL.createObjectURL(image)}
        />
        <GuiButton
          onClick={() => handleDelete(position)}
          icon="trash-alt"
          nativeProps={{ classes: { root: classes.delete } }}
        />
        {
          zoomOpen && <ZoomDialog image={image} onClose={() => setZoomOpen(false)} />
        }
      </div>
    }
  </div>
}

const useStyles = makeStyles(() => ({
  container: {
    height: "auto",
    cursor: "grab",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "160px",
    height: "160px",
    marginLeft: 8,
    marginRight: 8,
    objectFit: "cover",
  },
  delete: {
    position: "absolute",
    top: 2,
    right: 10,
    minWidth: "unset",
    margin: "4px",
    width: "32px !important",
    height: "32px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "7px !important",
    float: "left",
    marginRight: "5px !important",
    backgroundColor: "var(--overlayColor)",
    "&:hover": {
      backgroundColor: "var(--overlayColor)",
    },
    "& svg": {
      width: "16px !important",
      height: "16px !important",
      color: "var(--primaryError)",
    },
  },
}))


export default Image;
