import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import { Grid } from '@material-ui/core';
import { GuiButton, useWindowSize } from '@guibil/components';
import { GuiDateTimePicker, GuiForm, useGuiFormData, useGuiFormHook } from '@guibil/form';
import { useEffect, useState } from 'react';
import { commonCSS } from '@guibil/styles';


const GameArtistHistory = () => {
	const { width } = useWindowSize();
	const [showFilter, setShowFilter] = useState<boolean>(false);

	const [gridHook, setGridHook] = useGuiGridHook();
	const [data, setData] = useGuiFormData();
	const [filterHook, setFilterHook] = useGuiFormHook();
	const [filterParams, setFilterParams] = useState<any | undefined>({
		previous_datetime: "",
	});

	useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

	const handleSetFilter = () => {
		gridHook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
		gridHook?.setQueryParams({
			previous_datetime: data?.values?.previous_datetime,
		})
	}

	const handleResetFilter = () => {
		setFilterParams(undefined);
		gridHook?.setQueryParams({
			previous_datetime: ""
		});
		filterHook?.fetch();
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<GuiForm
					fetchData={() => filterParams}
					setHook={setFilterHook}
					setData={setData}
					showForm={!showFilter}
					title="gameTracker.notifications.gameArtistHistory.playStore.filter.filter"
					labelPath="gameTracker.notifications.gameArtistHistory.playStore.filter"
					disableLeaveFormPrompt
					headerComponent={
						<div>
							<GuiButton
								onClick={() => setShowFilter(!showFilter)}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon={showFilter ? "eye-slash" : "eye"}
							/>
							<GuiButton
								onClick={handleResetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon="undo-alt"
							/>
							<GuiButton
								onClick={handleSetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="success"
								icon="filter"
							/>
						</div>
					}
					hideButtons
					type="writer"
				>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="previous_datetime"
								disableTime
							/>
						</Grid>
					</Grid>
				</GuiForm>
			</Grid>

			<Grid item xs={12}>
				<GuiGrid
					setHook={setGridHook}
					queryParams={filterParams}
					fetchData="ps_games/game_developer_history"
					title="gameTracker.notifications.gameArtistHistory.playStore.title"
					labelPath="gameTracker.notifications.gameArtistHistory.playStore"
					columnExtensions={[
						{ columnName: "game.logo_image_url", width: 76 },
						{ columnName: "game.last_release_date", width: 140 },
					]}
					hiddenSortFields={[
						"game.logo_image_url",
					]}
					columns={[
						"game.logo_image_url",
						"game.last_release_date",
						"game.name",
						"old_developer",
						"new_developer",
					]}
					dateTimeFields={[
						{ targetField: "game.last_release_date" },
					]}
					customCells={[
						{
							targetFields: ["game.name"], component: (row: any) =>
								<Name
									linkTo={`/games/game/${row.row?.game?.id}`}
									name={row.row?.game?.name}
									url={row.row?.game?.url}
								/>
						},
						{
							targetFields: ["old_developer"], component: (row: any) =>
								<div style={{ display: "flex", alignItems: "center" }}>
									<div style={{ paddingRight: 5 }}>
										<FollowingStatus
											key={row.row?.id}
											isFollowing={row.row?.old_developer?.following}
											followUrl={`ps_developers/follow_developer/${row.row?.old_developer?.id}`}
											onSuccess={() => gridHook?.fetch()}
										/>
									</div>
									<div>
										<Name
											linkTo={`/makers/maker/${row.row?.old_developer?.id}`}
											name={row.row?.old_developer?.name}
											url={row.row?.old_developer?.url}
										/>
									</div>
								</div>
						},
						{
							targetFields: ["new_developer"], component: (row: any) =>
								<Name
									linkTo={`/makers/maker/${row.row?.new_developer?.id}`}
									name={row.row?.new_developer?.name}
									url={row.row?.new_developer?.url}
								/>
						},
						{
							targetFields: ["game.logo_image_url"], component: (row: any) =>
								<GameLogo logo_image_url={row.row?.game?.logo_image_url} />
						},
					]}
				/>
			</Grid>
			<Grid item xs={12} />
		</Grid >
	)
}

export default GameArtistHistory
