export const guibil_grid_tr = {
  showAll: 'Tümü',
  rowsPerPage: 'Sayfadaki satır sayısı:',
  info: 'Toplam {{count}} satırdan {{from}}-{{to}} arası',
  noData: 'Veri yok',
  groupByColumn: 'Gruplamak için bir grup kolon başlığını buraya sürükleyin',
  sort: 'Sırala',
  filter: {
    placeholder: 'Filtre',
    contains: 'İçeren',
    endsWith: 'Biten',
    equal: 'Eşit',
    greaterThan: 'Büyük',
    greaterThanOrEqual: 'Büyük eşit',
    lessThan: 'Küçük',
    lessThanOrEqual: 'Küçük eşit',
    notContains: 'İçermeyen',
    notEqual: 'Eşit değil',
    startsWith: 'Başlayan',

    title: "Filtre",
    filter: "Filtrele",
    editFilter: "Filtre Düzenleme",
    reset: "Sıfırla",
    startTime: "Başlangıç Tarihi",
    endTime: "Bitiş Tarihi",
    cardPortList: "Kart/Port",
    sourceSubscriberNo: "Kaynak Abone No",

  },
  cells: {
    boolean: {
      true: 'Evet',
      false: 'Hayır',
    },
    file: {
      title: 'Dosya Görüntüleme',
    },
  },
  button: {
    clear: 'Temizle',
  },
  exportPdf: "PDF'e Aktar",
  exportExcel: "Excel'e Aktar",
  export: "İndir",
};
