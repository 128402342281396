import { Table } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { SortableContainer } from 'react-sortable-hoc';
import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';

const BodyComponent = ({ ...restProps }) => {
  const classes = useStyles();
  const { dragging } = useGuiGridPropsContext();

  const TableBody = dragging?.enable ? SortableContainer(Table.TableBody) : Table.TableBody;

  return (
    <TableBody
      {...restProps}
      className={classes.bodyComponent}
    />
  );
};

const useStyles = makeStyles(() => ({
  bodyComponent: {
    background: "var(--bgPrimary)",
  },
}));

export { BodyComponent };
