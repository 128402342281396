import { useEffect } from 'react';
import { useGuiGridActionsContext } from '../../contexts/GuiGridActionsStateContext';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { useGuiGridStateContext } from '../../contexts/GuiGridStateContext';

const GuiGridConfiguration = () => {
  const { setHook, setData } = useGuiGridPropsContext();
  const { performFetch, exporter } = useGuiGridActionsContext();
  const { data, setQueryParams, setPagingState } = useGuiGridStateContext();

  useEffect(() => {
    if (setHook) {
      setHook({
        fetch: performFetch,
        exportAsExcel: exporter.performExcelExport,
        exportAsPdf: exporter.performPdfExport,
        setQueryParams: setQueryParams,
        setPagingState
      });
    }
  }, [performFetch]);

  useEffect(() => {
    if (setData) {
      setData(data);
    }
  }, [data]);

  return null;
};

export { GuiGridConfiguration };
