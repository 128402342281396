export const app_store_genre_tr = {
  "6014": "Oyunlar",
  "7001": "Aksiyon",
  "7002": "Macera",
  "7003": "Arcade",
  "7004": "Skor",
  "7005": "Kart",
  "7006": "Kumar",
  "7007": "Zar",
  "7008": "Eğitsel",
  "7009": "Aile",
  "7011": "Müzik",
  "7012": "Bulmaca",
  "7013": "Yarış",
  "7014": "Rol",
  "7015": "Simülasyon",
  "7016": "Spor",
  "7017": "Strateji",
  "7018": "Trivia",
  "7019": "Kelime",
};
