export const profile_en = {
  breadcrumbTitle: "Profile",
  pageTitle: "Profile",
  title: "Profile",
  menuTitle: "Profile",
  passwordTitle: "Password",

  email: "E-mail",
  first_name: "Name",
  last_name: "Surname",
  password: "Password",
  password_confirm: "Confirm Password"
};
