import { app_store_top_games_en } from "../app-store/locales/en_US";
import { play_store_top_games_en } from "../play-store/locales/en_US";


export const top_games_en = {
  breadcrumbTitle: "Top Games",
  pageTitle: "Top Games",
  title: "Top Games",
  menuTitle: "Top Games",

  appStore: app_store_top_games_en,
  playStore: play_store_top_games_en,
};
