import React, { useState, useEffect } from 'react';
import {
  GuiRefreshButton, GuiButtonsRenderer, LoadingComponent, GuiRequestError, classNames,
} from '@guibil/components';
import { useRequestErrors } from '@guibil/api';
import { IGuiPageProps } from './types';
import { useLang } from '@guibil/app';
import { commonCSS } from '@guibil/styles';
import { makeStyles } from '@material-ui/core/styles';

const INIT_FETCH = 'INIT_FETCH';
const REGULAR_FETCH = 'REGULAR_FETCH';

export const GuiPage: React.FC<IGuiPageProps> = (props) => {
  const classes = useStyles();
  const { requestErrors, onRequestError, removeRequestError } = useRequestErrors();
  const {
    title, titleComponent,
    fetchData, initialFetch,
    setData, setHook,
    interval, pause, autoRefresh, hideRefreshButton, hideIntervalOptions,
    buttons, buttonsPosition, headerComponent,
    children,
  } = props;
  const [loading, setLoading] = useState(true);
  const lang = useLang();

  const initFetch = async () => {
    if (initialFetch) {
      removeRequestError(INIT_FETCH);

      try {
        await initialFetch();
        regularFetch();
      } catch (e) {
        // Initial Fetch Error Handling
        onRequestError(INIT_FETCH, e, initFetch);
        setLoading(false);
      }
    } else {
      regularFetch();
    }
  };

  const regularFetch = async () => {
    if (fetchData) {
      try {
        const result = await fetchData();
        if (setData) setData(result); // set data hook with new data
        removeRequestError(REGULAR_FETCH);
      } catch (e) {
        // Regular Fetch Error Handling
        onRequestError(REGULAR_FETCH, e, regularFetch);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    initFetch();
    if (setHook) { setHook({ fetch: regularFetch }); }
  }, []);

  const renderTop = !(buttonsPosition?.split('-')[0] === 'bottom');

  const GuiRefreshButtonProps = {
    pause,
    hideIntervalOptions,
    autoRefresh,
    interval,
    onRefresh: regularFetch,
  };

  const PAGE_BODY = (
    <div className={classes.pageWrapper}>
      <div className={classNames(commonCSS.formActionBar, commonCSS.actionBarLeft)}>
        {title && <span className={commonCSS.guiFormTitle}>{lang(title)}</span>}
        {titleComponent && <span className={commonCSS.guiFormTitle}>{titleComponent}</span>}
        {!hideRefreshButton && <GuiRefreshButton icon="sync-alt" {...GuiRefreshButtonProps} />}
        {headerComponent}
        {renderTop && <GuiButtonsRenderer buttons={buttons} />}
      </div>
      {loading ? <div className={classes.loading}><LoadingComponent /></div> : <div className={classes.container}>{children}</div>}
      {!renderTop && <GuiButtonsRenderer buttons={buttons} />}
    </div>
  );

  return (
    <>
      {loading ? <div className={classes.loading}><LoadingComponent /></div> : (requestErrors.length > 0 ? <GuiRequestError error={requestErrors[0]} /> : PAGE_BODY)}
    </>
  );
};

const useStyles = makeStyles({
  pageWrapper: {
    backgroundColor: "var(--bgPrimary)",
    padding: "6px 18px",
    borderRadius: "2px",
  },
  container: {
    backgroundColor: "var(--bgPrimary)",
    paddingBottom: "18px"
  },
  loading: {
    opacity: 0.6
  },
});
