import { Grid } from "@material-ui/core"
import FollowingArtists from "../components/following-artists/FollowingArtists";
import FollowingGames from "../components/following-games/FollowingGames";
import Info from "../components/info/Info";

const Dashboard = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Info />
            </Grid>
            <Grid item xs={12}>
                <FollowingArtists />
            </Grid>
            <Grid item xs={12}>
                <FollowingGames />
            </Grid>
            <Grid item xs={12} />
        </Grid>
    )
}

export default Dashboard
