import { Plugin } from '@devexpress/dx-react-core';
import { RowDetailState } from '@devexpress/dx-react-grid';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { useGuiGridStateContext } from '@guibil/grid/contexts/GuiGridStateContext';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';

const GuiGridRowDetail = () => {
  const { data } = useGuiGridStateContext();
  const classes = useStyles();
  const { rowDetailComponent, expandAll } = useGuiGridPropsContext();

  useEffect(() => { setExpandedRowIds(expandAll ? data.list.map((_, i) => i) : []) }, [expandAll])

  const [expandedRowIds, setExpandedRowIds] = useState<React.ReactText[]>(expandAll ? data.list.map((_, i) => i) : []);

  if (!rowDetailComponent) {
    return null;
  }

  const CellComponent: any = (props: any) => {
    return <TableRowDetail.Cell className={classes.detailComponent} {...props} />
  }

  const ToggleCellComponent: any = (props: any) => {
    return <TableRowDetail.ToggleCell className={classes.expandComponent} {...props} />
  }

  return (
    <Plugin>
      <RowDetailState
        expandedRowIds={expandedRowIds}
        onExpandedRowIdsChange={setExpandedRowIds}
      />
      <TableRowDetail
        cellComponent={CellComponent}
        contentComponent={rowDetailComponent}
        toggleCellComponent={ToggleCellComponent}
      />
    </Plugin>
  );
};

const useStyles = makeStyles({
  expandComponent: {
    "& .MuiSvgIcon-root": {
      color: "var(--defaultTextColor) !important",
    }
  },
  detailComponent: {
    backgroundColor: "var(--bgPrimary) !important",
  }
});

export { GuiGridRowDetail };
