import i18n from 'i18next';
import { GuiApp } from '@guibil/app';
import { GuiDropdown, GuiForm } from '@guibil/form';
import { IFieldOnChangeCallback } from '@guibil/form/form-elements/hocs/withGuiFormItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

const avaiableLanguages = ["tr", "en"];


const Language = () => {
  const classes = useStyles();
  const fetchData = () => ({ lang: GuiApp.getLanguage(), _links: {} })

  const handleLanguageChange = (field: any, lang: any,) => {

    const { value } = lang.props;
    i18n.changeLanguage(value);
    GuiApp.setLanguage(value as IGuiApp.ISupportedLanguage);
    location.reload();
  };

  return <div className={classes.root}>
    <GuiForm fetchData={fetchData} type="writer" labelPath="app.language" refreshConfig={{ hide: true, onRefresh: () => null }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GuiDropdown field="language" label="app.language.language" controlled onChange={handleLanguageChange as IFieldOnChangeCallback} value={GuiApp.getLanguage()} selections={avaiableLanguages} selectionsEnum="AppLanguage" />
        </Grid>
      </Grid>
    </GuiForm >
  </div>
}

const useStyles = makeStyles(() => ({
  root: {
    "& > form > div": {
      padding: "0px !important"
    }
  }
}))

export default Language
