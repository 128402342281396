import { useEffect } from 'react';
import { useGuiFormActionsContext } from '../../../contexts/GuiFormActionsContext';
import { useGuiFormPropsContext } from '../../../contexts/GuiFormPropsContext';
import { useGuiFormStateContext } from '../../../contexts/GuiFormStateContext';

const FETCH_ON_MOUNT = 'FETCH_ON_MOUNT';

export const GuiFormRequest = () => {
  const { initialFetch } = useGuiFormPropsContext();
  const { performFetch } = useGuiFormActionsContext();
  const { onRequestError, removeRequestError } = useGuiFormStateContext();

  useEffect(() => {
    const fetchOnMount = async () => {
      removeRequestError(FETCH_ON_MOUNT);

      try {
        initialFetch && await initialFetch();

        // This check is here, because after fetchData, I need to let form know that data has been loaded
        await performFetch();
      } catch (err) {
        onRequestError(FETCH_ON_MOUNT, err, fetchOnMount);
      }
    };

    fetchOnMount();
  }, []);

  return null;
};
