import { Filter } from '@devexpress/dx-react-grid';
import { classNames } from '@guibil/components';
import { GuiText } from '@guibil/form';
import { commonCSS } from '@guibil/styles';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { IGuiGridFilterCustomizedCellProps } from './GuiGridFilterCell';


const GuiGridPhoneNumberFilterCell: React.FC<IGuiGridFilterCustomizedCellProps> = (props) => {
  const { filter, onFilter, column, defaultoperation } = props;

  const onChange = (field: string, value: any) => {
    const newFilter: Filter = {
      columnName: column.name,
      value: value,
      operation: filter?.operation || defaultoperation
    };
    onFilter(value ? newFilter : null);
  };

  let filterSelector = null;
  if (props.filteringEnabled && Array.isArray(props.children) && props.children.length === 2) {
    filterSelector = props.children[0];
  }
  return (
    <TableCell className={classNames(commonCSS.dateTimeFilterItem)}>
      <div>
        {filterSelector}
        <GuiText
          controlled
          field="phone-number-filter"
          value={filter?.value || ""}
          onChange={onChange}
          maskNormalizer="phone"
        />
      </div>
    </TableCell>
  )
};

export { GuiGridPhoneNumberFilterCell };

