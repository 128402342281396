import { useLang, useUser } from '@guibil/app';
import { useWindowSize } from '@guibil/components';
import User from '@guibil/styles/icons/User';
import { ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Link } from 'react-router-dom';

const AccountItem = (props: { setOpenDrawer: Function }) => {
  const { setOpenDrawer } = props;
  const { width } = useWindowSize();
  const classes = useStyles();
  const lang = useLang();
  const user = useUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    user.isAuth && user.logout();
  };

  return (
    <>
      <ListItem disableRipple button classes={{ root: classes.linkButton }} aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e)}>
        <ListItemIcon classes={{ root: classes.icon }}>
          <div>
            <User />
          </div>
        </ListItemIcon>
        <ListItemText className={classes.linkItemText} primary={user.isAuth && (user.detail.fullname || "")} />
        <ExpandMore classes={{ root: classes.icon }} />
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        classes={{ paper: classes.menuPaper }}
      >
        <Link to="/profile">
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              if (width < 666) setOpenDrawer(false);
            }}
          >
            {lang("routes.accountMenu.profile")}
          </MenuItem>
        </Link>

        {user?.role === "MANAGER" ?
          <Link to="/user_management">
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                if (width < 666) setOpenDrawer(false);
              }}
            >{lang("routes.accountMenu.userManagement")}
            </MenuItem>
          </Link> :
          null
        }

        {user?.role === "MANAGER" ?
          <Link to="/log_user_management">
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                if (width < 666) setOpenDrawer(false);
              }}
            >{lang("routes.accountMenu.logUserManagement")}
            </MenuItem>
          </Link> :
          null
        }
        <MenuItem onClick={handleLogout}>{lang("routes.accountMenu.logout")}</MenuItem>
      </Menu>
    </>
  )
}

const useStyles = makeStyles(() => ({
  icon: {
    color: 'inherit',
    minWidth: 30,
    fontSize: '1.6rem',
    '& svg': {
      width: '22px !important',
    },
  },
  linkButton: {
    borderRadius: "2px",
    paddingTop: 0,
    paddingLeft: 18,
    paddingBottom: 0,
    color: "var(--titleColor)",
    '&:hover': {
      backgroundColor: "transparent",
      "& > div": {
        opacity: 0.6,
      }
    },
    '& span': {
      fontSize: "16px !important",
      fontWeight: 500,
      letterSpacing: -0.75,
    },
  },
  linkItemText: {
    "& span": {
      fontSize: "16px !important",
      fontWeight: 500,
      letterSpacing: -0.75,
    }
  },
  menu: {
    marginLeft: 70,
    marginTop: 17
  },
  menuPaper: {
    borderRadius: "2px",
    backgroundColor: "var(--bgPrimary) !important",
    "& li": {
      color: "var(--secondaryTextColor)",
      paddingTop: 8,
      fontSize: "16px !important",
      fontWeight: 500,
      letterSpacing: -0.75,
    }
  }
}));

export default AccountItem
