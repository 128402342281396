import { app_store_random_games_en } from "../app-store/locales/en_US";
import { play_store_random_games_en } from "../play-store/locales/en_US";


export const random_games_en = {
  breadcrumbTitle: "Random Games",
  pageTitle: "Random Games",
  title: "Random Games",
  menuTitle: "Random Games",

  appStore: app_store_random_games_en,
  playStore: play_store_random_games_en,
};
