export const app_store_top_games_tr = {
  title: "Popüler Oyunlar",

  filter: {
    filter: "Filtrele",
    name: "Ad",
    chart_genre: "Kategori",
    chart_type: "Tip",
    previous_datetime: "Tarih",
  },

  topFreeGame: {
    title: "Popüler Oyunlar",
    order: "Sıra",
    follow: "Takip",
    change_of_order: "Sıra Değişimi",
    showScreenshots: "Göster",
    hideScreenshots: "Gizle",
    game: {
      logo_image_url: "Logo",
      name: "Adı",
      artist: "Yapımcı",
      rate: "Puan",
      store_rate: "Mağaza Puanı",
      release_date: "Yayın Tarihi",
      last_release_date: "Son Yayın Tarihi",
    },
  },
};
