import { Grid } from '@material-ui/core';
import DeveloperDetail from '../components/developer-detail/DeveloperDetail';
import DeveloperGames from '../components/developer-games/DeveloperGames';


const DeveloperDetails = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <DeveloperDetail />
      </Grid>
      <Grid item xs={12}>
        <DeveloperGames />
      </Grid>
      <Grid item xs={12} />
    </Grid>
  )
}

export default DeveloperDetails
