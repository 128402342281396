import { useParams } from 'react-router-dom';
import { GuiGrid } from '@guibil/grid'
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';

const ArtistGames = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <GuiGrid
      fetchData="games/games"
      queryParams={{
        artist_id: id,
      }}
      title="gameTracker.makerDetails.appStore.artistGames.title"
      labelPath="gameTracker.makerDetails.appStore.artistGames"
      columnExtensions={[
        { columnName: "object_order", width: 66 },
        { columnName: "name", wordWrapEnabled: true },
        { columnName: "logo_image_url", width: 76 },
        { columnName: "rate", width: 60 },
        { columnName: "store_rate", width: 66 },
        { columnName: "url", width: 80 },
        { columnName: "release_date", width: 140 },
        { columnName: "last_release_date", width: 140 },
        { columnName: "follow", width: 150 },
      ]}
      columns={[
        "object_order",
        "logo_image_url",
        "name",
        "rate",
        "store_rate",
        "release_date",
        "last_release_date",
        "follow",
      ]}
      dateTimeFields={[
        { targetField: "release_date" },
        { targetField: "last_release_date" },
      ]}
      customCells={[
        {
          targetFields: ["name"],
          component: (row: any) =>
            <Name
              linkTo={`/games/game/${row.row.id}`}
              name={row.row.name}
              url={row.row.url}
            />
        },
        {
          targetFields: ["logo_image_url"], component: (row: any) =>
            <GameLogo {...row.row} />
        },
        {
          targetFields: ["follow"], component: (row: any) =>
            <FollowingStatus
              key={row.row.id}
              isFollowing={row.row.following}
              followUrl={`games/follow_game/${row.row.id}`}
            />
        },
      ]}
    />
  )
}

export default ArtistGames
