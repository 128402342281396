import React, { useState } from "react";


export enum StoreType {
	AppStore = "AppStore",
	PlayStore = "PlayStore",
}

interface StoreContextType {
	store: StoreType;
	changeStore: (store: StoreType) => void;
}

let StoreContext = React.createContext<StoreContextType>(null!);

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
	var localStore = localStorage.getItem("store");

	if (localStore == null) {
		localStore = StoreType.AppStore;
		localStorage.setItem("store", StoreType.AppStore);
	}

	const [store, setStore] = useState<StoreType>(localStore as StoreType);

	const changeStore = (store: StoreType) => {
		localStorage.setItem("store", store);
		setStore(store);
	}


	return (
		<StoreContext.Provider value={{ store, changeStore }}>
			{children}
		</StoreContext.Provider>
	);
}

export const useStore = () => React.useContext(StoreContext);
