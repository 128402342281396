export const gids_tr = {
  breadcrumbTitle: "Oyun Fikirleri",
  pageTitle: "Oyun Fikirleri",
  title: "Oyun Fikirleri",
  menuTitle: "Oyun Fikirleri",

  addGid: {
    add: "Yeni",
    title: "Yeni Oyun Geliştirme Tanımı",
    save: "Kaydet",
    uploading: "Dosyalar Yükleniyor...",
  },

  updateGid: {
    title: "Oyun Geliştirme Tanımı Güncelle",
    uploading: "Dosyalar Yükleniyor...",
    update: "Değişiklikleri Kaydet",
  },

  filterGid: {
    filter: "Filtrele",
    title: "İsim",
    created_by: "Oluşturan",
    genre: "Kategori",
    rate: "Puan",
    min_created_datetime: "Min Oluşturma Tarihi",
    max_created_datetime: "Max Oluşturma Tarihi",
    min_updated_datetime: "Min Güncelleme Tarihi",
    max_updated_datetime: "Max Güncelleme Tarihi",
  },

  listGid: {
    title: "İsim",
    rate: "Puan",
    created_by: "Oluşturan",
    created_datetime: "Oluşturma Tarihi",
    updated_by: "Son Güncelleyen",
    updated_datetime: "Son Güncelleme Tarihi",
    action: " ",
    deleteConfirmMessage: "Seçili oyunu silmek istediğinizden emin misiniz?",
    deleteSuccessMessage: "Oyun başarılı bir şekilde silindi",
  },

  genres: {
    title: "Kategoriler",
    add: "Yeni",
    edit: "Kategoriler",
    deleteConfirmMessage: "Bu kategoriye ait oyunlar silinecekler! Seçili kategoriyi silmek istediğinizden emin misiniz?",
    deleteSuccessMessage: "Kategori başarı ile silindi",
    genre: "Kategori",
    actions: " ",
    addGenre: {
      title: "Yeni Kategori Ekle",
      genre: "Yeni Kategori İsmi",
    },
  },

  sections: {
    info: "Bilgiler",
    dynamic: "Dinamikler",
    mechanic: "Mekanikler",
    design: "Tasarım",
  },

  placeHolders: {
    title: "Oyunun adı",
    mantra: "Oyunun tek cümlelik tanıtımı",
    progress: "Oyunda nasıl ilerliyoruz?",
    goal: "Oyunda amacınız neler?",
    challenge: "Oyunda ne gibi zorluklar var?",
    core_loop: "Oyunun ana döngüleri neler?",
    core_mechanic: "Oyunun ana mekanikleri neler olmalı?",
    sub_mechanic: "Oyunun alt mekanikleri neler olmalı?",
    description: "Oyunu nasıl sunmalıyız?",
    notes: "Oyunu ait tasarım notları neler?",
  },

  validations: {
    progress: "'Süreç' alanı boş bırakılamaz",
    goal: "'Hedef' alanı boş bırakılamaz",
    challenge: "'Zorluk' alanı boş bırakılamaz",
  },

  general: {
    title: "İsim",
    status: "Durum",
    popularity: "Popülerlik",
    art_difficulty: "Tasarım Zorluğu",
    dev_difficulty: "Geliştirme Zorluğu",
    mantra: "Mantra",
    core_loops: "Ana döngüler",
    planned_developers: "Planlanan Geliştiriciler",
    planned_designers: "Planlanan Tasarımcılar",
  },

  dynamic: {
    genre: "Kategori",
    progress: "Süreç",
    goal: "Hedef",
    challenge: "Zorluk",
  },

  mechanic: {
    cores: "Ana Mekanikler",
    subs: "Alt Mekanikler",
  },

  design: {
    description: "Açıklamalar",
    notes: "Notlar",
    b64encodeImages: "Tasarım Görselleri",
    files: "Tasarım Dosyaları",
  },
};
