import { app_store_artists_tr } from "../app-store/locales/tr_TR";
import { play_store_developers_tr } from "../play-store/locales/tr_TR";


export const makers_tr = {
  breadcrumbTitle: "Yapımcılar",
  pageTitle: "Yapımcılar",
  title: "Yapımcılar",
  menuTitle: "Yapımcılar",

  appStore: app_store_artists_tr,
  playStore: play_store_developers_tr,
};
