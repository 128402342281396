import { makeStyles } from '@material-ui/core/styles'

interface PageItemProps {
  label: string,
  component: any,
}

const PageItem: React.FC<PageItemProps> = ({ label, component }) => {
  const classes = useStyles();

  let renderComponent = null;

  if (typeof component === "function") {
    renderComponent = component();
  } else {
    renderComponent = component;
  }

  return (
    <div>
      <div className={classes.label}>
        <span>{label}</span>
      </div>
      <div className={classes.container}>
        {renderComponent}
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  label: {
    paddingTop: "4px",
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
  },
  container: {
    paddingTop: "6px",
    marginTop: "5.5px",
    minHeight: "34.5px",
    color: "var(--defaultTextColor)",
    fontSize: "14px",
    borderRadius: "2px",
    fontWeight: 800,
  },
});

export default PageItem
