import { app_store_games_tr } from "../app-store/locales/tr_TR";
import { play_store_games_tr } from "../play-store/locales/tr_TR";


export const games_tr = {
  breadcrumbTitle: "Tüm Oyunlar",
  pageTitle: "Tüm Oyunlar",
  title: "Tüm Oyunlar",
  menuTitle: "Tüm Oyunlar",

  appStore: app_store_games_tr,
  playStore: play_store_games_tr,
};
