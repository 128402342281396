import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { GuiGenericDialog } from '@guibil/components'

interface ZoomDialogProps {
  image: Blob | string,
  onClose: () => void,
}

const ZoomDialog: React.FC<ZoomDialogProps> = ({ image, onClose }) => {
  const classes = useStyles();

  return <GuiGenericDialog
    isOpen
    size="md"
    fullWidth
    dismissible
    onClose={onClose}
  >
    <img
      className={classes.image}
      src={typeof image === "string" ? image : URL.createObjectURL(image)}
    />
  </GuiGenericDialog>
}

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: "-webkit-fill-available",
    maxHeight: "-webkit-fill-available",
    objectFit: "cover",
    borderRadius: "2px",
  },
}))

export default ZoomDialog
