import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import FollowingStatus from 'pages/components/FollowingStatus';
import Name from 'pages/components/Name';

const FollowingArtists = () => {
  const [hook, setHook] = useGuiGridHook();

  return (
    <GuiGrid
      fetchData="ps_developers/developers"
      queryParams={{
        followed: true,
      }}
      setHook={setHook}
      title="gameTracker.dashboard.playStore.followingDevelopers.title"
      labelPath="gameTracker.dashboard.playStore.followingDevelopers"
      columnExtensions={[
        { columnName: "object_order", width: 66 },
        { columnName: "name", wordWrapEnabled: true },
        { columnName: "following", width: 150 },
        { columnName: "rate", width: 60 },
        { columnName: "url", width: 80 },
        { columnName: "release_date", width: 140 },
      ]}
      columns={[
        "object_order",
        "name",
        "rate",
        "last_release_date",
        "following"
      ]}
      hiddenSortFields={["following"]}
      dateTimeFields={[
        { targetField: "last_release_date" },
      ]}
      customCells={[
        {
          targetFields: ["name"], component: (row: any) =>
            <Name
              linkTo={`makers/maker/${row.row?.id}`}
              name={row.row?.name}
              url={row.row?.url}
            />
        },
        {
          targetFields: ["following"], component: (row: any) =>
            <FollowingStatus
              key={row.row.id}
              isFollowing={row.value}
              followUrl={`/ps_developers/follow_developer/${row.row?.id}`}
              onSuccess={() => hook?.fetch()}
            />
        },
      ]}
    />
  )
}

export default FollowingArtists
