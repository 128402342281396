import React from 'react';
import { useLang, useUser } from '@guibil/app';
import { Link } from 'react-router-dom';
import { commonCSS } from '@guibil/styles';
import { makeStyles } from '@material-ui/core';
import { classNames } from '@guibil/components';
import HomeIcon from '@material-ui/icons/Home';
import { useGuiBreadcrumbCrumbs } from './hooks/useGuiBreadcrumbCrumbs';
import { IGuiBreadcrumb } from '../types';

export const GuiBreadcrumb: React.FC<IGuiBreadcrumb> = (props) => {
  const { config, openDrawer } = props;
  const { isAuth } = useUser();
  const { crumbs } = useGuiBreadcrumbCrumbs(isAuth ? config.auth : config.guest);
  const classes = useStyles();
  const lang = useLang();


  if (crumbs.length <= 1) return null;

  return (
    <div className={classNames(commonCSS.breadcrumb, classes.breadcrumb, openDrawer === false && classes.breadcrumbShifted)}>
      {
        crumbs.map(({
          isFirst, isLast, title, path
        }) => {
          const classNamesArray = [];
          const text = (isFirst ? '' : '')
            + (lang(title) || 'unnamed');

          if (isFirst) { classNamesArray.push('first-item'); }
          else if (isLast) { classNamesArray.push('last-item'); }
          else { classNamesArray.push('mid-item'); }

          if (isFirst) {
            return (
              <span key={path} className={classes.firstItem}>
                <Link to={path}><HomeIcon /></Link>
              </span>
            );
          }

          return (
            <span key={path} className={classNamesArray.join(' ')}>
              <Link to={path}>{text}</Link>
            </span>
          );
        })
      }
    </div>
  );
};

const useStyles = makeStyles({
  breadcrumb: {
    display: 'flex',
    '& > span': {
      position: 'relative',
      backgroundColor: 'var(--bgLight)',
      padding: '.8rem 1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      width: 260,
      height: 38,
      clipPath: 'polygon(88% 0, 100% 50%, 88% 100%, 0% 100%, 12% 50%, 0% 0%)',
      marginLeft: -25,
      whiteSpace: 'nowrap',
      fontSize: '0.8rem',
    },
  },
  breadcrumbShifted: {
    marginLeft: 43,
  },
  firstItem: {
    marginLeft: '0 !important',
    width: '70px !important',
    clipPath: 'polygon(56% 0, 100% 50%, 56% 100%, 0% 100%, 0% 50%, 0% 0%) !important',
    paddingLeft: '0px !important',
    '& svg': {
      width: 28,
      height: 28,
    },
  },
});
