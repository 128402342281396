import { installations_en } from "../installations/locales/en_US";
import { installation_details_en } from "../installations/installation-details/locales/en_US";
import { gids_en } from "../gids/locales/en_US";
import { gid_display_en } from "../gid-display/locales/en_US";
import { evaluation_en } from "../evaluation/locales/en_US";

export const development_en = {
  breadcrumbTitle: "Development",
  pageTitle: "Development",
  title: "Development",
  menuTitle: "Development",

  installations: installations_en,
  installationDetails: installation_details_en,
  gids: gids_en,
  gidDisplay: gid_display_en,
  evaluation: evaluation_en,
};
