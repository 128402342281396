import { IRequestError, useLang } from '@guibil/app';
import { commonCSS } from '@guibil/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GuiButton } from './GuiButton';

interface IProps {
  error?: IRequestError
}

export function GuiRequestError(props: IProps) {
  const { error } = props;
  const lang = useLang();

  if (!error) {
    return null;
  }

  const { title, message, catchedFunction } = error;

  return (
    (message && typeof (message) === "string") ?
      <div className={commonCSS.errorOutline}>
        <div className={commonCSS.generalErrorOutline}>
          <FontAwesomeIcon icon="exclamation-triangle" color="inherit" className={commonCSS.generalErrorIcon} />
          <div className={commonCSS.generalErrorText}>
            {lang(title || 'guibil:components.requestError.title')}
            <p>{message}</p>
          </div>
        </div>
        {typeof (catchedFunction) === 'function' && (
          <div className={commonCSS.buttonContainer}>
            <GuiButton text="guibil:components.requestError.button" icon="sync-alt" onClick={catchedFunction} />
          </div>
        )}
      </div> : <></>
  );
}
