import { GuiForm, GuiText, useGuiFormData } from '@guibil/form';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import { DATE_TIME_HUMAN_FORMAT } from '@guibil/form';
import moment from 'moment';
import { useRequest } from '@guibil/api';
import { GuiButton, guiNotifier } from '@guibil/components';
import validation from './validation';
import { useLang } from '@guibil/app';

interface CommentProps {
  id: number,
  username?: string,
  fullname?: string,
  is_editable: boolean,
  comment: string,
  created_datetime: string,
}

interface CommentsProps {
  label: string;
  onSuccess: any,
  postUrl: string,
  updateUrl: string,
  comments: CommentProps[]
}

const Comments: React.FC<CommentsProps> = ({ label, onSuccess, postUrl, updateUrl, comments }) => {
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();


  const NewComment: React.FC = () => {
    const submitHandler = async (data: any) => {
      await req.post(postUrl, { comment: data?.comment })
        .then(() => { onSuccess() })
        .catch((err: any) => guiNotifier().handleError(err))
    }

    return <div className={classes.comment}>
      <GuiForm
        type="writer"
        isDialogForm
        fetchData={() => { return { comment: "" } }}
        labelPath="gameTracker.components.comment"
        validationSchema={validation}
        refreshConfig={{
          hide: true,
          onRefresh: () => null
        }}
        mainButtonsConfig={{
          dialogSaveText: "gameTracker.components.comment.send",
        }}
        submitHandler={submitHandler}
      >
        <GuiText
          multiline
          maxLength={1000}
          field="comment"
        />
      </GuiForm >
    </div>
  }

  const OldComment: React.FC<CommentProps> = ({ id, comment, is_editable, created_datetime, fullname }) => {
    const [data, setData] = useGuiFormData();

    const submitHandler = async () => {
      await req.put(updateUrl, { id, comment: data?.values?.comment })
        .then(() => { onSuccess() })
    }

    return (
      <div className={classes.commentOld}>
        <GuiForm
          type="writer"
          isDialogForm
          setData={setData}
          validationSchema={validation}
          labelPath="gameTracker.components.comment"
          refreshConfig={{
            hide: true,
            onRefresh: () => null,
          }}
          hideButtons={true}
          fetchData={() => { return { comment: comment } }}
        >
          <div className={classes.commentInfo}>
            <div className={classes.text}>
              {fullname}
            </div>
            <div>
              {
                moment(created_datetime).isValid() ?
                  <span className={classes.text}>{moment(created_datetime).format(DATE_TIME_HUMAN_FORMAT)}</span> : ""
              }
            </div>
          </div>

          <div>
            <GuiText
              multiline
              editMode={is_editable}
              maxLength={1000}
              field="comment"
            />
            {
              is_editable &&
              <div>
                <GuiButton
                  onClick={submitHandler}
                  text="gameTracker.components.comment.update"
                  icon="edit"
                  variant="cancel"
                  disabled={!(data?.isValid)}
                  nativeProps={{ classes: { root: classes.updateButton } }}
                />
              </div>
            }
          </div>
        </GuiForm >
      </div>
    )
  }

  return (
    <div className={classes.commentRoot}>
      <div className={classes.label}>
        {label && lang(label)}
      </div>
      <div className={classes.container}>
        <NewComment />
      </div>
      {
        comments.map((comment: CommentProps, index: number) => {
          return <OldComment key={index} {...comment} />
        })
      }
    </div>
  )
}

const useStyles = makeStyles({
  label: {
    paddingTop: "4px",
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
  },
  container: {
    marginTop: "-10px",
    paddingBottom: "16px"
  },
  comment: {
    borderRadius: "2px",
    paddingTop: "0px",
    "& .MuiFormLabel-root": {
      display: "none",
    },
    "& > form": {
      "& div": {
        padding: "0px !important",
        "& div": {
          "& div": {
            "& div:nth-child(2)": {
              marginTop: "4px !important"
            },
          },
        }
      },
    },
  },
  commentOld: {
    "& > form": {
      "& div": {
        padding: "0px !important",
      },
    },
  },
  commentRoot: {
    maxHeight: 400,
    overflowY: "auto",
    overflowX: "hidden",

    "&::-webkit-scrollbar-track": {
      backgroundColor: "var(--primaryGridBorderColor2)",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar": {
      width: 10,
      height: 10,
      borderRadius: "2px",
      backgroundColor: "var(--primaryGridBorderColor2)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#8e8e8e",
    }
  },
  text: {
    color: "var(--titleColor) !important",
    fontSize: "12px",
  },
  commentInfo: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "7px",
    marginRight: "7px",
  },
  updateButton: {
    height: "36px !important",
    marginTop: "27px !important",
  },
});

export default Comments
