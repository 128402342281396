import React, { useMemo } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { mapArrayOrObject } from '@guibil/helpers';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Filter } from '@devexpress/dx-react-grid';
import { GuiEnums, useLang } from '@guibil/app';
import { commonCSS } from '@guibil/styles';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { IGuiGridFilterSelectionConfig } from '@guibil/grid/types';
import { classNames, getTranslatedSwitchText } from '@guibil/components';
import { IGuiGridFilterCustomizedCellProps } from './GuiGridFilterCell';
import { TableCell } from '@material-ui/core';

type ISelectionConfig = IGuiGridFilterSelectionConfig | undefined;

const GuiGridGenericDropDownFilterCell: React.FC<IGuiGridFilterCustomizedCellProps> = (props) => {
  const { filterSelections, enumCells, booleanFields, showFilterSelector } = useGuiGridPropsContext();
  const { filter, onFilter, column, defaultoperation } = props;
  const lang = useLang();


  const onChange = (e: any) => {

    const newFilter: Filter = {
      columnName: column.name,
      value: e.target.value,
      operation: filter?.operation || defaultoperation
    };
    onFilter(newFilter.value ? newFilter : null);
  };

  const getSelectionFromFilterSelections = (): ISelectionConfig => {
    return filterSelections?.find((filter) => filter.targetField === column.name);
  }
  const getSelectionFromEnumCells = (): ISelectionConfig => {
    const enumConfig = enumCells?.find(filter => filter.targetField === column.name);

    if (!enumConfig) { return undefined; }
    return {
      targetField: enumConfig.targetField,
      selectionsEnum: enumConfig.enumObject,
    }
  }
  const getSelectionFromBooleanCells = (): ISelectionConfig => {
    const boolConfig = booleanFields?.find(filter => filter.targetField === column.name);

    if (!boolConfig) { return undefined; }
    return {
      targetField: boolConfig.targetField,
      selections: getTranslatedSwitchText(boolConfig?.switchText, lang, {
        activeText: boolConfig?.activeText,
        passiveText: boolConfig?.passiveText,
      }),
    }
  }

  const selections = useMemo(() => {
    const filterSelection = getSelectionFromFilterSelections() || getSelectionFromEnumCells() || getSelectionFromBooleanCells();
    if (!filterSelection) { return null; }

    let selections = filterSelection.selections || {};
    if (filterSelection.selectionsEnum) {
      selections = GuiEnums.get(filterSelection.selectionsEnum);
    }
    return selections;
  }, [lang, filterSelections, enumCells, booleanFields]);

  if (!selections) {
    return <TableFilterRow.Cell {...props} className={commonCSS.gridFilterText} />;
  }


  const dropdownOptionsContainer = mapArrayOrObject(selections, (optionValue, key, type) => {
    let value = key;
    /**
     * If selection is enum|array, then we need to use optionValue
     * as value, since enums' value converted into optionValue
    */
    if (type === 'array') { value = optionValue; }
    
    return (
      <MenuItem key={`${key}-${value}`} value={optionValue}>
        {optionValue}
      </MenuItem>
    );
  });

  let filterSelector: any = null;
  if (props.filteringEnabled && Array.isArray(props.children) && props.children.length === 2) {
    filterSelector = props.children[0];
  }

  return (
    <TableCell className={classNames(commonCSS.dropdownFilterItem)}>
      <div>
        {showFilterSelector && filterSelector.props.availableValues.length > 1 && filterSelector}
        <Select
          fullWidth
          variant="outlined"
          value={filter ? filter.value : ''}
          onChange={onChange}
          classes={{ root: commonCSS.gridFilterSelect }}
          className={commonCSS.gridFilterContainer}
        >
          <MenuItem value="">
            {lang('guibil:grid.button.clear')}
            {' '}
          </MenuItem>
          {dropdownOptionsContainer}
        </Select>
      </div>
    </TableCell>
  );
};

export { GuiGridGenericDropDownFilterCell };
