import { useLang } from '@guibil/app';
import { commonCSS } from '@guibil/styles';
import React, { useRef } from 'react';
interface IGuiVideoViewerProps {
  src: string
  width?: number
  height?: number
  mute?: boolean
  autoplay?: boolean //Chromium browsers do not allow autoplay in most cases. However, muted autoplay is always allowed.
  loop?: boolean
  controls?: boolean
  className?: string
  hook?: any
}

type COMMAND = 'playVideo' | 'pauseVideo' | 'stopVideo';

export const GuiVideoViewer: React.FC<IGuiVideoViewerProps> = (props) => {

  const { src, width, height, mute, autoplay, loop, controls, hook } = props;
  const lang = useLang();

  const isYoutube = src.includes("youtube") || src.includes("youtu.be");

  let _src = src; //for manipulation for YouTube
  const ytRef = useRef<HTMLIFrameElement>(null);
  const nativeRef = useRef<HTMLVideoElement>(null);

  if (isYoutube) {
    //check for, the source is embedded form if it is not convert it
    //https://www.youtube.com/watch?v=xxxxx ---> https://www.youtube.com/embed/xxxxx

    if (!src.includes("/embed/")) {
      let index = src.indexOf("watch?v=")
      if (index != -1) {
        let endIndex = src.indexOf("?", index + 8);
        if (endIndex === -1) endIndex = src.length;
        _src = src.substring(0, index) + "embed/" + src.substring(index + 8, endIndex);
      }
    }

    if (mute || autoplay || !controls || loop) _src += "?";
    if (mute) { _src += "&mute=1" }
    if (autoplay) { _src += "&autoplay=1" }
    if (loop) { _src += "&loop=1" }
    if (!controls) { _src += "&controls=0" }

  }

  const nativeVideoPlayer = <>
    <video ref={nativeRef} className={commonCSS.viewer} width={width} height={height} controls={controls} muted={mute} autoPlay={autoplay} loop={loop} >
      <source src={src} type="video/mp4" />
      <source src={src} type="video/webm" />
      <source src={src} type="video/opgg" />
      {lang("guibil:form.unsupportedVideo")}
    </video>
  </>

  const youtubeVideoPlayer = <div className={commonCSS.iframeViewer}>
    <iframe ref={ytRef} width={width} height={height} src={_src}></iframe>
  </div>

  const controlVideo = (command: COMMAND) => {

    if (isYoutube && ytRef.current) {

      ytRef.current.contentWindow?.postMessage(JSON.stringify({
        'event': 'command',
        'func': command,
        'args': "args" || []
      }), "*")
      //Do not try to control a YouTube video from localhost. You will probably get a CORS Policy error!.
      //console.log(ytRef.current.contentWindow) //To Debug, use this
    } else {
      if (nativeRef.current) {
        switch (command) {
          case 'playVideo':
            nativeRef.current.play();
            break;
          case 'pauseVideo':
            nativeRef.current.pause();
            break;
          case 'stopVideo':
            //
            break;

        }

      }
    }

  }

  if (hook) {
    hook.data = { ...hook.data, controlVideo: controlVideo }
  }

  return <>
    {
      isYoutube ? youtubeVideoPlayer : nativeVideoPlayer
    }
  </>
}

GuiVideoViewer.defaultProps = {
  controls: true,
  mute: false,
  autoplay: false,
  loop: false
}

