import { classNames } from '@guibil/components';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import MenuOpen from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/icons/Menu';
import GuiMenu from './includes/GuiMenu';

const drawerWidth = 360;

const GuiAppFrame = (props: { openDrawer: boolean, setOpenDrawer: Function }) => {
  const classes = useStyles();
  const { openDrawer, setOpenDrawer } = props;

  const handleDrawerOpen = () => setOpenDrawer(!openDrawer);

  return (
    <>
      <div className={classNames(classes.menuIcon, openDrawer ? classes.menuOpen : classes.menuClsoed)}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={classes.menuButton}
        >
          {openDrawer ? <MenuOpen /> : <Menu />}
        </IconButton>
      </div>

      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !openDrawer && classes.drawerPaperClose),
        }}
      >
        <GuiMenu setOpenDrawer={setOpenDrawer} />
      </Drawer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    position: "absolute",
    zIndex: 1300,
  },
  menuOpen: {
    left: 322,
  },
  menuClsoed: {
    left: 24,
  },
  menuButton: {
    top: 18,
    width: 40,
    height: 40,
    background: "var(--menuBgSolid) !important",
    '& svg': {
      width: '30px',
      height: '30px',
      color: "var(--titleColor)"
    },
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    border: "none !important",
    width: drawerWidth,
    backgroundColor: "var(--menuBgSolid)",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: "var(--accordionColor) 0px 0px 10px 1px;",
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    boxShadow: "none",
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default GuiAppFrame;
