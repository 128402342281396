import React from 'react';
import { GuiImage } from '../GuiImage';

interface IProps {
  src: string,
}
export const GuiAvatarViewer: React.FC<IProps> = (props) => {
  const { src } = props;

  return (
    <GuiImage src={src} height={120} width={120} style={{ objectFit: 'cover', borderRadius: '50%' }} />
  );
};
