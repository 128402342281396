import React from 'react';
import { GuiGenericDialog } from '@guibil/components';
import {
  GuiForm, GuiText,
} from '@guibil/form';
import { Grid } from '@material-ui/core';
import validationSchema from './validation';

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
  id: string;
  username: string;
}
const UserPasswordUpdate: React.FC<IProps> = (props) => {
  const { onClose, onSuccess, id, username } = props;

  return (
    <GuiGenericDialog isOpen title="gameTracker.userManagement.updatePassword.title" onClose={onClose}>
      <GuiForm
        fetchData={() => ({
          username,
          password: "",
          password_confirm: ""
        })}
        type="new-form"
        isDialogForm
        config={{
          submitDataApi: 'users/maker_user/' + id,
          successMessage: "gameTracker.userManagement.updatePassword.successMessage",
          submitType: "post"
        }}
        labelPath="gameTracker.userManagement.updatePassword"
        validationSchema={validationSchema}
        successListener={onSuccess}
        onCancel={onClose}
        fieldsToDismissFromPost={["username"]}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GuiText field="username" disabled disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="password" type="password" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="password_confirm" type="password" disableAutoComplate />
          </Grid>
        </Grid>
      </GuiForm>
    </GuiGenericDialog>
  );
};

export default UserPasswordUpdate;
