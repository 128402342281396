import { Plugin } from '@devexpress/dx-react-core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { GuiGridActionCell } from '../../components/cells/GuiGridActionCell';
import { GuiGridBooleanCell } from '../../components/cells/GuiGridBooleanCell';
import { GuiGridFileCell } from '../../components/cells/GuiGridFileCell';
import { GuiGridPhoneNumberCell } from '@guibil/grid/components/cells/GuiGridPhoneNumberCell';
import { GuiGridDateTimeCell } from '@guibil/grid/components/cells/GuiGridDateTimeCell';
import { useGuiGridActionFields, useGuiGridBooleanFields, useGuiGridDateTimeFields, useGuiGridPhoneNumberFields, useGuiGridFileFields, useGuiGridTooltipFields } from '@guibil/grid/hooks/useGuiGridFields';
import { IGuiGridFilterGroupName, IGuiGridFilterLocalType } from '@guibil/grid/types';
import { GuiGridTooltipCell } from '@guibil/grid/components/cells/GuiGridTooltipCell';

const OPERATION_CONFIG: {
  [key in IGuiGridFilterGroupName]: IGuiGridFilterLocalType[]
} = {
  none: [],
  date: ['contains', 'startsWith', 'endsWith', 'equal', 'notEqual'],
  selection: ['equal'],
  number: ['equal', 'notEqual', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual'],
  string: ['contains', 'startsWith', 'endsWith', 'equal', 'notEqual'],
  default: ['equal', 'notEqual', 'contains', 'endsWith', 'startsWith', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
};



const GuiGridCustomizingFields = () => {
  const {
    /*actionFields, booleanFields, */ customCells, /*fileFields,
    phoneNumberFields, dateTimeFields, numberFields */
  } = useGuiGridPropsContext();
  const actionFieldsAr = useGuiGridActionFields();
  const booleanFieldAr = useGuiGridBooleanFields();
  const fileFieldsAr = useGuiGridFileFields();
  const phoneNumberFieldsAr = useGuiGridPhoneNumberFields();
  const dateTimeFieldsAr = useGuiGridDateTimeFields();
  const tooltipFieldsAr = useGuiGridTooltipFields();
  // const numberFieldsAr = useGuiGridNumberFields();

  return (
    <Plugin>
      <DataTypeProvider
        for={actionFieldsAr}
        formatterComponent={GuiGridActionCell}
      // availableFilterOperations={OPERATION_CONFIG.selection}
      />

      <DataTypeProvider
        for={booleanFieldAr}
        formatterComponent={GuiGridBooleanCell}
      // availableFilterOperations={OPERATION_CONFIG.selection}
      />
      <DataTypeProvider
        for={tooltipFieldsAr}
        formatterComponent={GuiGridTooltipCell}
      // availableFilterOperations={OPERATION_CONFIG.selection}
      />

      <DataTypeProvider
        for={fileFieldsAr}
        formatterComponent={GuiGridFileCell}
      />

      <DataTypeProvider
        for={phoneNumberFieldsAr}
        formatterComponent={GuiGridPhoneNumberCell}
      // availableFilterOperations={OPERATION_CONFIG.string}
      />

      <DataTypeProvider
        for={dateTimeFieldsAr}
        formatterComponent={GuiGridDateTimeCell}
        availableFilterOperations={OPERATION_CONFIG.string}
      />


      {(customCells || []).map((element, key) => (
        <DataTypeProvider
          key={key}
          for={element.targetFields}
          formatterComponent={element.component}
        />
      ))}
    </Plugin>
  );
};

export { GuiGridCustomizingFields };
