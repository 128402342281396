import React, { useState, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GuiGenericDialog } from './GuiGenericDialog';
import { makeStyles } from '@material-ui/core';

export interface IGuiGenericLoadingDialogConfig {
  description?: string
  loadingTime: number
  title?: string
  drag?: boolean
  hidePercentage?: boolean
}

interface IProps extends IGuiGenericLoadingDialogConfig {
  type: | 'infinite' | 'finite'
  isOpen?: boolean,
  onLoad?: Function
}

export function GuiGenericLoadingDialog(props: IProps) {
  const {
    type, title, description, drag, loadingTime, onLoad, hidePercentage,
  } = props;
  const classes = useClasses();

  const [count, setCount] = useState(0);
  const [completedPercent, setCompletedPercent] = useState(0);
  const [open, setOpen] = useState(false);

  // Component Hooks
  const [_description, _setDescription] = useState(description);
  const [_title, _setTitle] = useState(title);

  // componentWillReceiveProps
  useEffect(() => {
    setOpen(!!props.isOpen);
  }, [props]);

  const updateInterval = 100;

  useEffect(() => {
    // LinearProgress animation delay, 10000 is calibration number
    let timer: any = 0;
    if (loadingTime > 0 && updateInterval > 0 && loadingTime >= updateInterval) {
      timer = setTimeout(() => {
        const delta = 0.1 * (10000 / loadingTime);

        setCount(count + 1 + delta);
        setCompletedPercent(Math.min(Math.round(((count * updateInterval) / loadingTime) * 100), 100));
      }, updateInterval);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [count]);

  useEffect(() => {
    let timer: any = 0;
    if (loadingTime > 0 && updateInterval > 0 && loadingTime >= updateInterval) {
      timer = setTimeout(() => {
        setCompletedPercent(100);

        (async () => {
          if (onLoad) {
            await onLoad({
              // component hook functions
              // Be careful when using!
              setTitle: (title: string) => { if (title) _setTitle(title); },
              setDescription: (description: string) => { if (description) _setDescription(description); },

            });
          }
        })();
      }, loadingTime);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <GuiGenericDialog
      isOpen={!!open}
      hideCloseButton
      dismissible={false}
      title={_title || 'guibil:components.loadingDialog.title'}
      description={_description || 'guibil:components.loadingDialog.description'}
      drag={!!drag}
      className={classes.dialog}
    >
      <DialogContent>
        {!hidePercentage && (type === 'finite') && (
          <DialogContentText>
            {completedPercent}
            %
          </DialogContentText>
        )}
        {
          (type === 'finite') ? <LinearProgress className={classes.bar} variant="determinate" value={completedPercent} /> : <LinearProgress />
        }
      </DialogContent>
    </GuiGenericDialog>
  );
}

const useClasses = makeStyles({
  dialog: {
    "& h2": {
      fontSize: "1.2rem !important"
    },
    "& p": {
      textAlign: "center",
      fontWeight: "bold"
    },
  },
  bar: {
    height: 10,
    borderRadius: 7,
    "& > div": {
      borderRadius: 7,
      backgroundColor: "var(--primaryBlue)"
    }
  }
})