export const play_store_games_en = {
  title: "Games",

  games: {
    title: "Games",
    object_order: "Index",
    logo_image_url: "Logo",
    name: "Name",
    developer: "Developer",
    rate: "Rate",
    store_rate: "Store Rate",
    release_date: "Release Date",
    last_release_date: "Last Release Date",
    follow: "Follow",

    showTags: "Tags",
    tags: {
      title: "Game Tags",
      createButton: "Add",
      deleteConfirmMessage: "Are you sure you want to delete this tag?",
      deleteSuccessMessage: "Tag deleted successfully",
      tag: "Tag",
      actions: " ",
      create: {
        title: "Add Tag",
        tag: "New Tag",
      },
    },
  },

  gameFilter: {
    filter: "Filter",
    name: "Name",
    genre: "Genre",
    min_release_date: "Min Release Date",
    max_release_date: "Max Release Date",
    min_last_release_date: "Min Last Release Date",
    max_last_release_date: "Max Last Release Date",
    rate: "Rate",
    store_rate: "Store Rate",
    tag: "Tag",
  },
};
