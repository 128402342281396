import { game_artist_history_tr } from "../game-artist-history/locales/tr_TR";
import { new_in_top_games_tr } from "../new-in-top-games/locales/tr_TR";
import { notification_tr } from "../notification/locales/tr_TR";

export const notifications_tr = {
  breadcrumbTitle: "Bildirimler",
  pageTitle: "Bildirimler",
  title: "Bildirimler",
  menuTitle: "Bildirimler",

  notification: notification_tr,
  gameArtistHistory: game_artist_history_tr,
  newInTopGames: new_in_top_games_tr,
};
