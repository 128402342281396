import { commonCSS } from '@guibil/styles';
import React from 'react';

interface IProps {
  src: string,
}
export const GuiPdfViewer: React.FC<IProps> = (props) => {
  const { src } = props;

  return (
    <iframe src={src} className={commonCSS.viewer} width="1000" height="1400" />
  );
};
