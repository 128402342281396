import { useLang } from '@guibil/app';
import Account from '@guibil/styles/icons/Account';
import { Grid, ListItem, ListItemIcon, ListItemText, makeStyles, Menu } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import Language from "./language/Language";
import Theme from './theme/Theme';

const SettingsItem = () => {
  const classes = useStyles();
  const lang = useLang();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem disableRipple button classes={{ root: classes.linkButton }} aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e)} >
        <ListItemIcon classes={{ root: classes.icon }}>
          <div>
            <Account />
          </div>
        </ListItemIcon>
        <ListItemText className={classes.linkItemText} primary={lang("routes.settings")} />
        <ExpandMore classes={{ root: classes.icon }} />
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        classes={{ paper: classes.menuPaper }}
      >
        <h3 className={classes.title}>{lang("routes.settings")}</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Language />
          </Grid>
          <Grid item xs={12}>
            <Theme />
          </Grid>
        </Grid>
      </Menu>
    </>
  )
}

const useStyles = makeStyles(() => ({
  title: {
    color: "var(--titleColor)",
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 15,
    outline: "none !important"
  },
  icon: {
    color: 'inherit',
    minWidth: 30,
    fontSize: '1.6rem',
    '& svg': {
      width: '20px !important',
    },
  },
  linkButton: {
    borderRadius: '2px',
    paddingTop: 0,
    paddingLeft: 18,
    paddingBottom: 0,
    color: "var(--titleColor)",
    '&:hover': {
      backgroundColor: "transparent",
      "& > div": {
        opacity: 0.6,
      }
    },
    '& span': {
      fontSize: "18px !important",
      fontWeight: 500,
      letterSpacing: -0.75,
      color: 'inherit',
    },
  },
  linkItemText: {
    "& span": {
      fontSize: "16px !important",
      fontWeight: 500,
      letterSpacing: -0.75,
    }
  },
  menu: {
    marginLeft: 70,
  },
  menuPaper: {
    width: "300px",
    padding: 20,
    borderRadius: "2px",
    backgroundColor: "var(--bgPrimary) !important",
    "& li": {
      fontWeight: 700,
      fontSize: 14,
      color: "var(--secondaryTextColor)",
      paddingTop: 8
    },
    "& div": {
      outline: "none !important"
    }
  }
}));

export default SettingsItem
