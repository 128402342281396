import React, { useState } from 'react';
import { GuiTooltip } from '@guibil/components';

interface IGuiImageProps {
  src: string
  width?: number
  height?: number
  loading?: 'eager' | 'lazy',
  protect?: boolean
  fallback?: string | JSX.Element
  alt?: string
  [key: string]: any,
}

export const GuiImage: React.FC<IGuiImageProps> = (props) => {
  const {
    src, width, height, loading, protect, fallback, alt, ...otherProps
  } = props;

  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  const IMAGE = (
    <GuiTooltip title={alt}>
      <img
        src={src}
        alt={alt}
        onError={onError}
        width={width}
        height={height}
        draggable={!protect}
        loading={loading}
        onContextMenu={(e) => { if (protect) { e.preventDefault(); } }}
        {...otherProps}
      />
    </GuiTooltip>
  );

  return (
    <>
      {error ? fallback : IMAGE}
    </>
  );
};
