import { app_store_game_artist_history_tr } from "../app-store/locales/tr_TR";
import { play_store_game_developer_history_tr } from "../play-store/locales/tr_TR";

export const game_artist_history_tr = {
  breadcrumbTitle: "Yapımcı Değişiklikleri",
  pageTitle: "Yapımcı Değişiklikleri",
  menuTitle: "Yapımcı Değişiklikleri",
  title: "Yapımcı Değişiklikleri",

  appStore: app_store_game_artist_history_tr,
  playStore: play_store_game_developer_history_tr,
};
