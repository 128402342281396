import { Column, GridExporter } from "@devexpress/dx-react-grid-export";
import { useGuiGridActionsContext } from "@guibil/grid/contexts/GuiGridActionsStateContext";
import React from "react";
import { saveAs } from "file-saver";
import { useGuiGridStateContext } from "@guibil/grid/contexts/GuiGridStateContext";
import { useGuiGridPropsContext } from "@guibil/grid/contexts/GuiGridPropsContext";
import { GuiEnums, useLang } from "@guibil/app";
import moment from "moment";
import { DATE_TIME_HUMAN_FORMAT } from "@guibil/form";

interface IProps {
  columns: Column[];
}

const GuiGridExporter: React.FC<IProps> = ({ columns }) => {
  const lang = useLang();
  const { exporter } = useGuiGridActionsContext();
  const { data } = useGuiGridStateContext();
  const { rows, excelExporterProps, title, dateTimeFields } = useGuiGridPropsContext();

  const fileName: string = excelExporterProps?.fileName || (typeof (title) === "string" && title) || document.title;

  let _lock = false;
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      if (_lock) return;
      _lock = true;
      setTimeout(() => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          (lang(fileName)) + ".xlsx"
        );
        _lock = false;
      }, 100);
    });
  };

  const columnData: any = JSON.parse(JSON.stringify(rows || data.list));

  if (excelExporterProps?.enumCells) {
    columnData.forEach((_: any, i: any) => {
      excelExporterProps.enumCells?.forEach(({ targetField, enumObject }) => {

        columnData[i][targetField] = GuiEnums.get(enumObject, columnData[i][targetField])
      })
    })
  }
  if (dateTimeFields) {
    columnData.forEach((_: any, i: number) => {
      dateTimeFields?.forEach(({ targetField }) => {
        try {
          if (!columnData[i][targetField]) {
            columnData[i][targetField] = "-"
          }
          const momentValue = moment(columnData[i][targetField]);
          if (momentValue.isValid()) {
            columnData[i][targetField] = momentValue.format(DATE_TIME_HUMAN_FORMAT);
          }
        } catch (err) { }

      })
    })
  }

  if (excelExporterProps?.customFields) {
    columnData.forEach((row: any, i: number) => {
      excelExporterProps.customFields?.forEach(({ targetField, exportAs }) => {
        columnData[i][targetField] = exportAs({ row: row });
      })
    })
  }

  if (excelExporterProps?.booleanFields) {
    columnData.forEach((_: any, i: number) => {
      excelExporterProps.booleanFields?.forEach(({ targetField, switchText, activeText, passiveText }) => {

        columnData[i][targetField] = switchText ? lang(switchText!) : columnData[i][targetField] ? lang(activeText!) : lang(passiveText!)
      })
    })
  }

  let hiddenColumnNames: any = [];

  if (excelExporterProps?.columnNames) {
    columns.forEach((column: any) => {
      if (!excelExporterProps?.columnNames?.includes(column?.name)) hiddenColumnNames.push(column?.name);
    })
  }

  return (
    <GridExporter
      ref={exporter.exportRef}
      rows={columnData}
      columns={columns}
      hiddenColumnNames={hiddenColumnNames}
      onSave={onSave}
      {...excelExporterProps}
    />
  );
};

export default GuiGridExporter;
