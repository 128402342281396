export const play_store_genre_enum = [
  "GAME",
  "GAME_ARCADE",
  "GAME_PUZZLE",
  "GAME_CARD",
  "GAME_CASUAL",
  "GAME_RACING",
  "GAME_SPORTS",
  "GAME_ACTION",
  "GAME_ADVENTURE",
  "GAME_BOARD",
  "GAME_CASINO",
  "GAME_EDUCATIONAL",
  "GAME_MUSIC",
  "GAME_ROLE_PLAYING",
  "GAME_SIMULATION",
  "GAME_STRATEGY",
  "GAME_TRIVIA",
  "GAME_WORD",
];
