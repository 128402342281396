import { guiNotifier } from '@guibil/components';
import React, { useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GuiBrowserRouterContext } from './contexts/GuiBrowserRouterContext';
import { GuiRouteParamsContext } from './contexts/GuiRouteParamsContext';
import { IGuiBrowserRouter } from './types';

export const GuiBrowserRouter: React.FC<IGuiBrowserRouter> = (props) => {
  const { modulePermitted, basename } = props;

  const contextValues = {
    modulePermitted,
  };

  const getUserConfirmation = useCallback((message, callback) => {
    guiNotifier()
      .confirm({ description: message },
        () => { callback(true) },
        () => { callback(false) }
      )
  }, []);


  return (
    <BrowserRouter
      getUserConfirmation={getUserConfirmation}
      basename={basename}
    >
      <GuiBrowserRouterContext.Provider value={contextValues}>
        <GuiRouteParamsContext.Provider value={{}}>
          {props.children}
        </GuiRouteParamsContext.Provider>
      </GuiBrowserRouterContext.Provider>
    </BrowserRouter>
  );
};
