import { app_store_new_in_top_games_en } from "../app-store/locales/en_US";
import { play_store_new_in_top_games_en } from "../play-store/locales/en_US";

export const new_in_top_games_en = {
  breadcrumbTitle: "New In Top Games",
  pageTitle: "New In Top Games",
  menuTitle: "New In Top Games",
  title: "New In Top Games",

  appStore: app_store_new_in_top_games_en,
  playStore: play_store_new_in_top_games_en,
};
