export enum AppStoreGenre {
	// @ts-ignore
	"6014" = "6014",
	// @ts-ignore
	"7001" = "7001",
	// @ts-ignore
	"7002" = "7002",
	// @ts-ignore
	"7003" = "7003",
	// @ts-ignore
	"7004" = "7004",
	// @ts-ignore
	"7005" = "7005",
	// @ts-ignore
	"7006" = "7006",
	// @ts-ignore
	"7007" = "7007",
	// @ts-ignore
	"7008" = "7008",
	// @ts-ignore
	"7009" = "7009",
	// @ts-ignore
	"7011" = "7011",
	// @ts-ignore
	"7012" = "7012",
	// @ts-ignore
	"7013" = "7013",
	// @ts-ignore
	"7014" = "7014",
	// @ts-ignore
	"7015" = "7015",
	// @ts-ignore
	"7016" = "7016",
	// @ts-ignore
	"7017" = "7017",
	// @ts-ignore
	"7018" = "7018",
	// @ts-ignore
	"7019" = "7019",
}
