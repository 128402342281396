import { useRequest } from '@guibil/api'
import { GuiEnums, useLang, useUser } from '@guibil/app'
import { GuiButton, guiNotifier, useWindowSize } from '@guibil/components'
import { GuiDateTimePicker, GuiDropdown, GuiForm, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form'
import { GuiGrid } from '@guibil/grid'
import GuiGridRowButton from '@guibil/grid/components/buttons/GuiGridRowButton'
import { IUseGuiGridHook } from '@guibil/grid/types'
import { commonCSS } from '@guibil/styles'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GidStatus } from 'app/enums/app/general/gid-status/enum_type'
import Name from 'pages/components/Name'
import Rate from 'pages/components/Rate'
import { TagProps } from 'pages/components/Tagger'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UpdateGidDialog from '../update-gid/UpdateGidDialog'

type IGuiGridHook = IUseGuiGridHook | undefined;

interface GidListProps {
  status: GidStatus,
  onSuccess: () => void,
  hook: IGuiGridHook,
  setHook: React.Dispatch<React.SetStateAction<IGuiGridHook>>,
}

const GidList: React.FC<GidListProps> = ({ status, onSuccess, hook, setHook }) => {
  const user = useUser();
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();
  const history = useHistory();
  const { width } = useWindowSize();

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [updateGidDialogId, setUpdateGidDialogId] = useState<number | null>(null);
  const [genres, setGenres] = useState<string[]>([]);
  const [genresLoading, setGenresLoading] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<TagProps[]>([]);
  const [allUserNames, setAllUserNames] = useState<string[]>([]);
  const [allUsersLoading, setAllUsersLoading] = useState<boolean>(false);

  const [filterData, setFilterData] = useGuiFormData();
  const [filterHook, setFilterHook] = useGuiFormHook();
  const [filterParams, setFilterParams] = useState<any | undefined>({
    status: status,
    title: "",
    created_by: "",
    genre: "",
    rate: "",
    min_created_datetime: "",
    max_created_datetime: "",
    min_updated_datetime: "",
    max_updated_datetime: "",
  });

  useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

  useEffect(() => {
    async function get() {
      await Promise.all([getGenres(), getAllUsers()])
    }
    get();
  }, [])


  const getGenres = async () => {
    setGenresLoading(true);
    const res = await req.get("gids/genre/genres");
    setGenres(res.result.map((item: any) => item.genre));
    setGenresLoading(false);
  }

  const getAllUsers = async () => {
    setAllUsersLoading(true);
    const res = await req.get("gids/gid/all_users");
    setAllUsers(res);
    setAllUserNames(res.map((user: TagProps) => user.tag));
    setAllUsersLoading(false);
  }

  const handleSetFilter = () => {
    hook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
    hook?.setQueryParams({
      ...filterData?.values,
      created_by: allUsers.find(user => user.tag === filterData?.values.created_by)?.slug || ""
    })
  }

  const handleResetFilter = () => {
    setFilterParams(undefined);
    hook?.setQueryParams({ status: status });
    filterHook?.fetch();
  }

  const Action = (row: any) => {
    const { id } = row.row;

    const handleDelete = async () => {
      await req.delete("gids/gid/delete/" + id).then(() => {
        guiNotifier().success("gameTracker.development.gids.listGid.deleteSuccessMessage");
        onSuccess();
      });
    };

    const onDelete = () => guiNotifier().confirm({
      description: lang("gameTracker.development.gids.listGid.deleteConfirmMessage")
    }, handleDelete)

    const onUpdate = () => setUpdateGidDialogId(id);

    const onDisplay = () => history.push("/development/gids/gid/" + id);

    return <div>
      <GuiGridRowButton onClick={onDisplay} type="detail" />
      <GuiGridRowButton onClick={onUpdate} type="update" />
      <GuiGridRowButton hide={user.role !== "MANAGER"} onClick={onDelete} type="delete" />
    </div>
  }

  const Title = (row: any) => {
    const { id, title } = row.row;
    const link = "/development/gids/gid/" + id;
    return <Name
      linkTo={link}
      name={title}
      isUrl={true}
      url={link}
    />
  }

  const RateCo = (row: any) => {
    const { rate } = row.row;
    return <div className={classes.rate}>
      <Rate
        disabled
        withoutBg
        rate={rate}
      />
    </div>
  }


  return <Grid container spacing={0}>
    <Grid item xs={12}>
      <GuiForm
        fetchData={() => filterParams}
        setHook={setFilterHook}
        setData={setFilterData}
        showForm={!showFilter}
        title="gameTracker.development.gids.filterGid.filter"
        labelPath="gameTracker.development.gids.filterGid"
        disableLeaveFormPrompt
        headerComponent={
          <div>
            <GuiButton
              onClick={() => setShowFilter(!showFilter)}
              nativeProps={{ classes: { root: commonCSS.iconButton } }}
              variant="cancel"
              icon={showFilter ? "eye-slash" : "eye"}
            />
            <GuiButton
              onClick={handleResetFilter}
              nativeProps={{ classes: { root: commonCSS.iconButton } }}
              variant="cancel"
              icon="undo-alt"
            />
            <GuiButton
              onClick={handleSetFilter}
              nativeProps={{ classes: { root: commonCSS.iconButton } }}
              variant="success"
              icon="filter"
            />
          </div>
        }
        hideButtons
        type="writer"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <GuiText
              field="title"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <GuiDropdown
              field="created_by"
              selections={allUserNames}
              isLoading={allUsersLoading}
              showClearOption
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <GuiDropdown
              field="genre"
              selections={genres}
              isLoading={genresLoading}
              showClearOption
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <GuiDropdown
              field="rate"
              selections={[1, 2, 3, 4, 5]}
              showClearOption
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <GuiDateTimePicker
              field="min_created_datetime"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <GuiDateTimePicker
              field="max_created_datetime"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <GuiDateTimePicker
              field="min_updated_datetime"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <GuiDateTimePicker
              field="max_updated_datetime"
            />
          </Grid>
        </Grid>
      </GuiForm>
    </Grid>

    <Grid item xs={12}>
      <GuiGrid
        fetchData="gids/gid/summary"
        setHook={setHook}
        queryParams={{ status }}
        title={GuiEnums.get("GidStatus", status)}
        labelPath="gameTracker.development.gids.listGid"
        columnExtensions={[
          { columnName: "rate", width: 180 },
          { columnName: "action", width: user.role !== "MANAGER" ? 90 : 130 }
        ]}
        columns={[
          "title",
          "rate",
          "created_by",
          "created_datetime",
          "updated_by",
          "updated_datetime",
          "action",
        ]}
        hiddenSortFields={[
          "action"
        ]}
        customCells={[
          { targetFields: ["action"], component: Action },
          { targetFields: ["title"], component: Title },
          { targetFields: ["rate"], component: RateCo },
        ]}
        dateTimeFields={[
          { targetField: "created_datetime" },
          { targetField: "updated_datetime" },
        ]}
      >
        {
          updateGidDialogId && <UpdateGidDialog
            id={updateGidDialogId}
            onClose={() => setUpdateGidDialogId(null)}
            onSuccess={() => { setUpdateGidDialogId(null); onSuccess(); }}
          />
        }
      </GuiGrid>
    </Grid>
  </Grid>
}

const useStyles = makeStyles(() => ({
  rate: {
    "& div > div": {
      paddingTop: "0.02rem !important",
    }
  }
}))

export default GidList
