import React, { useState } from 'react'
import { GuiButton, GuiGenericDialog, guiNotifier } from '@guibil/components'
import { GuiForm, GuiText } from '@guibil/form'
import { Grid, makeStyles } from '@material-ui/core'
import validationSchema from './validation';
import { useRequest } from '@guibil/api';
import PageItem from 'pages/components/PageItem';
import { useLang } from '@guibil/app';
import { GuiGrid } from '@guibil/grid';
import Name from 'pages/components/Name';

interface NewArtistDialogProps {
  onClose: () => void,
  onSuccess: () => void,
}

const NewArtistDialog: React.FC<NewArtistDialogProps> = ({ onClose, onSuccess }) => {
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();
  const [details, setDetails] = useState<any>(null);

  const submitHandler = async (values: any) => {
    await req.post("tasks/app_store/artist_lookup", { url: values.url })
      .then((res: any) => { setDetails(null); setDetails(res.data); })
  }

  const saveToSystem = async () => {
    await req.post("tasks/app_store/artist_save", { artist_id: details?.artist_id })
      .then(() => {
        onSuccess();
        guiNotifier().success("gameTracker.makers.appStore.newArtistDialog.saveSuccessMessage")
      })
      .catch((err: any) => {
        guiNotifier().handleError(err);
      })
  }

  return (
    <GuiGenericDialog
      isOpen
      size="lg"
      onClose={onClose}
      confirmBeforeClose
      title="gameTracker.makers.appStore.newArtistDialog.title"
    >
      <>
        <GuiForm
          fetchData={() => { return { url: "" } }}
          type="new-form"
          labelPath="gameTracker.makers.appStore.newArtistDialog"
          disableSubmitTriggeredByEnter={false}
          validationSchema={validationSchema}
          isDialogForm
          mainButtonsConfig={{
            dialogSaveText: "gameTracker.makers.appStore.newArtistDialog.previewArtist",
            dialogSaveIcon: "search",
          }}
          submitHandler={submitHandler}
        >
          <Grid container spacing={1}>
            <GuiText
              field="url"
            />
          </Grid>
        </GuiForm>
        {details &&
          <div className={classes.root}>
            <div>
              <GuiForm
                fetchData={() => { return { ...details } }}
                title="gameTracker.makers.appStore.newArtistDialog.artist.artist"
                hideButtons
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <PageItem
                      label={lang("gameTracker.makers.appStore.newArtistDialog.artist.name")}
                      component={
                        details?.id !== null ?
                          <Name
                            linkTo={`/makers/maker/${details?.id}`}
                            name={details?.name}
                            url={details?.url}
                          />
                          :
                          <span>
                            {details?.name}
                          </span>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PageItem
                      label={lang("gameTracker.makers.appStore.newArtistDialog.artist.id")}
                      component={
                        <span>
                          {details?.artist_id}
                        </span>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PageItem
                      label={lang("gameTracker.makers.appStore.newArtistDialog.artist.url")}
                      component={
                        <a target="blank" href={`${details?.url}`} className={classes.url} >
                          {details?.url}
                        </a>
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.saveButton}>
                      <GuiButton
                        onClick={saveToSystem}
                        text={details?.id === null ? "gameTracker.makers.appStore.newArtistDialog.save" : "gameTracker.makers.appStore.newArtistDialog.artistExist"}
                        variant="success"
                        icon="save"
                        disabled={!(details && details.id === null)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </GuiForm>
            </div>
            <GuiGrid
              fetchData={() => { return { ...details?.apps } }}
              labelPath="gameTracker.makers.appStore.newArtistDialog.games"
              columns={[
                "url",
              ]}
              serverSide={false}
              refreshConfig={{
                hide: true,
                onRefresh: () => null,
              }}
              expandAll
              customCells={[
                {
                  targetFields: ["url"], component: (row: any) =>
                    <a className={classes.url} target="blank" href={`https://apps.apple.com/us/app/id` + row?.row?.id}>{`https://apps.apple.com/us/app/id` + row?.row?.id}</a>
                },
              ]}
            />
          </div>
        }
      </>
    </GuiGenericDialog>
  )
}

const useStyles = makeStyles({
  root: {
    padding: "10px 10px",
  },
  url: {
    color: "var(--primaryBlue)"
  },
  saveButton: {
    marginTop: 20
  },
});

export default NewArtistDialog
