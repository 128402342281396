import React from 'react';
import { Route } from 'react-router-dom';
import { useUser } from '@guibil/app';
import { IGuiGuestRoute } from '../types';
import { UnauthorizedPage } from '../error-pages/403';

/**
 * GuiGuestRoute - is route which needs user to be Not-auth, ex: LoginPage
 *
 * @param {*} props
 * @returns
 */
export const GuiGuestRoute: React.FC<IGuiGuestRoute> = (props) => {
  const user = useUser();

  if (user.isAuth && props.permitAuthUser !== true) {
    return (
      <Route {...props} component={undefined}>
        {UnauthorizedPage}
      </Route>
    );
  }

  return (
    <Route {...props}>
      {props.children}
    </Route>
  );
};

GuiGuestRoute.defaultProps = {
  permitAuthUser: false,
};
