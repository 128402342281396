export const play_store_games_tr = {
  title: "Oyunlar",

  games: {
    title: "Oyunlar",
    object_order: "Sıra",
    logo_image_url: "Logo",
    name: "Adı",
    developer: "Yapımcı",
    rate: "Puan",
    store_rate: "Mağaza Puanı",
    release_date: "Yayın Tarihi",
    last_release_date: "Son Yayın Tarihi",
    follow: "Takip",

    showTags: "Etiketler",
    tags: {
      title: "Oyun Etiketleri",
      createButton: "Ekle",
      deleteConfirmMessage:
        "Seçili etiketi silmek istediğinizden emin misiniz?",
      deleteSuccessMessage: "Etiket başarı ile silindi",
      tag: "Etiket",
      actions: " ",
      create: {
        title: "Etiket Ekle",
        tag: "Yeni Etiket",
      },
    },
  },

  gameFilter: {
    filter: "Filtrele",
    name: "Ad",
    genre: "Kategori",
    min_release_date: "Min Yayın Tarihi",
    max_release_date: "Max Yayın Tarihi",
    min_last_release_date: "Min Son Yayın Tarihi",
    max_last_release_date: "Max Son Yayın Tarihi",
    rate: "Puan",
    store_rate: "Mağaza Puanı",
    tag: "Etiket",
  },
};
