import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { formatDateTime } from '@guibil/router/utils/local-helpers';
import { useLang } from '@guibil/app';

export interface SpanningEventProps {
  created_datetime: string,
  name: string,
  start_value: any,
  start_description: any,
  event_start_datetime: string,
  finish_value: any,
  finish_description: any,
  event_finish_datetime: string,
  span_in_ms: number,
  span_in_ms_formatted: string,
}

interface SpanningEventsProps {
  spanning_events: SpanningEventProps[]
}

const SpanningEvents: React.FC<SpanningEventsProps> = ({ spanning_events }) => {
  const lang = useLang();
  const classes = useStyles();

  return <div>
    <div className={classes.tableTitle}>
      <span>{lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.title")}</span>
    </div>
    <div className={classes.tableWrapper}>
      <table className={classes.table}>
        <thead className={classes.tableHead}>
          <tr>
            <td>{lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.eventDateTime")}</td>
            < td > {
              lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.name")}</td>
            <td>{
              lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.startValue")}</td>
            < td > {
              lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.startDescription")}</td>
            <td>{
              lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.finishValue")}</td>
            < td > {
              lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.finishDescription")}</td>
            <td>{
              lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.eventLocalStartFinishDatetime")}</td>
            < td > {lang("gameTracker.development.installationDetails.players.playerLevelEvents.spanningEvents.span")}</td>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {spanning_events.map((event, index) => {
            return <tr key={index}>
              <td>{formatDateTime(event.created_datetime)}</td>
              <td>{event.name}</td>
              <td>{event.start_value}</td>
              <td>{event.start_description}</td>
              <td>{event.finish_value}</td>
              <td>{event.finish_description}</td>
              <td>
                <div>
                  {formatDateTime(event.event_start_datetime)}
                </div>
                <div>
                  {formatDateTime(event.event_finish_datetime)}
                </div>
              </td>
              <td>{event.span_in_ms_formatted}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  </div>
}

const useStyles = makeStyles({
  tableTitle: {
    padding: "8px",
    fontSize: "18px !important",
    fontWeight: 700,
    color: "var(--titleColor)",
  },
  tableWrapper: {
    background: "var(--bgColor)",
  },
  table: {
    width: "100%",
  },
  tableHead: {
    background: "var(--titleColor)",
    color: "var(--bgPrimary)",
    "& > th, td": {
      padding: "8px"
    }
  },
  tableBody: {
    color: "var(--titleColor)",
    "& > th, td": {
      verticalAlign: "middle",
      padding: "6px",
    },
    "& > tr:nth-child(even)": {
      padding: "6px",
      background: "var(--bgPrimary)",
    },
  },
})

export default SpanningEvents
