import { useRequest } from '@guibil/api';
import { classNames, GuiGenericDialog, guiNotifier } from '@guibil/components'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import Level, { LevelEventProps } from './components/Level';
import { commonCSS } from '@guibil/styles';
import { CircularProgress } from '@material-ui/core';

interface PlayerEventProps {
  player_id: string,
  onClose: () => void,
}

const PlayerLevelEventsDialog: React.FC<PlayerEventProps> = ({ player_id, onClose }) => {
  const req = useRequest();
  const classes = useStyles();

  const [loading, setIsLoading] = useState<boolean>(false);
  const [levelEvents, setLevelEvents] = useState<LevelEventProps[]>([]);

  useEffect(() => {
    async function fetch() {
      await fetchEvents();
    }
    fetch();
  }, [])

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      setLevelEvents(await req.get(`installations/player_level_events/${player_id}`))
    } catch (err) {
      guiNotifier().handleError(err)
    }
    setIsLoading(false);
  }

  return (
    <GuiGenericDialog
      isOpen
      size="xl"
      onClose={onClose}
      extraButtons={[
        {
          onClick: fetchEvents,
          isLoading: loading,
          icon: "sync-alt",
          text: "gameTracker.development.installationDetails.players.playerLevelEvents.refresh",
          nativeProps: { className: commonCSS.refreshButton },
        }
      ]}
      title="gameTracker.development.installationDetails.players.playerLevelEvents.title"
    >
      <div>
        <div className={classNames(classes.wrapper, loading && classes.wrapperDrop)}>
          {levelEvents.map((level, index) => <Level key={index} {...{ ...level, index }} />)}
        </div>
        {
          loading && <div className={classes.loading}>
            <CircularProgress disableShrink />
          </div>
        }
      </div>
    </GuiGenericDialog >
  )
}


const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    marginTop: "-8px",
    paddingBottom: "12px",
  },
  wrapperDrop: {
    opacity: 0.6,
  },
  loading: {
    position: "absolute",
    left: "48%",
    top: "48%",
    zIndex: 0,
    "& svg": {
      color: "var(--titleColor) !important"
    }
  },
})


export default PlayerLevelEventsDialog
