import { Swiper, SwiperSlide } from 'swiper/react';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import 'swiper/swiper.scss'

interface GameScreenshotsProps {
  showVideos: boolean,
  screenshots: {
    phone: [string],
    video: string | null
  }
}

const GameScreenshots: React.FC<GameScreenshotsProps> = ({ screenshots, showVideos }) => {
  const classes = useStyles();
  const { phone, video } = screenshots;

  return (
    <div style={{ backgroundColor: "transparent", borderRadius: "2px" }}>
      {(phone?.length > 0) ?
        <div className={classes.container}>

          <Swiper
            freeMode={false}
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{ clickable: true }}
            className={classes.swiperRoot}
          >
            {
              showVideos && video && <SwiperSlide>
                <iframe src={video} width="100%" height="100%">
                </iframe>
              </SwiperSlide>
            }

            {
              screenshots["phone"].map((screenshot: string, index: number) => {
                return (
                  <SwiperSlide key={index} >
                    <img
                      src={screenshot}
                      alt={screenshot}
                    />
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </div> : <div></div>}
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    padding: "8px",
    borderRadius: "2px",
  },
  swiperRoot: {
    "& .swiper-slide": {
      width: "auto",
      height: "auto"
    },

    "& .swiper-slide img": {
      borderRadius: "2px",
      maxWidth: "380px",
      maxHeight: "380px"
    }
  },
});

export default GameScreenshots
