export const components_tr = {
  empty: "Boş",
  follow: "Takip Et",
  following: "Takibi Bırak",
  rate_null: "-",
  unknown: "-",

  comment: {
    send: "Gönder",
    update: "Güncelle",
    comment: "Yorum",
  },

  valuesList: {
    value: "'Değer' alanı boş bırakılamaz",
  },

  base64Images: {
    maxImage: "10'dan fazla görsel yüklenemez",
    sizeError: "Görsel boyutu 5 mb'dan büyük olamaz",
    allowedFormats: "Sadece jpg/jpeg ve png dosyası yükleyebilirsiniz",
  },

  filesUpload: {
    maxFile: "10'dan fazla dosya yüklenemez",
    sizeError: "Dosya boyutu 200 mb'dan büyük olamaz",
  },
};
