import { useLang } from '@guibil/app';
import { classNames } from '@guibil/components';
import { makeStyles } from '@material-ui/core';
import React, { Children, isValidElement } from 'react';
import { useGuiFormPropsContext } from '../contexts/GuiFormPropsContext';
import { FormGridSpecificProps } from './GuiFormGrid';

const GuiFormGridHeader: React.FC<FormGridSpecificProps> = (props) => {
  const { columnExtension, actionFieldArr, extraColumns, labelField } = props;
  const { labelPath } = useGuiFormPropsContext();
  const classes = useClasses();
  const lang = useLang();
  return (
    <tr className={classNames(classes.row, classes.headerRow)}>
      {Children.map(props.children, ((child) => {
        if (isValidElement(child) && child && child?.props.field) {

          let extensions: any = {}

          if (columnExtension && columnExtension[`${child?.props.field}`]) {
            extensions.width = columnExtension[`${child?.props.field}`].width ? columnExtension[`${child?.props.field}`].width : "unset"
          }

          return <td
            className={classNames(labelField === child?.props.field && classes.labelField)}
            style={{ maxWidth: extensions.width, minWidth: extensions.width }}
          >
            {child?.props.label || lang(`${labelPath}.${child?.props.field}`)}
          </td>
        }
      }))}

      {extraColumns?.map(({ name }) => {
        let extensions: any = {}
        if (columnExtension && columnExtension[`${name}`]) {
          extensions.width = columnExtension[`${name}`].width ? columnExtension[`${name}`].width : "unset"
        }
        return <td key={name} style={{ maxWidth: extensions.width, minWidth: extensions.width }}>{lang(labelPath + "." + name)}</td>
      })}
      {/* for action buttons */}
      {actionFieldArr && <td></td>}
    </tr>
  )
}


const useClasses = makeStyles({
  row: {
    display: "flex",
    backgroundColor: "var(--activeTextColor)",
    minHeight: 49,
    borderRadius: 6,
    marginBottom: 2,
    "& > td": {
      flex: 1,
      marginRight: 6,
      position: "relative",
      marginLeft: 6,
      "& > div": {
        marginTop: -17,
        "& input": {
          fontSize: "12px !important",
          fontWeight: 600,
          height: 32,
          color: "var(--defaultTextColor) !important"
        },
        "& > div > .MuiInputBase-root": { // dropdown
          fontWeight: 600,
          color: "var(--defaultTextColor) !important",
          height: 33,
          marginTop: 20
        }
      },
      "& fieldset": {
        "& > div": {
          height: 32,
          border: "none !important",
          backgroundColor: "transparent !important",
          "& > span": {
            fontSize: "12px !important",
            fontWeight: 600,
            color: "var(--defaultTextColor) !important"
          }
        }
      }
    }
  },
  labelField: {
    maxWidth: 135,
    "& div > input": {
      border: "none !important",
      backgroundColor: "transparent !important"
    }
  },
  headerRow: {
    backgroundColor: "var(--bgColor)",
    paddingBlock: 7,
    paddingBottom: 9,
    minHeight: "unset",
    marginBottom: -5,
    "& > td": {
      display: "flex",
      alignItems: "center",
      fontSize: 12,
      fontWeight: 600,
      color: "var(--defaultTextColor) !important"
    }
  }
})
export default GuiFormGridHeader
