export const app_store_new_in_top_games_en = {
  title: "New Game In Top Games",

  filter: {
    filter: "Filter",
    name: "Name",
    chart_genre: "Genre",
    chart_type: "Type",
    previous_datetime: "Time to enter top games",
  },

  chart_genre: "Genre",
  game: {
    name: "Name",
    logo_image_url: "Logo",
    last_release_date: "Last Release Date",
    following: "Follow",
  },
  artist: "Artist",
};
