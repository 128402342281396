import { commonCSS } from '@guibil/styles';
import Brand from 'app/components/appFrame/includes/Brand';

interface Props {
  withBrand?: boolean
}

export const AppLoading: React.FC<Props> = ({ withBrand }) => {
  return <div className={commonCSS.loadWrap}>
    {withBrand && <Brand disableClick />}
    <div className={commonCSS.spinner}>
      <div className={commonCSS.spinneritem}></div>
      <div className={commonCSS.spinneritem}></div>
    </div>
  </div>
}
