import React from 'react';
import { useLang } from '@guibil/app';
import { makeStyles, Typography } from '@material-ui/core';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';

const GuiGridTitle = (): JSX.Element => {
  const { title: Title } = useGuiGridPropsContext();
  const lang = useLang();
  const classes = useStyles();

  if (!Title) { return <></>; }
  if (typeof (Title) === 'string') {
    return (
      <Typography classes={{ root: classes.title }} variant="h1">
        {' '}
        {lang(Title)}
      </Typography>
    );
  }
  return <Title />;
};

const useStyles = makeStyles(() => ({
  title: {
    margin: 0,
    padding: '0.9rem 0.4rem',
    paddingLeft: 0,
    fontWeight: "bold",
    fontSize: "18px",
    color: "var(--titleColor)",
  },
}));
export { GuiGridTitle };
