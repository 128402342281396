import moment from 'moment';
import { Grid } from '@material-ui/core'
import { useRequest } from '@guibil/api'
import { classNames, GuiButton, GuiGenericDialog, guiNotifier } from '@guibil/components'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import { DATE_FORMAT, GuiDateTimePicker, GuiDropdown, GuiForm, useGuiFormData, useGuiFormHook } from '@guibil/form'
import Datetime from 'pages/components/Datetime';
import { GuiApp, useLang } from '@guibil/app';
import { commonCSS } from '@guibil/styles';

interface RetentionDialogProps {
  id: string,
  register_date: string,
  onClose: () => void,
}

interface RetentionProps {
  date: string,
  player_count: number,
  days: [number | null]
}

interface RetentionsProps {
  result: {
    header: [string],
    retentions: [RetentionProps]
  }
}

const RetentionDialog: React.FC<RetentionDialogProps> = ({ id, register_date, onClose }) => {
  const defaultEndDay = 15;

  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();
  const selectedLang = GuiApp.getLanguage();

  const [filterHook, setFilterHook] = useGuiFormHook();
  const [filterData, setFilterData] = useGuiFormData();
  const [retentions, setRetentions] = useState<RetentionsProps | null>(null);


  const handleResetFilter = async () => {
    filterHook?.fetch();
  }

  const handleSetFilter = async () => {
    await fetchData();
  }

  const fetchFilterData = async () => {
    let registerDate = moment(register_date);
    let startDate = moment().subtract(defaultEndDay, "days");

    if (startDate < registerDate) {
      startDate = registerDate;
    }

    return {
      os_type: "",
      start_date: startDate.format(DATE_FORMAT),
      end_date: moment().format(DATE_FORMAT),
    }
  }

  const fetchData = async () => {
    if (!filterData?.values) return null;
    const filter = `start_date=${filterData?.values?.start_date}&end_date=${filterData?.values?.end_date}&os_type=${filterData?.values?.os_type}`

    await req.get(`installations/player_retention_statistics/${id}?${filter}`)
      .then((res: any) => {
        setRetentions(res);
      })
      .catch((err: any) => guiNotifier().handleError(err))
  }

  return (
    <GuiGenericDialog
      isOpen
      onClose={onClose}
      size="xl"
    >
      <div>
        <GuiForm
          fetchData={fetchFilterData}
          setData={setFilterData}
          setHook={setFilterHook}
          title="gameTracker.development.installationDetails.detail.retentionDialog.filter.filter"
          labelPath="gameTracker.development.installationDetails.detail.retentionDialog.filter"
          disableLeaveFormPrompt
          headerComponent={
            <>
              <GuiButton
                onClick={handleResetFilter}
                nativeProps={{ classes: { root: commonCSS.iconButton } }}
                variant="cancel"
                icon="undo-alt"
              />
              <GuiButton
                onClick={handleSetFilter}
                nativeProps={{ classes: { root: commonCSS.iconButton } }}
                variant="success"
                icon="filter"
              />
            </>
          }
          hideButtons
          type="writer"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} lg={3}>
              <GuiDropdown
                field="os_type"
                selectionsEnum="OsType"
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <GuiDateTimePicker
                field="start_date"
                disableTime
                options={{
                  minDate: moment(register_date).format(DATE_FORMAT),
                  maxDate: moment().subtract(1, "days").format(DATE_FORMAT),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <GuiDateTimePicker
                field="end_date"
                disableTime
                options={{
                  minDate: moment(filterData?.values?.start_date).add(1, "days").format(DATE_FORMAT),
                  maxDate: moment().format(DATE_FORMAT),
                }}
              />
            </Grid>
          </Grid>
        </GuiForm>
        {
          retentions ? <div className={classes.retentionRoot} >
            <table className={classes.retentionTable}>
              <thead className={classes.tableHeader}>
                <tr>
                  <td className={classes.tdDate}>{lang("gameTracker.development.installationDetails.detail.retentionDialog.date")}</td>
                  < td className={classes.tdPlayerCount} > {
                    lang("gameTracker.development.installationDetails.detail.retentionDialog.player_count")}</td>
                  {retentions?.result?.header.map((header: string, index: number) => <td className={classes.td} key={index}>{header}</td>)
                  }
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                {retentions?.result?.retentions.map((retention: RetentionProps, index: number) => {
                  return (
                    <tr key={index}>
                      <td className={classes.tdDate}><Datetime datetimeString={retention.date} timeDisabled={true} /></td>
                      <td className={classes.tdPlayerCount}>{retention.player_count}</td>
                      {retention?.days.map((value: number | null, index: number) => {
                        let className = classes.valueEmpty;
                        if (value != null) {
                          if (value > 66.0) {
                            className = classes.value66_100;
                          }
                          else if (value > 33.0) {
                            className = classes.value33_66;
                          }
                          else if (value > 0.0) {
                            className = classes.value0_33;
                          }
                          else if (value == 0) {
                            className = classes.value0;
                          }
                        }
                        return (
                          <td
                            className={classNames(classes.td, className)}
                            key={index}>{
                              value == null ?
                                <span style={{ fontSize: "14px" }}>ø</span> :
                                <span>
                                  {
                                    selectedLang === "tr" ? `%${value}` : `${value}%`
                                  }
                                </span>
                            }
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div> : null
        }
      </div>
    </GuiGenericDialog >
  )
}

const useStyles = makeStyles({
  headerComponent: {
    display: "flex"
  },
  osType: {
    marginRight: "10px",
    marginLeft: "-17px !important",
    "& .MuiInputBase-root": {
      marginTop: "-7px !important",
    }
  },
  retentionRoot: {
    margin: "16px",
    color: "var(--defaultTextColor)"
  },
  retentionTable: {
    "& td": {
      textAlign: "center",
      padding: 5
    }
  },
  tableHeader: {
    fontWeight: 500,
    fontSize: "14px"
  },
  tableBody: {
    fontWeight: 300,
    fontSize: "13px"
  },
  td: {
    minWidth: 60,
    borderBottom: "1px solid rgba(209, 209, 209, 0.43)",
    borderLeft: "1px solid rgba(209, 209, 209, 0.43)",
  },
  tdDate: {
    fontWeight: 500,
    borderBottom: "1px solid rgba(209, 209, 209, 0.43)",
    minWidth: 80,
  },
  tdPlayerCount: {
    borderBottom: "1px solid rgba(209, 209, 209, 0.43)",
    borderLeft: "1px solid rgba(209, 209, 209, 0.43)",
    minWidth: 100,
  },
  valueEmpty: {
    backgroundColor: "rgba(156, 156, 156, 0.39)",
    opacity: 0.6,
  },
  value66_100: {
    backgroundColor: "rgba(0, 255, 93, 0.64)"
  },
  value33_66: {
    backgroundColor: "rgba(0, 255, 93, 0.39)",
  },
  value0_33: {
    backgroundColor: "rgba(0, 255, 93, 0.14)",
  },
  value0: {
    backgroundColor: "rgba(0, 255, 93, 0.05)",
  },
});

export default RetentionDialog
