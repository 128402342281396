import React from 'react';
import { GuiGenericDialog } from '@guibil/components';
import {
  GuiDropdown,
  GuiForm, GuiSwitch, GuiText,
} from '@guibil/form';
import { Grid } from '@material-ui/core';
import validationSchema from './validation';

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
  id: string;
}

const UserUpdate: React.FC<IProps> = (props) => {
  const { onClose, onSuccess, id } = props;

  return (
    <GuiGenericDialog isOpen title="gameTracker.userManagement.update.title" onClose={onClose}>
      <GuiForm
        fetchData={'users/maker_user/' + id}
        type="new-form"
        isDialogForm
        config={{
          submitDataApi: 'users/maker_user/' + id,
          successMessage: "gameTracker.userManagement.update.successMessage",
          submitType: "put"
        }}
        labelPath="gameTracker.userManagement.update"
        validationSchema={validationSchema}
        successListener={onSuccess}
        onCancel={onClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GuiText disabled field="username" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiDropdown field="role" selectionsEnum="AuthUserRole" />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="email" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="first_name" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="last_name" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="password" type="password" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="password_confirm" type="password" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiSwitch field="is_active" switchText="active-passive" />
          </Grid>
        </Grid>
      </GuiForm>
    </GuiGenericDialog>
  );
};

export default UserUpdate;
