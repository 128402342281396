import * as Yup from 'yup';
import i18n from "i18next";

export default () => Yup.object().shape({
  title: Yup.string().required().max(1024).trim(),
  popularity: Yup.number().required(),
  art_difficulty: Yup.number().required(),
  dev_difficulty: Yup.number().required(),
  mantra: Yup.string().required().max(2048).trim(),
  dynamic: Yup.object({
    genre: Yup.string().required().max(128).trim(),
    progress: Yup.string().required().trim().test(
      "",
      i18n.t("gameTracker.development.gids.validations.progress"),
      function (value) {
        return value !== "<p><br></p>";
      }
    ),
    goal: Yup.string().required().trim().test(
      "",
      i18n.t("gameTracker.development.gids.validations.goal"),
      function (value) {
        return value !== "<p><br></p>";
      }
    ),
    challenge: Yup.string().required().trim().test(
      "",
      i18n.t("gameTracker.development.gids.validations.challenge"),
      function (value) {
        return value !== "<p><br></p>";
      }
    ),
  }),
});
