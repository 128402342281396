export const app_store_new_games_tr = {
  title: "Yeni Oyunlar",

  object_order: "Sıra",
  follow: "Takip",
  game: {
    logo_image_url: "Logo",
    name: "Adı",
    artist: "Yapımcı",
    rate: "Puan",
    store_rate: "Mağaza Puanı",
    release_date: "Yayın Tarihi",
    last_release_date: "Son Yayın Tarihi",
  },

  filter: {
    filter: "Filtrele",
    name: "Ad",
    date_switch: "Tarih Filtresi",
    date_switch_date: "Tarihe Göre",
    date_switch_between: "Tarihe Aralığına Göre",
    created_datetime: "Tarih",
    min_created_datetime: "Min Tarih",
    max_created_datetime: "Max Tarih",
  },
};
