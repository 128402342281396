export const routes_tr = {
  app: "Marker Games",
  controlPanel: "Kontrol Paneli",
  generalView: "Genel Görünüm",
  configuration: "Konfigürasyon",
  monitoring: "İzleme",
  alarm: "Alarm",
  settings: "Ayarlar",
  about: "Hakkında",
  account: "Komutan",
  interface: "Arayüz",

  settingsMenu: {
    theme: "Tema",
    about: "Hakkında",
    language: "Dil",
  },
  accountMenu: {
    profile: "Profilim",
    userManagement: "Kullanıcı Yönetimi",
    logUserManagement: "Log Kullanıcı Yönetimi",
    logout: "Çıkış",
  },

  close: {
    title: "Kapat",
    deviceClose: "Cihazı Kapat",
    closeConfirm: "Eğer devam ederseniz cihaz kapatılacaktır. Devam etmek istediğinize emin misiniz?"
  }
};
