import { app_store_game_details_en } from "../app-store/locales/en_US";
import { play_store_game_details_en } from "../play-store/locales/en_US";


export const game_details_en = {
  breadcrumbTitle: "Game Details",
  pageTitle: "Game Details",
  title: "Game Details",
  menuTitle: "Game Details",

  appStore: app_store_game_details_en,
  playStore: play_store_game_details_en,
};
