import { DEFAULT_SUBMIT_HANDLER } from '@guibil/form/constants';
import { GUI_FORM_ELEMENT_EVENT } from '@guibil/form/constants/gui-form-elements';
import { useGuiFormStateContext } from '@guibil/form/contexts/GuiFormStateContext';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const GuiFormBehaviorListener = () => {
  const { removeRequestError, guiEventSystem } = useGuiFormStateContext();
  const formik = useFormikContext();

  useEffect(() => {
    const unsubscribe = guiEventSystem.subscribe(GUI_FORM_ELEMENT_EVENT.FORM_DATA_FETCHED, () => {
      formik.resetForm();
      removeRequestError(DEFAULT_SUBMIT_HANDLER);
    });

    return unsubscribe;
  }, []);


  return null;
}

export default GuiFormBehaviorListener;
