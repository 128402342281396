import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Tooltip } from '@material-ui/core';

export const GuiGridTooltipCell: React.FC<DataTypeProvider.ValueFormatterProps> = (props) => {
  const { value } = props;
  return (
    <Tooltip title={value} placement="top-start" >
      <span style={{ cursor: "pointer" }}>{value}</span>
    </Tooltip>

  );
};
