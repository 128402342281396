import React, { useState } from 'react';
import { GuiGenericDialog, guiNotifier } from '@guibil/components';
import { GuiGrid, useGuiGridHook } from '@guibil/grid';
import GuiGridRowButton from '@guibil/grid/components/buttons/GuiGridRowButton';
import { useRequest } from '@guibil/api';
import TagCreateDialog from './components/TagCreateDialog';

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
}
const TagsDialog: React.FC<IProps> = (props) => {
  const { onClose, onSuccess } = props;
  const req = useRequest();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [gridHook, setGridHook] = useGuiGridHook();

  const Actions = (row: any) => {
    const handleDelete = async () => {
      try {
        await req.delete("ps_games/delete_game_tag/" + row.row.id).then(gridHook?.fetch);
        guiNotifier().success("gameTracker.games.playStore.games.tags.deleteSuccessMessage");
        onSuccess();
      } catch (err) {
        guiNotifier().handleError(err);
      }
    };
    const onDeleteClick = () => guiNotifier().confirm({ description: "gameTracker.games.playStore.games.tags.deleteConfirmMessage" }, handleDelete)

    return <GuiGridRowButton onClick={onDeleteClick} type="delete" />
  }

  return (
    <GuiGenericDialog isOpen onClose={onClose}>
      <>
        <GuiGrid
          setHook={setGridHook}
          fetchData={"ps_games/game_tags"}
          columns={["tag", "actions"]}
          title="gameTracker.games.playStore.games.tags.title"
          labelPath="gameTracker.games.playStore.games.tags"
          customCells={[{
            component: Actions,
            targetFields: ["actions"]
          }]}
          columnExtensions={[{
            columnName: "actions",
            width: 60
          }]}
          serverSide={false}
          extraButtons={[{
            onClick: () => setShowAddDialog(true),
            text: "gameTracker.games.playStore.games.tags.createButton",
            icon: "plus-circle",
            variant: "success"
          }]}
        />
        {showAddDialog && <TagCreateDialog onSuccess={async () => { await onSuccess(); setShowAddDialog(false); }} onClose={() => setShowAddDialog(false)} />}
      </>
    </GuiGenericDialog>
  );
};

export default TagsDialog;
