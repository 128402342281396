import React, { useCallback } from 'react';
import { logError } from '@guibil/app';
import { useRequest } from '@guibil/api';
import { IGuiFormResponse } from '@guibil/form/types';
import { useGuiFormPropsContext } from '../../contexts/GuiFormPropsContext';
import { useGuiFormStateContext } from '../../contexts/GuiFormStateContext';
import { GuiFormActionsContext } from '../../contexts/GuiFormActionsContext';
import { GUI_FORM_ELEMENT_EVENT } from '@guibil/form/constants/gui-form-elements';
import useGuiApiHelper from '@guibil/api/pack/map-route-params';
import { useFormikContext } from 'formik';

const FETCH_REFRESH = 'FETCH_REFRESH';

interface IProps { }

export const GuiFormActionsContainer: React.FC<IProps> = (props) => {
  const request = useRequest();
  const { mapParamsToString } = useGuiApiHelper();
  const { fetchData, fetchDefaultData, type } = useGuiFormPropsContext();
  const formik = useFormikContext();

  const {
    setData, setLoadingState, onRequestError, removeRequestError, guiEventSystem,
  } = useGuiFormStateContext();

  const fetch = React.useCallback(async (fetchData: string | (() => any), options?: { refetch?: boolean }) => {
    try {
      let fetchCallback = null;
      if (typeof (fetchData) === 'function') {
        fetchCallback = fetchData;
      } else if (typeof (fetchData) === 'string') {
        fetchCallback = async () => await request.get(mapParamsToString(fetchData));
      } else {
        logError(new Error('fetchData not provided to form'), 'Can not refresh/fetch data, missing fetchData');
      }

      if (fetchCallback) {
        setLoadingState((old) => (old === 'waiting' ? 'firstTimeLoading' : 'refreshing'));

        // const result: IGuiFormResponse | IGuiFormResponseEntity = await fetchCallback();
        const requestResult: IGuiFormResponse = await fetchCallback();

        if (typeof (fetchData) === "string") {
          options?.refetch ? formik?.setValues(requestResult || {}) : setData(requestResult || {})
        } else {
          options?.refetch ? formik?.setValues(requestResult || {}) : setData(requestResult || {})
        }
        !options?.refetch && guiEventSystem.emit(GUI_FORM_ELEMENT_EVENT.FORM_DATA_FETCHED, requestResult);
        setLoadingState('idle');

        if ((type === "writer" || type === "new-form") && formik) formik.validateForm();
      }
      removeRequestError(FETCH_REFRESH);
    } catch (err) {
      setLoadingState('idle');
      onRequestError(FETCH_REFRESH, err, performFetch);
    }
  }, [])

  const performFetch = useCallback(async () => {
    await fetch(fetchData);
  }, [fetchData]);

  const performFetchDefault = useCallback(async () => {
    fetchDefaultData && await fetch(fetchDefaultData, { refetch: true });
  }, [fetchDefaultData]);

  return (
    <GuiFormActionsContext.Provider
      value={{
        performFetch,
        performFetchDefault
      }}
    >
      {props.children}
    </GuiFormActionsContext.Provider>
  );
};
