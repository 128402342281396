export const user_management_en = {
  breadcrumbTitle: "User Management",
  pageTitle: "User Management",
  title: "User Management",
  menuTitle: "User Management",

  addUser: "Add",

  deleteConfirmMessage: "Are you sure you want to delete this user?",
  deleteSuccessMessage: "User deleted successfully",

  username: "Username",
  role: "Role",
  email: "E-mail",
  first_name: "First Name",
  last_name: "Last Name",
  is_active: "Is Active",
  actions: " ",

  create: {
    title: "Add User",
    username: "Username",
    email: "E-mail",
    role: "Role",
    first_name: "First Name",
    last_name: "Last Name",
    is_active: "Is Active",
    password: "Password",
    password_confirm: "Confirm Password",
    successMessage: "User created successfully",
  },

  update: {
    title: "Update User",
    username: "Username",
    email: "E-mail",
    role: "Role",
    first_name: "First Name",
    last_name: "Last Name",
    is_active: "Is Active",
    password: "Password",
    password_confirm: "Confirm Password",
    successMessage: "User updated successfully",
  },
  updatePassword: {
    title: "Update User Password",
    username: "Username",
    password: "Password",
    password_confirm: "Confirm Password",
    successMessage: "User password updated successfully",
  },
};
