import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core'
import { useLang } from '@guibil/app'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { formatDateTime } from '@guibil/router/utils/local-helpers';
import OneTimeEvent, { OneTimeEventProps } from './OneTimeEvent';
import SpanningEvent, { SpanningEventProps } from './SpanningEvent';

export interface LevelEventProps {
  index: number,
  level: number,
  created_datetime: string,
  one_time_events: OneTimeEventProps[],
  spanning_events: SpanningEventProps[],
}

const Level: React.FC<LevelEventProps> = ({ index, level, created_datetime, one_time_events, spanning_events }) => {
  const lang = useLang();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<boolean>(index === 0);

  return <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <div className={classes.titleRoot}>
        <div className={classes.titleWrapper}>
          <span>{lang("gameTracker.development.installationDetails.players.playerLevelEvents.level")}</span>
          < span className={classes.title} > {level}</span>
        </div>
        <div className={classes.titleWrapper}>
          <span>{lang("gameTracker.development.installationDetails.players.playerLevelEvents.play_time")}</span>
          < span className={classes.title} > {formatDateTime(created_datetime)}</span>
        </div>
      </div>
    </AccordionSummary>
    <AccordionDetails>
      <Grid spacing={4} container>
        <Grid item xs={12}>
          <OneTimeEvent one_time_events={one_time_events} />
        </Grid>
        <Grid item xs={12}>
          <SpanningEvent spanning_events={spanning_events} />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
}

const useStyles = makeStyles({
  titleRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  titleWrapper: {
    display: "flex",
    fontSize: "16px !important",
    fontWeight: 700,
    color: "var(--titleColor)",
  },
  title: {
    fontWeight: 800,
    paddingLeft: "8px",
  },
})


export default Level
