import { GuiButton, guiNotifier, useWindowSize } from '@guibil/components';
import { GuiDateTimePicker, GuiDropdown, GuiForm, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form';
import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useRequest } from '@guibil/api';
import Name from 'pages/components/Name';
import Tagger, { TagProps } from 'pages/components/Tagger';
import GameLogo from 'pages/components/GameLogo';
import FollowingStatus from 'pages/components/FollowingStatus';
import { useUser } from '@guibil/app';
import TagsDialog from '../components/tags/TagsDialog';
import { commonCSS } from '@guibil/styles';

const Games = () => {
	const req = useRequest();
	const { width } = useWindowSize();
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [selectedTags, setSelectedTags] = useState<TagProps[]>([]);
	const [availableTags, setAvailableTags] = useState<TagProps[] | "loading">([]);
	const [gridHook, setGridHook] = useGuiGridHook();
	const classes = useClasses();
	const user = useUser();
	const [showTagsDialog, setShowTagsDialog] = useState(false);

	const [filterData, setFilterData] = useGuiFormData();
	const [filterHook, setFilterHook] = useGuiFormHook();
	const [filterParams, setFilterParams] = useState<any | undefined>({
		name: "",
		genre: "",
		min_release_date: "",
		max_release_date: "",
		min_last_release_date: "",
		max_last_release_date: "",
		rate: "",
		store_rate: "",
		tag: "",
	});

	useEffect(() => { fetchTags(); }, [])
	useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

	const fetchTags = async () => {
		try {
			const res = await req.get("games/game_tags");
			setAvailableTags("loading");
			setAvailableTags(res?.result);
		} catch (err: any) {
			setAvailableTags([]);
			guiNotifier().handleError(err);
		}
	}

	const handleSetFilter = () => {
		gridHook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
		gridHook?.setQueryParams({
			name: filterData?.values?.name,
			genre: filterData?.values?.genre,
			min_release_date: filterData?.values?.min_release_date,
			max_release_date: filterData?.values?.max_release_date,
			min_last_release_date: filterData?.values?.min_last_release_date,
			max_last_release_date: filterData?.values?.max_last_release_date,
			rate: filterData?.values?.rate,
			store_rate: filterData?.values?.store_rate,
			tag: filterData?.values?.tag,
		})
	}

	const handleResetFilter = () => {
		setFilterParams(undefined);
		setSelectedTags([]);
		gridHook?.setQueryParams({});
		filterHook?.fetch();
	}

	const handleSelectedTags = (tags: TagProps[]) => {
		const tagAsCommaSeperated = tags.map((tag: TagProps) => tag.slug).join();
		filterData?.setValues({ ...filterData?.values, tag: tagAsCommaSeperated });
		setSelectedTags(tags);
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<GuiForm
					fetchData={() => filterParams}
					setHook={setFilterHook}
					setData={setFilterData}
					showForm={!showFilter}
					title="gameTracker.games.appStore.gameFilter.filter"
					labelPath="gameTracker.games.appStore.gameFilter"
					disableLeaveFormPrompt
					headerComponent={
						<div>
							<GuiButton
								onClick={() => setShowFilter(!showFilter)}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon={showFilter ? "eye-slash" : "eye"}
							/>
							<GuiButton
								onClick={handleResetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon="undo-alt"
							/>
							<GuiButton
								onClick={handleSetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="success"
								icon="filter"
							/>
						</div>
					}
					hideButtons
					type="writer"
				>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} lg={3}>
							<GuiText
								field="name"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="genre"
								selectionsEnum="AppStoreGenre"
								showClearOption
							/>
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="rate"
								selections={[1, 2, 3, 4, 5]}
								showClearOption
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="store_rate"
								selections={[1, 2, 3, 4, 5]}
								showClearOption
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="min_release_date"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="max_release_date"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="min_last_release_date"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="max_last_release_date"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<Grid container item spacing={0}>
								<Grid item xs={8} md={8} lg={8}>
									<Tagger
										label="gameTracker.games.appStore.gameFilter.tag"
										selecteds={selectedTags}
										availables={availableTags === "loading" ? [] : availableTags}
										isLoading={availableTags === "loading"}
										handleSelectedTags={handleSelectedTags}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<GuiButton
										nativeProps={{ classes: { root: classes.showBtn } }}
										variant="cancel"
										onClick={() => setShowTagsDialog(true)}
										text="gameTracker.games.appStore.games.showTags"
										icon="pen"
										disabled={user.role === "VIEWER"}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</GuiForm>
			</Grid>

			<Grid item xs={12}>
				<GuiGrid
					fetchData="games/games"
					title="gameTracker.games.appStore.games.title"
					labelPath="gameTracker.games.appStore.games"
					setHook={setGridHook}
					columnExtensions={[
						{ columnName: "object_order", width: 66 },
						{ columnName: "name", wordWrapEnabled: true },
						{ columnName: "artist", wordWrapEnabled: true },
						{ columnName: "logo_image_url", width: 76 },
						{ columnName: "follow", width: 150 },
						{ columnName: "rate", width: 60 },
						{ columnName: "store_rate", width: 110 },
						{ columnName: "url", width: 80 },
						{ columnName: "release_date", width: 150 },
						{ columnName: "last_release_date", width: 150 },
					]}
					hiddenSortFields={["follow"]}
					columns={[
						"object_order",
						"logo_image_url",
						"name",
						"artist",
						"rate",
						"store_rate",
						"release_date",
						"last_release_date",
						"follow"
					]}
					dateTimeFields={[
						{ targetField: "release_date" },
						{ targetField: "last_release_date" },
					]}
					customCells={[
						{
							targetFields: ["name"], component: (row: any) =>
								<Name
									linkTo={`/games/game/${row.row?.id}`}
									name={row.row?.name}
									url={row.row?.url}
								/>
						},
						{
							targetFields: ["artist"], component: (row: any) =>
								<Name
									linkTo={`/makers/maker/${row.row?.artist?.id}`}
									name={row.row?.artist?.name}
									url={row.row?.artist?.url}
								/>
						},
						{
							targetFields: ["logo_image_url"], component: (row: any) =>
								<GameLogo logo_image_url={row.row.logo_image_url} />
						},
						{
							targetFields: ["follow"], component: (row: any) =>
								<FollowingStatus
									key={row.row?.id}
									isFollowing={row.row.following}
									followUrl={`games/follow_game/${row.row?.id}`}
								/>
						},
					]}
				/>
			</Grid>
			<Grid item xs={12} />
			{
				showTagsDialog && <TagsDialog onClose={() => setShowTagsDialog(false)} onSuccess={async () => {
					setShowTagsDialog(false);
					setSelectedTags([]);
					await fetchTags();
				}}
				/>
			}
		</Grid>
	)
}

const useClasses = makeStyles({
	showBtn: {
		height: "35px !important",
		marginLeft: 15,
		marginTop: 28,
		width: "-webkit-fill-available",
		"& svg": {
			left: 8,
			width: "18px !important",
			height: "18px !important",
		},
	}
})

export default Games
