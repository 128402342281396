export const components_en = {
  empty: "Empty",
  follow: "Follow",
  following: "Unfollow",
  rate_null: "-",
  unknown: "-",

  comment: {
    send: "Send",
    update: "Update",
    comment: "Comment",
  },

  valuesList: {
    value: "'Value' field is required",
  },

  base64Images: {
    maxImage: "You can not upload more than 10 images",
    sizeError: "Image size cannot be larger than 5 mb",
    allowedFormats: "Only jpg/jpeg and png files are allowed",
  },

  filesUpload: {
    maxFile: "You can not upload more than 10 files",
    sizeError: "File size cannot be larger than 200 mb",
  },
};
