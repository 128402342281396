import { IModuleRoutes } from "@guibil/router/types";
import Notification from "./notification/Notification";
import GameArtistHistory from "./game-artist-history/GameArtistHistory";
import NotificationsIcon from "@material-ui/icons/NotificationsActive";
import CallSplitIcon from '@material-ui/icons/CallSplit';
import NewInTopGames from "./new-in-top-games/NewInTopGames";
import TrendingUp from '@material-ui/icons/TrendingUp';

const notifications: IModuleRoutes = [
  {
    url: "notification",
    title: "gameTracker.notifications.notification.menuTitle",
    component: Notification,
    icon: NotificationsIcon
  },
  {
    url: "game-artist-history",
    title: "gameTracker.notifications.gameArtistHistory.menuTitle",
    component: GameArtistHistory,
    icon: CallSplitIcon
  },
  {
    url: "new-in-top-free",
    title: "gameTracker.notifications.newInTopGames.menuTitle",
    component: NewInTopGames,
    icon: TrendingUp
  },
];

export { notifications };
