import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';
import { useMemo } from 'react';


const PREDEFINED_HIDDEN_FILTERS: string[] = ["drag", "actions"];

const useGuiGridHiddenFilterFields = () => {
  const { actionFields, filterConfig } = useGuiGridPropsContext();

  const hiddenFilterFields: string[] = useMemo(() => {
    const actionFieldsAr = (actionFields || []).map(({ targetField }) => targetField);
    const hiddenViaConfig = filterConfig?.hideFields || [];

    return [...actionFieldsAr, ...hiddenViaConfig, ...PREDEFINED_HIDDEN_FILTERS]
  }, [actionFields, filterConfig?.hideFields]);


  return hiddenFilterFields;
}

export default useGuiGridHiddenFilterFields;
