import React, { useMemo } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import moment from 'moment';
import { DATE_TIME_HUMAN_FORMAT } from '@guibil/form';

export const GuiGridDateTimeCell: React.FC<DataTypeProvider.ValueFormatterProps> = (props) => {
  const { value } = props;

  const parsedValue = useMemo(() => {
    try {
      const momentValue = moment(value);
      if (!value) {
        return "-"
      }

      if (momentValue.isValid()) {
        return momentValue.format(DATE_TIME_HUMAN_FORMAT);
      }
    } catch (err) { }

    return value;
  }, [value])

  return parsedValue;
};
