import React, { useState } from 'react';
import { IGuiGridData } from '../types';

type IGridData = IGuiGridData | undefined;

const useGuiGridData = (): [IGridData, React.Dispatch<React.SetStateAction<IGridData>>] => {
  const [guiGridData, setGuiGridData] = useState<IGridData>(undefined);

  return [guiGridData, setGuiGridData];
};

export { useGuiGridData };
