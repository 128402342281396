import React, { useCallback, useState } from 'react';
import { IGuiFormData } from '../types';

const useGuiFormData = (): [IGuiFormData, React.Dispatch<React.SetStateAction<IGuiFormData>>] => {
  const [guiGridData, _setGuiGridData] = useState<IGuiFormData>(undefined);
  const setGuiGridData = useCallback(_setGuiGridData, []);

  return [guiGridData, setGuiGridData];
};

export { useGuiFormData };
