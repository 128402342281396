export const play_store_genre_en = {
  "GAME": "Games",
  "GAME_ARCADE": "Arcade",
  "GAME_PUZZLE": "Puzzle",
  "GAME_CARD": "Card",
  "GAME_CASUAL": "Casual",
  "GAME_RACING": "Racing",
  "GAME_SPORTS": "Sports",
  "GAME_ACTION": "Action",
  "GAME_ADVENTURE": "Adventure",
  "GAME_BOARD": "Board",
  "GAME_CASINO": "Casino",
  "GAME_EDUCATIONAL": "Educational",
  "GAME_MUSIC": "Music",
  "GAME_ROLE_PLAYING": "RolePlaying",
  "GAME_SIMULATION": "Simulation",
  "GAME_STRATEGY": "Strategy",
  "GAME_TRIVIA": "Trivia",
  "GAME_WORD": "Word",
};
