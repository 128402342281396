import React from 'react';
import { GuiButton, GuiGenericDialog } from '@guibil/components';
import { GuiForm, GuiText, useGuiFormData } from '@guibil/form';
import { Grid } from '@material-ui/core';
import validationSchema from './validation';

export const generatePassword = () => {
  var length = 12,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}
interface IProps {
  onClose: () => void,
  onSuccess: () => void,
}
const UserCreate: React.FC<IProps> = (props) => {
  const { onClose, onSuccess } = props;
  const [data, setData] = useGuiFormData();

  const fillRandomPassword = () => {
    const randPass = generatePassword();
    data?.setValues({
      username: data?.values?.username,
      password: randPass,
      password_confirm: randPass,
    })
  }

  const fetchData = () => {
    return {
      username: "",
      password: "",
      password_confirm: "",
    };
  };

  return (
    <GuiGenericDialog isOpen title="gameTracker.logUserManagement.create.title" onClose={onClose}>
      <GuiForm
        fetchData={fetchData}
        setData={setData}
        type="new-form"
        isDialogForm
        config={{
          submitDataApi: 'users/add_log_user',
          successMessage: "gameTracker.logUserManagement.create.successMessage",
        }}
        labelPath="gameTracker.logUserManagement.create"
        validationSchema={validationSchema}
        successListener={onSuccess}
        onCancel={onClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GuiText field="username" disableAutoComplate />
          </Grid>

          <Grid item xs={12}>
            <br />
          </Grid>

          <Grid item xs={12}>
            <GuiButton
              onClick={fillRandomPassword}
              text="gameTracker.logUserManagement.create.randomPassword"
              variant="success"
              icon="random"
            />
          </Grid>

          <Grid item xs={12}>
            <GuiText field="password" type="password" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="password_confirm" type="password" disableAutoComplate />
          </Grid>
        </Grid>
      </GuiForm>
    </GuiGenericDialog >
  );
};

export default UserCreate;
