import { makeStyles } from '@material-ui/styles';
import React from 'react'
import Datetime from 'pages/components/Datetime';
import GameScreenshots from '../game-screenshots/GameScreenshots';
import { useLang } from '@guibil/app';

interface GameVersionProps {
  version?: string,
  version_datetime?: string,
  version_description?: string,
  version_description_html?: string,
  version_screenshots?: any,
}

interface GameVersionsProps {
  versions: [GameVersionProps]
}

const GameVersions: React.FC<GameVersionsProps> = ({ versions }) => {
  const lang = useLang();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {
        versions.map((version: GameVersionProps, index: number) => {
          return (
            <div className={classes.wrapper} key={index}>
              <div className={classes.version}>
                <div className={classes.title}>
                  {lang("gameTracker.gameDetails.appStore.version")}: {version?.version}
                </div>
                <div className={classes.title}>
                  <Datetime datetimeString={version?.version_datetime} />
                </div>
              </div>
              <div className={classes.description} dangerouslySetInnerHTML={{ __html: version?.version_description_html ? version?.version_description_html : "-" }} />
              <div>
                <GameScreenshots screenshots={version?.version_screenshots} showVideos={false} />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    fontSize: "14px",
    fontWeight: 500,
    color: "var(--defaultTextColor)",

    "&::-webkit-scrollbar-track": {
      backgroundColor: "var(--primaryGridBorderColor2)",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar": {
      width: 10,
      height: 10,
      borderRadius: "2px",
      backgroundColor: "var(--primaryGridBorderColor2)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#8e8e8e",
    }
  },
  version: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapper: {
    borderRadius: "2px",
    marginBottom: "30px",
    paddingLeft: "8px",
    paddingBottom: "12px",
  },
  title: {
    color: "var(--titleColor)",
    fontWeight: 800,
  },
  description: {
    paddingLeft: "8px"
  }
});

export default GameVersions
