export const installation_details_en = {
  breadcrumbTitle: "Playing Details",
  pageTitle: "Playing Details",
  title: "Playing Details",
  menuTitle: "Playing Details",

  detail: {
    title: "Playing Details",
    register_date: "Register Date",
    release_date: "Release Date",
    last_release_date: "Last Release Date",

    total: "Total",
    ios: "IOS",
    android: "Android",
    player_count: "Player Count",
    total_playtime_formatted: "Span",
    average_playtimes_formatted: "Average",

    retentionDialog: {
      title: "Retention",
      retention: "Retention",
      date: "Date",
      player_count: "Player Count",

      filter: {
        filter: "Filter",
        os_type: "Os Type",
        start_date: "Start Date",
        end_date: "End Date"
      }
    },

  },

  playTimeChart: {
    title: "Playing Time",
    count: "User Count",
  },

  players: {
    title: "Player Playing Times",
    download: "Download",
    version: "Ver",
    os: "Os",

    install_datetime: "Installation Date",
    play_count: "Total",
    playtimes_in_ms: "Span",
    average_playtimes_in_ms: "Average",
    level: "Level",

    details: " ",
    detailsButton: "Details",
    events: " ",
    eventsButton: "Events",

    playerPlayDetailsDialog: {
      playTimes: {
        title: "Play Times",
        playtime_in_ms: "Play Time",
        created_datetime: "Playing Time",
      },

      dailyPlayTimes: {
        title: "Daily Play Times",
        day: "Day",
        playtimes_in_ms: "Daily Play Time",
        created_datetime: "Played Day",
      }
    },

    playerLevelEvents: {
      title: "Player Events",
      refresh: "Refresh",
      level: "Level",
      play_time: "Play Time",

      oneTimeEvents: {
        title: "One Time Events",
        eventDateTime: "Event Time",
        name: "Event Name",
        value: "Value",
        localEventDateTime: "Event Time (Local)",
      },

      spanningEvents: {
        title: "Spanning Events",
        eventDateTime: "Event Time",
        name: "Name",
        startValue: "Start Value",
        startDescription: "Start Description",
        finishValue: "Finish Value",
        finishDescription: "Finish Description",
        eventLocalStartFinishDatetime: "Event Start Finish Time (Local)",
        span: "Span",
      }
    },
  },
};
