import React, { memo } from 'react';
import { GuiGridPropsContext } from './contexts/GuiGridPropsContext';
import { IGuiGridProps } from './types';
import { GuiGridRequest } from './behaviors/requests/GuiGridRequest';
import { GuiGridStateContainer } from './containers/GuiGridStateContainer';
import { GuiGridActionsContainer } from './containers/GuiGridActionsContainer';
import { GuiGridCoreContainer } from './containers/GuiGridCoreContainer';
import { GuiGridConfiguration } from './behaviors/configuration/GuiGridConfiguration';
import { guiIsEqual } from '@guibil/helpers';

const GuiGrid: React.FC<IGuiGridProps> = memo((props) => (
  <GuiGridPropsContext.Provider value={props}>
    <GuiGridStateContainer>
      <GuiGridActionsContainer>
        <GuiGridRequest />
        <GuiGridConfiguration />

        <GuiGridCoreContainer>
          {props.children}
        </GuiGridCoreContainer>

      </GuiGridActionsContainer>
    </GuiGridStateContainer>
  </GuiGridPropsContext.Provider>
), (prevProps, nextProps) => {
  if (nextProps.memoize) {
    return true;
  }
  return guiIsEqual(prevProps, nextProps);
});

// #region Default props
GuiGrid.defaultProps = {
  paging: {
    sizes: [10, 15, 20, 30, 40, 50],
  },
  serverSide: true,
  enableFiltering: false,
  enableGrouping: false,
  enableSorting: true,
  getCellValues: undefined,
  doNotRenderIfEmpty: false,
  showFilterSelector: false,
  classes: {},
};
// #endregion

export { GuiGrid };
