import { classNames, GuiButton } from '@guibil/components';
import { makeStyles } from '@material-ui/core';
import { times } from 'lodash'
import React, { Children, cloneElement, isValidElement } from 'react'
import { FormGridSpecificProps } from './GuiFormGrid';

type IProps = FormGridSpecificProps & {
  rows: any;
}

const GuiFormGridRow: React.FC<IProps> = (props) => {
  const { gridResponseName, extraFieldProps, columnExtension, actionFieldArr, extraColumns, labelField, rows } = props;
  const classes = useClasses();

  return (
    <>
      {times(rows.length, (i) => {
        return <tr key={i} className={classes.row}>
          {Children.map(props.children, ((child) => {
            if (isValidElement(child) && child && child?.props.field) {

              const field = gridResponseName ? `${gridResponseName}.${i}.${child?.props.field}` : `${i}.${child?.props.field}`;

              let extraProps = {};
              if (extraFieldProps && extraFieldProps[`${child?.props.field}`]) {
                extraProps = extraFieldProps[`${child?.props.field}`](rows[i], i);
              }

              let extensions: any = {}
              if (columnExtension && columnExtension[`${child?.props.field}`]) {
                extensions.width = columnExtension[`${child?.props.field}`].width ? columnExtension[`${child?.props.field}`].width : "unset"
              }

              const input = cloneElement(child, { field: field, label: " ", ...extraProps });
              return <td
                style={{ maxWidth: extensions.width, minWidth: extensions.width }}
                className={classNames(labelField === child?.props.field && classes.labelField)}
              >
                <div>{input}</div>
              </td>
            }
          }))}

          {extraColumns?.map(({ name, component }) => {
            let Component = component(rows[i], i);
            let extensions: any = {}
            if (columnExtension && columnExtension[`${name}`]) {
              extensions.width = columnExtension[`${name}`].width ? columnExtension[`${name}`].width : "unset";
              extensions.align = columnExtension[`${name}`].columnAlign ? columnExtension[`${name}`].columnAlign : "left";
            }
            return <td
              key={name + 1}
              style={{ maxWidth: extensions.width, minWidth: extensions.width, justifyContent: extensions.align === "right" ? "flex-end" : "center", display: "flex", alignItems: "center" }}
              className={classNames(labelField === name && classes.labelField)}
            >
              {Component}
            </td>
          })}

          {actionFieldArr && <td style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {actionFieldArr.map(field => <GuiButton key={i} {...field} onClick={() => field.onClick(rows[i])} />)}
          </td>}
        </tr>
      })}
    </>
  )
}

const useClasses = makeStyles({
  row: {
    display: "flex",
    backgroundColor: "var(--activeTextColor)",
    minHeight: 49,
    borderRadius: 4,
    marginBottom: 2,
    "& > td": {
      flex: 1,
      marginRight: 6,
      position: "relative",
      marginLeft: 6,
      "& > div": {
        marginTop: -17,
        "& input": {
          fontSize: "12px !important",
          fontWeight: 600,
          height: 32,
          color: "var(--defaultTextColor) !important"
        },
        "& > div > .MuiInputBase-root": { // dropdown
          fontWeight: 600,
          color: "var(--defaultTextColor) !important",
          height: 33,
          marginTop: 20
        }
      },
      "& fieldset": {
        "& > div": {
          height: 32,
          border: "none !important",
          backgroundColor: "transparent !important",
          "& > span": {
            fontSize: "12px !important",
            fontWeight: 600,
            color: "var(--defaultTextColor) !important"
          }
        }
      }
    }
  },
  labelField: {
    maxWidth: 135,
    "& div > input": {
      border: "none !important",
      backgroundColor: "transparent !important"
    }
  },
})

export default GuiFormGridRow
