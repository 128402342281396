export const play_store_new_games_tr = {
  title: "New Games",

  object_order: "Index",
  game: {
    logo_image_url: "Logo",
    name: "Name",
    developer: "Developer",
    rate: "Rate",
    store_rate: "Store Rate",
    release_date: "Release Date",
    last_release_date: "Last Release Date",
    follow: "Follow",
  },

  filter: {
    filter: "Filter",
    name: "Name",
    previous_datetime: "Date",
    chart_genre: "Genre",
    chart_type: "Type",
  },
};
