export enum PlayStoreGenre {
	"GAME" = "GAME",
	"GAME_ARCADE" = "GAME_ARCADE",
	"GAME_PUZZLE" = "GAME_PUZZLE",
	"GAME_CARD" = "GAME_CARD",
	"GAME_CASUAL" = "GAME_CASUAL",
	"GAME_RACING" = "GAME_RACING",
	"GAME_SPORTS" = "GAME_SPORTS",
	"GAME_ACTION" = "GAME_ACTION",
	"GAME_ADVENTURE" = "GAME_ADVENTURE",
	"GAME_BOARD" = "GAME_BOARD",
	"GAME_CASINO" = "GAME_CASINO",
	"GAME_EDUCATIONAL" = "GAME_EDUCATIONAL",
	"GAME_MUSIC" = "GAME_MUSIC",
	"GAME_ROLE_PLAYING" = "GAME_ROLE_PLAYING",
	"GAME_SIMULATION" = "GAME_SIMULATION",
	"GAME_STRATEGY" = "GAME_STRATEGY",
	"GAME_TRIVIA" = "GAME_TRIVIA",
	"GAME_WORD" = "GAME_WORD",
}
