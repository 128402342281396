import { DEFAULT_SUBMIT_HANDLER } from '@guibil/form/constants';
import { useGuiFormStateContext } from '@guibil/form/contexts/GuiFormStateContext';

export const useGuiFormRequestErrors = () => {
  let { requestErrors } = useGuiFormStateContext();

  const saveRequestError = requestErrors.find((reqErr) => reqErr.id === DEFAULT_SUBMIT_HANDLER);
  requestErrors = requestErrors.filter((reqErr) => reqErr.id !== DEFAULT_SUBMIT_HANDLER);

  return { saveRequestError, requestErrors };
}
