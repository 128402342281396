import { useEffect, useRef, useState } from 'react';
import { GuiRequestPack } from '../pack/gui-request-pack';

const useRequest = () => {
  const [requestPack, setRequestPack] = useState(new GuiRequestPack());
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) { isFirstRun.current = false; } else { setRequestPack(new GuiRequestPack()); }

    return () => {
      requestPack.unmount();
    };
  }, []);

  return requestPack;
};

export { useRequest };
