import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { makeStyles } from '@material-ui/styles';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import ReactHlsPlayer from 'react-hls-player';

import 'swiper/swiper.scss'

type ScreenshotType = "PHONE" | "TABLET"

interface GameScreenshotsProps {
  showVideos: boolean,
  screenshots: {
    phone: [string],
    tablet: [string],
    phone_video: string | null,
    tablet_video: string | null,
  }
}


const GameScreenshots: React.FC<GameScreenshotsProps> = ({ screenshots, showVideos }) => {
  const classes = useStyles();
  const { phone, tablet, phone_video, tablet_video } = screenshots;
  const [screenshotType, setScreenshotType] = React.useState<ScreenshotType>(phone?.length > 0 ? "PHONE" : "TABLET");

  const playerRef = useRef<HTMLVideoElement>(null);

  const ScreenshotSwitcher = () => {
    return <div className={classes.labelContainer}>
      <div>
        <FormControl component="fieldset">
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={screenshotType}
            onChange={(event: any) => setScreenshotType(event.target.value)}
            row
          >
            {phone?.length > 0 ? <FormControlLabel value="PHONE" control={<Radio />} label="Telefon" /> : null}
            {tablet?.length > 0 ? <FormControlLabel value="TABLET" control={<Radio />} label="Tablet" /> : null}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  }

  return (
    <div style={{ backgroundColor: "transparent", borderRadius: "2px" }}>
      {(phone?.length > 0 || tablet?.length > 0) ?
        <div className={classes.container}>
          <ScreenshotSwitcher />
          <Swiper
            freeMode={false}
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{ clickable: true }}
            className={classes.swiperRoot}
          >
            {
              showVideos && screenshotType === "PHONE" && phone_video && <SwiperSlide>
                <ReactHlsPlayer
                  playerRef={playerRef}
                  src={phone_video}
                  autoPlay={false}
                  controls={true}
                  width="auto"
                  height="380px"
                />
              </SwiperSlide>
            }
            {
              showVideos && screenshotType === "TABLET" && tablet_video && <SwiperSlide>
                <ReactHlsPlayer
                  playerRef={playerRef}
                  src={tablet_video}
                  autoPlay={false}
                  controls={true}
                  width="auto"
                  height="380px"
                />
              </SwiperSlide>
            }
            {
              screenshots[screenshotType === "PHONE" ? "phone" : "tablet"].map((screenshot: string, index: number) => {
                return (
                  <SwiperSlide key={index} >
                    <img
                      src={screenshot}
                      alt={screenshot}
                    />
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </div> : <div></div>}
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    padding: "8px",
    borderRadius: "2px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    color: "var(--defaultTextColor)",
    opacity: 0.8,

    "& svg": {
      color: "var(--defaultTextColor) !important",
    },

    "& span": {
      color: "var(--defaultTextColor) !important",
      fontSize: "14px !important",
    },
  },
  swiperRoot: {
    "& .swiper-slide": {
      width: "auto",
      height: "auto"
    },

    "& .swiper-slide img": {
      borderRadius: "2px",
      maxWidth: "380px",
      maxHeight: "380px"
    }
  },
});

export default GameScreenshots
