import * as Yup from "yup";
import { times } from "lodash";
import i18n from "i18next";
import { IGuiChainedFields } from "../form/features/chained-fields/GuiChainedFields";

class GuiFormGridAdapter {
  // public convertGridResponseToFormResponse(response: IGuiFormResponseEntity) {
  //   return response.reduce((acc: any, data: any, index: number) => {
  //     const items: any = {};

  //     Object.keys(data).forEach((item: any) => {
  //       items[`${item}${index}`] = data[item];
  //     });

  //     return { ...acc, ...items };
  //   }, {});
  // }

  public convertFormDataToGridSubmitData(values: any) {
    const data: any = [];
    Object.keys(values).map((i: any) => {
      data.push(values[i]);
    });

    return data;
  }

  public generateValidationSchema(
    schema: any,
    formSchema: any,
    rowCount: number,
    responseName?: string
  ) {
    if (!schema) return null;
    let objectShape: any = formSchema ? formSchema() : {};

    // if(formSchema) objectShape = {...};

    if (responseName) {
      objectShape = {
        ...objectShape,
        [responseName]: Yup.array().of(schema()),
      };
    } else {
      times(rowCount, (i) => {
        objectShape[i] = schema();
      });
    }

    // return () =>
    //   Yup.object().shape({
    //     sntpServers: Yup.array().of(schema()),
    //   });

    return () => Yup.object().shape(objectShape);
  }

  public generateChainedFields(fieldsArray: IGuiChainedFields[] | undefined, rowCount: number) {
    if(!fieldsArray) return undefined;

    let result:IGuiChainedFields[] = [];

    fieldsArray?.forEach(item => {
      times(rowCount, (i) => {
        result?.push({
          ...item,
          targetField: `${i}.${item.targetField}`, 
          fieldsToDisable: item.fieldsToDisable?.map(field => `${i}.${field}`), 
          fieldsToHide: item.fieldsToHide?.map(field => `${i}.${field}`), 
        })
      })
    })
    return result
  }

  public processErrorMessage(
    errors: any,
    paths: any,
    labelPath: string | undefined,
    message: any
  ) { 
    const subPaths = paths[0].split("[");

    // Which means we have array included
    if (subPaths.length === 2) {
      const subPathIndex = subPaths[1].slice(0, -1);

      errors[subPaths[0]] = {
        ...errors[subPaths[0]],
        [subPathIndex]: {
          ...(errors[subPaths[0]] ? errors[subPaths[0]][subPathIndex] : []),
          [paths[1]]: message.replace(
            "{{element}}",
            i18n.t(`${labelPath}.${paths[1]}`)
          ),
        },
      };
    } else {
      errors[paths[0]] = {
        ... errors[paths[0]],
        [paths[1]]: message.replace(
          "{{element}}",
          i18n.t(`${labelPath}.${paths[1]}`)
        ),
      };
    }

    return errors;
  }
}

const instance = new GuiFormGridAdapter();
export { instance as GuiFormGridAdapter };
