import { Filter, Sorting } from '@devexpress/dx-react-grid';
import { IUseRequestErrors } from '@guibil/api/types';
import React, { useContext } from 'react';
import { IGuiGridData, IPagingState, ILoadingState } from '../types';

interface IProps extends IUseRequestErrors {
  data: IGuiGridData,
  setData: React.Dispatch<React.SetStateAction<IGuiGridData>>,
  loadingState: ILoadingState,
  setLoadingState: React.Dispatch<React.SetStateAction<ILoadingState>>,
  sorting: undefined | Sorting[],
  setSorting: React.Dispatch<React.SetStateAction<undefined | Sorting[]>>,
  pagingState: IPagingState,
  setPagingState: React.Dispatch<React.SetStateAction<IPagingState>>,
  filters: Filter[],
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>,
  queryParams: any,
  setQueryParams: React.Dispatch<React.SetStateAction<any>>,
}

export const GuiGridStateContext = React.createContext<IProps | undefined>(undefined);

/**
 * if formElement is controlled, then it must not require any form context
 *
 * @param controlled
 */
export const useGuiGridStateContext = () => {
  const context = useContext(GuiGridStateContext);
  if (typeof (context) === 'undefined') {
    throw new Error('Please useGuiGridStateContext inside GuiForm');
  }

  return context;
};
