import { useEffect } from 'react';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { useGuiGridStateContext } from '../../contexts/GuiGridStateContext';
import { useGuiGridActionsContext } from '../../contexts/GuiGridActionsStateContext';

const PEFRORM_INTIAL_FETCH = 'PEFRORM_INTIAL_FETCH';

const GuiGridRequest = () => {
  const propsContext = useGuiGridPropsContext();
  const { onRequestError, removeRequestError } = useGuiGridStateContext();
  const { performFetch } = useGuiGridActionsContext();

  const performInitialFetch = async () => {
    removeRequestError(PEFRORM_INTIAL_FETCH);

    try {
      const { initialFetch } = propsContext;
      if (typeof (initialFetch) === 'function') {
        await initialFetch();
      }

      // This check is here, because after fetchData, I need to let form know that data has been loaded
      await performFetch();
    } catch (err) {
      onRequestError(PEFRORM_INTIAL_FETCH, err, performInitialFetch);
    }
  };

  useEffect(() => {
    const fetchOnMount = async () => {
      // Perform initialFetch
      await performInitialFetch();
    };

    fetchOnMount();
  }, []);

  return null;
};

export { GuiGridRequest };
