import { GuiApp } from '@guibil/app';

class ApiHeaderModel {
  getHeaders() {
    return {
      "Accept-Language": GuiApp.getLanguage(),
      "Accept": "application/json",
      "Content-Type": "application/json;",
      "Authorization": this.getAuthorization(),
    };
  }

  getFileUploadHeaders() {
    return {
      'Accept-Language': GuiApp.getLanguage(),
      'Content-Type': 'multipart/form-data;',
      'Authorization': this.getAuthorization(),
    };
  }

  getAuthorization() {
    return GuiApp.getAccessToken() ? `Bearer ${GuiApp.getAccessToken()}` : '';
  }
}

const apiHeaderModel = new ApiHeaderModel();
export { apiHeaderModel as ApiHeaderModel };
