export const log_user_management_en = {
  breadcrumbTitle: "Log User Management",
  pageTitle: "Log User Management",
  title: "Log User Management",
  menuTitle: "Log User Management",

  addUser: "Add",
  deleteConfirmTitle: "\"{{ name }}\" will be delete!",
  deleteConfirmMessage: "If \"{{ name }}\" is deleted, \"Installation Data\" will be deleted from this user, are you sure you want to continue?",
  deleteSuccessMessage: "Log user deleted successfully",

  username: "Username",
  basic_auth: "Token",
  is_active: "Is Active",
  is_test_user: "Test Account",
  actions: " ",

  create: {
    title: "Add Log User",
    username: "Username",
    password: "Password",
    password_confirm: "Confirm Password",
    successMessage: "Log user created successfully",
    randomPassword: "Generate Random Password"
  },

  update: {
    title: "Update Log User",
    username: "Username",
    is_active: "Is Active",
    is_test_user: "Test Account",
    successMessage: "Log user updated successfully",
  },

  updatePassword: {
    title: "Update Log User Password",
    username: "Username",
    password: "Password",
    password_confirm: "Confirm Password",
    successMessage: "User password updated successfully",
    randomPassword: "Generate Random Password"
  },
};
