export const play_store_developers_en = {
  title: "Developers",

  developers: {
    title: "Developers",
    object_order: "Index",
    name: "Name",
    rate: "Rate",
    last_release_date: "Last Release Date",
    follow: "Follow",
    showTags: "Tags",
    tags: {
      title: "Developer Tags",
      createButton: "Add",
      deleteConfirmMessage: "Are you sure you want to delete this tag?",
      deleteSuccessMessage: "Tag deleted successfully",
      tag: "Tag",
      actions: " ",
      create: {
        title: "Add Tag",
        tag: "New Tag",
      },
    },
  },

  developerFilter: {
    filter: "Filter",
    name: "Name",
    min_last_release_date: "Min Last Release Date",
    max_last_release_date: "Max Last Release Date",
    rate: "Rate",
    tag: "Tag",
    executive: "Executive",
  },

  newDeveloperDialog: {
    add: "New",
    title: "Add New Developer",
    url: "Developer or Game Url",
    preview: "Preview",
    save: "Save to System",
    developerExist: "Developer already exist",
    saveSuccessMessage: "Developer is saved in system",

    accepedFormats: {
      accepedFormats: "Acceped formats",
      developer: "Developer",
      game: "Game",
      or: "or"
    },

    developer: {
      developer: "Developer",
      id: "Id:",
      name: "Name:",
      url: "Url:",
    },

    games: {
      url: "Url",
      icon: " ",
      appId: "Id",
    }
  },
};
