import React, { useEffect, useState } from 'react';
import { Filter, Sorting } from '@devexpress/dx-react-grid';
import { useRequestErrors } from '@guibil/api';
import { GuiGridStateContext } from '../contexts/GuiGridStateContext';
import { IGuiGridData, ILoadingState, IPagingState } from '../types';
import { useGuiGridPropsContext } from '../contexts/GuiGridPropsContext';

interface IProps { }

const GuiGridStateContainer: React.FC<IProps> = (props) => {
  const { filterConfig, defaultSorting, paging, ...otherProps } = useGuiGridPropsContext();

  const initialPageSize = paging?.size !== undefined ? paging?.size : 50;

  const [data, setData] = useState<IGuiGridData>({ list: [], total: 0 });
  const [loadingState, setLoadingState] = useState<ILoadingState>('waiting');
  const [pagingState, setPagingState] = useState<IPagingState>({ pageIndex: 0, pageSize: initialPageSize });
  const [sorting, setSorting] = useState<Sorting[] | undefined>(defaultSorting || []);
  const [filters, setFilters] = useState<Filter[]>(filterConfig?.defaultFilters || []);
  const [queryParams, setQueryParams] = useState<any>(otherProps?.queryParams);
  const { requestErrors, onRequestError, removeRequestError } = useRequestErrors();


  const stateContext = {
    data,
    setData,
    requestErrors,
    onRequestError,
    removeRequestError,
    pagingState,
    setPagingState,
    sorting,
    setSorting,
    loadingState,
    setLoadingState,
    filters,
    setFilters,
    queryParams,
    setQueryParams
  };

  useEffect(() => {
    if (pagingState.pageIndex !== 0) {
      setPagingState(old => ({ ...old, pageIndex: 0 }));
    }
  }, [filters, sorting]);

  return (
    <GuiGridStateContext.Provider value={stateContext}>
      {props.children}
    </GuiGridStateContext.Provider>
  );
};

export { GuiGridStateContainer };
