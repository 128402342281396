import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  getFieldByName, getProperty, guiCloneDeep, guiIsEqual, isNotNullOrUndefined,
} from '@guibil/helpers';
import {
  IChainedFieldsToDisable, IChainedFieldsToHide, performChainedFieldsDisable, performChainedFieldsHide,
} from '../features/chained-fields/GuiChainedFields';
import { GuiFormElementContext } from '../../contexts/GuiFormElementContext';
import { useGuiFormPropsContext } from '../../contexts/GuiFormPropsContext';
import { useGuiFormStateContext } from '../../contexts/GuiFormStateContext';
import { IFieldOnChangeCallback } from '../../form-elements/hocs/withGuiFormItem';
import { IGuiFormType } from '../../types';
import { useFormikContext } from 'formik';

interface IProps { }

export const GuiFormElementContainer: FunctionComponent<IProps> = React.memo((props) => {
  const [fieldsToHide, setFieldsToHide] = useState<IChainedFieldsToHide>({});
  const [fieldsToDisable, setFieldsToDisable] = useState<IChainedFieldsToDisable>({});
  const [requiredFields, setRequiredFields] = useState<string[]>([]);

  const formPropsContext = useGuiFormPropsContext();
  const formStateContext = useGuiFormStateContext();
  const formik = useFormikContext();

  const {
    type, labelPath, roles, validationSchema,
  } = formPropsContext;
  const {
    data, formState, setFieldsToDismiss, isSubmitting,
  } = formStateContext;


  useEffect(() => {
    performChainedFieldsCheck(data);
  }, [data]);

  useEffect(() => {
    performChainedFieldsCheck(formik?.values);
  }, [formik?.values]);

  useEffect(() => {
    try {
      if (typeof (validationSchema) === "function") {
        const fields = validationSchema().fields;
        const requiredFields: string[] = [];

        // TODO: handle arrays' inner requiredFields, media[].ThumbnailPath 
        Object.keys(fields).forEach(key => {
          if (fields[key].exclusiveTests && fields[key].exclusiveTests.required === true) {
            requiredFields.push(key);
          }
        })

        setRequiredFields(requiredFields);
      }
    } catch (err) { }
  }, [validationSchema]);

  /** Performs dismissing data using Props. Checks for values in the form and updates all fields which need to be dismissed */
  const performChainedFieldsCheck = (data: any) => {
    const { chainedFieldsAr } = formPropsContext;
    let _fieldsToHide = {};

    if (Array.isArray(chainedFieldsAr) && chainedFieldsAr.length > 0) {
      _fieldsToHide = guiCloneDeep(fieldsToHide);
      let _fieldsToDisable = guiCloneDeep(fieldsToDisable);

      chainedFieldsAr.forEach((dependentFieldHide) => {
        const targetField = getFieldByName(data, dependentFieldHide.targetField);
        const targetFieldValue = getProperty(targetField, data); // since targetField may be, sth like prop1.subprop2, then I need to get property recursively
        _fieldsToHide = performChainedFieldsHide(dependentFieldHide, targetFieldValue, _fieldsToHide);
        _fieldsToDisable = performChainedFieldsDisable(dependentFieldHide, targetFieldValue, _fieldsToDisable);
      });

      setFieldsToHide(_fieldsToHide);
      setFieldsToDisable(_fieldsToDisable);
    }
    handleSendDismissData(_fieldsToHide); // Let form know about hidden fields, caused by DependentField
  };

  const formElementOnChange: IFieldOnChangeCallback = (field, newValue) => {
    const { chainedFieldsAr } = formPropsContext;

    // Hides/Toggles visibility of dependentFields to this field;
    if (Array.isArray(chainedFieldsAr)) {
      let _fieldsToHide = guiCloneDeep(fieldsToHide);
      let _fieldsToDisable = guiCloneDeep(fieldsToDisable);
      const filteredFieldsToHideAr = chainedFieldsAr.filter((item) => item.targetField === field);

      filteredFieldsToHideAr.forEach((dependentFieldHide) => {
        _fieldsToHide = performChainedFieldsHide(dependentFieldHide, newValue, _fieldsToHide);
        _fieldsToDisable = performChainedFieldsDisable(dependentFieldHide, newValue, _fieldsToDisable);
      });

      handleSendDismissData(_fieldsToHide); // Let form know about hidden fields, caused by DependentField
      if (!guiIsEqual(fieldsToHide, _fieldsToHide) || !guiIsEqual(fieldsToDisable, _fieldsToDisable)) {
        setFieldsToDisable(_fieldsToDisable);
        setFieldsToHide(_fieldsToHide);
      }
    }
    // End of Hides/Toggles visibility of dependentFields to this field;
  };

  const handleSendDismissData = (_fieldsToHide: IChainedFieldsToHide) => {
    const { fieldsToDismissFromPost } = formPropsContext;

    let fieldsToDismiss = Object.keys(_fieldsToHide).filter((field) => _fieldsToHide[field].length > 0);

    /**
     * Adds Fields which are located in Validation.js, but is not present in form fields
     * Otherwise, Validation.js tries to show error, but because of there is no such field, error will not be shown, and form will not be submitting
     * These fields ALSO will be used to make those Fields Error Free (fields will not have any error)
     * */
    if (validationSchema && validationSchema.fields && isNotNullOrUndefined(data)) {
      Object.keys(validationSchema.fields).map((field) => {
        if (typeof (data[field]) === 'undefined') {
          if (!fieldsToDismiss.includes(field)) {
            fieldsToDismiss.push(field);
          }
        }
      });
    }

    if (Array.isArray(fieldsToDismissFromPost) && fieldsToDismissFromPost.length > 0) {
      fieldsToDismiss = fieldsToDismiss.concat(fieldsToDismissFromPost);
    }

    setFieldsToDismiss(fieldsToDismiss);
  };

  const guiFormElementContextData = {
    formType: (type === 'new-form' ? 'add' : 'update') as IGuiFormType,
    formState,
    labelPath,
    isSubmitting,
    fieldsToHide,
    fieldsToDisable,
    formElementOnChange,
    roles: roles || {},
    requiredFields,
  };

  return (
    <GuiFormElementContext.Provider value={guiFormElementContextData}>
      {props.children}
    </GuiFormElementContext.Provider>
  );
});
