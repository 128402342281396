import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';
import { useMemo } from 'react';

export const useGuiGridActionFields = (): string[] => {
  const { actionFields } = useGuiGridPropsContext();

  return useMemo(
    () => (actionFields || []).map(({ targetField }) => targetField)
    , [actionFields]);
}

export const useGuiGridBooleanFields = (): string[] => {
  const { booleanFields } = useGuiGridPropsContext();

  return useMemo(
    () => (booleanFields || []).map(({ targetField }) => targetField)
    , [booleanFields]);
}

export const useGuiGridDateTimeFields = (): string[] => {
  const { dateTimeFields } = useGuiGridPropsContext();

  return useMemo(
    () => (dateTimeFields || []).map(({ targetField }) => targetField)
    , [dateTimeFields]);
}
export const useGuiGridNumberFields = (): string[] => {
  const { numberFields } = useGuiGridPropsContext();

  return useMemo(
    () => (numberFields || []).map(({ targetField }) => targetField)
    , [numberFields]);
}

export const useGuiGridFileFields = (): string[] => {
  const { fileFields } = useGuiGridPropsContext();

  return useMemo(
    () => (fileFields || []).map(({ targetField }) => targetField)
    , [fileFields]);
}

export const useGuiGridPhoneNumberFields = (): string[] => {
  const { phoneNumberFields } = useGuiGridPropsContext();

  return useMemo(
    () => (phoneNumberFields || []).map(({ targetField }) => targetField)
    , [phoneNumberFields]);
}

export const useGuiGridTooltipFields = (): string[] => {
  const { tooltipFields } = useGuiGridPropsContext();

  return useMemo(
    () => (tooltipFields || []).map(({ targetField }) => targetField)
    , [tooltipFields]);
}
