import { GuiAppRouter } from '@guibil/router';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useUser } from '@guibil/app';
import { Redirect } from 'react-router-dom';
import { authRouterConfig, guestRouterConfig } from './routes';
import GuiAppFrame from './components/appFrame/GuiAppFrame';
import { classNames, useWindowSize } from '@guibil/components';

export default function AppContainer(props: any) {
  const { width } = useWindowSize();
  const isToggleVisible = width < 500;
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const { } = props;
  const classes = useStyles();
  const user = useUser();

  useEffect(() => { width !== 0 && setOpenDrawer(width > 666); }, [width])

  const config = {
    auth: authRouterConfig,
    guest: guestRouterConfig,
  };

  return (
    <div className={classes.root}>
      {user?.isAuth && <GuiAppFrame openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />}
      <main className={classNames(classes.content, (!openDrawer && isToggleVisible) && classes.closedDrawer)}>
        <Container maxWidth="xl" className={classes.container}>
          <GuiAppRouter
            config={config}
            documentTitleConfig={{
              appName: "routes.app",
              seperator: " | ",
            }}
          >
            {!user?.isAuth && <Redirect to="/login" />}
            {user?.isAuth && <Redirect from="/login" to="/" />}
          </GuiAppRouter>
        </Container>
      </main>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: 'var(--bgPrimary)!important'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    minWidth: 220,
    overflow: "hidden",
    paddingTop: 20,
    paddingBottom: 8,
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down(640)]: {
      paddingTop: 8,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  closedDrawer: {
  }
}));
