import { useEffect, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import { useLang } from '@guibil/app';
import { useRequest } from '@guibil/api';
import { classNames, guiNotifier, GuiRefreshButton } from '@guibil/components';
import { useParams } from 'react-router-dom';
import { GuiDropdown } from '@guibil/form';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

interface xAxisProps {
  formatted: string,
  start: number,
  end: number
}

interface PlayTimeChartProps {
  xAxis: xAxisProps[],
  yAxis: number[],
}

const PlayTimeChart = () => {
  const { id } = useParams<{ id: string }>();

  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();
  const [loading, setIsLoading] = useState<boolean>(false);

  const [xAxis, setxAxis] = useState<xAxisProps[]>([]);
  const [yAxis, setyAxis] = useState<number[]>([]);
  const [gameVersions, setGameVersions] = useState<string[]>([]);
  const [osType, setOsType] = useState<string>("");
  const [gameVersion, setGameVersion] = useState<string>("");

  useEffect(() => {
    async function fetch() {
      await fetchChart();
      await fetchGameVersions();
    }
    fetch();
  }, [])


  useEffect(() => {
    fetchChart();
  }, [osType, gameVersion])

  const fetchGameVersions = async () => {
    try {
      const res = await req.get(`installations/game_versions/${id}`)
      setGameVersions(res);
    } catch (err) {
      guiNotifier().handleError(err)
    }
  }

  const fetchChart = async () => {
    setIsLoading(true);
    try {
      const res: PlayTimeChartProps = await req.get(`installations/play_time_chart/${id}?os_type=${osType}&game_version=${gameVersion}`)
      setxAxis(res.xAxis);
      setyAxis(res.yAxis);
    } catch (err) {
      guiNotifier().handleError(err)
    }
    setIsLoading(false);
  }

  const options = {
    chart: {
      type: 'column',
      backgroundColor: "var(--bgPrimary)",
      height: 200,
      style: {
        fontFamily: "inherit",
        color: "var(--titleColor)"
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        color: "var(--titleColor)",
      },
      itemHoverStyle: {
        color: "var(--defaultTextColor)",
      }
    },
    xAxis: {
      categories: xAxis.map(x => x.formatted),
      labels: {
        style: {
          color: "var(--titleColor)",
          fontFamily: "inherit",
          fontSize: "13px"
        }
      },
    },
    yAxis: {
      lineColor: "var(--primaryGray)",
      tickColor: "var(--primaryGray)",
      gridLineColor: "var(--primaryGray)",
      labels: {
        style: {
          color: "var(--titleColor)",
        }
      },
      title: {
        text: lang("gameTracker.development.installationDetails.playTimeChart.count"),
        style: {
          color: "var(--titleColor)",
          fontFamily: "inherit",
        }
      },
    },
    plotOptions: {
      column: {
        borderWidth: 0,
      },
    },
    series: [{
      color: "var(--primarySuccess)",
      name: lang("gameTracker.development.installationDetails.playTimeChart.count"),
      data: yAxis
    }],
  }

  return <div className={classNames(classes.wrapper, loading && classes.wrapperDrop)}>
    <div className={classes.headerComponent}>
      <div className={classes.wrapperTitle}>
        <span>{lang("gameTracker.development.installationDetails.playTimeChart.title")}</span>
      </div>
      <div className={classes.refreshButton}>
        <GuiRefreshButton
          icon="sync-alt"
          disabled={loading}
          onRefresh={fetchChart}
        />
      </div>
      <div className={classes.osType}>
        <div>{lang("gameTracker.development.installationDetails.players.os")}</div>
        < GuiDropdown
          field=""
          disabled={loading}
          controlled
          onChange={(_: any, e: any,) => setOsType(e?.props?.value)}
          showClearOption
          value={osType}
          selectionsEnum="OsType"
        />
      </div>
      <div className={classes.gameVersions}>
        <div>{lang("gameTracker.development.installationDetails.players.version")}</div>
        < GuiDropdown
          field=""
          disabled={loading}
          controlled
          onChange={(_: any, e: any,) => setGameVersion(e?.props?.value ? e?.props?.value : "")}
          value={gameVersion}
          showClearOption
          selections={gameVersions}
        />
      </div>
    </div>
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
    {
      loading && <div className={classes.loading}>
        <CircularProgress disableShrink />
      </div>
    }
  </div>
}

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    backgroundColor: "var(--bgPrimary)",
    padding: "16px 24px",
    borderRadius: "2px"
  },
  wrapperDrop: {
    opacity: 0.8,
  },
  wrapperTitle: {
    margin: 0,
    padding: '0.9rem 0.4rem',
    paddingLeft: 0,
    fontWeight: "bold",
    fontSize: 20,
    color: "var(--titleColor)",
  },
  refreshButton: {
    display: 'flex',
    padding: "8px 16px",
    justifyContent: 'flex-end',
    '& button': {
      color: 'var(--titleColor) !important',
    },
  },
  headerComponent: {
    display: "flex",
    alignItems: "center"
  },
  osType: {
    marginLeft: "-16px",
    marginRight: "10px",
    textAlign: "center",
    marginTop: "-19px",
    "& .MuiInputBase-root": {
      marginTop: "-7px !important",
    }
  },
  gameVersions: {
    textAlign: "center",
    marginTop: "-19px",
    "& .MuiInputBase-root": {
      marginTop: "-7px !important",
    }
  },
  loading: {
    position: "absolute",
    left: "48%",
    top: "48%",
    zIndex: 0,
    "& svg": {
      color: "var(--titleColor) !important"
    }
  },
})

export default PlayTimeChart
