export const app_store_game_details_en = {
  title: "Game Details",
  release_date: "Release Date",
  last_release_date: "Last Release Date",
  current_version: "Version",
  rate: "Team Rate",
  bundle: "Bundle Id",
  size: "Size",
  minimum_os_version: "Min OS Version",
  is_free: "Free",
  price: "Price",
  editor_is_choice: "Editor's Choice",
  total_number_of_reviews: "Review Count",
  store_rate: "Store Rate",
  tag: "Tags",
  comment: "Comments",
  description: "Description",
  version_history: "Version History",
  screenshots: "Screenshots",
  version: "Version",
  genre: "Genres",
  artist_history: "Artist Changes History",

  top_games_chart: "Daily Change in Top Games",
  order_change: "Order Change",
  order: "Order",
  daily_max_order: "Daily maximum order",
  daily_min_order: "Daily minimum order",
  date_selection: "Date",
  chart_type: "Type",
};
