import {
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { Column } from '@devexpress/dx-react-grid';
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid';

interface IProps {
  gridColumns: Column[],
}
/* 
  TODO List
  1-- Default column widths should be more than or equal to the grid' width in total so we need to more accurate while calculating each column width !!! ( col1.width + col2.width ... >= grid.width )
  2-- Column widths should be overridable by the columnExtensions prop 
  3-- id should be unique, so it will cause an error when two grids are on the same page
*/
export default (props: IProps) => {
  const { gridColumns } = props;
  const grid = document.getElementById("grid");
  // console.log(grid)
  // console.log(grid!.clientWidth);
  const MIN_WIDTH = grid ? grid.clientWidth / gridColumns.length : window.innerWidth / gridColumns.length;

  const defaultColumnWidths: TableColumnWidthInfo[] = gridColumns.map((column) => {
    return {
      columnName: column.name,
      width: MIN_WIDTH
    }
  })
  return (
    <TableColumnResizing
      defaultColumnWidths={defaultColumnWidths}
    />
  );

};