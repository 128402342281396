import React, { useEffect, useState } from 'react';
import { GuiEnums } from '@guibil/app';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import GidList from './components/gid-list/GidList';
import { GidStatus } from 'app/enums/app/general/gid-status/enum_type';
import { GuiButton } from '@guibil/components';
import AddGidDialog from './components/add-gid/AddGidDialog';
import { useGuiGridHook } from '@guibil/grid';
import { useRequest } from '@guibil/api';

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface GidSummary {
  idea: number,
  in_progress: number,
  waiting_for_development: number,
  dead: number,
  done: number,
}

const Gids = () => {
  const req = useRequest();
  const classes = useStyles();
  const [hook, setHook] = useGuiGridHook();
  const [value, setValue] = useState(0);
  const [isAddGidOpen, setIsAddGidOpen] = useState<boolean>();
  const [summary, setSummary] = useState<GidSummary>({ idea: 0, in_progress: 0, waiting_for_development: 0, dead: 0, done: 0 })

  useEffect(() => {
    async function get() {
      await getSummary();
    }
    get();
  }, [])

  const getSummary = async () => {
    setSummary(await req.get("gids/gid/count_summary"))
  }

  const onSuccess = async () => {
    await getSummary();
    hook?.fetch();
  }

  return (
    <div>
      <div className={classes.root}>
        <GuiButton
          onClick={() => setIsAddGidOpen(true)}
          icon="plus-circle"
          variant="success"
          text="gameTracker.development.gids.addGid.add"
          nativeProps={{ classes: { root: classes.button } }}
        />
        <Tabs
          value={value}
          onChange={(_: React.ChangeEvent<{}>, newValue: number) => setValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="auto tabs"
          TabIndicatorProps={{ style: { height: "0px" } }}
          className={classes.tabs}
        >
          <Tab label={`${summary.idea}` + " " + GuiEnums.get("GidStatus", GidStatus.IDEA)} {...a11yProps(0)} />
          <Tab label={`${summary.in_progress}` + " " + GuiEnums.get("GidStatus", GidStatus.IN_PROGRESS)} {...a11yProps(1)} />
          <Tab label={`${summary.waiting_for_development}` + " " + GuiEnums.get("GidStatus", GidStatus.WAITING_FOR_DEVELOPMENT)} {...a11yProps(2)} />
          <Tab label={`${summary.dead}` + " " + GuiEnums.get("GidStatus", GidStatus.DEAD)} {...a11yProps(3)} />
          <Tab label={`${summary.done}` + " " + GuiEnums.get("GidStatus", GidStatus.DONE)} {...a11yProps(4)} />
        </Tabs>
      </div>
      <br />
      {value === 0 && <GidList onSuccess={onSuccess} hook={hook} setHook={setHook} status={GidStatus.IDEA} />}
      {value === 1 && <GidList onSuccess={onSuccess} hook={hook} setHook={setHook} status={GidStatus.IN_PROGRESS} />}
      {value === 2 && <GidList onSuccess={onSuccess} hook={hook} setHook={setHook} status={GidStatus.WAITING_FOR_DEVELOPMENT} />}
      {value === 3 && <GidList onSuccess={onSuccess} hook={hook} setHook={setHook} status={GidStatus.DEAD} />}
      {value === 4 && <GidList onSuccess={onSuccess} hook={hook} setHook={setHook} status={GidStatus.DONE} />}
      {
        isAddGidOpen && <AddGidDialog
          onClose={() => setIsAddGidOpen(false)}
          onSuccess={() => { setIsAddGidOpen(false); hook?.fetch(); getSummary(); }}
        />
      }
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 26,
    display: "flex",
  },
  tabs: {
    width: "100%",
    "& .MuiTab-root": {
      minWidth: "202px",
      minHeight: "36px",
    },
    "& .MuiTabs-flexContainer": {
      borderRadius: "2px",
      overflow: "auto",
      "&::-webkit-scrollbar-track": {
        height: "0px !important",
        backgroundColor: "var(--primaryGridBorderColor2)",
      },

      "&::-webkit-scrollbar": {
        height: "0px !important",
        backgroundColor: "transparent",
      },

      "&::-webkit-scrollbar-thumb ": {
        height: "0px !important",
        backgroundColor: "var(--paperColor)",
      }
    },
    "& .MuiTab-wrapper": {
      textTransform: "none !important",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      backgroundColor: "var(--titleColor)",
      color: "var(--defaultTitleColor)",
      "& > span": {
        color: "var(--bgColor) !important",
        fontWeight: 600
      },
    },
    "& .MuiButtonBase-root": {
      backgroundColor: "var(--accordionColor)"
    }
  },
  button: {
    marginRight: "8px",
    height: "36px",
    paddingRight: "20px !important",
    width: "120px",
    "& svg": {
      left: 18,
      width: "18px !important",
      height: "18px !important",
    },
  },
}));

export default Gids;
