import { Grid } from '@material-ui/core';
import Detail from './components/detail/Detail';
import Players from './components/players/Players';
import PlayTimeChart from './components/play-time-chart/PlayTimeChart';


const InstallationDetails = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Detail />
      </Grid>
      <Grid item xs={12}>
        <PlayTimeChart />
      </Grid>
      <Grid item xs={12}>
        <Players />
      </Grid>
      <Grid item xs={12} />
    </Grid>
  )
}

export default InstallationDetails
