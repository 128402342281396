import { useCallback, useState } from 'react';
import { IRequestError, IRequestErrorCatchedFunction } from '@guibil/app';
import { AxiosError } from 'axios';

function preprocessRequestError(id: string, error: AxiosError, catchedFunction: IRequestErrorCatchedFunction, requestType: | 'save' | 'fetch' = 'fetch'): IRequestError {

  let title;
  let message;
  message = error?.response?.data?.exception_message?.exceptionMessage?.title
    || error?.response?.data?.exception_message?.exceptionMessage
    || error?.response?.data?.exception_message
    || error?.message;

  if (requestType === 'fetch' && !message) { message = ' '; }

  return {
    id,
    title: title || 'guibil:api.error.title',
    message: message || 'guibil:api.error.message',
    catchedFunction,
    statusCode: error.response?.status
  };
}

const useRequestErrors = () => {
  const [requestErrors, setRequestErrors] = useState<IRequestError[]>([]);

  const onRequestError = useCallback((id: string, err: any, request: IRequestErrorCatchedFunction) => {
    const requestError = preprocessRequestError(id, err, request);
    setRequestErrors((oldErrors) => {
      const otherErrors = oldErrors.filter((error) => error.catchedFunction !== requestError.catchedFunction);
      return [requestError, ...otherErrors];
    });
  }, [setRequestErrors]);

  const removeRequestError = useCallback((id: string) => {
    setRequestErrors((oldErrors) => oldErrors.filter((error) => error.id !== id));
  }, [setRequestErrors]);

  return { requestErrors, onRequestError, removeRequestError };
};

export { useRequestErrors };
