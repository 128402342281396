import { Plugin } from '@devexpress/dx-react-core';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { useLang } from '@guibil/app';
import React from 'react';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { GuiGridFilterCell } from '../../components/filters/GuiGridFilterCell';

const GuiGridFiltersConfiguration = () => {
  const { enableFiltering, showFilterSelector } = useGuiGridPropsContext();
  const lang = useLang();

  if (!enableFiltering) {
    return null;
  }
  return (
    <Plugin>
      <TableFilterRow
        cellComponent={GuiGridFilterCell}
        messages={{
          filterPlaceholder: lang('guibil:grid.filter.placeholder'),
          contains: lang('guibil:grid.filter.contains'),
          endsWith: lang('guibil:grid.filter.endsWith'),
          equal: lang('guibil:grid.filter.equal'),
          greaterThan: lang('guibil:grid.filter.greaterThan'),
          greaterThanOrEqual: lang('guibil:grid.filter.greaterThanOrEqual'),
          lessThan: lang('guibil:grid.filter.lessThan'),
          lessThanOrEqual: lang('guibil:grid.filter.lessThanOrEqual'),
          notContains: lang('guibil:grid.filter.notContains'),
          notEqual: lang('guibil:grid.filter.notEqual'),
          startsWith: lang('guibil:grid.filter.startsWith'),
        }}
        showFilterSelector={showFilterSelector}
      />
    </Plugin>
  );
};

export { GuiGridFiltersConfiguration };
