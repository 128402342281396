import { StoreType, useStore } from "app/components/appFrame/includes/store/StoreProvider";
import AppStore from "./app-store/views/TopGames";
import PlayStore from "./play-store/views/TopGames";

const TopGames = () => {
    const store = useStore();

    return store.store == StoreType.AppStore ? <AppStore /> : <PlayStore />
}

export default TopGames
