import { useLang, useUser } from '@guibil/app';
import React, { useEffect, useMemo } from 'react';
import { IGuiAppRouter } from '../types';
import { useGuiBreadcrumbCrumbs } from './hooks/useGuiBreadcrumbCrumbs';


const GuiDocumentTitleSync: React.FC<IGuiAppRouter> = (props) => {
  const { config } = props;
  const documentTitleConfig = props.documentTitleConfig!;
  const { isAuth } = useUser();
  const { crumbs } = useGuiBreadcrumbCrumbs(isAuth ? config.auth : config.guest);
  const lang = useLang();


  const seperator = documentTitleConfig.seperator || " | ";
  const appName = documentTitleConfig.appName || "";

  const documentTitle = useMemo(() => {
    const titles = crumbs
      .filter(crumb => !crumb.isFirst)
      .map((crumb) => lang(crumb.title))
      .reverse();

    titles.push(lang(appName));

    return titles
      .filter((item) => item)
      .join(seperator);
  }, [crumbs, seperator, appName])

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return null;
}

export default GuiDocumentTitleSync;
