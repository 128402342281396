import React, { FC } from 'react';
import { useLang, useUser } from '@guibil/app';
import { commonCSS } from '@guibil/styles';
import { useFormikContext } from 'formik';
import { getUserPermissionForAction } from '@guibil/helpers';
import { GuiButtonsRenderer, classNames, GuiRefreshButton, GuiButton } from '@guibil/components';
import { useGuiFormPropsContext } from '../../contexts/GuiFormPropsContext';
import { useGuiFormStateContext } from '../../contexts/GuiFormStateContext';
import { useGuiFormActionsContext } from '../../contexts/GuiFormActionsContext';
import { GUI_FORM_STATE_EVENT } from '@guibil/form/constants/gui-form-state';

interface IProps { }

const GuiFormButtons: FC<IProps> = React.memo(() => {
  const propsContext = useGuiFormPropsContext();
  const stateContext = useGuiFormStateContext();
  const actionContext = useGuiFormActionsContext();
  const formik = useFormikContext();
  const user = useUser();
  const lang = useLang();


  const {
    extraButtons, roles, disableSubmitButton, refreshConfig,
    extraButtonsPosition, type, isDialogForm, hideButtons, onCancel, fetchDefaultData
  } = propsContext;
  const {
    loadingState, formState, setFormState, isSubmitting, guiEventSystem
  } = stateContext;
  const { performFetch, performFetchDefault } = actionContext;
  const isFormRefreshing = loadingState === 'refreshing';
  const mainButtonsConfig = propsContext.mainButtonsConfig || {};

  const saveText = lang(mainButtonsConfig.saveText || 'guibil:form.button.save');
  const dialogSaveText = lang(mainButtonsConfig.dialogSaveText || 'guibil:form.button.dialogSave');
  const cancelText = lang(mainButtonsConfig.cancelText || 'guibil:form.button.close');
  const updateText = lang(mainButtonsConfig.updateText || 'guibil:form.button.update');
  const resetText = lang(mainButtonsConfig.resetText || 'guibil:form.button.reset');

  const saveIcon = mainButtonsConfig.saveIcon || 'check-circle';
  const dialogSaveIcon = mainButtonsConfig.dialogSaveIcon || 'save';
  const cancelIcon = mainButtonsConfig.cancelIcon || 'times-circle';
  const updateIcon = mainButtonsConfig.updateIcon || 'pen';
  const resetIcon = mainButtonsConfig.resetIcon || 'undo-alt';

  const submitButtonStyle = (formik.dirty && !disableSubmitButton && formik.isValid) ? commonCSS.saveButtonActive : commonCSS.saveButtonPassive;
  const dialogSubmitButtonStyle = (formik.dirty && !disableSubmitButton && formik.isValid) ? commonCSS.dialogSaveButtonActive : commonCSS.dialogSaveButtonPassive;
  const saveButtonClickDisabled = (isSubmitting || !formik.dirty || disableSubmitButton || isFormRefreshing || !formik.isValid); // disable button if form is being submitted or if no changes has been made to form
  const buttonClickDisabled = (isSubmitting || isFormRefreshing);

  let extraButtonsHorizontalClass = null;
  let extraButtonsVerticalClass = null;

  if (extraButtonsPosition === 'top-left' || extraButtonsPosition === 'bottom-left') { extraButtonsHorizontalClass = commonCSS.actionBarLeft; } else if (extraButtonsPosition === 'top-center' || extraButtonsPosition === 'bottom-center') { extraButtonsHorizontalClass = commonCSS.actionBarCenter; } else if (extraButtonsPosition === 'top-right' || extraButtonsPosition === 'bottom-right') { extraButtonsHorizontalClass = commonCSS.actionBarRight; }

  if (extraButtonsPosition === 'top-left' || extraButtonsPosition === 'top-center' || extraButtonsPosition === 'top-right') { extraButtonsVerticalClass = commonCSS.actionBarTop; } else if (extraButtonsPosition === 'bottom-left' || extraButtonsPosition === 'bottom-center' || extraButtonsPosition === 'bottom-right') { extraButtonsVerticalClass = commonCSS.actionBarBottom; }

  const isCancelButtonVisible = () => type === 'reader-writer' && formState === 'updating';
  const isRefreshButtonVisible = () => getUserPermissionForAction(user.role, roles, 'refresh') && (formState === 'monitoring' || type === "writer")
  const isExtraButtonsVisible = () => formState === 'monitoring' || formState === 'updating';
  const isSaveButtonVisible = () => getUserPermissionForAction(user.role, roles, 'submit') && formState === 'updating';
  const isUpdateButtonVisible = () => getUserPermissionForAction(user.role, roles, 'update') && type === 'reader-writer' && formState === 'monitoring';
  const isCloseButtonVisible = () => isDialogForm && onCancel;
  const isResetButtonVisible = () => (type === 'reader-writer' || type === 'writer') && formState === 'updating' && fetchDefaultData;

  return !hideButtons ? (
    <div className={classNames(commonCSS.formActionBar, isDialogForm && commonCSS.formDialogActionBar, extraButtonsHorizontalClass, extraButtonsVerticalClass)}>
      {isRefreshButtonVisible()
        && (
          <GuiRefreshButton
            {...refreshConfig}
            pause={refreshConfig?.pause /*|| formik.dirty*/}
            onRefresh={async () => { refreshConfig?.onRefresh(); await performFetch(); }}
            disabled={refreshConfig?.disabled}
            icon="sync-alt"
            isRefreshing={isFormRefreshing}
          />
        )}

      <GuiButton
        text={updateText}
        isLoading={isSubmitting}
        icon={updateIcon}
        hide={!isUpdateButtonVisible()}
        disabled={buttonClickDisabled}
        onClick={() => setFormState('updating')}
        variant="success"
        nativeProps={{
          variant: 'text',
          size: 'small',
          className: commonCSS.formEditButton
        }}
      />

      {
        mainButtonsConfig?.dialogSaveHide ?
          null :
          <GuiButton
            text={isDialogForm ? dialogSaveText : saveText}
            isLoading={isSubmitting}
            icon={isDialogForm ? dialogSaveIcon : saveIcon}
            hide={!isSaveButtonVisible()}
            disabled={saveButtonClickDisabled}
            onClick={formik.submitForm}
            variant="success"
            nativeProps={{
              size: 'small',
              className: isDialogForm ? dialogSubmitButtonStyle : submitButtonStyle,
            }}
          />
      }

      <GuiButton
        text={resetText}
        icon={resetIcon}
        hide={!isResetButtonVisible()}
        disabled={buttonClickDisabled}
        onClick={performFetchDefault}
        nativeProps={{
          variant: 'outlined',
          className: commonCSS.resetButton,
        }}
      />
      <GuiButton
        text={cancelText}
        icon={cancelIcon}
        hide={!isCancelButtonVisible()}
        disabled={buttonClickDisabled}
        onClick={() => { guiEventSystem.emit(GUI_FORM_STATE_EVENT.EXIT_FORM_UPDATE); }}
        variant="cancel"
        nativeProps={{
          size: 'small',
          className: commonCSS.cancelButton,
        }}
      />
      <GuiButton
        text={cancelText}
        icon={cancelIcon}
        hide={!isCloseButtonVisible()}
        disabled={buttonClickDisabled}
        onClick={() => onCancel && onCancel()}
        nativeProps={{
          variant: 'outlined',
          className: commonCSS.cancelButton,
        }}
      />

      {isExtraButtonsVisible()
        && (
          <GuiButtonsRenderer
            buttons={extraButtons}
            disableAll={buttonClickDisabled}
            shouldRender={(button) => !button.name || getUserPermissionForAction(user.role, roles, button.name)}
          />
        )}
    </div>
  ) : <div></div>;
});

export { GuiFormButtons };
