import React, { } from 'react'
import { Grid } from '@material-ui/core';
import validationSchema from './validation';
import { GuiForm, GuiText } from '@guibil/form'
import { GuiGenericDialog } from '@guibil/components';
import { GuiRichText } from '@guibil/form/form-elements/elements/GuiRichText';
import { makeStyles } from '@material-ui/styles';

interface AddEvaluationProps {
  isOpen: boolean,
  onSuccess: () => void,
  onClose: () => void,
}


const AddEvaluation: React.FC<AddEvaluationProps> = ({ isOpen, onSuccess, onClose }) => {
  const classes = useStyles();

  return <GuiGenericDialog
    size="lg"
    isOpen={isOpen}
    title="gameTracker.development.evaluation.add.title"
    onClose={onClose}
  >
    <div className={classes.form}>
      <GuiForm
        fetchData={() => ({
          game_id: "",
          comment: "",
        })}
        type="new-form"
        isDialogForm
        config={{
          submitDataApi: 'games/add_edit_evaluation',
        }}
        labelPath="gameTracker.development.evaluation.add"
        validationSchema={validationSchema}
        successListener={onSuccess}
        onCancel={onClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GuiText field="game_id" type="text" regexMask={/^(^$|[0-9]+)$/} />
          </Grid>
          <Grid item xs={12}>
            <GuiRichText field="comment" />
          </Grid>
        </Grid>
      </GuiForm>
    </div>
  </GuiGenericDialog>;
}

const useStyles = makeStyles(() => ({
  form: {
    "& form > div": {
      padding: "0px !important",
    }
  }
}));

export default AddEvaluation
