import { app_store_new_in_top_games_tr } from "../app-store/locales/tr_TR";
import { play_store_new_in_top_games_tr } from "../play-store/locales/tr_TR";

export const new_in_top_games_tr = {
  breadcrumbTitle: "Popülerlerde Yeni Oyun",
  pageTitle: "Popülerlerde Yeni Oyun",
  menuTitle: "Popülerlerde Yeni Oyun",
  title: "Popülerlerde Yeni Oyun",

  appStore: app_store_new_in_top_games_tr,
  playStore: play_store_new_in_top_games_tr,
};
