export interface IStorage {
  getItem: (key: string, defaultValue?: string | null) => string | null;
  setItem: (key: string, value: any) => void;
}

export class WindowLocalStorage implements IStorage {
  getItem(key: string, defaultValue?: string | null): string | null {
    if (window.localStorage.getItem(key) !== null && window.localStorage.getItem(key) !== 'undefined') {
      return window.localStorage.getItem(key);
    }
    if (defaultValue) {
      this.setItem(key, defaultValue);
      return defaultValue;
    }
    return null;
  }

  setItem(key: string, value: any) {
    if (typeof (value) !== 'string') {
      value = JSON.stringify(value);
    }
    window.localStorage.setItem(key, value);
  }
}
