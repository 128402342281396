import { app_store_game_artist_history_en } from "../app-store/locales/en_US";
import { play_store_game_developer_history_en } from "../play-store/locales/en_US";

export const game_artist_history_en = {
  breadcrumbTitle: "Artist Changes",
  pageTitle: "Artist Changes",
  menuTitle: "Artist Changes",
  title: "Artist Changes",

  appStore: app_store_game_artist_history_en,
  playStore: play_store_game_developer_history_en,
};
