export const play_store_developer_details_tr = {
  title: "Yapımcı Detayları",
  last_release_date: "Son Yayın Tarihi",
  rate: "Puan",
  tag: "Etiketler",
  comment: "Yorumlar",
  executive: "Üst yapımcı",
  executives: "Üst yapımcılar",

  executivesDialog: {
    title: "Üst yapımcılar",
    createButton: "Ekle",
    deleteConfirmMessage: "Seçili üst yapımcıyı silmek istediğinizden emin misiniz?",
    deleteSuccessMessage: "Üst yapımcı başarı ile silindi",
    name: "Yapımcı ismi",
    web_site: "Yapımcı web sitesi",
    actions: " ",
    create: {
      title: "Üst Yapımcı Ekle",
      name: "Yeni Üst Yapımcı İsmi",
      web_site: "Yeni Üst Yapımcı Web Sitesi",
    },
  },

  developerGames: {
    title: "Yapımcı Oyunları",
    object_order: "Sıra",
    logo_image_url: "Logo",
    name: "Adı",
    developer: "Yapımcı",
    rate: "Puan",
    store_rate: "Mağaza Puanı",
    release_date: "Yayın Tarihi",
    last_release_date: "Son Yayın Tarihi",
    follow: "Takip",
  }
};
