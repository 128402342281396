import { Plugin } from '@devexpress/dx-react-core';
import { GroupingState, IntegratedGrouping } from '@devexpress/dx-react-grid';
import { DragDropProvider } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';

const GuiGridGrouping = () => {
  const { groupingConfig, enableGrouping } = useGuiGridPropsContext();
  const { defaultGrouping, defaultExpandedGroups } = groupingConfig || {};

  if (!enableGrouping) {
    return null;
  }
  return (
    <Plugin>
      <DragDropProvider />
      <GroupingState
        defaultGrouping={defaultGrouping}
        defaultExpandedGroups={defaultExpandedGroups}
      />
      <IntegratedGrouping />
    </Plugin>
  );
};

export { GuiGridGrouping };
