import * as Yup from "yup";
import i18n from "i18next";

export default () =>
  Yup.object().shape({
    game_id: Yup.string().required().min(9).max(14),
    comment: Yup.string().required().trim().test(
      "",
      i18n.t("gameTracker.development.evaluation.commentValidation"),
      function (value) {
        return value !== "<p><br></p>";
      }
    ),
  });
