export const gid_display_en = {

  pdf: "Pdf",
  printGid: "Print",

  sections: {
    summary: "Summary",
    general: "Information",
    dynamic: "Dynamics",
    mechanic: "Mechanics",
    design: "Design",
  },

  summary: {
    created_by: "Created By",
    created_datetime: "Created Date",
    updated_by: "Last Updated By",
    updated_datetime: "Last Updated Date",
    rate: "Rate",
    status: "Status",
  },

  general: {
    title: "Name",
    mantra: "Mantra",
    popularity: "Popularity",
    art_difficulty: "Artistry Difficulty",
    dev_difficulty: "Development Difficulty",
    core_loops: "Core Loops",
    planned_developers: "Planned Developers",
    planned_designers: "Planned Artists",
  },

  dynamic: {
    genre: "Genre",
    progress: "Progress",
    goal: "Goal",
    challenge: "Challenge",
  },

  mechanic: {
    cores: "Core Mechanics",
    subs: "Sub Mechanics",
  },

  design: {
    description: "Descriptions",
    notes: "Notes",
    b64encode_images: "Design Images",
    files: "Design Files",
  },

  history: {
    title: "History",
    updated_by: "Updated By",
  },
};
