import { app_store_notification_tr } from "../app-store/locales/tr_TR";
import { play_store_notification_tr } from "../play-store/locales/tr_TR";

export const notification_tr = {
  breadcrumbTitle: "Oyun Bildirimleri",
  pageTitle: "Oyun Bildirimleri",
  title: "Oyun Bildirimleri",
  menuTitle: "Oyun Bildirimleri",

  appStore: app_store_notification_tr,
  playStore: play_store_notification_tr,
};
