import React from 'react'
import { useLang } from '@guibil/app'
import { GuiGenericDialog } from '@guibil/components'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { DATE_TIME_HUMAN_FORMAT, GuiText } from '@guibil/form'
import GameImages, { B64ImageProps } from './GameImages'
import { GuiRichText } from '@guibil/form/form-elements/elements/GuiRichText'
import FilesUpload, { FileUploadProps } from 'pages/components/filesUpload/FilesUpload'

export interface HistoryProps {
  created_by: string,
  created_datetime: string,
  value?: string,
  values?: string[],
  b64encode_images?: B64ImageProps[],
  files?: FileUploadProps[],
  richText?: boolean,
}

export interface HistoriesProps {
  title: string,
  histories: HistoryProps[],
  fullWidth?: boolean,
  richText?: boolean,
  onClose: () => void,
}

const History: React.FC<HistoryProps> = ({ created_by, created_datetime, value, values, b64encode_images, files, richText }) => {
  const lang = useLang();
  const classes = useStyles();
  const formatted_created_datetime = moment(created_datetime).format(DATE_TIME_HUMAN_FORMAT)

  return <div className={classes.container}>
    <div className={classes.infoContainer}>
      <div>
        {lang("gameTracker.development.gidDisplay.history.updated_by") + ":"}
        <span className={classes.updatedBy}>{created_by}</span>
      </div>
      <div>
        {formatted_created_datetime}
      </div>
    </div>
    <div className={classes.updated}>
      {
        value && richText ?
          <GuiRichText
            controlled
            field=''
            value={value}
            editMode={false}
          />
          : value && <GuiText
            controlled
            field=''
            multiline
            value={value}
            editMode={false}
          />
      }
      {
        values && <div>
          {values.map((value, key) => {
            return <div key={key}>
              <GuiText
                controlled
                field=''
                multiline
                value={value}
                editMode={false}
              />
            </div>
          })}
        </div>
      }
      {
        b64encode_images && <div>
          <GameImages b64encode_images={b64encode_images} />
        </div>
      }
      {files && <div>
        <FilesUpload files={files} disabled />
      </div>
      }
    </div>
  </div >
}

const HistoriesDialog: React.FC<HistoriesProps> = ({ title, histories, fullWidth, onClose, richText }) => {
  const lang = useLang();

  return <GuiGenericDialog
    isOpen
    dismissible
    size={fullWidth ? "xl" : "lg"}
    onClose={onClose}
    title={lang(title) + " " + lang("gameTracker.development.gidDisplay.history.title")}
  >
    <div>
      {
        histories.map((history, key) => <History key={key} {...history} richText={richText} />)
      }
    </div>
  </GuiGenericDialog>
}

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: "15px",
    color: "var(--titleColor)",
    fontSize: "14px",
  },
  infoContainer: {
    fontSize: "13px",
    opacity: 0.85,
    marginTop: "4px",
    display: "flex",
    justifyContent: "space-between",
  },
  updatedBy: {
    marginLeft: 4,
    fontWeight: 600,
  },
  updated: {
    margin: "4px",
  },
}));

export default HistoriesDialog
