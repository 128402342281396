import { app_store_new_games_en } from "../app-store/locales/en_US";
import { play_store_new_games_tr } from "../play-store/locales/en_US";


export const new_games_en = {
  breadcrumbTitle: "New Games",
  pageTitle: "New Games",
  title: "New Games",
  menuTitle: "New Games",

  appStore: app_store_new_games_en,
  playStore: play_store_new_games_tr,
};
