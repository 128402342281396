import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { guiNotifier } from '@guibil/components';
import { useGuiFormPropsContext } from '../../../contexts/GuiFormPropsContext';
import { useGuiFormStateContext } from '../../../contexts/GuiFormStateContext';
import { GUI_FORM_STATE_EVENT } from '@guibil/form/constants/gui-form-state';
import { useGuiFormActionsContext } from '@guibil/form/contexts/GuiFormActionsContext';

const GuiFormDefault = () => {
  const formik = useFormikContext();
  const formPropsContext = useGuiFormPropsContext();
  const { performFetch } = useGuiFormActionsContext();
  const {
    formState, setFormState, guiEventSystem,
  } = useGuiFormStateContext();

  const { type, config } = formPropsContext;

  useEffect(() => {
    formik.resetForm();
    formik.validateForm();
  }, [formState]);

  useEffect(() => {
    formik.validateForm();
  }, [formik.initialValues]);

  useEffect(() => {
    setFormState((type === 'writer' || type === 'new-form') ? 'updating' : 'monitoring');
  }, [type]);

  useEffect(() => {
    const unsubscribe = guiEventSystem.subscribe(GUI_FORM_STATE_EVENT.FORM_SUCCESSFULLY_SAVED, () => {
      config?.reFetchOnSuccess && performFetch();

      if (formState === 'updating' && type !== 'writer') {
        setFormState('monitoring');
      }
    });

    return unsubscribe;
  }, [formState, type]);

  useEffect(() => {
    const unsubscribe = guiEventSystem.subscribe(GUI_FORM_STATE_EVENT.EXIT_FORM_UPDATE, () => {
      const { cancelListener, type } = formPropsContext;

      if (type === 'reader-writer' && formState === 'updating') {
        const callback = () => {
          setFormState('monitoring');
          cancelListener && cancelListener();
        };
        if (formik.dirty) {
          guiNotifier().confirm({ description: 'guibil:form.confirmation.leavingUnsaved' }, callback);
        } else {
          callback();
        }
      }
    });

    return unsubscribe;
  }, [formState, formik.dirty]);

  return null;
};

export { GuiFormDefault };
