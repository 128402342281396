import Image from './Image';
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLang } from '@guibil/app';
import { guiNotifier } from '@guibil/components';


export interface Base64ImageProps {
  id: number,
  value: string,
}

interface Base64ImagesProps {
  label: string,
  b64encodeImages: Base64ImageProps[],
  setB64encodeImages: React.Dispatch<React.SetStateAction<Base64ImageProps[]>>,
}

const Base64Images: React.FC<Base64ImagesProps> = ({ label, b64encodeImages, setB64encodeImages }) => {
  const lang = useLang();
  const classes = useStyles();

  useEffect(() => { setB64encodeImages(b64encodeImages.length > 0 ? b64encodeImages : []); }, []);

  const handleDelete = (position: number) => {
    const copies = [...b64encodeImages];
    copies.splice(position, 1)
    setB64encodeImages(copies);
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const to = Number(e.currentTarget.id);
    const from = Number(e.dataTransfer.getData('position'));
    const copyListItems = [...b64encodeImages];
    const dragItemContent = copyListItems[from];
    copyListItems.splice(from, 1);
    copyListItems.splice(to, 0, dragItemContent);
    setB64encodeImages(copyListItems);
  }

  const handleImageSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {

      if (e.target.files.length > 10 || b64encodeImages.length > 10) {
        guiNotifier().error("gameTracker.components.base64Images.maxImage")
        return;
      }

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        var idxDot = file.name.lastIndexOf(".") + 1;
        var extFile = file.name.substring(idxDot, file.name.length).toLowerCase();

        if (extFile !== "jpg" && extFile !== "jpeg" && extFile !== "png") {
          guiNotifier().error("gameTracker.components.base64Images.allowedFormats");
          return;
        }

        if (file.size > 5242880) {
          guiNotifier().error("gameTracker.components.base64Images.sizeError")
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64data = reader.result;
          if (base64data) {
            setB64encodeImages(oldArray => [...oldArray, {
              id: b64encodeImages.length > 0 ? Math.max(...b64encodeImages.map(image => image.id)) + 1 : 1,
              value: base64data.toString(),
            }]);
          }
        }
      }
    }
  }

  return <div>
    <div className={classes.header}>
      <div className={classes.label}>
        {lang(label)}
      </div>
      <label className={classes.button} htmlFor="image-input">
        <FontAwesomeIcon icon={"plus-circle"} />
      </label>
      <input
        id="image-input"
        type="file"
        multiple
        value={""}
        name="gameImage"
        className={classes.imageInput}
        onChange={handleImageSelection}
        accept=".jpg, .jpeg, .png"
      />
    </div>
    <div className={classes.container}>
      {
        b64encodeImages.map((image, position) => <Image
          key={image.id}
          position={position}
          value={image.value}
          handleDelete={handleDelete}
          handleDrop={handleDrop}
        />)
      }
    </div>
  </div >
}


const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
  },
  label: {
    paddingTop: "4px",
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
  },
  imageInput: {
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  button: {
    marginLeft: 10,
    paddingTop: 2,
    cursor: "pointer",
    "& svg": {
      width: "20px !important",
      height: "20px !important",
      color: "var(--primarySuccess)"
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    marginTop: "2.5px",
    minHeight: "180px",
    borderRadius: "2px",
    backgroundColor: "var(--bgColor)",
    overflowX: "auto",
    "&::-webkit-scrollbar-track": {
      backgroundColor: "var(--primaryGridBorderColor2)",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar": {
      width: 10,
      height: 16,
      borderRadius: "2px",
      backgroundColor: "var(--primaryGridBorderColor2)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#8e8e8e",
    }
  },
}));

export default Base64Images
