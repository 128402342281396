export const app_store_game_artist_history_tr = {
  title: "Yapımcı Değişiklikleri",

  filter: {
    filter: "Filtrele",
    previous_datetime: "Tarih",
  },

  game: {
    name: "Adı",
    logo_image_url: "Logo",
    last_release_date: "Son Yayın Tarihi",
  },
  old_artist: "Eski Yapımcı",
  new_artist: "Yeni Yapımcı",
};
