import React from 'react';
import { GuiRefreshButton } from '@guibil/components';
import { useGuiGridActionsContext } from '../../contexts/GuiGridActionsStateContext';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { useGuiGridStateContext } from '../../contexts/GuiGridStateContext';
import { GuiGridButtonsRenderer } from './GuiGridButtonsRenderer';
import GuiGridExportButtons from './GuiGridExportButtons';

const GuiGridButtons = () => {
  const { extraButtons, refreshConfig } = useGuiGridPropsContext();
  const { performRefresh } = useGuiGridActionsContext();
  const { loadingState } = useGuiGridStateContext();

  const buttonClickDisabled = loadingState === 'refreshing';

  return (
    <>
      <GuiRefreshButton
        {...refreshConfig}
        icon="sync-alt"
        onRefresh={performRefresh}
        disabled={buttonClickDisabled}
      />
      <GuiGridButtonsRenderer buttons={extraButtons} />
      <GuiGridExportButtons />
    </>
  );
};

export { GuiGridButtons };
