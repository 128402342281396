export const evaluation_en = {
  breadcrumbTitle: "Game Evaluations",
  pageTitle: "Game Evaluations",
  title: "Game Evaluations",
  menuTitle: "Game Evaluations",

  object_order: "Order",
  game: {
    logo_image_url: "Logo",
    name: "Name",
  },
  created_datetime: "Created Datetime",
  comment: "Comment",
  follow: " ",
  action: " ",
  deleteConfirmMessage: "'Game Evaluation' will be deleted do you confirm?",

  add: {
    title: "New Game Evaluation",
    new: "Add New",
    game_id: "App Store Id",
    comment: "Comment",
  },

  edit: {
    title: "Update Game Evaluation",
    update: "Güncelle",
    game_id: "App Store Id",
    comment: "Comment",
  },

  commentValidation: "'Comment' can not be empty",
};
