import { useParams } from 'react-router-dom';
import { useRequest } from "@guibil/api"
import { useLang, useUser } from "@guibil/app";
import { GuiPage, useGuiPageData } from "@guibil/page"
import { Grid } from '@material-ui/core';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import { GuiDateTimePicker, GuiDropdown, useGuiFormHook } from '@guibil/form';
import Rate from 'pages/components/Rate';
import Tagger, { TagProps } from 'pages/components/Tagger';
import { useEffect, useState } from 'react';
import { classNames, GuiButton, guiNotifier, useWindowSize } from '@guibil/components';
import Comments from 'pages/components/comments/Comments';
import ComponentContainer from 'pages/components/ComponentContainer';
import ComponentItem from 'pages/components/ComponentItem';
import { makeStyles } from '@material-ui/styles';
import ExecutivesDialog from '../executives/ExecutivesDialog';


const ArtistDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { width } = useWindowSize();
  const lang = useLang();
  const req = useRequest();
  const user = useUser();
  const classes = useStyles();
  const [data, setData] = useGuiPageData();
  const [hook, setHook] = useGuiFormHook();
  const [selectedTags, setSelectedTags] = useState<TagProps[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [executives, setExecutives] = useState<any>(null);
  const [selectedExecutive, setSelectedExecutive] = useState<string>("");
  const [executivesObjects, setExecutivesObjects] = useState<any>(null);
  const [showExecutivesDialog, setShowExecutivesDialog] = useState(false);

  useEffect(() => { width !== 0 && setIsMobile(width > 666 ? false : true); }, [width])

  const fetchExecutivesData = async () => {
    setSelectedExecutive("-")
    const res = await req.get("artists/executives");
    setExecutivesObjects(res?.result);
    const executives = res?.result.map((executive: any) => { return executive?.name })
    setExecutives([lang("-"), ...executives]);
  }

  const changeArtistExecutives = async (id: number) => {
    await req.post(`artists/select_executive_artist/${data?.id}`, { id: id })
      .then(() => hook?.fetch())
      .catch(err => guiNotifier().handleError(err));
  }

  const handleExecutiveSelection = async (e: any) => {
    setSelectedExecutive(e?.target?.value);
    const executivesObject = executivesObjects.find((executive: any) => executive?.name === e.target.value);
    await changeArtistExecutives(executivesObject === undefined ? null : executivesObject.id);
  }

  const fetchData = async () => {
    await fetchExecutivesData();
    const res = await req.get(`artists/artist/${id}`);
    setSelectedTags(res?.tags?.tags);
    setSelectedExecutive(res?.executive ? res?.executive : "-");
    return { ...res }
  }

  const handleSingleSelectedTag = async (tag: TagProps) => {
    try {
      await req.post(`artists/tag_artist/${id}`, { slug: tag.slug })
    } catch (err: any) {
      guiNotifier().handleError(err)
    }
  }

  const handleSelectedTags = (tags: TagProps[]) => setSelectedTags(tags);

  return (
    <GuiPage
      fetchData={fetchData}
      setHook={setHook}
      setData={setData}
      title="gameTracker.makerDetails.appStore.title"
      headerComponent={
        <ComponentContainer>
          <ComponentItem
            item={
              <FollowingStatus
                isFollowing={data?.following}
                followUrl={`artists/follow_artist/${data?.id}`}
              />
            }
          />
        </ComponentContainer>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classNames(classes.artistHeader, isMobile ? classes.artistHeaderMobile : null)}>
            <Name
              linkTo={`makers/maker/${data?.id}`}
              name={data?.name}
              url={data?.url}
              isUrl={false}
              fontSize="28px"
              fontWeight={800}
            />
          </div>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <GuiDateTimePicker
              field=""
              editMode={false}
              controlled
              label={lang("gameTracker.makerDetails.appStore.last_release_date")}
              value={data?.last_release_date}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Rate
              label="gameTracker.makerDetails.appStore.rate"
              rate={data?.rate}
              rateUrl={`artists/rate_artist/${data?.id}`}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Grid container item spacing={0}>
              <Grid item xs={6} md={6} lg={6}>
                <GuiDropdown
                  field=""
                  label="gameTracker.makerDetails.appStore.executive"
                  controlled
                  disabled={user.role === "VIEWER"}
                  selections={executives}
                  onChange={handleExecutiveSelection}
                  value={selectedExecutive}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <GuiButton
                  nativeProps={{ classes: { root: classes.showExecutivesButton } }}
                  variant="cancel"
                  onClick={() => setShowExecutivesDialog(true)}
                  text="gameTracker.makerDetails.appStore.executives"
                  icon="pen"
                  disabled={user.role === "VIEWER"}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Tagger
              label="gameTracker.makerDetails.appStore.tag"
              selecteds={selectedTags}
              availables={data?.tags?.available_tags}
              handleSelectedTags={handleSelectedTags}
              handleSingleSelectedTag={handleSingleSelectedTag}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Comments
              label={"gameTracker.makerDetails.appStore.comment"}
              onSuccess={() => hook?.fetch()}
              postUrl={`artists/comment_artist/${data?.id}`}
              updateUrl={`artists/comment_artist/${data?.id}`}
              comments={data?.comments}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        showExecutivesDialog &&
        <ExecutivesDialog
          onClose={() => setShowExecutivesDialog(false)}
          onSuccess={async () => {
            setShowExecutivesDialog(false);
            await fetchExecutivesData();
          }}
        />
      }
    </GuiPage>
  )
}

const useStyles = makeStyles({
  showExecutivesButton: {
    marginLeft: 15,
    marginTop: 27,
    width: "-webkit-fill-available",
    "& svg": {
      left: 8,
      width: "18px !important",
      height: "18px !important",
    },
  },
  artistHeader: {
    display: "flex",
    paddingTop: 16,
  },
  artistHeaderMobile: {
    display: "inherit",
  },
  artistHeaderDivider: {
    paddingRight: 20,
  },
  artistHeaderDividerMobile: {
    paddingLeft: 0,
    paddingBottom: 6,
  }
});

export default ArtistDetail
