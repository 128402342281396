import { GuiRequestError } from '@guibil/components';
import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useGuiFormRequestErrors } from '../hooks/useGuiFormRequestErrors';

interface IProps {
  isLoaded: boolean
}

// @ts-ignore
const GuiFormChildrenOrLoaderRenderer: React.FC<IProps> = (props) => {
  const { isLoaded, children } = props;
  const { requestErrors } = useGuiFormRequestErrors();

  if (requestErrors.length) {
    return <GuiRequestError error={requestErrors[0]} />;
  }
  else if (!isLoaded) {
    return (
      <div style={{
        zIndex: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15,
      }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }

  return children;
}

export default GuiFormChildrenOrLoaderRenderer;
