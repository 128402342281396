import { useGuiFormStateContext } from '@guibil/form/contexts/GuiFormStateContext';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useGuiFormActionsContext } from '../../../contexts/GuiFormActionsContext';
import { useGuiFormPropsContext } from '../../../contexts/GuiFormPropsContext';

export const GuiFormConfiguration = () => {
  const { guiEventSystem, setLoadingState, loadingState, setIsSubmitting, setData: setFormInitialValues, formState, setFormState } = useGuiFormStateContext();
  const { setData, setHook, setGuiEventSystem } = useGuiFormPropsContext();
  const { performFetch } = useGuiFormActionsContext();
  const formik = useFormikContext();

  useEffect(() => {
    setData && setData(formik);
  }, [formik.values, formik.isValid, formik.errors, formik?.touched, formik?.status, formik?.isSubmitting, formik?.setSubmitting, loadingState]);

  useEffect(() => {
    setHook && setHook({
      fetch: performFetch,
      setLoadingState,
      loadingState,
      setIsSubmitting,
      setFormInitialValues,
      formState,
      setFormState
    });
  }, [setHook, formState]);

  useEffect(() => {
    setGuiEventSystem && setGuiEventSystem(guiEventSystem);
  }, [guiEventSystem]);

  return null;
};
