export const play_store_dashboard_en = {
  info: {
    title: "General",
    developer: "Total Developer Count:",
    game: "Total Game Count:",
    following_developer: "Following Developer Count:",
    following_game: "Following Game Count:",
  },

  followingDevelopers: {
    title: "Following Developers",
    object_order: "Index",
    name: "Name",
    rate: "Rate",
    last_release_date: "Last Release Date",
    following: "Follow",
  },

  followingGames: {
    title: "Following Games",
    object_order: "Index",
    logo_image_url: "Logo",
    name: "Name",
    developer: "Developer",
    rate: "Rate",
    store_rate: "Store Rate",
    release_date: "Release Date",
    last_release_date: "Last Release Date",
    following: "Follow",
  },
};
