import React, { useState } from 'react'
import { GuiButton, GuiGenericDialog, guiNotifier } from '@guibil/components'
import { GuiForm, GuiText } from '@guibil/form'
import { Grid, makeStyles } from '@material-ui/core'
import validationSchema from './validation';
import { useRequest } from '@guibil/api';
import PageItem from 'pages/components/PageItem';
import { useLang } from '@guibil/app';
import { GuiGrid } from '@guibil/grid';
import Name from 'pages/components/Name';
import GameLogo from 'pages/components/GameLogo';

interface NewDeveloperDialogProps {
  onClose: () => void,
  onSuccess: () => void,
}

const NewDeveloperDialog: React.FC<NewDeveloperDialogProps> = ({ onClose, onSuccess }) => {
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();
  const [details, setDetails] = useState<any>(null);

  const submitHandler = async (values: any) => {
    await req.post("tasks/play_store/developer_lookup", { url: values.url })
      .then((res: any) => { setDetails(null); setDetails(res.data); })
  }

  const saveToSystem = async () => {
    await req.post("tasks/play_store/developer_save", { developer: details?.name })
      .then(() => {
        onSuccess();
        guiNotifier().success("gameTracker.makers.playStore.newDeveloperDialog.saveSuccessMessage")
      })
      .catch((err: any) => {
        guiNotifier().handleError(err);
      })
  }

  return (
    <GuiGenericDialog
      isOpen
      size="lg"
      onClose={onClose}
      confirmBeforeClose
      title="gameTracker.makers.playStore.newDeveloperDialog.title"
    >
      <>
        <GuiForm
          fetchData={() => { return { url: "" } }}
          type="new-form"
          labelPath="gameTracker.makers.playStore.newDeveloperDialog"
          disableSubmitTriggeredByEnter={false}
          validationSchema={validationSchema}
          isDialogForm
          mainButtonsConfig={{
            dialogSaveText: "gameTracker.makers.playStore.newDeveloperDialog.preview",
            dialogSaveIcon: "search",
          }}
          submitHandler={submitHandler}
        >
          <Grid container spacing={1}>
            <GuiText
              field="url"
            />
          </Grid>
          <div className={classes.acceptedFormats}>
            <div>
              Kabul Edilen Formatlar
            </div>
            <div>
              <span>
                {lang("gameTracker.makers.playStore.newDeveloperDialog.accepedFormats.developer")}
              </span>
              <span className={classes.acceptedFormat}>
                {`\"Rollic Games\" ${lang("gameTracker.makers.playStore.newDeveloperDialog.accepedFormats.or")} \"https://play.google.com/store/apps/developer?id=Rollic Games\"`}
              </span>

            </div>
            <div>
              <span>
                {lang("gameTracker.makers.playStore.newDeveloperDialog.accepedFormats.game")}
              </span>
              <span className={classes.acceptedFormat}>
                {`\"com.fullfat.textordie\" ${lang("gameTracker.makers.playStore.newDeveloperDialog.accepedFormats.or")} \"https://play.google.com/store/apps/details?id=com.fullfat.textordie\"`}
              </span>
            </div>
          </div>
        </GuiForm>
        {details &&
          <div className={classes.root}>
            <div>
              <GuiForm
                fetchData={() => { return { ...details } }}
                title="gameTracker.makers.playStore.newDeveloperDialog.developer.developer"
                hideButtons
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <PageItem
                      label={lang("gameTracker.makers.playStore.newDeveloperDialog.developer.name")}
                      component={
                        details?.id !== null ?
                          <Name
                            linkTo={`/makers/maker/${details?.id}`}
                            name={details?.name}
                            url={details?.url}
                          />
                          :
                          <span>
                            {details?.name}
                          </span>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PageItem
                      label={lang("gameTracker.makers.playStore.newDeveloperDialog.developer.url")}
                      component={
                        <a target="blank" href={`${details?.url}`} className={classes.url} >
                          {details?.url}
                        </a>
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.saveButton}>
                      <GuiButton
                        onClick={saveToSystem}
                        text={details?.id === null ? "gameTracker.makers.playStore.newDeveloperDialog.save" : "gameTracker.makers.playStore.newDeveloperDialog.developerExist"}
                        variant="success"
                        icon="save"
                        disabled={!(details && details.id === null)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </GuiForm>
            </div>
            <GuiGrid
              fetchData={() => { return { ...details?.apps } }}
              labelPath="gameTracker.makers.playStore.newDeveloperDialog.games"
              columns={[
                "icon",
                "url",
                "appId",
              ]}
              columnExtensions={[
                { columnName: "icon", width: 76 },
              ]}
              serverSide={false}
              refreshConfig={{
                hide: true,
                onRefresh: () => null,
              }}
              hiddenSortFields={["icon"]}
              expandAll
              customCells={[
                {
                  targetFields: ["icon"], component: (row: any) =>
                    <GameLogo logo_image_url={row.row.icon} />
                },
                {
                  targetFields: ["url"], component: (row: any) =>
                    <a className={classes.url} target="blank" href={row?.row?.url}>{row?.row?.title}</a>
                },
              ]}
            />
          </div>
        }
      </>
    </GuiGenericDialog>
  )
}

const useStyles = makeStyles({
  root: {
    padding: "10px 10px",
  },
  url: {
    color: "var(--primaryBlue)"
  },
  saveButton: {
    marginTop: 20
  },
  acceptedFormats: {
    marginTop: "10px",
    marginLeft: "-4px",
    fontWeight: 600,
    fontSize: "14px",
  },
  acceptedFormat: {
    marginLeft: "4px",
    fontWeight: 500,
  }
});

export default NewDeveloperDialog
