import React, { useState } from 'react';
import { IUseGuiGridHook } from '../types';

type IGuiGridHook = IUseGuiGridHook | undefined;

const useGuiGridHook = (): [IGuiGridHook, React.Dispatch<React.SetStateAction<IGuiGridHook>>] => {
  const [guiGridHook, setGuiGridHook] = useState<IGuiGridHook>(undefined);

  return [guiGridHook, setGuiGridHook];
};

export { useGuiGridHook };
