import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useGuiFormStateContext } from '../../../contexts/GuiFormStateContext';
import { useGuiFormPropsContext } from '@guibil/form/contexts/GuiFormPropsContext';
import { GUI_FORM_ELEMENT_EVENT } from '@guibil/form/constants/gui-form-elements';
import { GUI_FORM_STATE_EVENT } from '@guibil/form/constants/gui-form-state';


export const GuiFormKeyboardEvents = () => {
  const { guiEventSystem } = useGuiFormStateContext();
  const formik = useFormikContext();
  const { disableSubmitTriggeredByEnter } = useGuiFormPropsContext();
  const [requestsToDisableSubmitOnEnter, setRequestsToDisableSubmitOnEnter] = useState(0);

  useEffect(() => {
    /**
     * Listens for all keydowns,
     * Esc - go to previos page
     * Enter - submits page
     *
     * @memberof GuiForm
     */
    const keydownFunctionListener = (e: any) => {
      // Esc
      if (e.keyCode === 27) { guiEventSystem.emit(GUI_FORM_STATE_EVENT.EXIT_FORM_UPDATE); }
      // Enter
      if (e.keyCode === 13 && disableSubmitTriggeredByEnter !== true && requestsToDisableSubmitOnEnter === 0) {
        formik.submitForm();
      }
    };

    document.addEventListener('keydown', keydownFunctionListener);

    return () => {
      document.removeEventListener('keydown', keydownFunctionListener);
    };
  }, [disableSubmitTriggeredByEnter, requestsToDisableSubmitOnEnter, formik.submitForm]);

  useEffect(() => {
    guiEventSystem.subscribe(GUI_FORM_ELEMENT_EVENT.VOTE_TO_DISABLE_FORM_SUBMIT_ON_ENTER, () => {
      setRequestsToDisableSubmitOnEnter((prev) => prev + 1);
    });

    guiEventSystem.subscribe(GUI_FORM_ELEMENT_EVENT.CANCEL_VOTE_TO_DISABLE_FORM_SUBMIT_ON_ENTER, () => {
      setRequestsToDisableSubmitOnEnter((prev) => prev - 1);
    });
  }, [setRequestsToDisableSubmitOnEnter]);


  return null;
};
