import { CssBaseline, ThemeProvider as MuiThemeProvider, ThemeOptions, withStyles } from '@material-ui/core';
import React from 'react';
import { useTheme } from './useTheme';

// Re-export with a default theme
// import { makeStyles } from '@material-ui/core/styles';

// Original module with no default theme
// import { makeStyles } from '@material-ui/styles';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  // by using global CSS we can integrate our theme with mui
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiPopover-paper ': {
      backgroundColor: "var(--paperColor)",
    },
    ".MuiDialog-paper": {
      backgroundColor: "var(--bgPrimary) !important",
      borderRadius: "2px !important",
      margin: "10px"
    },
    ".MuiPopover-paper": {
      backgroundColor: "var(--bgColor) !important",
    },
    ".MuiDialog-paperFullWidth": {
      width: "calc(100% - 10px)",
    },
    ".MuiButtonBase-root": {
      border: "none !important",
    },
    ".MuiInputBase-multiline": {
      padding: "0px !important",
    },
    ".MuiDialogContent-root": {
      border: 0,
      padding: 0,
      marginBottom: 12,
      marginLeft: 2,
      marginRight: 2,
      "&::-webkit-scrollbar-track": {
        borderRadius: 2,
        marginBottom: 12,
        backgroundColor: "var(--primaryGridBorderColor2)",
      },

      "&::-webkit-scrollbar": {
        width: 10,
        borderRadius: 2,
        backgroundColor: "transparent",
      },

      "&::-webkit-scrollbar-thumb ": {
        backgroundColor: "var(--bgLight)",
        borderRadius: 2
      }
    },
    '.MuiListItemIcon-root': {
      color: "var(--defaultTextColor)"
    },
    ".Mui-error": {
      color: "var(--primaryErrorText) !important"
    },
    ".MuiAutocomplete-popupIndicator": {
      color: "var(--defaultTextColor)"
    },
    ".MuiBackdrop-root": {
      backgroundColor: "var(--backdrop)"
    },
    ".MuiPaper-rounded": {
      borderRadius: "2px !important",
    },
    ".MuiSelect-select:focus": {
      backgroundColor: "var(--bgColor) !important"
    },
    ".MuiAccordion-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      "&.Mui-expanded": {
        minHeight: 52,
        "& > div:first-child": {
          borderRadius: "2px 2px 0 0",
        }
      }
    },
    ".MuiAccordionSummary-root": {
      backgroundColor: "var(--accordionColor)",
      borderRadius: "2px",
      "&.Mui-expanded": {
        minHeight: 57
      }
    },
    ".MuiAccordionSummary-content": {
      "& h2": {
        fontSize: "0.8rem!important",
        fontWeight: 600,
        color: "var(--titleColor) !important",
        marginLeft: -5,
      },
      "&.Mui-expanded": {
        margin: "12px 0"
      }
    },
    ".MuiAccordionSummary-expandIcon": {
      order: -1,
      marginRight: -5,
      marginLeft: -20,
      color: "var(--titleColor) !important"
    },
    ".MuiAutocomplete-clearIndicator": {
      marginRight: -5,
      marginTop: 3
    },
    ".MuiTableRow-root": {
      "& > td > div": {
        backgroundColor: "transparent"
      }
    },
    ".MuiTab-wrapper": {
      color: "var(--defaultTextColor)"
    },
    ".MuiTab-textColorPrimary.Mui-selected > .MuiTab-wrapper": {
      color: "var(--defaultTextColor) !important"
    },
    ".MuiTableCell-root": {
      borderColor: "var(--bgColor)",
      padding: "0px 4px !important",
      borderBottom: "1px solid var(--backdrop) !important",
      "& big": {
        color: "var(--titleColor) !important",
        padding: "10px 0px"
      },
    },
    ".MuiMenu-paper": {
      "&::-webkit-scrollbar-track": {
        backgroundColor: " var(--primaryGridBorderColor2)",
      },

      "&::-webkit-scrollbar": {
        width: 8,
        backgroundColor: " var(--primaryGridBorderColor2)",
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#8e8e8e",
      },

    },
    ".MuiAccordionDetails-root": {
      paddingBottom: "20px",
      backgroundColor: "var(--accordionColor)",
      borderRadius: "0 0 2px 2px",
    },
    ".MuiCheckbox-root": {
      color: "var(--titleColor)",
      ".MuiSvgIcon-root": {
        fill: "var(--titleColor)"
      }
    },
  },
})(() => null);

const GuiThemeProvider = (props: { children: React.ReactNode, theme: ThemeOptions }) => {
  useTheme();
  const { children, theme } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalCss />
      {children}
    </MuiThemeProvider>
  );
};

export { GuiThemeProvider };
