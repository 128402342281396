import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface NameProps {
  linkTo: string,
  name?: string,
  url?: string,
  isUrl?: boolean,
  fontSize?: string,
  fontWeight?: number,
}

const Name: React.FC<NameProps> = ({ name, url, linkTo, fontWeight = 500, fontSize = "14px", isUrl = true }) => {
  const classes = useStyles();

  if (!name || !linkTo) return <span>-</span>

  return <div className={classes.root} style={{ fontWeight, fontSize }}>
    {isUrl ?
      <Link to={`${linkTo}`} style={{ fontSize: fontSize }} className={classes.url}>{name}</Link>
      :
      <span style={{ fontSize: fontSize }}>{name}
      </span>}
    <span className={classes.divider} />
    {
      url && <a href={url} style={{ fontSize: fontSize }} target="blank" className={classes.url}>
        <FontAwesomeIcon style={{ fontSize: fontSize }} icon="share-square" />
      </a>
    }
  </div>
}

const useStyles = makeStyles({
  root: {
    color: "var(--titleColor)",
  },
  url: {
    color: "var(--textColor)",
    fontWeight: 600
  },
  divider: {
    paddingLeft: "10px"
  },
});

export default Name
