import React, { useMemo } from 'react';
import Chip from '@material-ui/core/Chip';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { makeStyles } from '@material-ui/core';
import { classNames, useSwitchText } from '@guibil/components';
import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';
import { GuiIcon } from '@guibil/components/components/GuiIcon';

export const GuiGridBooleanCell: React.FC<DataTypeProvider.ValueFormatterProps> = (props) => {
  const { booleanFields } = useGuiGridPropsContext();
  const classes = useStyles();
  const { value, column } = props;
  const columnName = column.name;

  const boolConfig = useMemo(
    () => (booleanFields || [])
      .find(({ targetField }) => targetField === columnName),
    [value, booleanFields],
  );

  const label = useSwitchText(value, boolConfig?.switchText, {
    activeText: boolConfig?.activeText,
    passiveText: boolConfig?.passiveText,
  });

  const valueForColor = boolConfig?.reverseColor ? !value : value;

  if (boolConfig?.type === "chip") {
    return (
      <Chip
        classes={{ root: classNames(classes.chip, valueForColor ? classes.success : classes.danger) }}
        label={label}
      />
    );
  }

  return <div className={classNames(classes.container, valueForColor ? classes.success : classes.danger)}>
    <GuiIcon icon={valueForColor ? "check-circle" : "times-circle"} />
    <span>{label}</span>
  </div>
};

const useStyles = makeStyles(() => ({
  chip: {
    color: 'var(--activeTextColor)',
    fontWeight: 500,
    minWidth: 70,

    '& > span': {
      marginBottom: 2,
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: 5,
      width: "18px !important",
      height: "18px !important",
      marginTop: 1
    }
  },
  success: {
    color: "var(--primarySuccess)",
  },
  danger: {
    color: "var(--primaryErrorText)",
  },
}));
