import { Grid } from '@material-ui/core';
import ArtistDetail from '../components/artist-detail/ArtistDetail';
import ArtistGames from '../components/artist-games/ArtistGames';


const ArtistDetails = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <ArtistDetail />
      </Grid>
      <Grid item xs={12}>
        <ArtistGames />
      </Grid>
      <Grid item xs={12} />
    </Grid>
  )
}

export default ArtistDetails
