import { Plugin } from '@devexpress/dx-react-core';
import { IntegratedSorting, Sorting, SortingState } from '@devexpress/dx-react-grid';
import { useGuiGridStateContext } from '@guibil/grid/contexts/GuiGridStateContext';
import { useMemo, useRef } from 'react';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import useGuiGridNotSortableFields from './hooks/useGuiGridNotSortableFields';

const SERVER_SIDE_SORTING_CHANGE_DEBOUNCE_IN_MS = 50;

const GuiGridSorting = () => {
  const { sorting, setSorting } = useGuiGridStateContext();
  const { enableSorting, serverSide, defaultSorting, integratedSortingColumnExtensions } = useGuiGridPropsContext();
  const notSortableFields = useGuiGridNotSortableFields();
  const setSortingTimeout = useRef<any>(0);

  const sortingStateColumnExtensions: SortingState.ColumnExtension[] = useMemo(() => {
    return notSortableFields.map(field => ({
      columnName: field,
      sortingEnabled: false,
    }))
  }, [notSortableFields])

  const handleSortingChange = (sorting: Sorting[]) => {
    clearTimeout(setSortingTimeout.current);

    setSortingTimeout.current = setTimeout(() => {
      setSorting(sorting)
    }, SERVER_SIDE_SORTING_CHANGE_DEBOUNCE_IN_MS);
  }

  if (!enableSorting) {
    return null;
  }
  return (
    <Plugin>
      {serverSide
        ? (
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
            columnExtensions={sortingStateColumnExtensions}
          />
        )
        : <SortingState columnExtensions={sortingStateColumnExtensions} defaultSorting={defaultSorting} />}
      <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
    </Plugin>
  );
};

export { GuiGridSorting };
