import React, { useEffect, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import moment from 'moment';
import { DATE_FORMAT, GuiDropdown } from '@guibil/form';
import { GuiEnums, useLang } from '@guibil/app';
import { useRequest } from '@guibil/api';
import { guiNotifier } from '@guibil/components';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { PlayStoreGenre } from 'app/enums/app/general/play-store-genre/enum_type';
import { PlayStoreChart } from 'app/enums/app/general/play-store-chart/enum_type';


interface yAxisDataProps {
  order: number,
  created_datetime?: string,
  daily_max_order?: any,
  daily_min_order?: any,
}

interface yAxisProps {
  genre: PlayStoreGenre,
  data: yAxisDataProps[]
}

interface TopChartProps {
  exist: boolean,
  xAxis: string[],
  yAxis: yAxisProps[],
}

interface yAxisDisplayDataProps {
  y?: number;
  daily_max_order?: any;
  daily_min_order?: any;
};
interface yAxisDisplayProps {
  color: string;
  name: string;
  data: yAxisDisplayDataProps[]
}

interface Props { game_id: string }

const TopChart: React.FC<Props> = ({ game_id }) => {
  const lang = useLang();
  const req = useRequest();
  const classes = useClasses();

  const [exist, setExist] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [xAxisSeries, setxAxisSeries] = useState<string[]>([]);
  const [yAxisSeries, setyAxisSeries] = useState<yAxisDisplayProps[]>([]);
  const [timeSelection, setTimeSelection] = useState<string>("oneWeek");
  const [chartType, setChartType] = useState<string>(PlayStoreChart.FREE);

  useEffect(() => { fetchData(); }, [timeSelection, chartType])

  const fetchData = async () => {
    setLoading(true);
    await req.get(`ps_games/game_top_chart/${game_id}?time=${timeSelection}&chart_type=${chartType}`)
      .then((res: TopChartProps) => {
        setExist(res.exist);
        setxAxisSeries(res.xAxis?.map((datetime: string) => {
          return moment(datetime).format(DATE_FORMAT);
        }));

        var yAxisSeriesData: {
          name: any;
          color: string;
          data: yAxisDisplayDataProps[];
        }[] = [];


        res.yAxis?.forEach((item: yAxisProps) => {
          let yAxisDisplayData: yAxisDisplayDataProps[] = []
          res.xAxis?.forEach((datetime: string) => {
            var found = item.data?.find((item: yAxisDataProps) => item.created_datetime === datetime);
            if (found !== undefined) {
              yAxisDisplayData.push({
                y: found.order,
                daily_max_order: found.daily_max_order || "-",
                daily_min_order: found.daily_min_order || "-"
              });
            } else {
              yAxisDisplayData.push({});
            }
          });

          var color = ""
          switch (item.genre) {
            case "GAME":
              color = "#2a991c";
              break;
            case "GAME_ARCADE":
              color = "#DC143C";
              break;
            case "GAME_PUZZLE":
              color = "#D2691E";
              break;
            case "GAME_CARD":
              color = "#7FFFD4";
              break;
            case "GAME_CASUAL":
              color = "#8B008B";
              break;
            case "GAME_RACING":
              color = "#FF8C00";
              break;
            case "GAME_SPORTS":
              color = "#FF1493";
              break;
            case "GAME_ACTION":
              color = "#4B0082";
              break;
            case "GAME_ADVENTURE":
              color = "#FFA07A";
              break;
            case "GAME_BOARD":
              color = "#FF6347";
              break;
            case "GAME_CASINO":
              color = "#00CED1";
              break;
            case "GAME_EDUCATIONAL":
              color = "#A52A2A";
              break;
            case "GAME_MUSIC":
              color = "#B8860B";
              break;
            case "GAME_ROLE_PLAYING":
              color = "#7a4b7a";
              break;
            case "GAME_SIMULATION":
              color = "#9400D3";
              break;
            case "GAME_STRATEGY":
              color = "#FA8072";
              break;
            case "GAME_TRIVIA":
              color = "#6B8E23";
              break;
            case "GAME_WORD":
              color = "#000080";
              break;
            default:
              break;
          }

          if (item?.data && item?.data.length > 0) {
            yAxisSeriesData.push({
              name: GuiEnums.get("PlayStoreGenre", item.genre),
              color: color,
              data: yAxisDisplayData
            })
          }

        });

        setyAxisSeries(yAxisSeriesData)
        setLoading(false);
      })
      .catch((err: any) => {
        guiNotifier().handleError(err);
        setLoading(false);
      });
  }

  const options = {
    chart: {
      type: 'line',
      backgroundColor: "var(--bgPrimary)",
      height: 200,
      style: {
        fontFamily: "inherit",
        color: "var(--titleColor)"
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        color: "var(--titleColor)"
      },
      itemHoverStyle: {
        color: "var(--defaultTextColor)",
      }
    },
    xAxis: {
      lineColor: "var(--primaryGray)",
      categories: xAxisSeries,
      labels: {
        style: {
          color: "var(--titleColor)",
          fontFamily: "inherit",
          fontSize: "13px"
        }
      },
    },
    yAxis: {
      reversed: true,
      lineColor: "var(--primaryGray)",
      tickColor: "var(--primaryGray)",
      gridLineColor: "var(--primaryGray)",
      labels: {
        style: {
          color: "var(--titleColor)",
        }
      },
      title: {
        text: lang("gameTracker.gameDetails.playStore.order_change"),
        style: {
          color: "var(--titleColor)",
          fontFamily: "inherit",
        }
      },
    },
    tooltip: {
      formatter: function () {
        const that: any = this;
        return (
          that.x +
          "</br>" +
          that.series.name +
          ": " +
          "<b>" +
          that.y +
          "</b> </br>" +
          lang("gameTracker.gameDetails.playStore.daily_max_order") +
          ": " +
          "<b>" +
          that.point.daily_max_order +
          "</b> </br>" +
          lang("gameTracker.gameDetails.playStore.daily_min_order") +
          ": " +
          "<b>" +
          that.point.daily_min_order + "</b>"
        );
      },
    },
    plotOptions: {
      area: {
        connectNulls: false,
        stacking: 'normal',
        lineColor: 'red',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: 'red'
        }
      },
      series: {
        turboThreshold: 5000
      },
    },
    series: yAxisSeries
  }

  if (loading) {
    return <div className={classes.loadingIndicator}>
      <CircularProgress />
    </div>
  }


  if (!exist) return null;


  return <Grid container spacing={1}>
    <Grid item xs={12}>
      <div className={classes.root}>
        <div className={classes.title}>
          {lang("gameTracker.gameDetails.playStore.top_games_chart")}
        </div>

        <div className={classes.timeSelection}>
          <GuiDropdown
            field=""
            label="gameTracker.gameDetails.playStore.chart_type"
            controlled
            onChange={(_: any, e: any,) => setChartType(e?.props?.value)}
            value={chartType}
            selectionsEnum="PlayStoreChart"
          />
          <div style={{ marginLeft: 5 }}></div>
          <GuiDropdown
            field=""
            label="gameTracker.gameDetails.playStore.date_selection"
            controlled
            onChange={(_: any, e: any,) => setTimeSelection(e?.props?.value)}
            value={timeSelection}
            selectionsEnum="DateFilter"
          />
        </div>
      </div>

    </Grid>
    <Grid item xs={12}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </Grid>
  </Grid>
}

const useClasses = makeStyles({
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    "& svg": {
      color: "var(--titleColor) !important"
    }
  },
  root: {
    display: "flex",
    alignItems: "end",
    flexWrap: "wrap",

  },
  title: {
    opacity: 0.8,
    color: "var(--defaultTextColor)",
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: 6,
    paddingBottom: 10
  },
  timeSelection: {
    display: "flex",
    minWidth: 300,
    paddingLeft: 20
  },
})

export default TopChart
