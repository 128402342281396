import { GuiButton } from '@guibil/components';
import { useGuiGridActionsContext } from '@guibil/grid/contexts/GuiGridActionsStateContext';
import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import React from 'react';

const GuiGridExportButtons = () => {
  const { excelExporterProps, pdfExporterProps } = useGuiGridPropsContext();
  if (!excelExporterProps && !pdfExporterProps) return null; // IF NO PROPS IS 
  const classes = useClasses();
  const { exporter } = useGuiGridActionsContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{ position: "absolute", right: 8, top: 14, zIndex: 1234 }}
      className={classes.buttonContainer}
    >
      <GuiButton
        text="guibil:grid.export"
        icon={<GetAppIcon />}
        variant="cancel"
        iconPosition="right"
        onClick={handleClick}
        ariaHasPopup
        ariaControls="long-menu"

      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        style={{ transform: "translate(-42px, 3px)" }}
      >
        {excelExporterProps && <MenuItem disabled={excelExporterProps.disabled}>
          <GuiButton
            disabled={excelExporterProps.disabled}
            onClick={exporter.performExcelExport}
            text="guibil:grid.exportExcel"
            icon="file-excel"
            variant="cancel"
            iconPosition="right"
            aria-controls="long-menu"
            aria-haspopup="true"
            nativeProps={{ classes: { root: classes.exportButton } }}
          />
        </MenuItem>}
        {pdfExporterProps && <MenuItem disabled={pdfExporterProps.disabled}>
          <GuiButton
            disabled={pdfExporterProps.disabled}
            onClick={exporter.performPdfExport}
            text="guibil:grid.exportPdf"
            icon="file-pdf"
            variant="cancel"
            iconPosition="right"
            nativeProps={{ classes: { root: classes.exportButton } }}
          />
        </MenuItem>}
      </Menu>
    </div>
  )
}

const useClasses = makeStyles({
  buttonContainer: {
    "&  button": {
      border: "transparent !important",
      paddingLeft: "7px !important",
      paddingRight: "19px !important",
      marginRight: "10px !important",
      // paddingBottom: "2px !important",
      "& span": {
        fontSize: 12,
      },
      "& svg": {
        left: "unset",
        right: 0,
        width: "30px !important",
        height: "30px !important",
        backgroundColor: "var(--primaryLight2) !important",
        padding: "3px !important",
        borderRadius: "50% !important"
      },
      "& > span": {
      }
    }
  },

  exportButton: {
    border: "transparent !important",
    backgroundColor: "transparent !important",
    paddingRight: "6px !important",
    "& svg": {
      width: "18px !important",
      height: "18px !important",
    }
  },
})


export default GuiGridExportButtons
