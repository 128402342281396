export const app_store_artists_tr = {
  menuTitle: "Yapımcılar",

  artists: {
    title: "Yapımcılar",
    object_order: "Sıra",
    name: "Adı",
    rate: "Puan",
    last_release_date: "Son Yayın Tarihi",
    follow: "Takip",
    showTags: "Etiketler",
    tags: {
      title: "Yapımcı Etiketleri",
      createButton: "Ekle",
      deleteConfirmMessage: "Seçili etiketi silmek istediğinizden emin misiniz?",
      deleteSuccessMessage: "Etiket başarı ile silindi",
      tag: "Etiket",
      actions: " ",
      create: {
        title: "Etiket Ekle",
        tag: "Yeni Etiket",
      },
    },
  },

  artistFilter: {
    filter: "Filtrele",
    name: "Ad",
    min_last_release_date: "Min Son Yayın Tarihi",
    max_last_release_date: "Max Son Yayın Tarihi",
    rate: "Puan",
    tag: "Etiket",
    executive: "Üst Yapımcı",
  },

  newArtistDialog: {
    add: "Ekle",
    title: "Yeni Yapımcı Ekle",
    url: "Yapımcı veya Oyun Linki",
    previewArtist: "Önizle",
    save: "Sisteme Kaydet",
    artistExist: "Yapımcı zaten kayıtlı",
    saveSuccessMessage: "Yapımcı sisteme kayıt edildi",

    artist: {
      artist: "Yapımcı",
      id: "Id:",
      name: "Adı:",
      url: "Url:",
    },

    games: {
      url: "Url",
    },
  },
};
