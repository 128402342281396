import { app_store_dashboard_tr } from "../app-store/locales/tr_TR";
import { play_store_dashboard_tr } from "../play-store/locales/tr_TR";


export const dashboard_tr = {
  breadcrumbTitle: "Ana Sayfa",
  pageTitle: "Ana Sayfa",
  title: "Ana Sayfa",
  menuTitle: "Ana Sayfa",

  appStore: app_store_dashboard_tr,
  playStore: play_store_dashboard_tr,
};
