/* eslint no-template-curly-in-string: "off" */
export const guibil_form_en = {
  confirmation: {
    title: 'Confirm',
    submit: 'Are you sure you want to submit form?',
    leavingUnsaved: 'If you leave the page, all unsaved changes will be lost!',
  },
  successMessage: "Your request completed successfully",
  successUpdateMessageWithTitle: "'{{title}}' updated successfully",
  successCreateMessageWithTitle: "'{{title}}' created successfully",
  errorMessage: "An error occured during your operation",
  button: {
    save: 'Save',
    close: 'Close',
    update: 'Edit',
    dialogSave: 'Save',
    reset: "Reset To Default",
  },
  dateTime: {
    confirmText: 'Ok',
  },
  dropdown: {
    clear: 'Clear',
  },
  validation: {
    mixed: {
      default: "'{{element}}' is invalid",
      required: "'{{element}}' field is required",
      oneOf: "'{{element}}' must be one of the following values: ${values}",
      notOneOf: "'{{element}}' must not be one of the following values: ${values}",
      notType: "'{{element}}' field is required",
    },
    string: {
      required: "'{{element}}' field is required",
      length: "'{{element}}' must be exactly ${length} characters",
      min: "'{{element}}' must be at least ${min} characters",
      max: "'{{element}}' must be at most ${max} characters",
      matches: "'{{element}}' must match the following: '${regex}'",
      email: "'{{element}}' must be a valid email",
      url: "'{{element}}' must be a valid URL",
      trim: "'{{element}}' must be a trimmed string",
      lowercase: "'{{element}}' must be a lowercase string",
      uppercase: "'{{element}}' must be a uppercase string",
    },
    number: {
      min: "'{{element}}' must be greater than or equal to ${min}",
      max: "'{{element}}' must be less than or equal to ${max}",
      lessThan: "'{{element}}' must be less than ${less}",
      moreThan: "'{{element}}' must be greater than ${more}",
      notEqual: "'{{element}}' must be not equal to ${notEqual}",
      positive: "'{{element}}' must be a positive number",
      negative: "'{{element}}' must be a negative number",
      integer: "'{{element}}' must be an integer",
    },
    date: {
      min: "'{{element}}' field must be later than ${min}",
      max: "'{{element}}' field must be at earlier than ${max}",
    },
    object: {
      noUnknown: "'{{element}}' field cannot have keys not specified in the object shape",
    },
    array: {
      min: "'{{element}}' field must have at least ${min} items",
      max: "'{{element}}' field must have less than or equal to ${max} items",
    },
    onlyLetters: 'Only letters',
    onlyLettersAndDigits: 'Only letters and digits',
    atLeastOneUpperCase: 'Must contain at least one uppercase letter',
    atLeastOneLowerCase: 'Must contain at least one lowercase letter',
    atLeastOneDigit: 'Must contain at least one number',
    atLeastOneSpecial: 'Must contain at least one special character',
    passwordsMustMatch: 'Passwords mismatch',
    invalidPhoneNumber: 'Phone number is invalid',
  },

  validIpMessage: 'Enter a valid IP address',
  validHexMessage: 'Enter a valid HEX value',
  validMacMessage: 'Enter a valid MAC address',
  validSubnetMaskMessage: 'Enter a valid subnet mask',
  validIpOrDomainMessage: "Enter a valid IP address or Domain name",
  uniqueIpMessage: 'Enter a unique IP address',

  defaultValue: "Default Value '{{defaultValue}}'"
};
