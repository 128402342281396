import React, { useEffect, useState } from 'react'
import { GuiText } from '@guibil/form';
import { makeStyles } from '@material-ui/styles';
import { classNames, GuiButton } from '@guibil/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLang } from '@guibil/app';
import { isEmpty } from 'lodash';


interface ValueProps {
  value: string,
  position: number,
  disabled?: boolean,
  allowDelete: boolean,
  handleDelete: (position: number) => void,
  handleChange: (position: number, item: string) => void,
  handleDragStart: (e: React.DragEvent<HTMLDivElement>) => void,
  enableDropping: (e: React.DragEvent<HTMLDivElement>) => void,
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void,
}

const Value: React.FC<ValueProps> = ({ value, position, disabled, allowDelete, handleDelete, handleChange, handleDragStart, enableDropping, handleDrop }) => {
  const lang = useLang();
  const classes = useStyles();

  const [input, setInput] = useState<string>(value);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => setError(isEmpty(input) ? lang("gameTracker.components.valuesList.value") : undefined), [input])

  const handleInputChange = (e: any) => {
    setInput(e.target.value)
    handleChange(position, e.target.value);
  }

  return <div className={classes.root}>
    {
      !disabled && <div
        id={position.toString()}
        onDragStart={handleDragStart}
        onDragOver={enableDropping}
        onDrop={handleDrop}
        className={classes.buttonDrag}
        draggable>
        <FontAwesomeIcon icon={"grip-lines"} />
      </div>
    }

    <GuiText
      field=''
      multiline
      controlled
      editMode={!disabled}
      value={input}
      fieldError={error}
      onChange={handleInputChange}
    />

    <GuiButton
      onClick={() => handleDelete(position)}
      icon="trash-alt"
      disabled={!allowDelete}
      hide={disabled}
      nativeProps={{ classes: { root: classNames(classes.button, classes.buttonDelete) } }}
    />
  </div>
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  button: {
    marginTop: "11px",
    width: "34.5px",
    height: "34.5px",
    marginLeft: "4px !important",
    minWidth: "0px !important",
    "& svg": {
      left: "9px",
      width: "16px !important",
      height: "16px !important",
    },
  },
  buttonDelete: {
    "& svg": {
      color: "var(--primaryError)",
    },
  },
  buttonDrag: {
    textAlign: "center",
    cursor: "grab",
    marginTop: "21px",
    width: "34.5px",
    height: "34.5px",
    "& svg": {
      left: "9px",
      color: "var(--titleColor)",
      width: "17px !important",
      height: "17px !important",
    },
  }
}))


export default Value;
