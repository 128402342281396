import { createMuiTheme, responsiveFontSizes, ThemeOptions } from '@material-ui/core';
import { defaultTheme } from './theme/defaultTheme';

const { merge } = require('lodash');

const createGuiTheme = (overrides: ThemeOptions) => {
  const theme = merge(
    defaultTheme,
    overrides,
  );

  return responsiveFontSizes(createMuiTheme(theme));
};

export { createGuiTheme };
