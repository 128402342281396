import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import { Grid } from '@material-ui/core';
import { DATE_FORMAT, GuiDateTimePicker, GuiDropdown, GuiForm, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form';
import { GuiButton, useWindowSize } from '@guibil/components';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { commonCSS } from '@guibil/styles';

const NewInTopGames = () => {
  const { width } = useWindowSize();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [gridHook, setGridHook] = useGuiGridHook();
  const [data, setData] = useGuiFormData();
  const [filterHook, setFilterHook] = useGuiFormHook();
  const [filterParams, setFilterParams] = useState<any | undefined>({
    name: "",
    chart_type: "",
    chart_genre: "",
    previous_datetime: moment().startOf("day").format(DATE_FORMAT),
  });

  useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

  const handleSetFilter = () => {
    gridHook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
    gridHook?.setQueryParams({
      name: data?.values?.name,
      chart_type: data?.values?.chart_type,
      chart_genre: data?.values?.chart_genre,
      previous_datetime: data?.values?.previous_datetime,
    })
  }

  const handleResetFilter = () => {
    setFilterParams(undefined);
    gridHook?.setQueryParams({
      previous_datetime: moment().startOf("day").format(DATE_FORMAT),
    });
    filterHook?.fetch();
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <GuiForm
          fetchData={() => filterParams}
          setHook={setFilterHook}
          setData={setData}
          showForm={!showFilter}
          title="gameTracker.notifications.newInTopGames.playStore.filter.filter"
          labelPath="gameTracker.notifications.newInTopGames.playStore.filter"
          disableLeaveFormPrompt
          headerComponent={
            <div>
              <GuiButton
                onClick={() => setShowFilter(!showFilter)}
                nativeProps={{ classes: { root: commonCSS.iconButton } }}
                variant="cancel"
                icon={showFilter ? "eye-slash" : "eye"}
              />
              <GuiButton
                onClick={handleResetFilter}
                nativeProps={{ classes: { root: commonCSS.iconButton } }}
                variant="cancel"
                icon="undo-alt"
              />
              <GuiButton
                onClick={handleSetFilter}
                nativeProps={{ classes: { root: commonCSS.iconButton } }}
                variant="success"
                icon="filter"
              />
            </div>
          }
          hideButtons
          type="writer"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <GuiText
                field="name"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <GuiDropdown
                field="chart_genre"
                showClearOption
                selectionsEnum="PlayStoreGenre"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <GuiDropdown
                field="chart_type"
                showClearOption
                selectionsEnum="PlayStoreChart"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <GuiDateTimePicker
                field="previous_datetime"
                disableTime
              />
            </Grid>
          </Grid>
        </GuiForm>
      </Grid>

      <Grid item xs={12}>
        <GuiGrid
          setHook={setGridHook}
          queryParams={filterParams}
          fetchData="ps_games/new_in_top_games"
          title="gameTracker.notifications.newInTopGames.playStore.title"
          labelPath="gameTracker.notifications.newInTopGames.playStore"
          columnExtensions={[
            { columnName: "game.logo_image_url", width: 76 },
            { columnName: "game.following", width: 150 },
          ]}
          hiddenSortFields={[
            "game.logo_image_url",
            "game.following",
          ]}
          columns={[
            "game.logo_image_url",
            "chart_genre",
            "game.name",
            "game.last_release_date",
            "game.following",
          ]}
          dateTimeFields={[
            { targetField: "game.last_release_date" },
          ]}
          enumCells={[
            { targetField: "chart_genre", enumObject: "PlayStoreGenre" }
          ]}
          customCells={[
            {
              targetFields: ["game.name"], component: (row: any) =>
                <Name
                  linkTo={`/games/game/${row.row?.game?.id}`}
                  name={row.row?.game?.name}
                  url={row.row?.game?.url}
                />
            },
            {
              targetFields: ["game.logo_image_url"], component: (row: any) =>
                <GameLogo logo_image_url={row.row?.game?.logo_image_url} />
            },
            {
              targetFields: ["game.following"], component: (row: any) =>
                <FollowingStatus
                  key={row.row?.id}
                  isFollowing={row.row?.game?.following}
                  followUrl={`ps_games/follow_game/${row.row?.game?.id}`}
                />
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} />
    </Grid >
  )
}

export default NewInTopGames
