import React from 'react'
import moment from 'moment';
import { DATE_HUMAN_FORMAT, DATE_TIME_HUMAN_FORMAT } from '@guibil/form';
import { useLang } from '@guibil/app';

interface DatetimeProps {
  datetimeString?: string,
  timeDisabled?: boolean,
}

const Datetime: React.FC<DatetimeProps> = ({ datetimeString, timeDisabled }) => {
  const lang = useLang();
  const momentValue = moment(datetimeString);

  if (!datetimeString) {
    return <span>{lang("gameTracker.components.unknown")}</span>
  }

  if (momentValue.isValid()) {
    return <span>{momentValue.format(timeDisabled ? DATE_HUMAN_FORMAT : DATE_TIME_HUMAN_FORMAT)}</span>
  }
  else {
    return <span>{lang("gameTracker.components.unknown")}</span>
  }
}

export default Datetime
