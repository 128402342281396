import { GuiApp } from '@guibil/app';

export function getApiURL(pathToAppend = '') {
  let result = GuiApp.getConfig().API_ROOT_URL;

  if (typeof (pathToAppend) === 'string' && pathToAppend.length > 0) {
    if (isAbsolutePath(pathToAppend)) {
      result = pathToAppend;
    }
    else {
      result = joinURLs(result, pathToAppend);
    }
  }

  return result;
}

export function isAbsolutePath(path: string) {
  return /^https?:\//.test(path);
}

export function joinURLs(...paths: string[]) {
  let url = "";
  paths.forEach(path => {
    if (url) { url += "/"; }
    url += path;
  })
  // https://stackoverflow.com/questions/40649382/how-to-replace-double-multiple-slash-to-single-in-url
  url = url.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
  return url;
}

// https://stackoverflow.com/a/18650828
export function formatBytes(bytes: number, decimals = 1) {
  if (bytes === 0) return '0 Byte';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const ind = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, ind)).toFixed(dm)) + ' ' + sizes[ind];
}

// ###########################      Helpers used in Requests      ###########################
// For test purpose, simulates latency
export function sleepFor(sleep = 2000) {
  // @ts-ignore
  if (process.env.NODE_ENV !== 'development') { sleep = 0; }
  return new Promise((r) => setTimeout(r, sleep));
}
