import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// export type IGuiIconConfig = {
//   icon: IconProp | JSX.Element,
//   size?: SizeProp | 'inherit' | 'default' | 'small' | 'large',
// }
interface IGuiIconProps {
  // config?: IGuiIconConfig,
  icon?: IconProp | JSX.Element | string,
}

export const GuiIcon: React.FC<IGuiIconProps> = (props): any => {
  const { icon } = props;

  if (!icon) { return null; }

  const iconContainer = typeof (icon) === 'string'
    ? <FontAwesomeIcon icon={icon as IconProp} color="inherit" />
    : icon;

  return iconContainer;
};
