import { app_store_top_games_tr } from "../app-store/locales/tr_TR";
import { play_store_top_games_tr } from "../play-store/locales/tr_TR";


export const top_games_tr = {
  breadcrumbTitle: "Popüler Oyunlar",
  pageTitle: "Popüler Oyunlar",
  title: "Popüler Oyunlar",
  menuTitle: "Popüler Oyunlar",

  appStore: app_store_top_games_tr,
  playStore: play_store_top_games_tr,
};
