export const theme_tr = {
  title: "Tema",
  breadcrumbTitle: "Tema",
  pageTitle: "Tema Ayarı",

  theme: "Tema",
  dark: "Koyu",
  light: "Açık",

  use: "Kullan",
  using: "Kullanılıyor",
};
