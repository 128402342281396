import { game_artist_history_en } from "../game-artist-history/locales/en_US";
import { new_in_top_games_en } from "../new-in-top-games/locales/en_US";
import { notification_en } from "../notification/locales/en_US";

export const notifications_en = {
  breadcrumbTitle: "Notifications",
  pageTitle: "Notifications",
  title: "Notifications",
  menuTitle: "Notifications",

  notification: notification_en,
  gameArtistHistory: game_artist_history_en,
  newInTopGames: new_in_top_games_en,
};
