import { app_store_notification_en } from "../app-store/locales/en_US";
import { play_store_notification_en } from "../play-store/locales/en_US";

export const notification_en = {
  breadcrumbTitle: "Game Notifications",
  pageTitle: "Game Notifications",
  title: "Game Notifications",
  menuTitle: "Game Notifications",

  appStore: app_store_notification_en,
  playStore: play_store_notification_en,
};
