import { GuiApp } from '@guibil/app';
import React, { useState } from 'react'
import { useRequest } from '@guibil/api';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames, GuiButton, guiNotifier } from '@guibil/components';

interface UploadFileProps {
  position: number,
  filename: string,
  disabled?: boolean,
  downloadUrl?: string,
  handleDelete: (position: number) => void,
}

const UploadFile: React.FC<UploadFileProps> = ({ position, filename, disabled, downloadUrl, handleDelete }) => {
  const req = useRequest();
  const classes = useStyles();
  const [percentage, setPercentage] = useState<Number | null>(null);

  const onDownloadProgress = (progress: any) => {
    setPercentage(Math.round((progress.loaded * 100) / progress.total))
  }

  const handleDownload = async () => {
    try {
      setPercentage(0);
      if (downloadUrl) await req.download(downloadUrl, filename, onDownloadProgress);
    } catch (err) {
      guiNotifier().handleError(err)
    } finally {
      setPercentage(null);
    }
  }

  return <div className={classes.container}>
    <div className={classes.fileContainer}>
      <div className={classes.icon}><FontAwesomeIcon size="6x" icon="file" /></div>
      <div className={classes.filename}>{filename}</div>
      <div className={classes.percentage}>
        {percentage && (GuiApp.getLanguage() === "tr" ? `%${percentage}` : `${percentage}%`)}
      </div>
    </div>

    <div className={classes.buttonContainer}>
      <GuiButton
        onClick={() => handleDownload()}
        icon="file-download"
        hide={!downloadUrl}
        disabled={!!percentage}
        nativeProps={{ classes: { root: classNames(classes.buttonCommon, classes.download) } }}
      />

      {!disabled &&
        <GuiButton
          onClick={() => handleDelete(position)}
          icon="trash-alt"
          hide={!!percentage}
          nativeProps={{ classes: { root: classNames(classes.buttonCommon, classes.delete) } }}
        />
      }
    </div>
  </div >
}

const useStyles = makeStyles(() => ({
  container: {
    cursor: "default",
    position: "relative",
  },
  fileContainer: {
    position: "relative",
    width: "160px",
    height: "160px",
    marginLeft: 8,
    marginRight: 8,
    backgroundColor: "var(--bgPrimary)",
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& svg": {
      color: "var(--backdrop)"
    }
  },
  filename: {
    padding: 2,
    color: "var(--titleColor)",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "12px",
    textAlign: "center",
    width: "100%",
  },
  buttonContainer: {
    position: "absolute",
    top: 2,
    right: 10,
  },
  buttonCommon: {
    minWidth: "unset",
    margin: "4px",
    width: "32px !important",
    height: "32px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "7px !important",
    float: "left",
    marginRight: "5px !important",
    backgroundColor: "var(--overlayColor)",
    "&:hover": {
      backgroundColor: "var(--overlayColor)",
    },
    "& span": {
      margin: 0,
    },
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& svg": {
      position: "static !important",
      width: "16px !important",
      height: "16px !important",
    }
  },
  delete: {
    "& svg": {
      color: "var(--primaryError)",
    },
  },
  download: {
    "& svg": {
      color: "var(--primarySuccess)",
    },
    "& .MuiCircularProgress-root": {
      color: "var(--primarySuccess)",
      left: "6.5px !important"
    },
  },
  percentage: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    bottom: 10,
    fontSize: "13px",
    fontWeight: 600,
    color: "var(--primarySuccess)",
  },
}))


export default UploadFile;
