import i18n from 'i18next';
import validation from '../validation';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { GuiForm, GuiText } from '@guibil/form';
import { GuiApp, useLang, useUser } from '@guibil/app';
import Brand from 'app/components/appFrame/includes/Brand';

const Login = () => {
  const lang = useLang();
  const user = useUser();
  const classes = useStyles();
  const selectedLanguage = GuiApp.getLanguage()


  const handleLanguageChange = (value: IGuiApp.ISupportedLanguage) => {
    if (selectedLanguage === value) return;

    i18n.changeLanguage(value);
    GuiApp.setLanguage(value as IGuiApp.ISupportedLanguage);
    location.reload();
  };

  const successListener = (res: any) => {
    !user.isAuth && user.login(res.data.access, res.data.refresh);
  }

  const fetchData = () => {
    return { username: '', password: '' };
  };


  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginBox}>
        <Brand disableClick displayLogo />
        <GuiForm
          type="new-form"
          disableSubmitTriggeredByEnter={false}
          fetchData={fetchData}
          title="login.title"
          labelPath="login"
          isDialogForm
          validationSchema={validation}
          config={{ submitDataApi: "users/token" }}
          successListener={successListener}
          refreshConfig={{
            hide: true,
            onRefresh: () => null,
          }}
          paperHeight={175}
          mainButtonsConfig={{
            dialogSaveText: "login.login",
            dialogSaveIcon: "sign-in-alt"
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GuiText field="username" />
            </Grid>
            <Grid item xs={12}>
              <GuiText field="password" type="password" />
            </Grid>
          </Grid>
        </GuiForm>
        <div className={classes.language}>
          <Button style={{ opacity: selectedLanguage === "tr" ? 1 : 0.6 }} onClick={() => handleLanguageChange("tr")}>
            {lang("login.tr")}
          </Button>
          <Button style={{ opacity: selectedLanguage === "en" ? 1 : 0.6 }} onClick={() => handleLanguageChange("en")}>
            {lang("login.en")}
          </Button>
        </div>
      </div>
    </div >
  )
};

const useStyles = makeStyles(() => ({

  loginContainer: {
    position: "fixed",
    background: "var(--bgPrimary)",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginBox: {
    width: "360px !important",
    maxWidth: "360px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& form > div > div > span:nth-child(1)": {
      margin: "0 !important",
    },
    "& form > div > div > div > div:nth-child(2)": {
      padding: "12px 0px !important"
    },
  },
  language: {
    marginTop: 15,
    display: "flex",
    alignItems: "center",
    "& span": {
      color: "#4D4D4D",
      textTransform: "capitalize"
    }
  }
}));

export default Login;
