import { useLang, useUser } from '@guibil/app';
import { classNames, GuiTooltip, LoadingComponent } from '@guibil/components';
import { commonCSS } from '@guibil/styles';
import { useField } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGuiFormElementContext } from '../../contexts/GuiFormElementContext';
import { getUserPermissionForForm } from '../../includes/FormHelpers';

/**
 * IGuiFormElementCommonProps - is what is visible to component as it's properties
 * If you want to add Form Element specific prop, then add to components IAvailableProps
 */
export interface IGuiFormElementCommonProps {
  /**
   * Field name
   */
  field: string,
  /**
   * Label of the element, if not set it will try to use
   * `${labelPath}.${field}`, where labelPath is provided
   * in GuiForm component
   */
  label?: string,
  /**
   * Whether this field should be out of CustomForm/GuiForm/WithCustomForm,
   * but should show value and trigger onChange
   */
  controlled?: boolean,
  /**
   * Will try to translate and show tooltip if element is in EditMode,
   * you can pass tooltipParam if you need parameter for translation
   */
  tooltip?: string,
  /**
   * Params for tooltip translation.
   * Ex: {{minFrequency}} is the bla...., where minFrequency
   * should be passed in tooltip props, as {minFrequency: 56200}
   * */
  tooltipParams?: { [key: string]: any },
  /**
   * Weather input item is disabled, overwrites "enabled"
   */
  disabled?: boolean,
  /**
   * Weather input item is enabled, overwritten by "disabled"
   */
  enabled?: boolean,
  /**
   * Function which will be triggered onChange
   * then it will return (e, fieldName, newValue)
   */
  onChange?: IFieldOnChangeCallback,
  /** Value - of the field */
  value?: IFormElementValueType,
  /**
   * EditMode - will show different components in different variants.
   * Ex: if set to false, switch will be displayed as text.
   * Works only when form item is 'controlled', otherwise ignored
   * */
  editMode?: boolean,
  color?: IGuiElementColor,
  className?: any,
  fieldError?: string,
  /** To show loading indicator instead of element, while you load your data */
  isLoading?: boolean,
  placeholder?: string,
  /**
 * Array of additional field Messages to display
 */
  helperMessages?: IFormItemHelper[]
}

type IGuiElementColor = | 'green' | 'red' | 'wideGreen' | 'wideRed' | 'wideYellow' | 'wideOrange';
export type IFieldOnChangeCallback = (field: string, value: IFormElementValueType) => void;
export type IFormElementValueType = | string | number | number[] | boolean | string[];
export type IFormItemHelper = { text: string, variant?: "success" | "warning" | "danger" }


/**
 * IDefaultFormElementProps - is what is sent to HOC's inner component as properties,
 * if HOC is sending extra properties, then add thcem  in order to get suggestions,
 * in Form Elements implementations
 */
export interface IDefaultFormElementProps {
  field: string,
  controlled?: boolean,
  fieldEditMode: boolean,
  fieldValue: string | number | any,
  fieldRequired?: boolean,
  fieldError?: string,
  fieldOnChange: (value: IFormElementValueType) => void,
  disabled?: boolean,
  label?: string,
  tooltip?: string,
  className?: any,
  helperMessages?: IFormItemHelper[]
}

const SYNC_FIELD_VALUE_WITH_FORM_IN_MS = 200;
const SYNC_FORM_WITH_FIELD_VALUE_IN_MS = 200;

export function withGuiFormItem<T extends IGuiFormElementCommonProps>(WrappedComponent: any) {
  const RenderControlledElement = (props: T) => {
    const lang = useLang();

    const fieldValue = useMemo(() => {
      return typeof (props.value) === "undefined" ? "" : props.value;
    }, [props.value])

    /**
     * useCallback - used to prevent generating new function,
     * since handleFieldChange is passed to elements,
     * it forced rerender of all form elements
     */
    const handleFieldChange = useCallback((newValue: IFormElementValueType) => {
      const { onChange, field } = props;

      if (fieldValue === newValue) { return; }

      onChange && onChange(field, newValue);
    }, [props.onChange, fieldValue]);


    const fieldLabel = useMemo(() => {
      const { label } = props;
      return label ? lang(label) : "";
    }, [props.label]);

    const {
      // eslint-disable-next-line unused-imports/no-unused-vars
      field, enabled, editMode, tooltip, tooltipParams, isLoading, controlled, ...otherProps
    } = props;

    const componentProps = {
      field,
      fieldValue,
      fieldEditMode: typeof (editMode) === "boolean" ? editMode : true,
      fieldOnChange: handleFieldChange,
      disabled: typeof (enabled) === 'boolean' ? !enabled : undefined,
      ...otherProps,
      label: fieldLabel,
      tooltip: (typeof (tooltip) === 'string') ? lang(tooltip, tooltipParams || {}) : null,
      className: processClassnames(props.className, props.color),
    };

    if (isLoading) {
      return <GuiFormElementLoadingComponent fieldLabel={fieldLabel} />;
    }
    return (
      <GuiTooltip
        title={componentProps?.tooltip || ''}
        hide={!componentProps?.fieldEditMode}
      >
        <WrappedComponent
          {...componentProps}
        />
      </GuiTooltip>
    )
  }

  const RenderUncontrolledElement = (props: T) => {
    const lang = useLang();
    const context = useGuiFormElementContext(false);
    const [formikField, formikMeta, formikHelpers] = useField({ name: props.field });
    const [localValue, setLocalValue] = useState<IFormElementValueType>(formikField.value);
    const syncValueToFormTimeout = useRef<any>();
    const syncFormToValueTimeout = useRef<any>();

    const { role: userRole } = useUser();

    // Debounced local value sync with Form
    useEffect(() => {
      clearTimeout(syncValueToFormTimeout.current);
      clearTimeout(syncFormToValueTimeout.current);
      syncValueToFormTimeout.current = setTimeout(() => {
        const { onChange, field } = props;
        const { formElementOnChange } = context;

        if (formikField.value === localValue) { return; }

        formElementOnChange(field, localValue);

        // Set value in formik
        formikHelpers.setValue(localValue);

        if (formikMeta.touched === false) {
          formikHelpers.setTouched(true);
        }

        onChange && onChange(field, localValue);
      }, SYNC_FIELD_VALUE_WITH_FORM_IN_MS);
    }, [localValue]);

    // If form value changes independently, sync it with local value
    useEffect(() => {
      clearTimeout(syncFormToValueTimeout.current);
      syncFormToValueTimeout.current = setTimeout(() => {

        if (formikField.value !== localValue) {
          setLocalValue(formikField.value);
        }
      }, SYNC_FORM_WITH_FIELD_VALUE_IN_MS);
    }, [formikField.value]);


    const fieldRequired = useMemo(() => {
      return context.requiredFields.includes(props.field);
    },
      [context.requiredFields, props.field]
    )

    const fieldLabel = useMemo(() => {
      const { labelPath } = context;
      const { label, field } = props;

      if (label) {
        return lang(label);
      }
      else if (labelPath) {
        return lang(`${labelPath}.${field}`);
      }
      return "";
    }, [context.labelPath, props.label, props.field]);

    const getFieldError = (fieldEditMode: boolean) => {
      const { formType } = context;
      const { error } = formikMeta;

      if (error && fieldEditMode && (formType === 'add' || formType === 'update')) {
        return error.replace('{{element}}', fieldLabel);
      }

      return undefined;
    };

    const { formState, roles, fieldsToHide, isSubmitting, fieldsToDisable } = context;
    const { field, enabled, disabled, tooltip, tooltipParams, isLoading, ...otherProps } = props;

    let fieldEditMode = typeof (otherProps.editMode) === 'boolean' ? otherProps.editMode : formState === 'updating';
    delete otherProps.editMode;

    const permission = getUserPermissionForForm(roles, userRole, field, fieldEditMode);

    const isFieldDisabled = disabled === true || enabled === false || isSubmitting === true || (Array.isArray(fieldsToDisable[field]) && fieldsToDisable[field].length > 0);

    if (permission === 'I' || typeof (formikField.value) === "undefined" || (Array.isArray(fieldsToHide[field]) && fieldsToHide[field].length > 0)) {
      return null;
    }
    if (permission === 'R') {
      fieldEditMode = false;
    }
    const componentProps = {
      field,
      fieldValue: localValue,
      fieldEditMode,
      fieldRequired,
      fieldError: getFieldError(fieldEditMode),
      fieldOnChange: setLocalValue,
      disabled: isFieldDisabled,
      ...otherProps,
      label: fieldLabel,
      tooltip: (typeof (tooltip) === 'string') ? lang(tooltip, tooltipParams || {}) : undefined,
      className: processClassnames(props.className, props.color),
    };


    if (isLoading) {
      return <GuiFormElementLoadingComponent fieldRequired={fieldRequired} fieldLabel={fieldLabel} />;
    }
    return (
      <GuiTooltip
        title={componentProps?.tooltip || ''}
        hide={!componentProps?.fieldEditMode}
      >
        <WrappedComponent
          {...componentProps}
        />
      </GuiTooltip>
    )
  };

  const GuiFormItem: FC<T> = (props) => {
    const { controlled } = props;

    const component = useMemo(() => {
      if (controlled) {
        return <RenderControlledElement {...props} />
      }
      return <RenderUncontrolledElement {...props} />
    }, [props]);
    return component;
  };

  return GuiFormItem;
}


interface ILoadingProps {
  fieldRequired?: boolean,
  fieldLabel: string,
}
const GuiFormElementLoadingComponent: React.FC<ILoadingProps> = ({ fieldRequired, fieldLabel }: ILoadingProps) => {
  return (
    <div className={commonCSS.loadingInput}>
      <label className={classNames(fieldRequired && commonCSS.loadingRequiredLabel)}>{fieldLabel}</label>
      <LoadingComponent />
    </div>
  )
}

const processClassnames = (className?: any, color?: IGuiElementColor) => {
  let result: any = {};

  if (typeof (className) !== 'undefined') {
    if (typeof (className) === 'string') {
      result[className] = true;
    } else {
      result = { ...className };
    }
  }

  if (color === 'green') {
    result[commonCSS.formItemSuccess] = true;
  } else if (color === 'red') {
    result[commonCSS.formItemDanger] = true;
  } else if (color === 'wideGreen') {
    result[commonCSS.formItemWideSuccess] = true;
  } else if (color === 'wideRed') {
    result[commonCSS.formItemWideDanger] = true;
  } else if (color === 'wideYellow') {
    result[commonCSS.formItemWideLightWarning] = true;
  } else if (color === 'wideOrange') {
    result[commonCSS.formItemWideDarkWarning] = true;
  }

  if (Object.keys(result).length === 0) result = undefined;
  return result;
};
