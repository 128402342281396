import { ACCESS_TOKEN, APP_LOCALE, REFRESH_TOKEN } from 'app/constants';
import { IStorage, WindowLocalStorage } from './models/Storage';
import moment from "moment";
import 'moment/locale/tr'
import flatpickr from "flatpickr"
import Cookies from 'universal-cookie';

const cookies = new Cookies();
interface IConfig {
  API_ROOT_URL: string,
}



class GuiApp {
  private _config?: IConfig;

  private storage: IStorage;

  constructor() {
    this.storage = new WindowLocalStorage();
  }

  public setConfig(config: IConfig) {
    this._config = config;

    if (this.getLanguage()) {
      this.changeDocumentLanguage(this.getLanguage())
      this.setOtherComponentsLanguage()
    }
  }

  public getConfig() {
    if (!this._config) { throw new Error('Please set GuiApp.setConfig(config)'); }
    return this._config;
  }

  changeDocumentLanguage(lang: "tr" | "en") {

    if (lang === "en") {
      cookies.set('.AspNetCore.Culture', 'c=en-US|uic=en-US', { path: '/' });
    } else {
      cookies.set('.AspNetCore.Culture', 'c=tr-TR|uic=tr-TR', { path: '/' });
    }
    //console.log(cookies.get('.AspNetCore.Culture.'));
    document.documentElement.setAttribute('lang', lang);
  }

  getLanguage(): IGuiApp.ISupportedLanguage {
    return (this.storage.getItem(APP_LOCALE) || 'tr') as IGuiApp.ISupportedLanguage;
  }

  setOtherComponentsLanguage() {

    const appLocale = this.getLanguage();
    // Set up locale for moment, which is used by functions like humanize(human readable date)
    moment.locale(appLocale);

    // Set up locale for Flatpickr, which is DateTime picker
    let flatpickrLocaleImport;
    if (appLocale === "tr") {
      flatpickrLocaleImport = require("flatpickr/dist/l10n/tr.js").default.tr;
    } else {
      flatpickrLocaleImport = require("flatpickr/dist/l10n/default.js").default;
    }
    flatpickr.localize(flatpickrLocaleImport);

  }
  setLanguage(lang: IGuiApp.ISupportedLanguage) {
    this.storage.setItem(APP_LOCALE, lang);
    this.changeDocumentLanguage(lang);
    this.setOtherComponentsLanguage();
  }

  getAccessToken() {
    return this.storage.getItem(ACCESS_TOKEN);
  }
  getRefreshToken() {
    return this.storage.getItem(REFRESH_TOKEN);
  }

  setAccessToken(token: string) {
    this.storage.setItem(ACCESS_TOKEN, token);
  }
  setRefreshToken(token: string) {
    this.storage.setItem(REFRESH_TOKEN, token);
  }
}

const guiApp = new GuiApp();
export { guiApp as GuiApp };
