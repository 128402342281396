import { commonCSS } from '@guibil/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLang } from '@guibil/app';
import React, { useRef, useState } from 'react';
import { classNames } from '@guibil/components';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { isNotNullOrUndefined, guiIsEqual } from '@guibil/helpers';
import useDisableSubmitOnEnter from './hooks/useDisableSubmitOnEnter';
import { withGuiFormItem, IGuiFormElementCommonProps, IDefaultFormElementProps } from '../hocs/withGuiFormItem';

const DISPATCH_CHANGE_TIMEOUT_IN_MS = 350;
const colors = [
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
  "indigo",
  "violet",
  "purple",
  "pink",
  "silver",
  "gold",
  "beige",
  "brown",
  "gray",
]
export const RichTextModules = {
  toolbar: [
    [
      { 'header': [1, 2, 3, 4, false] }
    ],
    [
      { 'size': ['small', false, 'large', 'huge'] }
    ],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote'
    ],
    [
      { 'align': '' },
      { 'align': 'center' },
      { 'align': 'justify' },
      { 'align': 'right' },
    ],
    [
      { 'color': colors },
      { 'background': colors },
    ],
    [
      { 'list': 'ordered' },
      { 'list': 'bullet' },
      { 'indent': '-1' },
      { 'indent': '+1' },
    ],
    [
      'link',
      'image',
      'clean'
    ]
  ],
};

export const RichTextFormats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'align',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'background',
  'clean',
];

interface IElementSpecificProps {
  textField?: string,
  placeholder?: string,
}

type IProps = IElementSpecificProps & IDefaultFormElementProps;

const GuiRichText: React.FC<IProps> = (props) => {
  const lang = useLang();
  const classes = useStyles();

  useDisableSubmitOnEnter();

  let { fieldValue, fieldEditMode, fieldError, fieldOnChange, label, placeholder, className } = props;

  const editorRef = useRef<ReactQuill | null>(null);
  const dispatchChangeTimeout = useRef<any>(0);
  const [value, setValue] = useState(fieldValue);

  const handleFieldChange = (content: string) => {
    setValue(content);
    clearTimeout(dispatchChangeTimeout.current);
    dispatchChangeTimeout.current = setTimeout(() => {
      fieldOnChange(content);
    }, DISPATCH_CHANGE_TIMEOUT_IN_MS);
  };

  if (fieldEditMode) {
    return (
      <FormControl className={commonCSS.formItem} error={isNotNullOrUndefined(fieldError)}>
        <label className={classes.label}>{label}</label>
        <div id="editor-container">
          <ReactQuill
            value={value}
            ref={editorRef}
            placeholder={placeholder && lang(placeholder)}
            onChange={handleFieldChange}
            modules={RichTextModules}
            formats={RichTextFormats}
            className={classNames(classes.richText, className && className)}
            bounds="#editor-container"
          />
        </div>
        <FormHelperText className={commonCSS.formHelper}>{fieldError}</FormHelperText>
      </FormControl>
    );
  }

  return (
    <FormControl className={commonCSS.formItem} error={isNotNullOrUndefined(fieldError)}>
      <label className={classes.label}>{label}</label>
      <ReactQuill
        value={value}
        readOnly
        modules={RichTextModules}
        formats={RichTextFormats}
        className={classNames(classes.richText, classes.richTextDisabled, className && className)}
      />
    </FormControl>
  );
};

const GuiRichTextComponent = withGuiFormItem<IElementSpecificProps & IGuiFormElementCommonProps>(
  React.memo(GuiRichText, (prevProps, nextProps) => guiIsEqual(prevProps, nextProps)),
);

const useStyles = makeStyles(() => ({
  label: {
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
  },
  richText: {
    marginTop: "3.5px",
    backgroundColor: "var(--bgColor)",
    borderRadius: "2px",
    "& > div:nth-child(1)": {
      fontFamily: "inherit",
      fontSize: "14px",
      border: "none !important",
    },
    "& > div:nth-child(2)": {
      border: "none !important",
      color: "var(--defaultTextColor)",
      minHeight: "120px",
      fontFamily: "inherit",
      "& > div:nth-child(1)": {
        fontSize: "14px",
        fontWeight: 500,
        "& em": {
          fontStyle: "italic  !important",
        },
        "& strong": {
          fontWeight: 900,
        },
      },
      "& > div:nth-child(1):before": {
        opacity: 0.5,
        fontWeight: 600,
        color: "var(--defaultTextColor)",
        fontStyle: "normal  !important",
        fontSize: "14px",
      },
    }
  },
  richTextDisabled: {
    opacity: 0.70,
    "& > div:nth-child(1)": {
      display: "none",
    },
    "& > div:nth-child(2)": {
      cursor: "default !important",
      "& > div:nth-child(1)": {
        "& p": {
          cursor: "default !important",
        }
      }
    }
  }
}));

export { GuiRichTextComponent as GuiRichText };
