import { login_tr } from "pages/login/locales/tr_TR";
import { dashboard_tr } from "pages/dashboard/locales/tr_TR";
import { notifications_tr } from "pages/notifications/locales/tr_TR";
import { makers_tr } from "pages/makers/maker/locales/tr_TR";
import { games_tr } from "pages/games/game/locales/tr_TR";
import { new_games_tr } from "pages/games/new-games/locales/tr_TR";
import { top_games_tr } from "pages/games/top-games/locales/tr_TR";
import { random_games_tr } from "pages/games/random-games/locales/tr_TR";
import { profile_tr } from "pages/profile/locales/tr_TR";
import { user_management_tr } from "pages/user-management/locales/tr_TR";
import { maker_details_tr } from "pages/makers/maker-details/locales/tr_TR";
import { components_tr } from "pages/components/locales/tr_TR";
import { game_details_tr } from "pages/games/game-details/locales/tr_TR";
import { log_user_management_tr } from "pages/log-user-management/locales/tr_TR";
import { development_tr } from "pages/development/locales/tr_TR";

export const game_tracker_tr = {
  components: components_tr,
  login: login_tr,
  dashboard: dashboard_tr,
  notifications: notifications_tr,
  development: development_tr,
  makers: makers_tr,
  makerDetails: maker_details_tr,
  games: games_tr,
  gameDetails: game_details_tr,
  newGames: new_games_tr,
  topGames: top_games_tr,
  randomGames: random_games_tr,
  profile: profile_tr,
  userManagement: user_management_tr,
  logUserManagement: log_user_management_tr,
};
