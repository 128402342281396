import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { formatDateTime } from '@guibil/router/utils/local-helpers';
import { useLang } from '@guibil/app';

export interface OneTimeEventProps {
  created_datetime: string,
  name: string,
  value: any,
  event_datetime: string,
}

interface OneTimeEventsProps {
  one_time_events: OneTimeEventProps[]
}

const OneTimeEvents: React.FC<OneTimeEventsProps> = ({ one_time_events }) => {
  const lang = useLang();
  const classes = useStyles();

  return <div>
    <div className={classes.tableTitle}>
      <span>{lang("gameTracker.development.installationDetails.players.playerLevelEvents.oneTimeEvents.title")}</span>
    </div>
    <div className={classes.tableWrapper}>
      <table className={classes.table}>
        <thead className={classes.tableHead}>
          <tr>
            <td>{lang("gameTracker.development.installationDetails.players.playerLevelEvents.oneTimeEvents.eventDateTime")}</td>
            < td > {
              lang("gameTracker.development.installationDetails.players.playerLevelEvents.oneTimeEvents.name")}</td>
            <td>{
              lang("gameTracker.development.installationDetails.players.playerLevelEvents.oneTimeEvents.value")}</td>
            < td > {lang("gameTracker.development.installationDetails.players.playerLevelEvents.oneTimeEvents.localEventDateTime")}</td>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {one_time_events.map((event, index) => {
            return <tr key={index}>
              <td>{formatDateTime(event.created_datetime)}</td>
              <td>{event.name}</td>
              <td>{event.value}</td>
              <td>{formatDateTime(event.event_datetime)}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  </div>
}

const useStyles = makeStyles({
  tableTitle: {
    padding: "8px",
    fontSize: "18px !important",
    fontWeight: 700,
    color: "var(--titleColor)",
  },
  tableWrapper: {
    background: "var(--bgColor)",
  },
  table: {
    width: "100%",
  },
  tableHead: {
    background: "var(--titleColor)",
    color: "var(--bgPrimary)",
    "& > th, td": {
      padding: "8px"
    }
  },
  tableBody: {
    color: "var(--titleColor)",
    "& > th, td": {
      verticalAlign: "middle",
      padding: "6px",
    },
    "& > tr:nth-child(even)": {
      padding: "6px",
      background: "var(--bgPrimary)",
    },
  },
})

export default OneTimeEvents
