import { useLang } from '@guibil/app';
import { Grid } from '@material-ui/core'
import { useRequest } from '@guibil/api';
import validationSchema from "./validation";
import React, { useEffect, useState } from 'react'
import { GuiButton, GuiGenericDialog, guiNotifier } from '@guibil/components'
import { makeStyles } from '@material-ui/core/styles';
import Tagger, { TagProps } from 'pages/components/Tagger';
import { GuiDropdown, GuiForm, GuiText, useGuiFormData } from '@guibil/form'
import ValuesList, { ValuesProps } from 'pages/components/valuesList/ValuesList';
import GenresDialog from '../genres/GenresDialog';
import Base64Images, { Base64ImageProps } from 'pages/components/base64Images/Base64Images';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { commonCSS } from '@guibil/styles';
import { GuiRichText } from '@guibil/form/form-elements/elements/GuiRichText';
import FilesUpload, { FileUploadProps } from 'pages/components/filesUpload/FilesUpload';

interface UpdateGidDialogProps {
  id: number,
  onClose: () => void,
  onSuccess: () => void,
}

const UpdateGidDialog: React.FC<UpdateGidDialogProps> = ({ id, onClose, onSuccess }) => {
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();

  const [data, setData] = useGuiFormData();
  const [initialResponse, setInitialResponse] = useState<any>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [hasAnyDirty, setHasAnyDirty] = useState<boolean>(false);
  const [hasAnyError, setHasAnyError] = useState<boolean>(false);

  const [availableDevelopers, setAvailableDevelopers] = useState<TagProps[]>([]);
  const [availableDesigners, setAvailableDesigners] = useState<TagProps[]>([]);
  const [genres, setGenres] = useState<string[]>([]);
  const [genresLoading, setGenresLoading] = useState<boolean>(false);
  const [displayGenres, setDisplayGenres] = useState(false);
  const [selectedDevUsers, setSelectedDevUsers] = useState<TagProps[]>([]);
  const [selectedArtUsers, setSelectedArtUsers] = useState<TagProps[]>([]);
  const [coreLoops, setCoreLoops] = useState<ValuesProps[]>([]);
  const [hasCoreLoopsError, setHasCoreLoopsError] = useState<boolean>(false);
  const [mechanicCores, setMechanicCores] = useState<ValuesProps[]>([]);
  const [hasMechanicCoresError, setHasMechanicCoresError] = useState<boolean>(false);
  const [mechanicSubs, setMechanicSubs] = useState<ValuesProps[]>([]);
  const [hasMechanicSubsError, setHasMechanicSubsError] = useState<boolean>(false);
  const [b64encodeImages, setB64encodeImages] = useState<Base64ImageProps[]>([]);
  const [files, setFiles] = useState<FileUploadProps[]>([]);
  const [percentage, setPercentage] = useState<Number | null>(null);

  useEffect(() => {
    data?.values && data?.setFieldValue("core_loops", coreLoops);
    data?.values && data?.setFieldValue("planned_developers", selectedDevUsers);
    data?.values && data?.setFieldValue("planned_designers", selectedArtUsers);
    data?.values && data?.setFieldValue("mechanic.cores", mechanicCores);
    data?.values && data?.setFieldValue("mechanic.subs", mechanicSubs);
    data?.values && data?.setFieldValue("design.b64encode_images", b64encodeImages);
    data?.values && data?.setFieldValue("design.files", files);
  }, [coreLoops, selectedDevUsers, selectedArtUsers, mechanicCores, mechanicSubs, b64encodeImages, files])

  useEffect(() => {
    setDisabled(hasAnyError ? true : hasAnyDirty ? false : true);
  }, [hasAnyDirty, hasAnyError])

  useEffect(() => {
    setHasAnyError(!isEmpty(data?.errors) || hasCoreLoopsError || hasMechanicCoresError || hasMechanicSubsError);
  }, [data?.errors, hasCoreLoopsError, hasMechanicCoresError, hasMechanicSubsError])

  useEffect(() => {
    if (initialResponse) {
      const anyDirty =
        !isEqual(initialResponse.core_loops, coreLoops) ||
        !isEqual(initialResponse.mechanic.cores, mechanicCores) ||
        !isEqual(initialResponse.mechanic.subs, mechanicSubs)
      setHasAnyDirty(data?.dirty || anyDirty);
    }
  }, [data?.dirty, initialResponse, coreLoops, mechanicCores, mechanicSubs])


  const getGenres = async () => {
    setGenresLoading(true);
    const res = await req.get("gids/genre/genres");
    setGenres(res.result.map((item: any) => item.genre));
    setGenresLoading(false);
  }

  const fetchData = async () => {
    await getGenres();
    const response = await req.get("gids/gid/detail/" + id);
    setCoreLoops(response.core_loops);
    setSelectedDevUsers(response.planned_developers);
    setSelectedArtUsers(response.planned_designers);
    setAvailableDevelopers(response.available_developers);
    setAvailableDesigners(response.available_designers);
    delete response["available_developers"];
    delete response["available_designers"];
    setMechanicCores(response.mechanic.cores);
    setMechanicSubs(response.mechanic.subs);
    setB64encodeImages(response.design.b64encode_images);
    setFiles(response.design.files);
    setInitialResponse(cloneDeep(response));
    return response;
  }

  const onUploadProgress = (progress: any) => {
    setPercentage(Math.round((progress.loaded * 100) / progress.total))
  }

  const uploadFiles = async () => {
    try {
      setPercentage(0);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const item = files[i];
        if (item.file) formData.append("design_files", item.file);
      }

      if (formData.getAll("design_files").length > 0) {
        const newUploadedFiles = await req.upload("gids/gid/add_design_files", formData, onUploadProgress);
        const existingUploadedFiles = files.filter((item) => item.download_url !== undefined);
        return [...existingUploadedFiles, ...newUploadedFiles];
      }
    } catch (err) {
      guiNotifier().handleError(err);
      return null;
    } finally {
      setPercentage(null);
    }
  }

  const submitHandler = async (data: any) => {
    let values = { ...data }
    const uploadedFiles = await uploadFiles();
    if (uploadedFiles) {
      values["design"]["files"] = uploadedFiles;
    }
    return await req.put("gids/gid/update/" + id, values);
  }

  return <GuiGenericDialog
    isOpen
    onClose={onClose}
    title="gameTracker.development.gids.updateGid.title"
    size="xl"
  >
    <div className={classes.root}>
      <GuiForm
        type="new-form"
        fetchData={fetchData}
        setData={setData}
        submitHandler={submitHandler}
        validationSchema={validationSchema}
        successListener={onSuccess}
        labelPath="gameTracker.development.gids"
        isDialogForm
        mainButtonsConfig={{ dialogSaveHide: true }}
        extraButtons={[{
          text: percentage ? lang("gameTracker.development.gids.updateGid.uploading") : lang("gameTracker.development.gids.updateGid.update"),
          onClick: () => data?.submitForm(),
          isLoading: data?.isSubmitting,
          nativeProps: { classes: { root: disabled ? commonCSS.saveButtonPassive : commonCSS.saveButtonActive } },
          icon: "save",
          disabled: disabled
        }]}
      >
        {/* General */}
        <div>
          <div className={classes.title}>
            {lang("gameTracker.development.gids.sections.info")}
          </div>
          <div className={classes.container}>
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <GuiText
                  field="title"
                  label="gameTracker.development.gids.general.title"
                  placeholder="gameTracker.development.gids.placeHolders.title"
                />
              </Grid>
              <Grid item xs={12}>
                <GuiText
                  field="mantra"
                  multiline
                  label="gameTracker.development.gids.general.mantra"
                  placeholder="gameTracker.development.gids.placeHolders.mantra"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <GuiDropdown
                  field="status"
                  label="gameTracker.development.gids.general.status"
                  selectionsEnum="GidStatus"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <GuiDropdown
                  field="popularity"
                  label="gameTracker.development.gids.general.popularity"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <GuiDropdown
                  field="art_difficulty"
                  label="gameTracker.development.gids.general.art_difficulty"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <GuiDropdown
                  field="dev_difficulty"
                  label="gameTracker.development.gids.general.dev_difficulty"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Tagger
                  label="gameTracker.development.gids.general.planned_developers"
                  availables={availableDevelopers}
                  selecteds={selectedDevUsers}
                  handleSelectedTags={setSelectedDevUsers}
                  handleSingleSelectedTag={() => null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Tagger
                  label="gameTracker.development.gids.general.planned_designers"
                  availables={availableDesigners}
                  selecteds={selectedArtUsers}
                  handleSelectedTags={setSelectedArtUsers}
                  handleSingleSelectedTag={() => null}
                />
              </Grid>
              <Grid item xs={12}>
                <ValuesList
                  label={lang("gameTracker.development.gids.general.core_loops")}
                  placeholder="gameTracker.development.gids.placeHolders.core_loop"
                  values={coreLoops}
                  setValues={setCoreLoops}
                  setHasError={setHasCoreLoopsError}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Dynamics */}
        <div>
          <div className={classes.title}>
            {lang("gameTracker.development.gids.sections.dynamic")}
          </div>
          <div className={classes.container}>
            <Grid container spacing={1} >
              <Grid container item xs={12}>
                <Grid item xs={7} md={8} lg={9}>
                  <GuiDropdown
                    field="dynamic.genre"
                    selections={genres}
                    isLoading={genresLoading}
                  />
                  {
                    displayGenres &&
                    <GenresDialog
                      onClose={() => setDisplayGenres(false)}
                      onSuccess={async () => {
                        await getGenres();
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={5} md={4} lg={3}>
                  <GuiButton
                    variant="cancel"
                    onClick={() => setDisplayGenres(true)}
                    text="gameTracker.development.gids.genres.edit"
                    icon="pen"
                    nativeProps={{ classes: { root: classes.displayGenres } }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <GuiRichText
                  field="dynamic.progress"
                  placeholder="gameTracker.development.gids.placeHolders.progress"
                />
              </Grid>
              <Grid item xs={12}>
                <GuiRichText
                  field="dynamic.goal"
                  placeholder="gameTracker.development.gids.placeHolders.goal"
                />
              </Grid>
              <Grid item xs={12}>
                <GuiRichText
                  field="dynamic.challenge"
                  placeholder="gameTracker.development.gids.placeHolders.challenge"
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Mechanics */}
        <div>
          <div className={classes.title}>
            {lang("gameTracker.development.gids.sections.mechanic")}
          </div>
          <div className={classes.container}>
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <ValuesList
                  label={lang("gameTracker.development.gids.mechanic.cores")}
                  placeholder="gameTracker.development.gids.placeHolders.core_mechanic"
                  values={mechanicCores}
                  setValues={setMechanicCores}
                  setHasError={setHasMechanicCoresError}
                />
              </Grid>
              <Grid item xs={12}>
                <ValuesList
                  label={lang("gameTracker.development.gids.mechanic.subs")}
                  placeholder="gameTracker.development.gids.placeHolders.sub_mechanic"
                  values={mechanicSubs}
                  setValues={setMechanicSubs}
                  setHasError={setHasMechanicSubsError}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Design */}
        <div>
          <div className={classes.title}>
            {lang("gameTracker.development.gids.sections.design")}
          </div>
          <div className={classes.container}>
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <GuiRichText
                  field="design.description"
                  placeholder="gameTracker.development.gids.placeHolders.description"
                />
              </Grid>
              <Grid item xs={12}>
                <GuiRichText
                  field="design.notes"
                  placeholder="gameTracker.development.gids.placeHolders.notes"
                />
              </Grid>
              <Grid item xs={12}>
                <Base64Images
                  label="gameTracker.development.gids.design.b64encodeImages"
                  b64encodeImages={b64encodeImages}
                  setB64encodeImages={setB64encodeImages}
                />
              </Grid>
              <Grid item xs={12}>
                <FilesUpload
                  label="gameTracker.development.gids.design.files"
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </GuiForm>
    </div>
  </GuiGenericDialog>
}

const useStyles = makeStyles(() => ({
  root: {
    "& form > div": {
      padding: "8px !important",
      background: "transparent !important"
    },
  },
  container: {
    padding: "10px 0px 30px 0px",
    background: "var(--bgPrimary)"
  },
  title: {
    fontWeight: 800,
    fontSize: 22,
    marginLeft: 7,
    marginBottom: 5,
    color: "var(--titleColor)"
  },
  displayGenres: {
    marginLeft: 15,
    marginTop: 27,
    width: "-webkit-fill-available",
    "& svg": {
      left: 8,
      width: "18px !important",
      height: "18px !important",
    },
  },
}));

export default UpdateGidDialog
