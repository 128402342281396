import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import useGuiGridHiddenFilterFields from '@guibil/grid/behaviors/filter/hooks/useGuiGridHiddenFilterFields';
import {  useGuiGridNumberFields, useGuiGridPhoneNumberFields } from '@guibil/grid/hooks/useGuiGridFields';
import { IGuiGridFilterLocalType } from '@guibil/grid/types';
import { commonCSS } from '@guibil/styles';
import { TableCell } from '@material-ui/core';
import { GuiGridGenericDropDownFilterCell } from './GuiGridGenericDropDownFilterCell';
import { GuiGridNumberFilterCell } from './GuiGridNumberFilterCell';
import { GuiGridPhoneNumberFilterCell } from './GuiGridPhoneNumberFilterCell';

export interface IGuiGridFilterCustomizedCellProps extends TableFilterRow.CellProps {
  defaultoperation: IGuiGridFilterLocalType,
}

const GuiGridFilterCell: React.FC<TableFilterRow.CellProps> = (props) => {
  const { name } = props.column;
  const hiddenFilterFields = useGuiGridHiddenFilterFields();
  // const dateTimeFields = useGuiGridDateTimeFields();
  const phoneNumberFields = useGuiGridPhoneNumberFields();
  const numberFieldsAr = useGuiGridNumberFields();

  const defaultoperation = "equal";

  if (hiddenFilterFields.includes(name) || !props.filteringEnabled) {
    return <TableCell className={commonCSS.gridFilterText} />;
  }
  // if (dateTimeFields.includes(name)) {
  //   return <GuiGridDateTimeFilterCell {...props} defaultoperation={defaultoperation} />
  // }
  if (numberFieldsAr.includes(name)) {
    return <GuiGridNumberFilterCell {...props} defaultoperation={defaultoperation} />
  }
  if (phoneNumberFields.includes(name)) {
    return <GuiGridPhoneNumberFilterCell {...props} defaultoperation={defaultoperation} />
  }
  /** 
   * Since dropdown can be generated from too many factors,
   * like: Enums/booleans/filterSelection props
   * 
   * GuiGridGenericDropDownFilterCell will check if dropdown 
   * is applicable, if not applicable will render default 
   * <TableFilterRow.Cell {...props} />
  */
  return <GuiGridGenericDropDownFilterCell {...props} defaultoperation={defaultoperation} />;
};

export { GuiGridFilterCell };
