import { Filter } from '@devexpress/dx-react-grid';
import { useLang } from '@guibil/app';
import { classNames } from '@guibil/components';
import { GuiText } from '@guibil/form';
import { commonCSS } from '@guibil/styles';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { IGuiGridFilterCustomizedCellProps } from './GuiGridFilterCell';


const GuiGridNumberFilterCell: React.FC<IGuiGridFilterCustomizedCellProps> = (props) => {
  const { filter, onFilter, column, defaultoperation } = props;
  const lang = useLang();

  const onChange = (field: string, value: any) => {
    const newFilter: Filter = {
      columnName: column.name,
      value: String(value),
      operation: filter?.operation || defaultoperation
    };
    onFilter(typeof(value) === "number" ? newFilter : null);
  };

  let filterSelector = null;
  if (props.filteringEnabled && Array.isArray(props.children) && props.children.length === 2) {
    filterSelector = props.children[0];
  }
  return (
    <TableCell className={classNames(commonCSS.dateTimeFilterItem)}>
      <div>
        {filterSelector}
        <GuiText
          controlled
          field="number-filter"
          value={filter?.value}
          onChange={onChange}
          type="number"
          placeholder={lang('guibil:grid.filter.placeholder')}
        />
      </div>
    </TableCell>
  )
};

export { GuiGridNumberFilterCell };

