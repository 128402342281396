import React, { FunctionComponent } from 'react';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import { isNotNullOrUndefined, guiIsEqual } from '@guibil/helpers';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { commonCSS } from '@guibil/styles';
import { classNames } from '@guibil/components';
import { GuiText } from './GuiText';
import { withGuiFormItem, IGuiFormElementCommonProps, IDefaultFormElementProps } from '../hocs/withGuiFormItem';

interface IElementSpecificProps {
  min: number,
  max: number,
  step?: number,
  isVertical?: boolean,
  isVerticalDetailed?: boolean,
  measurementLabel?: string,
}

// since HOC transmits extra data, I need to extend it, in order get access to all props
type IProps = IElementSpecificProps & IDefaultFormElementProps;

const GuiSlider: FunctionComponent<IProps> = (props) => {
  const {
    className, isVertical, isVerticalDetailed, fieldValue, fieldEditMode,
    fieldError, fieldOnChange, field, measurementLabel, label, ...otherProps
  } = props;

  const handleFieldChange = (e: React.ChangeEvent<{}>, value: number | number[]): void => {
    fieldOnChange(value);
  };
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    fieldOnChange(e.target.value);
  };

  if (fieldEditMode) {
    if (isVertical) {
      return (
        <FormControl
          error={isNotNullOrUndefined(fieldError)}
          className={classNames(commonCSS.sliderContainerOutline, commonCSS.sliderContainerOutlineVertical)}
        >
          <Slider
            value={fieldValue}
            orientation="vertical"
            onChange={handleFieldChange}
            {...otherProps}
          />
        </FormControl>
      );
    }
    if (isVerticalDetailed) {
      return (
        <FormControl
          error={isNotNullOrUndefined(fieldError)}
          className={classNames(commonCSS.sliderContainerOutline, commonCSS.sliderContainerOutlineVerticalDetailed)}
        >
          <label className={commonCSS.sliderValue}>{fieldValue}</label>
          <Slider
            value={fieldValue}
            orientation="vertical"
            onChange={handleFieldChange}
            {...otherProps}
          />
          <label className={commonCSS.sliderLabel}>{label}</label>
        </FormControl>
      );
    }

    return (
      <FormControl
        error={isNotNullOrUndefined(fieldError)}
        className={commonCSS.sliderContainerOutline}
      >
        <div className={isNotNullOrUndefined(fieldError) ? commonCSS.sliderContainerStyleError : commonCSS.sliderContainerStyle}>
          <div className={commonCSS.sliderLabelStyle}>
            {' '}
            {label}
          </div>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={8} md={8} lg={8}>
              <Slider
                className={commonCSS.sliderSlideField}
                value={fieldValue}
                onChange={handleFieldChange}
                {...otherProps}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <TextField
                className={commonCSS.sliderValueField}
                value={fieldValue}
                variant="outlined"
                margin="dense"
                onChange={handleTextFieldChange}
                inputProps={{
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
          </Grid>
        </div>

        <FormHelperText className={commonCSS.formHelper}>{fieldError}</FormHelperText>
      </FormControl>
    );
  }

  return (
    <GuiText
      controlled
      disabled
      field={field}
      measurementLabel={measurementLabel}
      label={label}
      editMode={false}
      className={className}
    />
  );
};

const GuiSliderComponent = withGuiFormItem<IElementSpecificProps & IGuiFormElementCommonProps>(
  React.memo(GuiSlider, (prevProps, nextProps) => guiIsEqual(prevProps, nextProps)),
);

export { GuiSliderComponent as GuiSlider };
