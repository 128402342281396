import { classNames, useWindowSize } from '@guibil/components';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'

const ComponentContainer = (props: any) => {
  const classes = useStyles();
  const { width } = useWindowSize();
  const [flexColumn, setFlexColumn] = useState<boolean>(false);
  useEffect(() => { width !== 0 && setFlexColumn(width < 666 ? true : false); }, [width])

  return (
    <div className={classNames(classes.container, flexColumn ? classes.flexColumn : classes.flexRow)}>
      {props.children}
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
  flexColumn: {
    flexDirection: "column",
  },
  flexRow: {
    flexDirection: "row",
  }
});

export default ComponentContainer
