import React from 'react'
import { makeStyles } from '@material-ui/styles';

interface GameLogoProps {
  logo_image_url?: string,
  width?: number,
  height?: number,
  border_radius?: string,
}

const GameLogo: React.FC<GameLogoProps> = ({ logo_image_url, width, height, border_radius = "2px" }) => {
  const classes = useStyles();

  return logo_image_url ? <div>
    <img
      style={{ borderRadius: border_radius ? border_radius : undefined }}
      src={logo_image_url}
      width={width ? width : 64}
      height={height ? height : 64}
      className={classes.logo}
    />
  </div> : null
}

const useStyles = makeStyles({
  logo: {
    border: "solid 0px transparent",
    backgroundColor: "transparent !imporant"
  },
});

export default GameLogo
