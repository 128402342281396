export const evaluation_tr = {
  breadcrumbTitle: "Oyun Değerlendirmeleri",
  pageTitle: "Oyun Değerlendirmeleri",
  title: "Oyun Değerlendirmeleri",
  menuTitle: "Oyun Değerlendirmeleri",

  object_order: "Sıra",
  game: {
    logo_image_url: "Logo",
    name: "Adı",
  },
  created_datetime: "Oluşuturulma Tarihi",
  comment: "Yorum",
  follow: " ",
  action: " ",
  deleteConfirmMessage: "'Oyun Değerlendirmesi' silinecek onaylıyor musunuz?",

  add: {
    title: "Yeni Oyun Değerlendirmesi",
    new: "Yeni Ekle",
    game_id: "App Store Id",
    comment: "Yorum",
  },

  edit: {
    title: "Oyun Değerlendirmesi Güncelle",
    update: "Güncelle",
    game_id: "App Store Id",
    comment: "Yorum",
  },

  commentValidation: "'Yorum' alanı boş bırakılamaz",
};
