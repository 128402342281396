import React, { useState } from 'react';
import { IUseGuiFormHook } from '../types';

const useGuiFormHook = (): [IUseGuiFormHook, React.Dispatch<React.SetStateAction<IUseGuiFormHook>>] => {
  
  const [guiFormdHook, setGuiFormdHook] = useState<IUseGuiFormHook>(undefined);

  return [guiFormdHook, setGuiFormdHook];
};

export { useGuiFormHook };
