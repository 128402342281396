import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import FollowingStatus from 'pages/components/FollowingStatus';
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';

const FollowingGames = () => {
  const [hook, setHook] = useGuiGridHook();

  return (
    <GuiGrid
      fetchData="games/games"
      queryParams={{
        followed: true,
      }}
      setHook={setHook}
      title="gameTracker.dashboard.appStore.followingGames.title"
      labelPath="gameTracker.dashboard.appStore.followingGames"
      columnExtensions={[
        { columnName: "object_order", width: 66 },
        { columnName: "name", wordWrapEnabled: true },
        { columnName: "artist", wordWrapEnabled: true },
        { columnName: "logo_image_url", width: 76 },
        { columnName: "following", width: 150 },
        { columnName: "rate", width: 60 },
        { columnName: "store_rate", width: 66 },
        { columnName: "url", width: 80 },
        { columnName: "release_date", width: 140 },
        { columnName: "last_release_date", width: 140 },
      ]}
      columns={[
        "object_order",
        "logo_image_url",
        "name",
        "artist",
        "rate",
        "store_rate",
        "release_date",
        "last_release_date",
        "following"
      ]}
      hiddenSortFields={["following"]}
      dateTimeFields={[
        { targetField: "release_date" },
        { targetField: "last_release_date" },
      ]}
      customCells={[
        {
          targetFields: ["name"], component: (row: any) =>
            <Name
              linkTo={`/games/game/${row.row.id}`}
              name={row.row.name}
              url={row.row.url}
            />
        },
        {
          targetFields: ["artist"], component: (row: any) =>
            <Name
              linkTo={`/makers/maker/${row.row.artist?.id}`}
              name={row.row.artist?.name}
              url={row.row.artist?.url}
            />
        },
        {
          targetFields: ["logo_image_url"], component: (row: any) =>
            <GameLogo logo_image_url={row.row.logo_image_url} />
        },
        {
          targetFields: ["following"], component: (row: any) =>
            <FollowingStatus
              key={row.row.id}
              isFollowing={row.row.following}
              followUrl={`games/follow_game/${row.row.id}`}
              onSuccess={() => hook?.fetch()}
            />
        },
      ]}
    />
  )
}

export default FollowingGames
