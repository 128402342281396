import { useRequest } from '@guibil/api'
import { GuiButton, guiNotifier } from '@guibil/components'
import React, { useEffect, useState } from 'react'

interface FollowingStatusProps {
  isFollowing: boolean,
  followUrl: string,
  onSuccess?: () => void
}

const FollowingStatus: React.FC<FollowingStatusProps> = ({ isFollowing, followUrl, onSuccess }) => {
  const req = useRequest();
  const [following, setFollowing] = useState<boolean>(isFollowing);

  useEffect(() => {
    setFollowing(isFollowing)
  }, [isFollowing])

  const followUnfollow = async () => {
    await req.get(followUrl).
      then((res: any) => {
        setFollowing(res?.followed);
        onSuccess && onSuccess();
      }).
      catch((err) => guiNotifier().handleError(err))
  }

  return (
    following ?
      <GuiButton
        onClick={followUnfollow}
        text={"gameTracker.components.following"}
        variant={"cancel"}
        icon="times-circle"
      />
      :
      <GuiButton
        onClick={followUnfollow}
        text={"gameTracker.components.follow"}
        variant={"cancel"}
        icon="plus-circle"
      />
  )
}

export default FollowingStatus
