import { GuiForm, GuiText } from "@guibil/form"
import { Grid } from "@material-ui/core";
import validation from './validation';
import passwordValidation from './password-validation';
import { useUser } from "@guibil/app";

const Profile = () => {
	const user = useUser();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<GuiForm
					fetchData={"users/maker_user_profile"}
					type="writer"
					title="gameTracker.profile.title"
					labelPath="gameTracker.profile"
					validationSchema={validation}
					successListener={() => {
						if (user.isAuth) {
							user.checkAuth();
						}
					}}
				>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<GuiText field="email" />
						</Grid>
						<Grid item xs={12}>
							<GuiText field="first_name" />
						</Grid>
						<Grid item xs={12}>
							<GuiText field="last_name" />
						</Grid>
					</Grid>
				</GuiForm>
			</Grid>
			<Grid item xs={12} md={6}>
				<GuiForm
					fetchData={() => ({
						password: "",
						password_confirm: ""
					})}
					type="writer"
					title="gameTracker.profile.passwordTitle"
					labelPath="gameTracker.profile"
					validationSchema={passwordValidation}
					config={{
						submitDataApi: "users/maker_user_profile",
						submitType: "post",
						// successMessage: "users.update.success",
						// errorMessage: "users.update.error"
					}}
					refreshConfig={{
						hide: true,
						onRefresh: () => null
					}}
				>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<GuiText field="password" type="password" />
						</Grid>
						<Grid item xs={12}>
							<GuiText field="password_confirm" type="password" />
						</Grid>
					</Grid>
				</GuiForm>
			</Grid>
		</Grid>
	)
}

export default Profile
