import UploadFile from './FileUpload';
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLang } from '@guibil/app';
import { guiNotifier } from '@guibil/components';


export interface FileUploadProps {
  id: number,
  filename: string,
  file?: File,
  download_url?: string,
}

interface FilesUploadProps {
  files: FileUploadProps[],
  label?: string,
  disabled?: boolean,
  setFiles?: React.Dispatch<React.SetStateAction<FileUploadProps[]>>,
}

const FilesUpload: React.FC<FilesUploadProps> = ({ files, label, setFiles, disabled }) => {
  const lang = useLang();
  const classes = useStyles();

  useEffect(() => { setFiles && setFiles(files.length > 0 ? files : []); }, []);

  const handleDelete = (position: number) => {
    const copies = [...files];
    copies.splice(position, 1)
    setFiles && setFiles(copies);
  }

  const handleFilesSelection = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];

        if (file.size > 104857600) {
          guiNotifier().error("gameTracker.components.filesUpload.sizeError")
          return;
        }

        if (e.target.files.length > 10 || files.length > 10) {
          guiNotifier().error("gameTracker.components.filesUpload.maxFile")
          return;
        }

        setFiles && setFiles(oldArray => [...oldArray, {
          id: files.length > 0 ? Math.max(...files.map(file => file.id)) + 1 : 1,
          filename: file.name,
          file: file,
        }]);
      }
    }
  }

  return <div>
    <div className={classes.header}>
      <div className={classes.label}>
        {label && lang(label)}
      </div>
      {
        !disabled && <>
          <label className={classes.button} htmlFor="file-input">
            <FontAwesomeIcon icon={"plus-circle"} />
          </label>
          <input
            id="file-input"
            type="file"
            multiple
            value={""}
            className={classes.fileInput}
            onChange={handleFilesSelection}
          />
        </>
      }
    </div>
    <div className={classes.container}>
      {
        files.map((file, position) => <UploadFile
          key={file.id}
          position={position}
          filename={file.filename}
          downloadUrl={file.download_url}
          handleDelete={handleDelete}
          disabled={disabled}
        />)
      }
    </div>
  </div >
}


const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
  },
  label: {
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
  },
  fileInput: {
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  button: {
    marginLeft: 10,
    cursor: "pointer",
    "& svg": {
      width: "20px !important",
      height: "20px !important",
      color: "var(--primarySuccess)"
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    marginTop: "9px",
    minHeight: "180px",
    borderRadius: "2px",
    backgroundColor: "var(--bgColor)",
    overflowX: "auto",
    "&::-webkit-scrollbar-track": {
      backgroundColor: "var(--primaryGridBorderColor2)",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar": {
      width: 10,
      height: 16,
      borderRadius: "2px",
      backgroundColor: "var(--primaryGridBorderColor2)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#8e8e8e",
    }
  },
}));

export default FilesUpload
