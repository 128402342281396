import { useLang } from '@guibil/app';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid'
import React from 'react'

interface IProps {
  title?: string;
}

const GuiPageTitle: React.FC<IProps> = (props) => {
  const lang = useLang();
  const classes = useStyles();

  if (!props.title) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* <p className={classes.deviceName}>1. Moltu</p> */}
        <h1 className={classes.pageTitle}>{lang(props.title)}</h1>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  deviceName: {
    marginTop: 15,
    fontWeight: 700,
    color: "var(--titleColor)"
  },
  pageTitle: {
    fontSize: "2rem",
    fontWeight: 700,
    color: "var(--titleColor)",
    marginBottom: 15
  }
}))

export default GuiPageTitle
