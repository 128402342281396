import React from 'react';
import { Plugin } from '@devexpress/dx-react-core';
import { Column } from '@devexpress/dx-react-grid';
import { TableHeaderRow, Table } from '@devexpress/dx-react-grid-material-ui';
import { useLang } from '@guibil/app';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { useGuiGridStateContext } from '../../contexts/GuiGridStateContext';
import { CellComponent } from './components/CellComponent';
import { TableComponent } from './components/TableComponent';
import { HeadComponent } from './components/HeadComponent';
import { RowComponent } from './components/RowComponent';
import { BodyComponent } from './components/BodyComponent';
import GuiGridResizing from '../resizing/GuiGridResizing';
import { makeStyles } from '@material-ui/core';

interface IProps {
  gridColumns: Column[],
}
const GuiGridTable: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { gridColumns } = props;
  const { columnExtensions, enableSorting, enableResizing } = useGuiGridPropsContext();
  const { loadingState } = useGuiGridStateContext();
  const lang = useLang();

  const gridColumnExtensions = React.useMemo((): Table.ColumnExtension[] => {
    const permittedColumns = gridColumns.map((column) => column.name);
    return (columnExtensions || []).filter((columnExt) => permittedColumns.includes(columnExt.columnName));
  }, [gridColumns, columnExtensions]);

  const isLoaded = loadingState !== 'waiting' && loadingState !== 'firstTimeLoading';

  const TableContainer = (props: any) => (
    <Table.Container {...props} className={classes.tableContainer} />
  );

  return (
    <Plugin>
      <Table
        messages={{ noData: isLoaded ? lang('guibil:grid.noData') : '' }}
        columnExtensions={gridColumnExtensions}
        tableComponent={TableComponent} // <table>
        headComponent={HeadComponent} // <thead>
        bodyComponent={BodyComponent} // <tbody>
        containerComponent={TableContainer}
        rowComponent={RowComponent} // <tr>
        cellComponent={CellComponent} // <td>
      />
      {enableResizing && <GuiGridResizing /*width={ref.current}*/ gridColumns={gridColumns} />}
      <TableHeaderRow
        showSortingControls={enableSorting}
        messages={{ sortingHint: lang('guibil:grid.sort') }}
      />
    </Plugin>
  );
};

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderRadius: "2px !important",
    paddingTop: "4px",
    backgroundColor: "var(--bgColor)",
  }
}))

export { GuiGridTable };
