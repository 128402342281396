import React from 'react';
import { useUser } from '@guibil/app';
import { Route } from 'react-router-dom';
import { IGuiAuthRoute } from '../types';
import { UnauthorizedPage } from '../error-pages/403';
import { useGuiBrowserRouterContext } from '../contexts/GuiBrowserRouterContext';
import GuiPageTitle from '@guibil/components/components/GuiPageTitle';

export const GuiAuthRoute: React.FC<IGuiAuthRoute> = (props) => {
  const user = useUser();
  const { modulePermitted } = useGuiBrowserRouterContext();
  const { module, pageTitle } = props;
  
  // Has no permission to visit page
  if (
    !user.isAuth
    || (module && (!modulePermitted || modulePermitted(module)))) {
    return (
      <Route {...props} component={undefined}>
        <UnauthorizedPage />
      </Route>
    );
  }

  return (
    <>
      <GuiPageTitle title={pageTitle} />
      <Route {...props} key={props.location?.key}>
        {props.children}
      </Route>
    </>
  );
};
