import React, { } from 'react'
import { Grid } from '@material-ui/core';
import validationSchema from './validation';
import { GuiForm, GuiText } from '@guibil/form'
import { GuiGenericDialog } from '@guibil/components';
import { GuiRichText } from '@guibil/form/form-elements/elements/GuiRichText';
import { makeStyles } from '@material-ui/styles';

interface EditEvaluationProps {
  game_id: string,
  comment: string,
  onSuccess: () => void,
  onClose: () => void,
}


const EditEvaluation: React.FC<EditEvaluationProps> = ({ game_id, comment, onSuccess, onClose }) => {
  const classes = useStyles();

  return <GuiGenericDialog
    size="lg"
    isOpen
    title="gameTracker.development.evaluation.edit.title"
    onClose={onClose}
  >
    <div className={classes.form}>
      <GuiForm
        fetchData={() => ({
          game_id: game_id,
          comment: comment,
        })}
        type="new-form"
        isDialogForm
        mainButtonsConfig={{
          dialogSaveText: "gameTracker.development.evaluation.edit.update"
        }}
        config={{
          submitDataApi: 'games/add_edit_evaluation',
        }}
        labelPath="gameTracker.development.evaluation.edit"
        validationSchema={validationSchema}
        successListener={onSuccess}
        onCancel={onClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GuiText field="game_id" editMode={false} />
          </Grid>
          <Grid item xs={12}>
            <GuiRichText field="comment" />
          </Grid>
        </Grid>
      </GuiForm>
    </div>
  </GuiGenericDialog>;
}

const useStyles = makeStyles(() => ({
  form: {
    "& form > div": {
      padding: "0px !important",
    }
  }
}));

export default EditEvaluation
