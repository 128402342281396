import { useRequest } from "@guibil/api"
import { classNames, GuiButton, guiNotifier } from "@guibil/components";
import { GuiGrid, useGuiGridHook } from "@guibil/grid"
import GuiGridRowButton from "@guibil/grid/components/buttons/GuiGridRowButton"
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import UserCreate from "../components/create/UserCreate";
import UserPasswordUpdate from "../components/update-password/UserPasswordUpdate";
import UserUpdate from "../components/update/UserUpdate";

const UserManagement = () => {
	const req = useRequest();
	const classes = useClasses();
	const [gridHook, setGridHook] = useGuiGridHook();
	const [showAddDialog, setShowAddDialog] = useState(false);
	const [editRowId, setEditRowId] = useState<any>(null);
	const [editPasswordRowData, setEditPasswordRowData] = useState<any>(null);

	const Action = (row: any) => {
		const handleDelete = async () => {
			await req.delete("users/maker_user/" + row.row.id).then(() => {
				gridHook?.fetch();
				guiNotifier().success("gameTracker.userManagement.deleteSuccessMessage");
			});

		};

		const onDeleteClick = () => guiNotifier().confirm({ description: "gameTracker.userManagement.deleteConfirmMessage" }, handleDelete)

		return (
			<>
				<GuiGridRowButton onClick={() => setEditRowId(row.row.id)} type="update" />
				<GuiButton
					nativeProps={{ classes: { root: classNames(classes.button, classes.lock) } }}
					variant="cancel" onClick={() => setEditPasswordRowData({ id: row.row.id, username: row.row.username })}
					icon="lock"
				/>
				<GuiGridRowButton onClick={onDeleteClick} type="delete" />
			</>
		)
	}
	return (
		<>
			<GuiGrid
				fetchData="users/maker_users"
				columns={["username", "role", "email", "first_name", "last_name", "is_active", "actions"]}
				title="gameTracker.userManagement.title"
				labelPath="gameTracker.userManagement"
				setHook={setGridHook}
				booleanFields={[
					{ targetField: "is_active", switchText: "active-passive" }
				]}
				customCells={[{
					targetFields: ["actions"], component: Action
				}]}
				columnExtensions={[{
					columnName: "actions",
					width: 140
				}]}
				enumCells={[
					{ targetField: "role", enumObject: "AuthUserRole" }
				]}
				extraButtons={[{
					onClick: () => setShowAddDialog(true),
					text: "gameTracker.userManagement.addUser",
					icon: "plus-circle",
					variant: "success"
				}]}
			/>
			{
				showAddDialog && <UserCreate
					onClose={() => setShowAddDialog(false)}
					onSuccess={() => { setShowAddDialog(false); gridHook?.fetch(); }}
				/>
			}
			{
				editRowId && <UserUpdate
					id={editRowId}
					onClose={() => setEditRowId(null)}
					onSuccess={() => { setEditRowId(null); gridHook?.fetch(); }}
				/>
			}
			{
				editPasswordRowData && <UserPasswordUpdate
					username={editPasswordRowData.username}
					id={editPasswordRowData.id}
					onClose={() => setEditPasswordRowData(null)}
					onSuccess={() => { setEditPasswordRowData(null) }}
				/>
			}
		</>
	)
}

const useClasses = makeStyles({
	button: {
		margin: "4px",
		minWidth: "unset",
		width: "32px !important",
		height: "32px !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "7px !important",
		float: "left",
		marginRight: "5px !important",
		"& span": {
			margin: 0,
		},
		"& div": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		"& svg": {
			position: "static !important",
			width: "14px !important",
			height: "14px !important",
		}
	},
	lock: {
		"& .MuiCircularProgress-root": {
			left: "6.5px !important"
		},
		"& svg": {
			color: "var(--primaryBlue) !important",
			position: "static !important",
		}
	},
})

export default UserManagement
