/* eslint camelcase:"off" */
import * as yup from 'yup';
import { guibil_form_en, guibil_form_tr } from '@guibil/form';
import i18n from 'i18next';

const setupValidation = () => {
  const yupLangPackage = i18n.language === 'en' ? guibil_form_en.validation : guibil_form_tr.validation;
  yup.setLocale({
    mixed: yupLangPackage.mixed,
    string: yupLangPackage.string,
    number: yupLangPackage.number,
    date: yupLangPackage.date,
    object: yupLangPackage.object,
    array: yupLangPackage.array,
  });
};

setupValidation();
