import React, { useState } from 'react';

type IGuiPageData = any | undefined;

const useGuiPageData = (): [IGuiPageData, React.Dispatch<React.SetStateAction<IGuiPageData>>] => {
  const [guiPageData, setGuiPageData] = useState<IGuiPageData>(undefined);

  return [guiPageData, setGuiPageData];
};

export { useGuiPageData };
