export const guibil_grid_en = {
  showAll: "Show all",
  rowsPerPage: "Rows per page:",
  info: "{{from}}-{{to}} of {{count}} lines in total",
  noData: "No data",
  groupByColumn: "Drag a column header here to group by that column",
  sort: "Sort",
  filter: {
    placeholder: "Filter",
    contains: "Contains",
    endsWith: "Ends with",
    equal: "Equals",
    greaterThan: "Greater than",
    greaterThanOrEqual: "Greater than or equal to",
    lessThan: "Less than",
    lessThanOrEqual: "Less than or equal to",
    notContains: "Does not contain",
    notEqual: "Does not equal",
    startsWith: "Starts with",

    title: "Filter",
    filter: "Filter",
    editFilter: "Edit Filter",
    reset: "Reset",
    startTime: "Start Date",
    endTime: "End Date",
    cardPortList: "Card/Port",
    sourceSubscriberNo: "Source Subscriber No",
  },
  cells: {
    boolean: {
      true: "Yes",
      false: "No",
    },
    file: {
      title: "View File",
    },
  },
  button: {
    clear: "Clear",
  },

  exportPdf: "Export To Pdf",
  exportExcel: "Export To Excel",
  export: "Export",
};
