export const play_store_notification_tr = {
  filter: {
    filter: "Filtrele",
    game_name: "Oyun Adı",
    min_created_datetime: "Min Bildirim Tarihi",
    max_created_datetime: "Max Bildirim Tarihi",
    notification_type: "Bildirim Tipi"
  },

  deleteAll: "Tümünü Sil",
  deleteAllConfirmMessage: "Tüm bildirimler silinecek. Devam etmek istiyor musunuz?",
  deleteAllSuccessMessage: "Tüm bildirimler silindi.",
  deleteConfirmMessage: "Bildirimler silinecek. Devam etmek istiyor musunuz?",
  deleteSuccessMessage: "Bildirimler silindi.",

  title: "Bildirimler",
  object_order: "Sıra",
  type: "Bildirim Tipi",
  created_datetime: "Bildirim Tarihi",
  actions: " ",

  game: {
    logo_image_url: "Logo",
    name: "Adı",
    developer: "Yapımcı",
    rate: "Puan",
    store_rate: "Mağaza Puanı",
    release_date: "Yayın Tarihi",
    last_release_date: "Son Yayın Tarihi",
  },
};
