import { GuiGenericDialog } from '@guibil/components'
import { Grid } from '@material-ui/core'
import React from 'react'
import DailyPlayTimes from './DailyPlayTimes'
import PlayTimes from './PlayTimes'

interface PlayerPlayDetails {
  player_id: string,
  onClose: () => void,
}

const PlayerPlayDetailsDialog: React.FC<PlayerPlayDetails> = ({ player_id, onClose }) => {
  return (
    <GuiGenericDialog
      isOpen
      onClose={onClose}
      size="lg"
    >
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ marginBottom: 30 }}>
          <DailyPlayTimes player_id={player_id} />
        </Grid>
        <Grid item xs={12}>
          <PlayTimes player_id={player_id} />
        </Grid>
      </Grid>
    </GuiGenericDialog>
  )
}

export default PlayerPlayDetailsDialog
