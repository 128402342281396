export const play_store_dashboard_tr = {
  info: {
    title: "Genel",
    developer: "Toplam Yapımcı Sayısı:",
    game: "Toplam Oyun Sayısı:",
    following_developer: "Takip Edilen Yapımcı Sayısı:",
    following_game: "Takip Edilen Oyun Sayısı:",
  },

  followingDevelopers: {
    title: "Takip Edilen Yapımcılar",
    object_order: "Sıra",
    name: "Adı",
    rate: "Puan",
    last_release_date: "Son Yayın Tarihi",
    following: "Takip",
  },

  followingGames: {
    title: "Takip Edilen Oyunlar",
    object_order: "Sıra",
    logo_image_url: "Logo",
    name: "Adı",
    developer: "Yapımcı",
    rate: "Puan",
    store_rate: "Mağaza Puanı",
    release_date: "Yayın Tarihi",
    last_release_date: "Son Yayın Tarihi",
    following: "Takip",
  },
};
