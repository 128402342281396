export const play_store_notification_en = {
  filter: {
    filter: "Filter",
    game_name: "Game Name",
    min_created_datetime: "Min Notification Date",
    max_created_datetime: "Max Notification Date",
    notification_type: "Notification Type"
  },

  deleteAll: "Delete All",
  deleteAllConfirmMessage: "All notifications will be deleted. Do you want to continue?",
  deleteAllSuccessMessage: "All notifications deleted.",
  deleteConfirmMessage: "Notification will be deleted. Do you want to continue?",
  deleteSuccessMessage: "Notification deleted.",

  title: "Notifications",
  object_order: "Index",
  type: "Notification Type",
  created_datetime: "Notification Date",
  actions: " ",

  game: {
    logo_image_url: "Logo",
    name: "Name",
    developer: "Artist",
    rate: "Rate",
    store_rate: "Store Rate",
    release_date: "Release Date",
    last_release_date: "Last Release Date",
  },
};
