import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { IModuleRoutes } from "../../types";
import { arrayToString, convertGroupIntoOneLevel } from "../../utils/local-helpers";

export const useGuiBreadcrumbCrumbs = (config: IModuleRoutes) => {
  const { pathname } = useLocation();

  const crumbs = useMemo(() => {
    const result = convertGroupIntoOneLevel(config, '')
      .filter((route) => pathname.startsWith(arrayToString(route.path as (string | string[] | undefined), '__none__')))
      .filter((route) => !!route.component);

    return result.map((route, key) => ({
      path: Array.isArray(route.path) ? route.path[0] : route.path || '',
      title: route.title || '',
      isFirst: key === 0,
      isLast: result.length === key - 1,
    }));
  }, [config, pathname]);

  return { crumbs };
};
