import { GuiSwitch } from '@guibil/form';
import { commonCSS } from '@guibil/styles';
import React from 'react';
import { GuiButton, IGuiButtonProps, IGuiSwitchButtonProps } from './GuiButton';

export type IGuiButtonsRenderer = (IGuiButtonProps | IGuiSwitchButtonProps)[];
interface IProps {
  buttons?: IGuiButtonsRenderer,
  disableAll?: boolean,
  shouldRender?: (button: IGuiButtonProps) => boolean,
}

export const GuiButtonsRenderer: React.FC<IProps> = (props) => {
  const { buttons, disableAll, shouldRender } = props;

  if (!buttons) {
    return null;
  }

  const buttonsContainer = buttons.map((button, i) => {

    if (button.variant === "switch") {
      return <GuiSwitch
        key={i}
        nativeProps={{
          classes: {
            root: commonCSS.actionswitchRoot,
            track: commonCSS.actionswitchTrack,
            thumb: commonCSS.actionswitchThumb,
            checked: commonCSS.checkedactionSwitch,
            switchBase: commonCSS.actionswitchBase
          },
          disableRipple: true,
        }}
        className={commonCSS.actionswitchButton}
        field=""
        controlled
        value={button.value}
        onChange={() => button.onClick()}
        switchText={button.switchText}
        disabled={button.disabled}
        hideOnFirtload
      />
    }

    if (!shouldRender || shouldRender(button)) {
      return <GuiButton key={i} {...button} disabled={disableAll || button.disabled} />
    }
  });

  return <>{buttonsContainer}</>;
};
