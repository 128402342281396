import jsPDF from 'jspdf';
import moment from 'moment';
import { Fragment } from 'react';
import ReactQuill from 'react-quill';
import html2canvas from 'html2canvas';
import { GuiEnums, useLang } from '@guibil/app';
import { Grid, makeStyles } from '@material-ui/core';
import { DATE_TIME_HUMAN_FORMAT } from '@guibil/form';
import { RichTextFormats, RichTextModules } from '@guibil/form/form-elements/elements/GuiRichText';
import { GuiGenericDialog } from '@guibil/components';
import { B64ImageProps } from './GameImages';


interface IProps {
  data: any;
  onClose: () => void;
}

const GidToPdf: React.FC<IProps> = ({ data, onClose }) => {
  const lang = useLang();
  const classes = useStyles();

  const getPrintArea = async () => {
    const input = document.getElementById('printArea');
    if (input) {
      return await html2canvas(input, {
        allowTaint: false,
        useCORS: true,
      });
    } else {
      return null;
    }
  }

  const handlePdf = async () => {
    const margin = 100;
    const padding = 50;
    const drawWidth = 1240;
    const drawHeight = 1754;

    const canvasWidth = drawWidth + margin;
    const canvasHeight = drawHeight + margin;

    const pdf = new jsPDF("p", "px", "a4");
    const printAreaCanvas = await getPrintArea();

    if (printAreaCanvas) {
      const canvas = document.createElement('canvas');
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.fillStyle = "#ffffff";
        let addedPageHeights = 0;
        while (printAreaCanvas.height > addedPageHeights) {
          ctx.fillRect(0, 0, canvasWidth, canvasHeight);
          ctx.drawImage(printAreaCanvas, 0, addedPageHeights, drawWidth, drawHeight, padding, padding, drawWidth, drawHeight);
          addedPageHeights = addedPageHeights + drawHeight;
          pdf.addImage(canvas, 'JPEG', 0, 0, 447, 632);

          if (printAreaCanvas.height > addedPageHeights) {
            pdf.addPage();
          }
        }

        pdf.save(`${data.title}.pdf`);
      }
    }
  }

  const Item: React.FC<{ children: React.ReactNode, label: string }> = ({ label, children }) => {
    return <div>
      <div className={classes.label}>{lang(label)}</div>
      <div className={classes.itemChild}>{children}</div>
    </div>
  }

  return <GuiGenericDialog
    size="lg"
    title={data.title}
    onClose={onClose}
    extraButtons={[
      {
        onClick: handlePdf,
        text: "gameTracker.development.gidDisplay.pdf",
        variant: "success",
        icon: "file-pdf"
      },
    ]}
  >
    <div id="printArea" className={classes.printContainer}>
      <Grid container spacing={2}>

        {/* General */}
        <Fragment>
          <Grid item xs={12}>
            <span className={classes.title}>
              {data.title}
            </span>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.general.mantra">
              <span className={classes.text}>
                {data.mantra}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.summary.created_by">
              <span className={classes.text}>
                {data.created_by}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.summary.updated_by">
              <span className={classes.text}>
                {data.updated_by}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.summary.rate">
              <span className={classes.text}>
                {data.rate ? data.rate : "-"}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.summary.created_datetime">
              <span className={classes.text}>
                {moment(data.created_datetime).isValid() ? <span className={classes.text}>{moment(data.created_datetime).format(DATE_TIME_HUMAN_FORMAT)}</span> : "-"}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.summary.updated_datetime">
              <span className={classes.text}>
                {moment(data.updated_datetime).isValid() ? <span className={classes.text}>{moment(data.updated_datetime).format(DATE_TIME_HUMAN_FORMAT)}</span> : "-"}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.summary.status">
              <span className={classes.text}>
                {GuiEnums.get("GidStatus", data.status)}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.general.popularity">
              <span className={classes.text}>
                {data.popularity}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.general.art_difficulty">
              <span className={classes.text}>
                {data.art_difficulty}
              </span>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item label="gameTracker.development.gidDisplay.general.dev_difficulty">
              <span className={classes.text}>
                {data.dev_difficulty}
              </span>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.general.core_loops">
              <span className={classes.text}>
                {data.core_loops.map((item: any, key: number) => <div key={key}>{item.value}</div>)}
              </span>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item label="gameTracker.development.gidDisplay.general.planned_developers">
              <span className={classes.text}>
                {data.planned_developers.map((item: any, key: number) => <div key={key}>{item.tag}</div>)}
              </span>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item label="gameTracker.development.gidDisplay.general.planned_designers">
              <span className={classes.text}>
                {data.planned_designers.map((item: any, key: number) => <div key={key}>{item.tag}</div>)}
              </span>
            </Item>
          </Grid>
        </Fragment>

        {/* Dynamic */}
        <Fragment>
          <Grid item xs={12}>
            <span className={classes.title}>
              {lang("gameTracker.development.gidDisplay.sections.dynamic")}
            </span>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.dynamic.genre">
              <span className={classes.text}>
                {data.dynamic.genre}
              </span>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.dynamic.progress">
              <ReactQuill
                value={data.dynamic.progress}
                readOnly
                modules={RichTextModules}
                formats={RichTextFormats}
                className={classes.richText}
              />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.dynamic.goal">
              <ReactQuill
                value={data.dynamic.goal}
                readOnly
                modules={RichTextModules}
                formats={RichTextFormats}
                className={classes.richText}
              />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.dynamic.challenge">
              <ReactQuill
                value={data.dynamic.challenge}
                readOnly
                modules={RichTextModules}
                formats={RichTextFormats}
                className={classes.richText}
              />
            </Item>
          </Grid>
        </Fragment>

        {/* Mechanic */}
        <Fragment>
          <Grid item xs={12}>
            <span className={classes.title}>
              {lang("gameTracker.development.gidDisplay.sections.mechanic")}
            </span>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.mechanic.cores">
              <span className={classes.text}>
                {data.mechanic?.cores.map((item: any, key: number) => <div key={key}>{item.value}</div>)}
              </span>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.mechanic.cores">
              <span className={classes.text}>
                {data.mechanic?.subs.map((item: any, key: number) => <div key={key}>{item.value}</div>)}
              </span>
            </Item>
          </Grid>
        </Fragment>

        {/* Design */}
        <Fragment>
          <Grid item xs={12}>
            <span className={classes.title}>
              {lang("gameTracker.development.gidDisplay.sections.design")}
            </span>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.design.description">
              <ReactQuill
                value={data.design.description}
                readOnly
                modules={RichTextModules}
                formats={RichTextFormats}
                className={classes.richText}
              />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item label="gameTracker.development.gidDisplay.design.notes">
              <ReactQuill
                value={data.design.notes}
                readOnly
                modules={RichTextModules}
                formats={RichTextFormats}
                className={classes.richText}
              />
            </Item>
          </Grid>


          <Grid item xs={12}>
            <Grid container item spacing={2}>
              {
                data.design.b64encode_images.map((image: B64ImageProps, index: number) => {
                  return (
                    <Grid key={index} item xs={6}>
                      <img
                        style={{ maxWidth: 600, maxHeight: 600 }}
                        src={image.value}
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
          </Grid>
        </Fragment>
      </Grid>
    </div>
  </GuiGenericDialog>
}

const useStyles = makeStyles(() => ({
  printContainer: {
    padding: 10,
    width: 1240,
    background: "#ffffff",
  },
  title: {
    marginLeft: "14px !important",
    fontWeight: 800,
    fontSize: "24px",
    color: "#0f0f0f"
  },
  label: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#0f0f0f",
  },
  itemChild: {
    marginLeft: 10,
  },
  text: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#000000",
  },
  richText: {
    marginLeft: -12,
    "& > div:nth-child(1)": {
      display: "none",
      fontFamily: "inherit",
      fontSize: "14px",
      border: "none !important",
    },
    "& > div:nth-child(2)": {
      border: "none !important",
      color: "#000000",
      minHeight: "120px",
      fontFamily: "inherit",
      "& > div:nth-child(1)": {
        fontSize: "14px",
        fontWeight: 500,
        "& em": {
          fontStyle: "italic  !important",
        },
        "& strong": {
          fontWeight: 900,
        },
      },
      "& > div:nth-child(1):before": {
        opacity: 0.5,
        fontWeight: 600,
        color: "#000000",
        fontStyle: "normal  !important",
        fontSize: "14px",
      },
    }
  },
}));

export default GidToPdf
