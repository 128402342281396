import { Column, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import { GuiEnums } from '@guibil/app';
import { getProperty } from '@guibil/helpers';
import React from 'react';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { useGuiGridStateContext } from '../../contexts/GuiGridStateContext';
import { IGuiGridDatum } from '../../types';

interface IProps {
  gridColumns: Column[],
}
const GuiGridCore: React.FC<IProps> = (props) => {
  const { getCellValues, enumCells, rows } = useGuiGridPropsContext();
  const { data, setFilters } = useGuiGridStateContext();

  const getCellValue = React.useCallback((row: IGuiGridDatum, columnName: string) => {
    const gridEnumCells = enumCells || [];
    let value = getProperty(columnName, row);

    // Custom getCellFunction
    if (typeof (getCellValues) !== 'undefined') {
      for (let it = 0; it < getCellValues.length; it++) {
        const item = getCellValues[it];
        if (item.targetFields.includes(columnName)) {
          value = item.function(row, columnName);
          if (item.terminateWithFunctionResult === true) {
            return value;
          }
        }
      }
    }
    // End of Custom getCellFunction

    for (let it = 0; it < gridEnumCells.length; it++) {
      const { targetField, enumObject } = gridEnumCells[it];
      if (targetField === columnName) {
        value = GuiEnums.get(enumObject, value);
        break;
      }
    }

    return value;
  }, [enumCells, getCellValues, GuiEnums.locale]);

  return (
    <Grid
      rows={rows || data.list}
      columns={props.gridColumns}
      getCellValue={getCellValue}
    >
      <FilteringState onFiltersChange={setFilters} />
      <IntegratedFiltering />
      {props.children}
    </Grid>
  );
};

export { GuiGridCore };
