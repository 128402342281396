import Value from './Value';
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { GuiButton } from '@guibil/components';
import { useState } from 'react';
import { GuiText } from '@guibil/form';


export interface ValuesProps {
  id: number,
  value: string,
}


interface ValuesListProps {
  label: string,
  values: ValuesProps[],
  disabled?: boolean,
  required?: boolean,
  placeholder?: string,
  setValues?: React.Dispatch<React.SetStateAction<ValuesProps[]>>,
  setHasError?: React.Dispatch<React.SetStateAction<boolean>>,
}

const ValuesList: React.FC<ValuesListProps> = ({ label, values, disabled, required, placeholder, setValues, setHasError }) => {
  const classes = useStyles();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setValues && setValues(values.length > 0 ? values : required ? [{ id: 0, value: "" }] : []);
  }, [required]);


  useEffect(() => {
    setError(isAnyEmpty(values));
    setHasError && setHasError(isAnyEmpty(values));
  }, [values])

  const isAnyEmpty = (arr: ValuesProps[]) => {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].value === "") return true;
    }
    return false;
  }

  const handleAdd = () => {
    setValues && setValues(oldArray => [...oldArray, { id: Math.random(), value: "" }]);
  }

  const handleDelete = (position: number) => {
    const copies = [...values];
    copies.splice(position, 1)
    setValues && setValues(copies);
  }

  const handleChange = (position: number, value: string) => {
    setValues && setValues(oldArray => {
      const copies = [...oldArray];
      copies[position].value = value;
      return copies;
    });
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => e.dataTransfer.setData('id', e.currentTarget.id)

  const enableDropping = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const to = Number(e.currentTarget.id);
    const from = Number(e.dataTransfer.getData('id'));
    const copyValues = [...values];
    const dragItemContent = copyValues[from];
    copyValues.splice(from, 1);
    copyValues.splice(to, 0, dragItemContent);
    setValues && setValues(copyValues);
  }

  return <div>
    <div className={classes.label}>
      <div>
        {label}
      </div>
      {
        !disabled && <div>
          <GuiButton
            onClick={handleAdd}
            icon="plus-circle"
            disabled={error}
            nativeProps={{ classes: { root: classes.button } }}
          />
        </div>
      }
    </div>
    <div className={classes.container}>
      {
        values.length === 0 &&
        <GuiText
          field=''
          controlled
          disabled
          placeholder={placeholder}
        />
      }
      {
        values.map((value, position) => <Value
          key={value.id}
          position={position}
          disabled={disabled}
          value={value.value}
          allowDelete={required ? (values.length > 1) : true}
          handleDelete={handleDelete}
          handleChange={handleChange}
          handleDragStart={handleDragStart}
          enableDropping={enableDropping}
          handleDrop={handleDrop}
        />)
      }
    </div>
  </div>
}


const useStyles = makeStyles(() => ({
  label: {
    paddingTop: "4px",
    cursor: "default",
    fontSize: "0.8rem !important",
    fontWeight: 600,
    color: "var(--titleColor)",
    marginLeft: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  container: {
    marginTop: "-5.5px",
  },
  button: {
    marginLeft: 4,
    width: "34.5px",
    height: "28.5px",
    minWidth: "0px !important",
    "& svg": {
      left: "9px",
      width: "20px !important",
      height: "20px !important",
      color: "var(--primarySuccess)"
    },
  },
}));

export default ValuesList
