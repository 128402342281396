export const app_store_top_games_en = {
  title: "Top Games",

  filter: {
    filter: "Filter",
    name: "Name",
    chart_genre: "Genre",
    chart_type: "Type",
    previous_datetime: "Date",
  },

  topFreeGame: {
    title: "Top Games",
    order: "Index",
    follow: "Follow",
    change_of_order: "Order Change",
    showScreenshots: "Show",
    hideScreenshots: "Hide",
    game: {
      logo_image_url: "Logo",
      name: "Name",
      artist: "Artist",
      rate: "Rate",
      store_rate: "Store Rate",
      release_date: "Release Date",
      last_release_date: "Last Release Date",
    },
  },
};
