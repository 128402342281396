import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useGuiGridStateContext } from '@guibil/grid/contexts/GuiGridStateContext';
import { useLang, useUser } from '@guibil/app';
import { getUserPermissionForAction } from '@guibil/helpers';
import { classNames, GuiButton, guiNotifier, IGuiButtonProps } from '@guibil/components';
import { commonCSS } from '@guibil/styles';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { Grow, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { GuiIcon } from '@guibil/components/components/GuiIcon';

/**
 * Cell which includes Actions
 * ex: update / delete
 */
export const GuiGridActionCell: React.FC<DataTypeProvider.ValueFormatterProps> = (props): any => {
  const { actionFields } = useGuiGridPropsContext();
  const { loadingState } = useGuiGridStateContext();
  const { roles } = useGuiGridPropsContext();
  const user = useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const lang = useLang();
  const classes = useStyles();

  const { column: { name } } = props;
  const curGridAction = (actionFields || []).filter(({ targetField }) => targetField === name)[0];

  if (!curGridAction) { return null; }

  const { buttons, variant } = curGridAction;

  const buttonClickDisabled = loadingState === 'refreshing';
  const shouldRender = (button: IGuiButtonProps) => !button.name || getUserPermissionForAction(user.role, roles, button.name);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const typeCalculator = (button: IGuiButtonProps) => {
    switch (button.actionType) {
      case 'danger':
        return commonCSS.dangerAction;
      case 'warning':
        return commonCSS.warningAction;
      case 'success':
        return commonCSS.successAction;
      case 'info':
        return commonCSS.infoAction;
    }
  }


  if (variant === "menu") {

    return (
      <>
        <GuiButton nativeProps={{ classes: { root: classes.expandIcon } }} icon="ellipsis-v" onClick={handleMenuOpen} />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          TransitionComponent={Grow}
          classes={{ paper: classes.menuPaper }}
        >
          {buttons.map(button => {
            if (!shouldRender(button)) { return null; }

            return (
              <MenuItem disabled={button.disabled} key={button.text} className={classNames(classes.menuItem)} onClick={async (e) => {
                const handleClick = async () => {
                  button.onClick && await button?.onClick(props.row, e);
                  handleCloseMenu();
                }

                if (button.confirm) {
                  guiNotifier().confirm({
                    description: `${(typeof (button.confirm) === "string") ? (lang(button.confirm) || "") : lang('guibil:components.confirmation.text')}`
                  }, handleClick);
                }
                else {
                  handleClick();
                }
              }}>
                <GuiIcon icon={button.icon} />
                {lang(button.text || "")}
              </MenuItem>
            )
          })}
        </Menu>
      </>
    )
  } else {
    return (
      <>
        {buttons?.map(((button, key) => {
          if (!shouldRender(button)) { return null; }
          let actionType = typeCalculator(button);
          return (
            <GuiButton
              key={key}
              {...button}
              disabled={buttonClickDisabled || button.disabled}
              icon={button.icon}
              onClick={async (e: any) => {
                button.onClick && await button?.onClick(props.row, e);
              }}
              nativeProps={{
                classes: { root: classNames(commonCSS.gridAction, actionType) },
                variant: "contained"
              }}
            />
          );
        }))}
      </>
    );
  }
};

const useStyles = makeStyles(() => ({
  expandIcon: {
    minWidth: "unset",
    width: "2.7rem",
    height: "2.7rem",
    fontSize: 18,
    borderRadius: "50%",
    "& > span > div > span": {
      color: "var(--defaultTextColor)"
    }
  },
  menuPaper: {
    transform: "translateX(-30px) !important",
    backgroundColor: "var(--bgColor) !important",
    boxShadow: "0px 4px 12px -4px rgba(0,0,0,0.28)",
  },
  menuItem: {
    fontWeight: 500,
    backgroundColor: "var(--bgColor) !important",
    "& svg": {
      fontSize: 18,
      width: "1.2rem !important",
      height: "1.2rem !important",
      marginRight: 12
    },
  }
}))