import React from 'react';
import { GuiFormPropsContext } from '../contexts/GuiFormPropsContext';
import { IGuiFormProps } from '../types';
import { GuiFormActionsContainer } from './containers/GuiFormActionsContainer';
import { GuiFormCoreContainer } from './containers/GuiFormCoreContainer';
import { GuiFormStateContainer } from './containers/GuiFormStateContainer';
import { GuiFormikContainer } from './containers/GuiFormikContainer';
import { GuiFormElementContainer } from './containers/GuiFormElementContainer';

const GuiForm: React.FC<IGuiFormProps> = (props) => (
  <GuiFormPropsContext.Provider value={props}>
    {/* Form state */}
    <GuiFormStateContainer>
      {/* Formik */}
      <GuiFormikContainer>
      {/* Actions */}
        <GuiFormActionsContainer>
          {/* Form loading, errors and buttons */}
          <GuiFormCoreContainer>
            {/* Form elements container */}
            <GuiFormElementContainer>
              {props.children}
            </GuiFormElementContainer>
          </GuiFormCoreContainer>
        </GuiFormActionsContainer>
      </GuiFormikContainer>
    </GuiFormStateContainer>
  </GuiFormPropsContext.Provider>
);

GuiForm.defaultProps = {
  type: 'reader-writer',
  extraButtons: [],
  chainedFieldsAr: [],
  disableSubmitButton: false,
  fieldsToDismissFromPost: [],
  confirmOnSubmit: false,
  extraButtonsPosition: 'top-left',
  disableLeaveFormPrompt: false,
  roles: {},
  disableSubmitTriggeredByEnter: true,
  config: {
    reFetchOnSuccess: true
  }
};

export { GuiForm };
