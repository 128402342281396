import { app_store_artists_en } from "../app-store/locales/en_US";
import { play_store_developers_en } from "../play-store/locales/en_US";


export const makers_en = {
  breadcrumbTitle: "Makers",
  pageTitle: "Makers",
  title: "Makers",
  menuTitle: "Makers",

  appStore: app_store_artists_en,
  playStore: play_store_developers_en,
};
