import React, { useEffect, useRef } from 'react';
import { useRequest } from '@guibil/api';
import { GuiGridActionsContext } from '../contexts/GuiGridActionsStateContext';
import { useGuiGridPropsContext } from '../contexts/GuiGridPropsContext';
import { useGuiGridStateContext } from '../contexts/GuiGridStateContext';
import { IGuiGridDatum, IGuiGridResponseEntity } from '../types';

const PERFORM_FETCH = 'PERFORM_FETCH';
interface Props {
  performExcelExport: any,
  performPdfExport: any,
  exportRef: any,
  children: any
}

const GuiGridFetchContainer: React.FC<Props> = ({ children, performExcelExport, performPdfExport, exportRef }) => {
  const { fetchData, filterConfig, onFilter, serverSide } = useGuiGridPropsContext();
  const {
    setLoadingState, setData, pagingState, onRequestError,
    removeRequestError, sorting, queryParams
  } = useGuiGridStateContext();
  const request = useRequest();
  const isFirstRun = useRef(true);

  const performFetch = React.useCallback(async (queryString = '') => {
    setLoadingState((state) => (state === 'waiting' ? 'firstTimeLoading' : 'refreshing'));

    removeRequestError(PERFORM_FETCH);

    try {
      let requestResult: IGuiGridResponseEntity;

      queryString = new URLSearchParams({
        ...queryParams,
        ...{
          pageIndex: pagingState.pageIndex + 1,
          pageSize: pagingState.pageSize,
        }
      }).toString()

      queryString = `${fetchData}?${queryString}`

      if (typeof (fetchData) === 'function') {
        requestResult = await fetchData(queryString);
      } else if (typeof (fetchData) === 'string') {
        requestResult = await request.get(queryString);
      } else {
        throw new Error("Fetch not provided");
      }

      let list: IGuiGridDatum[] = requestResult!.result;
      let total: number = requestResult!.total_count;

      setData({ list, total });
    } catch (err) {
      onRequestError(PERFORM_FETCH, err, () => { performFetch(queryString); });
    } finally {
      setLoadingState('idle');
    }
  },
    /**
       * fetch intentionally skipped from dependencies since,
       * page always generates fetch without useCallback
      */
    [setLoadingState, queryParams, filterConfig, setData, pagingState, sorting, onRequestError, removeRequestError, onFilter, fetchData]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else if (serverSide) {
      performFetch();
    }
  }, [pagingState, filterConfig, queryParams]);

  const actionsContext = React.useMemo(() => ({
    performFetch,
    performRefresh: performFetch,
    exporter: {
      exportRef,
      performExcelExport,
      performPdfExport,
    }
  }), [performFetch]);

  return (
    <GuiGridActionsContext.Provider value={actionsContext}>
      {children}
    </GuiGridActionsContext.Provider>
  );
}

export default GuiGridFetchContainer
