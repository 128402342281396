import { useLocation, useParams } from "react-router";

const useGuiApiHelper = () => {
  const params: { [key: string]: any } = useParams();
  const { pathname } = useLocation();

  const mapParamsToString = (str: string) => {
    let res = str;
    Object.keys(params).forEach((key: string) => {
      res = res.replace(`{${key}}`, params[key]);
    });
    return res;
  };

  const mapParamsToPath = (url: string) => {
    let res = url;
    let location = "";
    if (typeof pathname === "string") {
      // if(url.substring(url.length-":location".length)===":location"){
      //   res = res + "/";
      // }  
      let pathUrl = pathname?.split("/");
      if (pathUrl.length >= 4 && pathUrl[1] === "devices") {
        location = pathUrl[3]
      }
    }
    res = res.replace(":location", location);
    // console.log("params",url,pathname);
    // // console.log(url);
    // const pathNames = pathname.split("/");
    // const urls = url.split("/");
    // const indexes: number[] = [];

    // urls.forEach((url: string, index: number) => {
    //   if (url.startsWith(":")) indexes.push(index);
    // });

    // if (indexes.length < 1) return url;

    // indexes.forEach((index) => (urls[index] = pathNames[index]));

    // console.log(urls.join("/"));
    return res;
  };

  return {
    mapParamsToString,
    mapParamsToPath,
  };
};

export default useGuiApiHelper;
