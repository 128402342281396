import React from 'react';
import { PagingState, CustomPaging, IntegratedPaging } from '@devexpress/dx-react-grid';
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { Plugin } from '@devexpress/dx-react-core';
import { useLang } from '@guibil/app';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { useGuiGridStateContext } from '../../contexts/GuiGridStateContext';
import { makeStyles } from '@material-ui/core';

const GuiGridPaging = () => {
  const classes = useStyles();
  const { paging, serverSide } = useGuiGridPropsContext();
  const { pagingState, setPagingState, data } = useGuiGridStateContext();
  const lang = useLang();
  const pageSizes = paging?.sizes || [10, 15, 20, 30, 40, 50, 0];

  if (paging?.hide) {
    return null;
  }

  const PagingContainer = (props: any) => (
    <PagingPanel.Container {...props} className={classes.paging} />
  );

  return (
    <Plugin>
      <PagingState
        currentPage={pagingState.pageIndex}
        pageSize={pagingState.pageSize}
        onCurrentPageChange={(pageIndex: number) => { setPagingState((data) => ({ ...data, pageIndex })); }}
        onPageSizeChange={(pageSize: number) => { setPagingState((data) => ({ ...data, pageIndex: 0, pageSize })); }}
      />
      {serverSide
        ? <CustomPaging totalCount={typeof (data.total) === 'number' ? data.total : 0} />
        : <IntegratedPaging />}
      <PagingPanel
        containerComponent={PagingContainer}
        pageSizes={pageSizes}
        messages={{
          showAll: lang('guibil:grid.showAll'),
          rowsPerPage: lang('guibil:grid.rowsPerPage'),
          info: (parameters: { from: number; to: number; count: number; }) => lang('guibil:grid.info', { ...parameters })
        }}
      />
    </Plugin>
  );
};

const useStyles = makeStyles(() => ({
  paging: {
    "& > div": {
      paddingRight: 20
    }
  }
}))

export { GuiGridPaging };
