import React, { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isNotNullOrUndefined, guiIsEqual, guiParseFloat } from '@guibil/helpers';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  Button, IconButton, makeStyles,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '@guibil/components';
import { commonCSS } from '@guibil/styles';
import { useLang } from '@guibil/app';
import { withGuiFormItem, IGuiFormElementCommonProps, IDefaultFormElementProps, IFormItemHelper } from '../hocs/withGuiFormItem';
import Inputmask from "inputmask";


export type MaskNormalizerType = | "phone" | "ip" | "mac";
export type TGuiFormItemMaskNumber = boolean | {
  decimalLimit?: number,
  allowNegative?: number,
};

interface IElementSpecificProps {
  min?: number,
  max?: number,
  step?: number,
  type?: | 'number' | 'password' | 'text',
  multiline?: boolean,
  inputProps?: any,
  measurementLabel?: string,
  maskNormalizer?: MaskNormalizerType,
  clearable?: boolean,
  placeholder?: string,
  nativeProps?: TextFieldProps,
  disableAutoComplate?: boolean,
  regexMask?: RegExp,
  helperMessages?: IFormItemHelper[],
  maxLength?: number
}

// since HOC transmits extra data, I need to extend it, in order get access to all props
type IProps = IElementSpecificProps & IDefaultFormElementProps;

function getInputMask(maskNormalizer?: MaskNormalizerType): any {
  switch (maskNormalizer) {
    case "phone":
      return { "data-inputmask": "'mask': '+\e9\e0 (999) 999 99 99', 'removeMaskOnSubmit': true, 'escapeChar': '\e'" };
    case "ip":
      return { ["data-inputmask-alias"]: "ip" }
    case "mac":
      return { ["data-inputmask-alias"]: "mac", };
    default:
      return {};
  }
}


const GuiText: React.FC<IProps> = (props) => {
  let {
    className, fieldRequired, fieldValue, fieldEditMode, fieldError,
    fieldOnChange, children, label, field, measurementLabel, clearable,
    placeholder, step, maskNormalizer, regexMask, type, nativeProps, helperMessages, maxLength,
    disableAutoComplate, multiline, ...otherProps
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const lang = useLang();
  const classes = useStyles();
  const inputRef = useRef<any>();

  measurementLabel = lang(measurementLabel || "");

  const disableScientificCharactersForNumberFields: KeyboardEventHandler = (e) => {
    var invalidChars = ["E", "e"]; //include "." if you only want integers
    if (invalidChars.includes(e.key) && type === "number") {
      e.preventDefault();
    }
  }

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: string | number = e.target.value;

    if (maxLength && newValue.length > maxLength) return;
    if (
      (type === 'number')
      && typeof (newValue) !== 'number' && isNaN(parseFloat(newValue)) === false
    ) {
      newValue = guiParseFloat(newValue);
    }

    if (maskNormalizer && inputRef.current) {
      const input = inputRef.current.querySelector("input");
      if (input && input.inputmask && maskNormalizer !== "mac") {
        if (!newValue) {
          newValue = "";
        } else {
          newValue = input.inputmask.unmaskedvalue();
        }
      }
    }

    if (regexMask && type === "text" && typeof newValue === "string") {
      if (regexMask.test(newValue)) newValue = newValue
      else return;
    }

    fieldOnChange(newValue);
  };

  useEffect(() => {
    if (maskNormalizer && inputRef.current) {
      Inputmask().mask(inputRef.current.querySelector("input"));
    }
  }, [maskNormalizer, inputRef.current])

  let value = fieldValue;
  if (typeof value === "number") value = value.toString();

  if (fieldEditMode) {
    return (
      <FormControl className={commonCSS.formItem} error={isNotNullOrUndefined(fieldError)}>
        <TextField
          ref={inputRef}
          name={field}
          value={value || ""}
          label={label}
          rowsMax={6}
          multiline={multiline}
          onChange={handleFieldChange}
          autoComplete={disableAutoComplate ? 'new-password' : ''}
          disabled={false}                                                                          /**Do not refactor this !! */
          className={classNames({ [commonCSS.editableDrop]: true, [commonCSS.editableDropDisabled]: otherProps.disabled, [commonCSS.requiredLabel]: fieldRequired, ...className })}
          error={isNotNullOrUndefined(fieldError)}
          placeholder={placeholder ? lang(placeholder) : ''}
          InputLabelProps={{ shrink: true }}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          onKeyDown={disableScientificCharactersForNumberFields}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <>
                <InputAdornment className={commonCSS.endAdornment} position="end">
                  {(clearable && fieldValue)
                    && (
                      <Button
                        className={commonCSS.formClearButton}
                        onClick={() => { fieldOnChange(''); }}
                      >
                        <FontAwesomeIcon icon="times-circle" />
                      </Button>
                    )}

                  {(type === 'password')
                    && (
                      <span className={commonCSS.showPassword}>
                        <IconButton
                          // className={commonCSS.formClearButton}
                          onClick={() => { setShowPassword((old) => !old); }}
                        >
                          <FontAwesomeIcon icon={showPassword ? 'eye-slash' : 'eye'} />
                        </IconButton>
                      </span>
                    )}

                  {(measurementLabel || null)}
                </InputAdornment>
              </>
            ),
          }}
          {...otherProps}
          {...nativeProps}
          inputProps={{
            step,
            ...otherProps.inputProps,
            ...getInputMask(maskNormalizer),

          }}
        >
          {children}
        </TextField>
        <FormHelperText className={commonCSS.formHelper}>{fieldError}</FormHelperText>
        {
          helperMessages?.map(({ text, variant }) => {
            return <FormHelperText
              error={variant === "danger"}
              key={text}
              className={classNames(commonCSS.formHelper, variant === "success" && classes.success, variant === "warning" && classes.warning)}
            >
              {lang(text)}
            </FormHelperText>
          })
        }
      </FormControl>
    );
  }

  return (
    <FormControl className={commonCSS.formItem} error={isNotNullOrUndefined(fieldError)}>
      <TextField
        ref={inputRef}
        name={field}
        value={value || ""}
        label={label}
        rowsMax={6}
        multiline={multiline}
        error={isNotNullOrUndefined(fieldError)}
        autoComplete={disableAutoComplate ? 'new-password' : ''}
        className={classNames({ [commonCSS.editableDrop]: true, [commonCSS.editableDropDisabled]: true, ...className })}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment className={commonCSS.endAdornment} position="end">
              {(measurementLabel || '')}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        type={type}
        {...otherProps}
        {...nativeProps}
        disabled
        inputProps={{
          ...otherProps.inputProps,
          ...getInputMask(maskNormalizer),
        }}
      >
        {children}
      </TextField>
      <FormHelperText className={commonCSS.formHelper}>{fieldError}</FormHelperText>
      {helperMessages?.map(({ text, variant }) => {
        return <FormHelperText
          error={variant === "danger"}
          key={text}
          className={classNames(commonCSS.formHelper, variant === "success" && classes.success, variant === "warning" && classes.warning)}
        >
          {lang(text)}
        </FormHelperText>
      })}
    </FormControl>
  );
};

const useStyles = makeStyles({
  underline: {
    '&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  success: {
    color: "var(--primarySuccess)"
  },
  warning: {
    color: "var(--primaryWarning)"
  }
});

const GuiTextComponent = withGuiFormItem<IElementSpecificProps & IGuiFormElementCommonProps>(
  React.memo(GuiText, (prevProps, nextProps) => guiIsEqual(prevProps, nextProps)),
);

export { GuiTextComponent as GuiText };
