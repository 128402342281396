import { IFileCategory } from '../shared-types';

export const FILE_TYPES_CONFIG: {
  [key in IFileCategory]: {
    allowedFiles: string[],
    // in bytes
    maxFileSize: number
  }
} = {
  image: {
    allowedFiles: ['image/jpg', 'image/jpeg', 'image/png', 'image/jfif', 'image/gif'],
    maxFileSize: 500 * 1000 * 1000,
  },
  avatar: {
    allowedFiles: ['image/jpg', 'image/jpeg', 'image/png', 'image/jfif', 'image/gif'],
    maxFileSize: 500 * 1000 * 1000,
  },
  pdf: {
    allowedFiles: ['application/pdf'],
    maxFileSize: 500 * 1000 * 1000,
  },
  video: {
    allowedFiles: ['video/mp4'],
    maxFileSize: 5000 * 1000 * 1000,
  },
  'image-video': {
    allowedFiles: ['image/jpg', 'image/jpeg', 'image/png', 'image/jfif', 'image/gif', 'video/mp4'],
    maxFileSize: 500 * 1000 * 1000,
  },
};
