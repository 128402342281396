import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';
import { useMemo } from 'react';

const useGuiGridNotSortableFields = () => {
  const { actionFields, hiddenSortFields } = useGuiGridPropsContext();

  const notSortableFields: string[] = useMemo(() => {
    const actionFieldsAr = (actionFields || []).map(({ targetField }) => targetField);
    const hiddenFields = hiddenSortFields ? hiddenSortFields : [];

    return [...actionFieldsAr, ...hiddenFields];
  }, [actionFields]);

  return notSortableFields;
}

export default useGuiGridNotSortableFields;
