import { installations_tr } from "../installations/locales/tr_TR";
import { installation_details_tr } from "../installations/installation-details/locales/tr_TR";
import { gids_tr } from "../gids/locales/tr_TR";
import { gid_display_tr } from "../gid-display/locales/tr_TR";
import { evaluation_tr } from "../evaluation/locales/tr_TR";

export const development_tr = {
  breadcrumbTitle: "Geliştirme",
  pageTitle: "Geliştirme",
  title: "Geliştirme",
  menuTitle: "Geliştirme",

  installations: installations_tr,
  installationDetails: installation_details_tr,
  gids: gids_tr,
  gidDisplay: gid_display_tr,
  evaluation: evaluation_tr,
};
