import { app_store_new_games_tr } from "../app-store/locales/tr_TR";
import { play_store_new_games_tr } from "../play-store/locales/tr_TR";


export const new_games_tr = {
  breadcrumbTitle: "Yeni Oyunlar",
  pageTitle: "Yeni Oyunlar",
  title: "Yeni Oyunlar",
  menuTitle: "Yeni Oyunlar",

  appStore: app_store_new_games_tr,
  playStore: play_store_new_games_tr,
};
