import React, { useContext } from 'react';


interface IAuthUser {
  isAuth: true,
  role: Exclude<IGuiApp.IAppUserRole, 'GUEST'>,
  logout: () => void,
  checkAuth: () => void,
  detail: {
    username: string,
    fullname: string,
  },
}

interface IUnauthUser {
  isAuth: false,
  role: 'GUEST',
  login: (accessToken: string, refreshToken: string) => void,
}
export type IUserContext = IAuthUser | IUnauthUser

const UserContext = React.createContext<IUserContext | undefined>(undefined);

const useUser = (): IUserContext => {
  const context = useContext(UserContext);
  if (!context) {
    return {
      isAuth: false,
      role: 'GUEST',
      login: () => alert('Please wrap your component with GuiAppProvider'),
    };
  }

  return context;
};

export { UserContext, useUser };
