export const play_store_new_games_tr = {
  title: "Yeni Oyunlar",

  object_order: "Sıra",
  game: {
    logo_image_url: "Logo",
    name: "Adı",
    developer: "Yapımcı",
    rate: "Puan",
    store_rate: "Mağaza Puanı",
    release_date: "Yayın Tarihi",
    last_release_date: "Son Yayın Tarihi",
    follow: "Takip",
  },

  filter: {
    filter: "Filtrele",
    name: "Ad",
    previous_datetime: "Tarih",
    chart_genre: "Kategori",
    chart_type: "Tip",
  },
};
