import { IUseRequestErrors } from '@guibil/api/types';
import { GuiEventSystem } from '@guibil/app';
import React, { useContext } from 'react';
import { IGuiFormLoadingState, IGuiFormState } from '../types';

export interface IGuiFormStateContext extends IUseRequestErrors {
  data: any,
  setData: React.Dispatch<React.SetStateAction<any>>,
  formState: IGuiFormState,
  setFormState: React.Dispatch<React.SetStateAction<IGuiFormState>>,
  loadingState: IGuiFormLoadingState,
  setLoadingState: React.Dispatch<React.SetStateAction<IGuiFormLoadingState>>,
  fieldsToDismiss: string[],
  setFieldsToDismiss: React.Dispatch<React.SetStateAction<string[]>>,
  isSubmitting: boolean,
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  guiEventSystem: GuiEventSystem,
}

export const GuiFormStateContext = React.createContext<IGuiFormStateContext | undefined>(undefined);

export const useGuiFormStateContext = () => {
  const context = useContext(GuiFormStateContext);
  if (typeof (context) === 'undefined') {
    throw new Error('Please useGuiFormStateContext inside GuiForm');
  }

  return context;
};
