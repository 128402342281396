import React from 'react';
import { CircularProgress, makeStyles, Paper } from '@material-ui/core';
import { Column } from '@devexpress/dx-react-grid';
import { classNames, GuiRequestError } from '@guibil/components';
import { getUserPermissionForAction } from '@guibil/helpers';
import { useLang, useUser } from '@guibil/app';
import { useGuiGridPropsContext } from '../contexts/GuiGridPropsContext';
import { GuiGridTitle } from '../components/title/GuiGridTitle';
import { GuiGridButtons } from '../components/buttons/GuiGridButtons';
import { useGuiGridStateContext } from '../contexts/GuiGridStateContext';
import { GuiGridPaging } from '../behaviors/paging/GuiGridPaging';
import { GuiGridSorting } from '../behaviors/sorting/GuiGridSorting';
import { GuiGridGrouping } from '../behaviors/grouping/GuiGridGrouping';
import { GuiGridTable } from '../behaviors/table/GuiGridTable';
import { GuiGridRowDetail } from '../behaviors/row-detail/GuiGridRowDetail';
import { GuiGridGroupingConfiguration } from '../behaviors/grouping/GuiGridGroupingConfiguration';
import { GuiGridFiltersConfiguration } from '../behaviors/filter/GuiGridFiltersConfiguration';
import { GuiGridCustomizingFields } from '../behaviors/customizing-fields/GuiGridCustomizingFields';
import { GuiGridCore } from '../behaviors/grid/GuiGridCore';
import GuiGridExporter from '../behaviors/excel/GuiGridExporter';

interface IProps { }

const GuiGridCoreContainer: React.FC<IProps> = (props): any => {
  const {
    doNotRenderIfEmpty,
    roles, labelPath, columns, actionFields, classes, enableGrouping, dragging, headerComponent
  } = useGuiGridPropsContext();
  const { requestErrors, loadingState, data, } = useGuiGridStateContext();
  const lang = useLang();
  const { role } = useUser();
  const { gridContainer, groupingGridContainer, ...defaultClasses } = useStyles();

  const isLoaded = loadingState !== 'waiting' && loadingState !== 'firstTimeLoading';
  const isLoading = loadingState === 'firstTimeLoading' || loadingState === 'refreshing';

  const gridColumns = React.useMemo((): Column[] => {
    /**
     * Processes, columns.
     * If column is string, then it will be converted to {name: "string"},
     * let {columnName, title, labelPath} = column
     *
     * typeof(column) ==="string"     -> { name: column }
     * typeof(title) !== "undefined"  -> lang(title)
     * typeof(title) === "string"     -> lang("string")
     * typeof(title) === "undefined" && typeof(labelPath) === "string" -> lang(labelPath.columnName)
     * */
    const newColumns: Column[] = columns.map(columnItem => {
      let name = "", title = "";

      if (typeof (columnItem) === "string") {
        name = columnItem;
        title = lang(labelPath ? `${labelPath}.${name}` : name);
      } else {
        name = columnItem.name;
        title = lang(columnItem.title || columnItem.name);
        if (columnItem.title === '') { title = ' '; }
      }

      return { name, title }
    })

    // ##########     Removes action columns    ##########
    const columnsToRemove: string[] = [];

    // removes Action columns if empty (if authuser does not have any permission on that column)
    (actionFields || []).forEach(({ targetField }) => {
      let actionExists = false;
      if (getUserPermissionForAction(role, roles, targetField)) {
        actionExists = true;
      }

      if (!actionExists) { columnsToRemove.push(targetField); }
    });
    const filteredColumns = newColumns.filter((column) => !columnsToRemove.includes(column.name));
    // ##########     End of Removes action columns    ##########


    return filteredColumns;
  }, [columns, roles, labelPath, actionFields, lang]);

  if (requestErrors.length > 0) {
    return (
      <div>
        <GuiGridTitle />
        <GuiRequestError error={requestErrors[0]} />
      </div>
    );
  }

  // ##########    Drag Column    ##########
  if (dragging?.enable) gridColumns.unshift({ name: "drag", title: " " });
  // ##########    End of Drag Column    ##########

  if (isLoaded && data.total === 0 && doNotRenderIfEmpty) {
    return doNotRenderIfEmpty === true ? null : doNotRenderIfEmpty;
  }

  return (
    <div id="grid" className={classNames(defaultClasses.gridWrapper, isLoading && defaultClasses.loadingGrid)}>
      <div className={classNames(defaultClasses.headerContainer, classes?.headerContainer)}>
        <GuiGridTitle />
        <div className={classNames(defaultClasses.buttons, classes?.buttons)}>
          <GuiGridButtons />
        </div>
        {headerComponent}
      </div>
      <Paper classes={{ root: classNames(defaultClasses.paper, enableGrouping ? groupingGridContainer : gridContainer) }}>
        <GuiGridCore
          gridColumns={gridColumns}
        >
          {/* <GuiGridFilters gridColumns={gridColumns} /> */}
          <GuiGridCustomizingFields />
          <GuiGridPaging />
          <GuiGridSorting />
          <GuiGridGrouping />
          <GuiGridExporter columns={gridColumns} />
          {
            isLoading && <div className={defaultClasses.loading}>
              <CircularProgress disableShrink />
            </div>
          }
          <GuiGridTable gridColumns={gridColumns} />
          <GuiGridRowDetail />
          <GuiGridGroupingConfiguration />
          <GuiGridFiltersConfiguration />

          {props.children}
        </GuiGridCore>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  loading: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& svg": {
      color: "var(--titleColor) !important"
    }
  },
  gridWrapper: {
    position: 'relative',
    backgroundColor: "var(--bgPrimary)",
    padding: "16px 24px",
    borderRadius: "2px",
    minWidth: 400,
  },
  loadingGrid: {
    opacity: 0.6,
  },
  paper: {
    boxShadow: "none",
    backgroundColor: "var(--bgPrimary)",
    "& > div > div:nth-child(2)": {
      "& div": {
        "& *": {
          color: "var(--titleColor)",
        }
      }
    }
  },
  gridContainer: {
    '& .MuiTableContainer-root': {
      "&::-webkit-scrollbar-track": {
        borderRadius: 2,
        backgroundColor: "var(--primaryGridBorderColor2)",
        marginTop: 69,
      },

      "&::-webkit-scrollbar": {
        width: 10,
        borderRadius: 2,
        backgroundColor: "transparent",
      },

      "&::-webkit-scrollbar-thumb ": {
        backgroundColor: "var(--paperColor)",
        borderRadius: 2
      }
    },
    "& > div > div:nth-child(2)": {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  groupingGridContainer: {
    "& .MuiToolbar-root": {
      border: "2px solid var(--primaryGridBorderColor)",
      borderRadius: 4,
      fontStyle: "italic",
      minHeight: "40px !important"
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttons: {
    display: 'flex',
    padding: "8px 16px",
    justifyContent: 'flex-end',
    '& button': {
      color: 'var(--titleColor) !important',
    },
  },
}));

export { GuiGridCoreContainer };
