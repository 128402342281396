import React, { useContext } from 'react';
import { IGuiGridProps } from '../types';

export const GuiGridPropsContext = React.createContext<IGuiGridProps | undefined>(undefined);

/**
 * if formElement is controlled, then it must not require any form context
 *
 * @param controlled
 */
export const useGuiGridPropsContext = () => {
  const context = useContext(GuiGridPropsContext);
  if (typeof (context) === 'undefined') {
    throw new Error('Please useGuiGridPropsContext inside GuiForm');
  }

  return context;
};
