export const routes_en = {
  app: "Marker Games",
  controlPanel: "Control Panel",
  generalView: "General View",
  configuration: "Configuration",
  monitoring: "Monitoring",
  alarm: "Alarm",
  settings: "Settings",
  about: "About",
  account: "Komutan",
  interface: "Interface",

  settingsMenu: {
    theme: "Theme",
    about: "About",
    language: "Language",
  },
  accountMenu: {
    profile: "Profile",
    userManagement: "User Management",
    logUserManagement: "Log User Management",
    logout: "Logout",
  },

  close: {
    title: "Close",
    deviceClose: "Close Device",
    closeConfirm: "If you continue, the device will be closed. Are you sure you want to continue?"
  }
};
