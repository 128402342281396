import { app_store_game_details_tr } from "../app-store/locales/tr_TR";
import { play_store_game_details_tr } from "../play-store/locales/tr_TR";


export const game_details_tr = {
  breadcrumbTitle: "Oyun Detayları",
  pageTitle: "Oyun Detayları",
  title: "Oyun Detayları",
  menuTitle: "Oyun Detayları",

  appStore: app_store_game_details_tr,
  playStore: play_store_game_details_tr,
};
