export const app_store_new_games_en = {
  title: "New Games",

  object_order: "Index",
  follow: "Follow",
  game: {
    logo_image_url: "Logo",
    name: "Name",
    artist: "Artist",
    rate: "Rate",
    store_rate: "Store Rate",
    release_date: "Release Date",
    last_release_date: "Last Release Date",
  },

  filter: {
    filter: "Filter",
    name: "Name",
    date_switch: "Date Filter",
    date_switch_date: "By Date",
    date_switch_between: "By Date Range",
    created_datetime: "Date",
    min_created_datetime: "Min Date",
    max_created_datetime: "Max Date",
  },
};
