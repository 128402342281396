import { useLang } from '@guibil/app';
import { useGuiFormPropsContext } from '@guibil/form/contexts/GuiFormPropsContext';
import { useGuiFormStateContext } from '@guibil/form/contexts/GuiFormStateContext';
import { useFormikContext } from 'formik';
import React from 'react';
import { Prompt } from 'react-router-dom';

const GuiFormPromptLeave = () => {
  const formik = useFormikContext();
  const lang = useLang();
  const { disableLeaveFormPrompt } = useGuiFormPropsContext();
  const { formState } = useGuiFormStateContext();

  if (disableLeaveFormPrompt) { return null; }
  return (
    <Prompt
      when={formState === 'updating' && formik.dirty}
      message={lang('guibil:form.confirmation.leavingUnsaved')}
    />
  );
}

export default GuiFormPromptLeave;
