import { GuiApp } from '@guibil/app';
import { useTranslation } from 'react-i18next';
import { GuiBrowserRouter } from '@guibil/router';
import { GuiNotificationProvider } from '@guibil/components';
import { SnackbarProvider } from 'notistack';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { GuiThemeProvider } from '@guibil/styles';
import { theme } from 'theme/theme';
import { GuiAppProvider } from '@guibil/app/providers/GuiAppProvider';
import { enumsCollection } from 'app/enums/enums-collection';
import './app/lang/translation';
import './app/validation/setup';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import AppContainer from 'app/AppContainer';
import { StoreProvider } from 'app/components/appFrame/includes/store/StoreProvider';

library.add(fas);

// const API_ROOT_URL = 'http://localhost:8000/api';
const API_ROOT_URL = 'https://api.marker.games/api';

GuiApp.setConfig({ API_ROOT_URL });

function App() {
  const trans = useTranslation();
  const generateClassName = createGenerateClassName({ productionPrefix: "game_tracker" });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <GuiThemeProvider theme={theme}>
        <GuiAppProvider lang={trans.t} enumsCollection={enumsCollection}>
          <SnackbarProvider>
            <StoreProvider>
              <GuiNotificationProvider />
              <GuiBrowserRouter>
                <AppContainer />
              </GuiBrowserRouter>
            </StoreProvider>
          </SnackbarProvider>
        </GuiAppProvider>
      </GuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
