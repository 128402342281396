import React from 'react'

const User = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.15" height="26.794" viewBox="0 0 24.15 26.794">
      <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-4.5 -3)">
        <path id="Path_26" data-name="Path 26" d="M27.15,30.431V27.787A5.287,5.287,0,0,0,21.862,22.5H11.287A5.287,5.287,0,0,0,6,27.787v2.644" transform="translate(0 -2.138)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
        <path id="Path_27" data-name="Path 27" d="M22.575,9.787A5.287,5.287,0,1,1,17.287,4.5a5.287,5.287,0,0,1,5.287,5.287Z" transform="translate(-0.713 0)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
      </g>
    </svg>
  )
}

export default User
