export const guibil_components_tr = {
  close: "Kapat",
  guiRefreshButton: {
    text: "Yenile",
  },
  confirmation: {
    title: "Onay",
    confirm: "Tamam",
    cancel: "İptal",
  },
  genericDialog: {
    loading: "Yükleniyor...",
    close: "Kapat",
  },
  loadingDialog: {
    title: "Yükleniyor",
    description: "Lütfen Bekleyin...",
  },
  locationPicker: {
    title: "Konum Seç",
    getCurrentLocation: "Yaklaşık Konumumu Bul",
    pickLocation: "Tamam",
    province: "İl",
    suburb: "Mahalle",
    district: "İlçe",
    center: "Merkez",
    region: "Bölge",
    longitude: "Boylam",
    latitude: "Enlem",
    warning:
      "Uyarı: Bu özellik gerçek konumunuzu göstermez, yalnızca yaklaşık bir değerdir.",
  },
  requestError: {
    title: "İstek Hatası",
    button: "Tekrar Dene",
  },
  timeInterval: {
    title: "Yenileme Süresi (sn)",
    second: "Saniye",
  },
  loading: {
    dots: "Yükleniyor...",
    actionPerforming: "Lütfen Bekleyin...",
  },
  switchText: {
    active: "Aktif",
    passive: "Pasif",
    enabled: "Devrede",
    disabled: "Devre Dışı",
    on: "Açık",
    off: "Kapalı",
    yes: "Evet",
    no: "Hayır",
    published: "Yayında",
    draft: "Taslak",
    invalid: "Geçersiz",
    exists: "Var",
    notExists: "Yok",
    registered: "Kayıtlı",
    notRegistered: "Kayıtsız",
    defined: "Tanımlı",
    notDefined: "Tanımsız"
  },
};
