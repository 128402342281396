import { useRequest } from '@guibil/api'
import useGuiApiHelper from '@guibil/api/pack/map-route-params'
import { GuiForm } from '@guibil/form'
import { IGuiFormData, IGuiFormProps } from '@guibil/form/types'
import React, { Children, isValidElement, useEffect, useState } from 'react'
import { GuiFormGridAdapter } from './GuiFormGridAdapter'
import { makeStyles } from '@material-ui/core'
import { IGuiButtonProps } from '@guibil/components'
import GuiFormGridHeader from './GuiFormGridHeader'
import GuiFormGridRow from './GuiFormGridRow'

/*
  TODO FOR IMPROVEMENTS
  1) Add extra column order. For now it only adds at the en of the table.
*/
export type FormGridSpecificProps = {
  submitAs?: string;
  gridResponseName?: string;
  /** Validation for the form part of the grid. Expects a simple validation object ** NOT a Yup object ** Yup.object().shape... etc */
  formValidationSchema?: any;
  /** Takes rows data and it's index then returns props object for the field */
  extraFieldProps?: {
    [key: string]: (rowData: any, index: number) => any;
  },
  columnExtension?: {
    [key: string]: {
      width?: number;
      columnAlign?: "left" | "right" | "center"
      headerAlign?: "left" | "right" | "center"
    }
  },
  extraColumns?: {
    name: string;
    component: (rowData: any, index: number) => any;
  }[];
  /** Provides custom row data to sync with when you use formData.setValues */
  formData?: IGuiFormData;
  actionFieldArr?: IGuiButtonProps[],
  beforeSubmit?: (values: any) => any;
  labelField?: string;
}

type IGuiFormGridProps = IGuiFormProps & FormGridSpecificProps;

const GuiFormGrid: React.FC<IGuiFormGridProps> = (props) => {
  const req = useRequest();
  const classes = useClasses();
  const { submitAs, gridResponseName, formData } = props;
  const { mapParamsToString } = useGuiApiHelper();
  const [rows, setRows] = useState<any>([]);

  const fetchData = async () => {
    let res: any = null;

    if (typeof props.fetchData === "function") {
      res = await props.fetchData();
    } else {
      res = await req.get(mapParamsToString(props.fetchData));
    }

    setRows(gridResponseName ? res[gridResponseName] : res);

    return {
      ...res
    };
  }

  const beforeSubmit = (values: any) => {
    let data = GuiFormGridAdapter.convertFormDataToGridSubmitData(gridResponseName ? values[gridResponseName] : values);

    if (props.beforeSubmit) return props.beforeSubmit(values);

    if (submitAs) {
      return { [submitAs]: data }
    } else {
      return values;
    }
  }

  useEffect(() => {
    if (formData?.values && (!gridResponseName || formData?.values[gridResponseName])) {
      const data = GuiFormGridAdapter.convertFormDataToGridSubmitData(gridResponseName ? formData?.values[gridResponseName] : formData?.values);
      if (data[data.length - 1] && data[data.length - 1].self) data.pop(); // check if it is _links and remove it. we do not want to set it as rows data
      setRows(data);
    }
  }, [formData?.values])


  let validation = GuiFormGridAdapter.generateValidationSchema(props.validationSchema, props.formValidationSchema, rows.length, gridResponseName);
  let chainedFields = GuiFormGridAdapter.generateChainedFields(props.chainedFieldsAr, rows.length);

  return (
    <GuiForm {...props} fetchData={fetchData} beforeSubmit={beforeSubmit} validationSchema={validation} chainedFieldsAr={chainedFields}>
      {/* We render what we have first external component */}
      {Children.map(props.children, ((child) => {
        if (isValidElement(child) && child && !child?.props.field) {
          return child
        }
      }))}

      <table>
        <tbody className={classes.body}>
          <GuiFormGridHeader {...props} />
          {/* Then we loop what direct inputs we have as row times, for grid */}
          <GuiFormGridRow {...props} rows={rows} />
        </tbody>
      </table>

    </GuiForm >
  )
}


const useClasses = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    backgroundColor: "var(--activeTextColor)",
    minHeight: 49,
    borderRadius: 6,
    marginBottom: 2,
    "& > td": {
      flex: 1,
      marginRight: 6,
      position: "relative",
      marginLeft: 6,
      "& > div": {
        marginTop: -17,
        "& input": {
          fontSize: "12px !important",
          fontWeight: 600,
          height: 32,
          color: "var(--defaultTextColor) !important"
        },
        "& > div > .MuiInputBase-root": { // dropdown
          fontWeight: 600,
          color: "var(--defaultTextColor) !important",
          height: 33,
          marginTop: 20
        }
      },
      "& fieldset": {
        "& > div": {
          height: 32,
          border: "none !important",
          backgroundColor: "transparent !important",
          "& > span": {
            fontSize: "12px !important",
            fontWeight: 600,
            color: "var(--defaultTextColor) !important"
          }
        }
      }
    }
  },
  labelField: {
    maxWidth: 135,
    "& div > input": {
      border: "none !important",
      backgroundColor: "transparent !important"
    }
  },
  headerRow: {
    backgroundColor: "var(--bgColor)",
    paddingBlock: 7,
    paddingBottom: 9,
    minHeight: "unset",
    marginBottom: -5,
    "& > td": {
      display: "flex",
      alignItems: "center",
      fontSize: 12,
      fontWeight: 600,
      color: "var(--defaultTextColor) !important"
    }
  }
})

export { GuiFormGrid }
