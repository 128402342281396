import React from 'react';
import { GuiButton, GuiGenericDialog } from '@guibil/components';
import { GuiForm, GuiText, useGuiFormData } from '@guibil/form';
import { Grid } from '@material-ui/core';
import validationSchema from './validation';
import { generatePassword } from '../create/UserCreate';

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
  id: string;
  username: string;
}
const UserPasswordUpdate: React.FC<IProps> = (props) => {
  const { onClose, onSuccess, id, username } = props;
  const [data, setData] = useGuiFormData();

  const fillRandomPassword = () => {
    const randPass = generatePassword();

    data?.setValues({
      username: username,
      password: randPass,
      password_confirm: randPass,
    })
  }

  return (
    <GuiGenericDialog isOpen title="gameTracker.logUserManagement.updatePassword.title" onClose={onClose}>
      <GuiForm
        fetchData={() => ({
          username,
          password: "",
          password_confirm: ""
        })}
        type="new-form"
        setData={setData}
        isDialogForm
        config={{
          submitDataApi: 'users/log_user/' + id,
          successMessage: "gameTracker.logUserManagement.updatePassword.successMessage",
          submitType: "post"
        }}
        labelPath="gameTracker.logUserManagement.updatePassword"
        validationSchema={validationSchema}
        successListener={onSuccess}
        onCancel={onClose}
        fieldsToDismissFromPost={["username"]}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GuiText field="username" disabled disableAutoComplate />
          </Grid>

          <Grid item xs={12}>
            <br />
          </Grid>

          <Grid item xs={12}>
            <GuiButton
              onClick={fillRandomPassword}
              text="gameTracker.logUserManagement.updatePassword.randomPassword"
              variant="success"
              icon="random"
            />
          </Grid>

          <Grid item xs={12}>
            <GuiText field="password" type="password" disableAutoComplate />
          </Grid>
          <Grid item xs={12}>
            <GuiText field="password_confirm" type="password" disableAutoComplate />
          </Grid>
        </Grid>
      </GuiForm>
    </GuiGenericDialog>
  );
};

export default UserPasswordUpdate;