import { app_store_random_games_tr } from "../app-store/locales/tr_TR";
import { play_store_random_games_tr } from "../play-store/locales/tr_TR";


export const random_games_tr = {
  breadcrumbTitle: "Rastgele Oyunlar",
  pageTitle: "Rastgele Oyunlar",
  title: "Rastgele Oyunlar",
  menuTitle: "Rastgele Oyunlar",

  appStore: app_store_random_games_tr,
  playStore: play_store_random_games_tr,
};
