import React from 'react';
import { GuiGenericDialog } from '@guibil/components';
import { GuiForm, GuiText } from '@guibil/form';
import validationSchema from "./validation";

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
}
const AddGenreDialog: React.FC<IProps> = (props) => {
  const { onClose, onSuccess } = props;
  const fetchData = () => ({ genre: "" });

  return (
    <GuiGenericDialog
      isOpen
      size="sm"
      title="gameTracker.development.gids.genres.addGenre.title"
      onClose={onClose}>
      <GuiForm
        type="new-form"
        fetchData={fetchData}
        isDialogForm
        config={{
          submitDataApi: 'gids/genre/add_genre',
        }}
        validationSchema={validationSchema}
        labelPath="gameTracker.development.gids.genres.addGenre"
        successListener={onSuccess}
      >
        <GuiText field="genre" />
      </GuiForm>
    </GuiGenericDialog>
  );
};

export default AddGenreDialog;
