import { useLang } from '@guibil/app';
import { classNames, ISwitchText, useSwitchText } from '@guibil/components';
import { guiIsEqual } from '@guibil/helpers';
import { commonCSS } from '@guibil/styles';
import { FormHelperText, makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import React, { FunctionComponent } from 'react';
import {
  IDefaultFormElementProps,
  IGuiFormElementCommonProps, withGuiFormItem
} from '../hocs/withGuiFormItem';
import { GuiText } from './GuiText';

interface IElementSpecificProps {
  activeText?: string;
  passiveText?: string;
  /** switchText - uses custom active/passive text translation, can be overwritten  */
  switchText?: ISwitchText,
  // to use simple switch style
  simpleSwitch?: boolean;
  measurementLabel?: string;
  nativeProps?: SwitchProps,
  hideOnFirtload?: boolean
}

// since HOC transmits extra data, I need to extend it, in order get access to all props
type IProps = IElementSpecificProps & IDefaultFormElementProps;

const GuiSwitch: FunctionComponent<IProps> = (props) => {
  let {
    className, fieldValue, fieldEditMode, fieldError,
    // eslint-disable-next-line unused-imports/no-unused-vars
    fieldOnChange, field, measurementLabel,
    label, simpleSwitch,
    switchText, activeText, passiveText, nativeProps, disabled, hideOnFirtload, helperMessages
  } = props;

  const switchLabel = useSwitchText(fieldValue, switchText, { activeText, passiveText });
  const lang = useLang();
  const classes = useStyles();

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    fieldOnChange(checked);
  };

  if (typeof fieldValue !== 'boolean' && !hideOnFirtload) {
    /**
     * It may occur in first loads, when value is not boolean.
     * If value is not boolean, it's left in order to
     * find out field with missing content in form
     * */
    return (
      <GuiText
        controlled
        disabled
        field={field}
        label={label}
        value=""
        editMode={false}
        className={className}
      />
    );
  }

  // Edit mode
  // if (fieldEditMode) {
  return (
    <fieldset
      className={classNames(simpleSwitch ? commonCSS.switchOutlineSimple : commonCSS.switchOutline, (disabled || !fieldEditMode) && commonCSS.disabledSwitch, className)
      }
    >
      <legend>{label}</legend>
      <div className={classNames(commonCSS.switchInput, classes.switch)}>
        <FormControlLabel
          value="bottom"
          className={commonCSS.inputSwitch}
          control={(
            <div>
              <Switch
                checked={!!fieldValue}
                name={field}
                onChange={handleFieldChange}
                classes={{ root: commonCSS.switchRoot, track: commonCSS.switchTrack, thumb: commonCSS.switchThumb, checked: commonCSS.checkedSwitch }}
                color="primary"
                disabled={disabled || !fieldEditMode}
                {...nativeProps}
              />
            </div>
          )}
          label=""
          labelPlacement="bottom"
        />

        <span className={commonCSS.switchValue}>
          {switchLabel}
        </span>
      </div>
      <span>{fieldError}</span>
      {helperMessages?.map(({ text, variant }) => {
        return <FormHelperText
          error={variant === "danger"}
          key={text}
          className={classNames(variant === "success" && classes.success, variant === "warning" && classes.warning)}
        >
          {lang(text)}
        </FormHelperText>
      })}
    </fieldset>
  );
  // }

  // View mode
  // return (
  //   <GuiText
  //     controlled
  //     disabled
  //     field={field}
  //     measurementLabel={measurementLabel}
  //     label={label}
  //     value={switchLabel}
  //     editMode={false}
  //     className={className}
  //   />
  // );
};

const useStyles = makeStyles({
  switch: {
    "& .MuiIconButton-label": {
      marginTop: -1
    }
  },
  success: {
    color: "var(--primarySuccess)"
  },
  warning: {
    color: "var(--primaryWarning)"
  }
});

const GuiSwitchComponent = withGuiFormItem<
  IElementSpecificProps & IGuiFormElementCommonProps
>(
  React.memo(GuiSwitch, (prevProps, nextProps) => guiIsEqual(prevProps, nextProps)),
);

export { GuiSwitchComponent as GuiSwitch };
