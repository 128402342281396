import { app_store_artist_details_en } from "../app-store/locales/en_US";
import { play_store_developer_details_en } from "../play-store/locales/en_US";


export const maker_details_en = {
  breadcrumbTitle: "Maker Details",
  pageTitle: "Maker Details",
  title: "Maker Details",
  menuTitle: "Maker Details",

  appStore: app_store_artist_details_en,
  playStore: play_store_developer_details_en,
};
