import {
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core/styles';

const TableComponent = ({ ...restProps }) => {
  const classes = useStyles();

  return (
    <Table.Table
      {...restProps}
      className={classes.tableComponent}
    />
  );
};

const useStyles = makeStyles(() => ({
  tableComponent: {
  },
}));

export { TableComponent };
