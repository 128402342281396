import React, { useState } from 'react';
import { GuiGenericDialog, guiNotifier } from '@guibil/components';
import { GuiGrid, useGuiGridHook } from '@guibil/grid';
import GuiGridRowButton from '@guibil/grid/components/buttons/GuiGridRowButton';
import { useRequest } from '@guibil/api';
import AddGenreDialog from './AddGenreDialog';

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
}
const GenresDialog: React.FC<IProps> = ({ onClose, onSuccess }) => {
  const req = useRequest();
  const [hook, setHook] = useGuiGridHook();
  const [showAddDialog, setShowAddDialog] = useState(false);

  const Actions = (row: any) => {
    const handleDelete = async () => {
      await req.delete("gids/genre/delete_genre/" + row.row.id)
        .then(async () => {
          hook?.fetch();
          await onSuccess();
          guiNotifier().success("gameTracker.development.gids.genres.deleteSuccessMessage");
        })
        .catch(err => guiNotifier().handleError(err))
    };
    const onDeleteClick = () => guiNotifier().confirm({ description: "gameTracker.development.gids.genres.deleteConfirmMessage" }, handleDelete)

    return <GuiGridRowButton onClick={onDeleteClick} type="delete" />
  }

  return (
    <GuiGenericDialog
      isOpen
      size="md"
      onClose={onClose}>
      <>
        <GuiGrid
          setHook={setHook}
          fetchData={"gids/genre/genres"}
          columns={["genre", "actions"]}
          title="gameTracker.development.gids.genres.title"
          labelPath="gameTracker.development.gids.genres"
          customCells={[{
            component: Actions,
            targetFields: ["actions"]
          }]}
          columnExtensions={[{
            columnName: "actions",
            width: 60
          }]}
          serverSide={false}
          extraButtons={[{
            onClick: () => setShowAddDialog(true),
            text: "gameTracker.development.gids.genres.add",
            icon: "plus-circle",
            variant: "success"
          }]}
        />
        {
          showAddDialog && <AddGenreDialog
            onSuccess={async () => {
              hook?.fetch();
              await onSuccess();
              setShowAddDialog(false);
            }}
            onClose={() => setShowAddDialog(false)} />
        }
      </>
    </GuiGenericDialog>
  );
};

export default GenresDialog;
