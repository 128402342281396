import React from 'react';
import { getApiURL, isAbsolutePath } from '@guibil/api';
import { FILE_TYPES_CONFIG, IFileCategory } from '@guibil/app';
import { convertToArray } from '@guibil/helpers';
import { GuiVideoViewer } from './GuiVideoViewer';
import { GuiPdfViewer } from './GuiPdfViewer';
import { GuiImageViewer } from './GuiImageViewer';
import { GuiAvatarViewer } from './GuiAvatarViewer';

function isFileOfType(type: IFileCategory, path: string) {
  const allowedFiles = FILE_TYPES_CONFIG[type].allowedFiles;
  return allowedFiles.some((type) => {
    const extension = type.substr(type.indexOf('/'));
    return path.includes(`.${extension}`);
  });
}

interface IProps {
  category: IFileCategory,
  viewApi: string,
  filePath: string | string[],
  onFileRemove?: (path: string) => void,
}

export const GuiFileViewer: React.FC<IProps> = (props) => {
  const {
    category, filePath, viewApi, ...otherProps
  } = props;
  const filePathAr = convertToArray(filePath);

  const getPath = (path: string) => {
    if (isAbsolutePath(path)) { return path.replace(/:\//, "://"); }
    return getApiURL(`${viewApi}?fileId=${path}`);
  }

  const ViewerComponentFactory = (path: string) => {
    if (category === 'image') { return GuiImageViewer; }
    if (category === 'avatar') { return GuiAvatarViewer; }
    if (category === 'pdf') { return GuiPdfViewer; }
    if (category === 'video') { return GuiVideoViewer; }
    if (category === 'image-video') {
      if (isFileOfType('image', path)) { return GuiImageViewer; }
      if (isFileOfType('video', path)) return GuiVideoViewer;
    }
    return null;
  };

  const ViewerComponent = ViewerComponentFactory(filePathAr[0] || '');

  if (ViewerComponent) {
    return (
      <>
        {filePathAr.map((path) => (
          <span
            key={path}
          >
            <ViewerComponent
              src={getPath(path)}
              {...otherProps}
            />
          </span>
        ))}
      </>
    );
  }
  return <div>Unsupported viewer</div>;
};
