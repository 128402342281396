import { Plugin } from '@devexpress/dx-react-core';
import { GroupingPanel, TableGroupRow, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { useLang } from '@guibil/app';
import React from 'react';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';

const ToolbarRoot = ({ ...restProps }) => (
  <Toolbar.Root {...restProps} />
);

const GuiGridGroupingConfiguration = () => {
  const { enableGrouping } = useGuiGridPropsContext();
  const lang = useLang();

  if (!enableGrouping) {
    return null;
  }
  return (
    <Plugin>
      <TableGroupRow />
      <Toolbar rootComponent={ToolbarRoot} />
      <GroupingPanel
        showGroupingControls
        messages={{ groupByColumn: lang('guibil:grid.groupByColumn') }}
      />
    </Plugin>
  );
};

export { GuiGridGroupingConfiguration };
