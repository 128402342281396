import { useLang } from '@guibil/app';
import { commonCSS } from '@guibil/styles';
import React from 'react';

interface IProps {
  title?:  string | React.ElementType;
}
const GuiFormTitle: React.FC<IProps> = (props): JSX.Element => {
  const { title: Title } = props;
  const lang = useLang();

  if (!Title) { return <></>; }
  if (typeof (Title) === 'string') {
    return <h1 className={commonCSS.guiFormTitle}>{lang(Title)}</h1>;
  }
  return <Title />;
};

export { GuiFormTitle };
