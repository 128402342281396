import React from 'react';
import { GuiGenericDialog } from '@guibil/components';
import {
  GuiForm, GuiText,
} from '@guibil/form';
import { Grid } from '@material-ui/core';

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
}
const TagCreateDialog: React.FC<IProps> = (props) => {
  const { onClose, onSuccess } = props;
  const fetchData = () => ({
    tag: "",
  });

  return (
    <GuiGenericDialog isOpen title="gameTracker.games.playStore.games.tags.create.title" onClose={onClose}>
      <GuiForm
        fetchData={fetchData}
        type="new-form"
        isDialogForm
        config={{
          submitDataApi: 'ps_games/add_game_tag',
        }}
        labelPath="gameTracker.games.playStore.games.tags.create"
        successListener={onSuccess}
        onCancel={onClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GuiText field="tag" />
          </Grid>
        </Grid>
      </GuiForm>
    </GuiGenericDialog>
  );
};

export default TagCreateDialog;
