import React from 'react';
import {
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { classNames } from '@guibil/components';
import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';

const HeadComponent = ({ ...restProps }) => {
  const classes = useStyles();
  const { enableFiltering } = useGuiGridPropsContext()

  return (
    <Table.TableHead
      {...restProps}
      className={classNames(classes.headComponent, !enableFiltering && classes.headComponentNoFilter)}
    />
  );
};

const useStyles = makeStyles(() => ({
  headComponent: {
    "&:first-child": {},
    '& > tr': {
      "& th": {
        border: "none",
      },
      '& span': {
        color: "var(--titleColor) !important",
        paddingLeft: 3,
        textOverflow: "clip",
        fontSize: 13,
        fontWeight: 600,
        "& svg": {
          color: "var(--defaultTextColor) !important",
          marginLeft: 0,
          marginRight: -10,
        },
        "&:hover": {
          opacity: .9
        }
      },
      '&:nth-child(1)': {
        '& > th': {
          paddingBottom: 0,
          paddingTop: 4,
          fontSize: ".9rem !important",
          verticalAlign: "middle",
          paddingLeft: 8,
          "&:first-child": {
          },

          "& > div": {
            marginBottom: 4
          }
        },

      },
      '&:nth-child(2)': {
        '& > th': {
          paddingBottom: "0px !important",
          paddingLeft: "4px !important",
          verticalAlign: "middle",
          minHeight: 51,
          height: 51,
          "&:first-child": {
          }
        },
      },
    },
  },
  headComponentNoFilter: {
    "&:first-child": {},
    '& > tr': {

      '&:nth-child(1)': {
        '& > th': {
          paddingBottom: 12,
          paddingTop: 6,
          fontSize: ".9rem !important",
          verticalAlign: "middle",
          paddingLeft: 3,
        },
      },
    },
  },
}));

export { HeadComponent };
