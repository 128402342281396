import { IModuleRoutes } from "@guibil/router/types";
import MobileFriendly from '@material-ui/icons/MobileFriendly';
import Comment from '@material-ui/icons/CommentOutlined';
import Transform from '@material-ui/icons/Transform';
import Gids from "./gids/Gids";
import GidDisplay from "./gid-display/GidDisplay";
import Evaluation from "./evaluation/Evaluation";
import Installations from "./installations/Installations";
import InstallationDetails from "./installations/installation-details/InstallationDetails";

const development: IModuleRoutes = [
  {
    url: "gids",
    title: "gameTracker.development.gids.menuTitle",
    component: Gids,
    icon: Transform,
    forbiddenRoles: ["VIEWER"],
  },
  {
    url: "gids/gid/:id",
    component: GidDisplay,
    hidden: true,
    forbiddenRoles: ["VIEWER"],
  },
  {
    url: "evaluation",
    title: "gameTracker.development.evaluation.menuTitle",
    component: Evaluation,
    icon: Comment,
    forbiddenRoles: ["VIEWER"],
  },
  {
    url: "installations",
    title: "gameTracker.development.installations.menuTitle",
    component: Installations,
    icon: MobileFriendly,
    forbiddenRoles: ["VIEWER"],
  },
  {
    url: "installations/installation/:id",
    component: InstallationDetails,
    hidden: true,
    forbiddenRoles: ["VIEWER"],
  },
];

export { development };
