import { GUI_FORM_ELEMENT_EVENT } from '@guibil/form/constants/gui-form-elements';
import { useGuiFormStateContext } from '@guibil/form/contexts/GuiFormStateContext';
import { useEffect } from 'react';


const useDisableSubmitOnEnter = () => {
  const { guiEventSystem } = useGuiFormStateContext();

  useEffect(() => {
    guiEventSystem.emit(GUI_FORM_ELEMENT_EVENT.VOTE_TO_DISABLE_FORM_SUBMIT_ON_ENTER);
    return () => {
      guiEventSystem.emit(GUI_FORM_ELEMENT_EVENT.CANCEL_VOTE_TO_DISABLE_FORM_SUBMIT_ON_ENTER);
    };
  }, []);

  return null;
}

export default useDisableSubmitOnEnter;
