import React, { useContext } from 'react';

export const GuiRouteParamsContext = React.createContext<any | undefined>(undefined);

/**
 * If you want to access params, which you passed
 * from GuiRoute/GuiRouteGroup, then use this hook
 * to get those params
 */
export const useGuiRouteParamsContext = () => {
  const context = useContext(GuiRouteParamsContext);
  if (typeof (context) === 'undefined') {
    throw new Error('use useGuiRouteParamsContext inside GuiBrowserRouter');
  }

  return context;
};
