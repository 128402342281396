import React from 'react';
import { GuiGenericDialog } from '@guibil/components';
import { GuiForm, GuiText } from '@guibil/form';
import { Grid } from '@material-ui/core';

interface IProps {
  onClose: () => void,
  onSuccess: () => void,
}
const ExecutiveCreateDialog: React.FC<IProps> = (props) => {
  const { onClose, onSuccess } = props;
  const fetchData = () => ({
    name: "",
    web_site: "",
  });

  return (
    <GuiGenericDialog isOpen size="md" title="gameTracker.makerDetails.appStore.executivesDialog.create.title" onClose={onClose}>
      <GuiForm
        fetchData={fetchData}
        type="new-form"
        isDialogForm
        config={{
          submitDataApi: 'artists/add_new_executive',
        }}
        labelPath="gameTracker.makerDetails.appStore.executivesDialog.create"
        successListener={onSuccess}
        onCancel={onClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <GuiText field="name" />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <GuiText field="web_site" />
          </Grid>
        </Grid>
      </GuiForm>
    </GuiGenericDialog>
  );
};

export default ExecutiveCreateDialog;
