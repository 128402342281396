import React, { useState } from 'react';
import {
  Button, ButtonProps, makeStyles, CircularProgress,
} from '@material-ui/core';
import { IconProp } from '@fortawesome//fontawesome-svg-core';
import { useLang } from '@guibil/app';
import { classNames, guiNotifier, ISwitchText } from '@guibil/components';
import { commonCSS } from '@guibil/styles';
import { GuiTooltip } from './GuiTooltip';
import { GuiIcon } from './GuiIcon';
import { IFieldOnChangeCallback, IFormElementValueType } from '@guibil/form/form-elements/hocs/withGuiFormItem';

export interface IGuiButtonProps {
  alwaysShow?: boolean;
  text?: string,
  /** used to identify button permission */
  name?: string,
  icon?: IconProp | JSX.Element,
  onClick: Function,
  confirm?: boolean | string,
  disabled?: boolean,
  hide?: boolean,
  onBeforeLoading?: () => void,
  onAfterLoading?: () => void,
  size?: 'small' | 'medium' | 'large',
  nativeProps?: ButtonProps,
  isLoading?: boolean,
  tooltip?: string,
  isFormButton?: boolean,
  iconPosition?: "left" | "right",
  /**
   *  Changes styles of button
   */
  variant?: 'danger' | 'warning' | 'success' | 'cancel' | 'secondary' | "switch",
  /**
   *  Changes styles of grid action button
   */
  actionType?: 'danger' | 'warning' | 'success' | 'info',
  ariaControls?: string,
  ariaHasPopup?: boolean,

  onChange?: IFieldOnChangeCallback | undefined,
  value?: IFormElementValueType | undefined,
  switchText?: ISwitchText | undefined
}

export interface IGuiSwitchButtonProps extends IGuiButtonProps {
  variant: "switch",
  onChange: IFieldOnChangeCallback | undefined,
  value: IFormElementValueType | undefined,
  switchText: ISwitchText | undefined
}

const useStyles = makeStyles((theme) => ({
  labelInner: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    color: "var(--titleColor)"
  },
  icon: {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    color: "var(--titleColor)"
  },
  buttonChild: {
    width: '100%',
  },
}));


export const GuiButton: React.FC<IGuiButtonProps | IGuiSwitchButtonProps> = (props) => {
  const lang = useLang();
  const {
    text, icon, iconPosition, variant, confirm, disabled, hide, onClick, onBeforeLoading, onAfterLoading, size, nativeProps, isLoading, tooltip, alwaysShow, isFormButton, ariaControls, ariaHasPopup
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  if (hide) {
    return null;
  }

  let onButtonClick = (e: any) => {
    if (onClick) {
      const handleClick = async () => {
        try {
          setLoading(true);
          if (onBeforeLoading) await onBeforeLoading();
          await onClick(e);
          if (onAfterLoading) await onAfterLoading();
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }

      }

      if (!confirm) { handleClick() }
      else {

        guiNotifier().confirm({
          description: `${(typeof (confirm) === "string") ? (lang(confirm) || confirm) : lang('guibil:components.confirmation.text')}`
        },
          handleClick);
      }
    }

  }

  return (
    <>
      <GuiTooltip title={lang(tooltip || '') || tooltip} hide={false}>
        <Button
          size={size}
          disabled={disabled || loading}
          aria-controls={ariaControls}
          aria-haspopup={!!ariaHasPopup}
          variant={(variant === "success" || variant === "secondary") ? "contained" : "text"}
          className={classNames(variant === 'danger' && commonCSS.dangerButton, variant === 'success' && commonCSS.successButton, variant === 'cancel' && commonCSS.cancelButton, variant === 'secondary' && commonCSS.secondaryButton)}
          onClick={onButtonClick}
          {...nativeProps}
          classes={{
            disabled: commonCSS.disabledButton,
            root: isFormButton ? commonCSS.inFormButton : "",
            ...nativeProps?.classes,
          }}
          datatype={(alwaysShow) ? 'alwaysshow' : 'regular'}
        >
          <div className={classes.labelInner}>
            {/* icon */}
            {iconPosition !== "right" && <span className={icon && classes.icon}>
              {(isLoading || loading)
                ? <CircularProgress className={commonCSS.loadingIcon} disableShrink size={20} />
                : <GuiIcon icon={icon} />}
            </span>}
            {/* icon end */}

            {lang(text || "")}
          </div>

          {/* external component such as progressbar */}
          {props.children
            && (
              <div className={classes.buttonChild}>
                {props.children}
              </div>
            )}
          {/* external comp end */}

          {/* icon */}
          {iconPosition === "right" && <span style={{ marginBottom: 1 }} className={icon && classes.icon}>
            {(isLoading || loading)
              ? <CircularProgress className={commonCSS.loadingIcon} disableShrink size={20} />
              : <GuiIcon icon={icon} />}
          </span>}
          {/* icon end */}
        </Button>
      </GuiTooltip>
    </>
  );



};
