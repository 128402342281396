import { app_store_artist_details_tr } from "../app-store/locales/tr_TR";
import { play_store_developer_details_tr } from "../play-store/locales/tr_TR";


export const maker_details_tr = {
  breadcrumbTitle: "Yapımcı Detayları",
  pageTitle: "Yapımcı Detayları",
  title: "Yapımcı Detayları",
  menuTitle: "Yapımcı Detayları",

  appStore: app_store_artist_details_tr,
  playStore: play_store_developer_details_tr,
};
