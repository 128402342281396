import { GuiGrid, useGuiGridHook } from '@guibil/grid'
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import { Grid } from '@material-ui/core';
import { DATE_FORMAT, GuiDateTimePicker, GuiDropdown, GuiForm, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form';
import { GuiButton, useWindowSize } from '@guibil/components';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { commonCSS } from '@guibil/styles';
import GameScreenshots from 'pages/games/game-details/play-store/components/game-screenshots/GameScreenshots';
import { PlayStoreGenre } from 'app/enums/app/general/play-store-genre/enum_type';
import { PlayStoreChart } from 'app/enums/app/general/play-store-chart/enum_type';

const TopGames = () => {
	const { width } = useWindowSize();
	const [showFilter, setShowFilter] = useState<boolean>(false);

	const [gridHook, setGridHook] = useGuiGridHook();
	const [data, setData] = useGuiFormData();
	const [filterHook, setFilterHook] = useGuiFormHook();
	const [expandAll, setExpandAll] = useState(false);
	const [filterParams, setFilterParams] = useState<any | undefined>({
		name: "",
		chart_type: PlayStoreChart.FREE,
		chart_genre: PlayStoreGenre.GAME,
		previous_datetime: moment().startOf("day").format(DATE_FORMAT),
	});

	useEffect(() => { width !== 0 && setShowFilter(width > 666 ? true : false); }, [width])

	const handleSetFilter = () => {
		gridHook?.setPagingState((oldState) => ({ ...oldState, pageIndex: 0 }))
		gridHook?.setQueryParams({
			name: data?.values?.name,
			chart_type: data?.values?.chart_type,
			chart_genre: data?.values?.chart_genre,
			previous_datetime: data?.values?.previous_datetime,
		})
	}

	const handleResetFilter = () => {
		setFilterParams(undefined);
		gridHook?.setQueryParams({
			chart_genre: PlayStoreGenre.GAME,
			chart_type: PlayStoreChart.FREE,
			previous_datetime: moment().startOf("day").format(DATE_FORMAT),
		});
		filterHook?.fetch();
	}

	const ChangeOfOrder = (row: any) => {
		const { change_of_order } = row.row;
		if (change_of_order === 0) {
			return <div>-</div>
		} else if (change_of_order > 0) {
			return <div style={{ color: "var(--primarySuccess)" }}>+{change_of_order}</div>
		} else {
			return <div style={{ color: "var(--primaryError)" }}>{change_of_order}</div>
		}
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<GuiForm
					fetchData={() => filterParams}
					setHook={setFilterHook}
					setData={setData}
					showForm={!showFilter}
					title="gameTracker.topGames.playStore.filter.filter"
					labelPath="gameTracker.topGames.playStore.filter"
					disableLeaveFormPrompt
					headerComponent={
						<div>
							<GuiButton
								onClick={() => setShowFilter(!showFilter)}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon={showFilter ? "eye-slash" : "eye"}
							/>
							<GuiButton
								onClick={handleResetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="cancel"
								icon="undo-alt"
							/>
							<GuiButton
								onClick={handleSetFilter}
								nativeProps={{ classes: { root: commonCSS.iconButton } }}
								variant="success"
								icon="filter"
							/>
						</div>
					}
					hideButtons
					type="writer"
				>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} lg={3}>
							<GuiText
								field="name"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="chart_genre"
								selectionsEnum="PlayStoreGenre"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDropdown
								field="chart_type"
								selectionsEnum="PlayStoreChart"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<GuiDateTimePicker
								field="previous_datetime"
								disableTime
							/>
						</Grid>
					</Grid>
				</GuiForm>
			</Grid>

			<Grid item xs={12}>
				<GuiGrid
					fetchData="ps_games/top_games"
					title="gameTracker.topGames.playStore.topFreeGame.title"
					labelPath="gameTracker.topGames.playStore.topFreeGame"
					rowDetailComponent={(row: any) => <GameScreenshots screenshots={row?.row?.screenshots} showVideos={false} />}
					expandAll={expandAll}
					setHook={setGridHook}
					queryParams={filterParams}
					columnExtensions={[
						{ columnName: "order", width: 50 },
						{ columnName: "game.name", wordWrapEnabled: true },
						{ columnName: "game.developer", wordWrapEnabled: true },
						{ columnName: "game.logo_image_url", width: 76 },
						{ columnName: "game.rate", width: 60 },
						{ columnName: "game.store_rate", width: 110 },
						{ columnName: "game.release_date", width: 140 },
						{ columnName: "game.last_release_date", width: 140 },
						{ columnName: "follow", width: 140 },
					]}
					hiddenSortFields={["follow"]}
					columns={[
						"order",
						"game.logo_image_url",
						"game.name",
						"game.developer",
						"game.rate",
						"game.store_rate",
						"game.release_date",
						"game.last_release_date",
						"change_of_order",
						"follow",
					]}
					extraButtons={[
						{
							onClick: () => setExpandAll(!expandAll),
							text: expandAll ? "gameTracker.topGames.playStore.topFreeGame.hideScreenshots" : "gameTracker.topGames.playStore.topFreeGame.showScreenshots",
							icon: "file-image",
							variant: "cancel"
						}
					]}
					dateTimeFields={[
						{ targetField: "game.release_date" },
						{ targetField: "game.last_release_date" },
					]}
					customCells={[
						{
							targetFields: ["order"], component: (row: any) => row?.row?.order != null ? row?.row?.order : "-"
						},
						{
							targetFields: ["game.name"], component: (row: any) =>
								<Name
									linkTo={`/games/game/${row.row?.game?.id}`}
									name={row.row?.game?.name}
									url={row.row?.game?.url}
								/>
						},
						{
							targetFields: ["game.developer"], component: (row: any) =>
								<Name
									linkTo={`/makers/maker/${row.row?.game?.developer?.id}`}
									name={row.row?.game?.developer?.name}
									url={row.row?.game?.developer?.url}
								/>
						},
						{
							targetFields: ["game.logo_image_url"], component: (row: any) =>
								<GameLogo logo_image_url={row.row?.game?.logo_image_url} />
						},
						{
							targetFields: ["change_of_order"], component: ChangeOfOrder
						},
						{
							targetFields: ["follow"], component: (row: any) =>
								<FollowingStatus
									key={row.row?.id}
									isFollowing={row.row?.following}
									followUrl={`games/follow_game/${row.row?.game.id}`}
								/>
						},
					]}
				/>
			</Grid>
			<Grid item xs={12} />
		</Grid >
	)
}

export default TopGames
