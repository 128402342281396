import { logError } from '@guibil/app';
import { guiNotifier } from '@guibil/components';
import { fileDownload } from '@guibil/helpers';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { ApiHeaderModel } from '../models/ApiHeaderModel';
import { getApiURL } from '../utils';

export const COMPONENT_UNMOUNTED = "COMPONENT_UNMOUNTED";

const AXIOS_CANCEL_TOKEN = axios.CancelToken;

type RequestToken = {
  _id: any,
  cancelToken: CancelTokenSource
}

const axiosInstance = axios.create({});

interface IRequestConfig {
  disableDataPreprocess?: boolean,
  disableLinkPreprocess?: boolean,
  enableSuppressValidationErrors?: boolean,
  onSuccess?: (data: any) => void,
  onError?: (err: any) => void,
  returnFullResponse?: boolean,
}

interface IPostRequestConfig extends IRequestConfig {
  putDataInLink?: boolean,
}

export class GuiRequestPack {
  private requestTokens: RequestToken[];

  private isMounted: boolean;

  constructor() {
    this.requestTokens = [];
    this.isMounted = true;
  }

  private generateCancelToken = () => {
    const requestToken: RequestToken = {
      _id: Symbol('random'),
      cancelToken: AXIOS_CANCEL_TOKEN.source(),
    };
    this.requestTokens.push(requestToken);

    return requestToken;
  }

  private removeCancelToken = (requestToken: RequestToken) => {
    this.requestTokens = this.requestTokens.filter((request) => request._id !== requestToken._id);
  }

  private request = (
    config: AxiosRequestConfig,
    reqConfig: IPostRequestConfig | IRequestConfig,
    header?: object
  ) => {
    if (!this.isMounted) logError(new Error('Request in unmounted component'))

    const requestToken = this.generateCancelToken();

    return axiosInstance
      .request({
        cancelToken: requestToken.cancelToken.token,
        headers: header ? header : ApiHeaderModel.getHeaders(),
        baseURL: getApiURL(),
        ...config,
      })
      .then((res) => {
        const { data } = res;

        reqConfig.onSuccess && reqConfig.onSuccess(data);
        return reqConfig.returnFullResponse ? res : data;
      })
      .catch((err) => {
        logError(err, 'Error on request');

        if (reqConfig.onError) {
          reqConfig.onError(err);
        }
        else {
          throw err;
        }
      })
      .finally(() => {
        this.removeCancelToken(requestToken);
      });
  }

  get = (
    url: string,
    data?: any,
    reqConfig: IRequestConfig = {}
  ) => this.request({
    url,
    data,
    method: 'GET',
  }, reqConfig)

  post = (
    url: string,
    data?: any,
    reqConfig: IPostRequestConfig = {}
  ) => {
    data = data || {};

    return this.request({
      url,
      data,
      params: reqConfig.putDataInLink === true ? data : undefined,
      method: 'POST',
    }, { returnFullResponse: true, ...reqConfig });
  }

  put = (
    url: string,
    data?: any,
    reqConfig: IRequestConfig = {}
  ) => this.request({
    url,
    data,
    method: 'PUT',
  }, { returnFullResponse: true, ...reqConfig })

  delete = (
    url: string,
    data?: any,
    reqConfig: IRequestConfig = {}
  ) => this.request({
    url,
    data,
    method: 'DELETE',
  }, reqConfig)

  download = (
    url: string,
    filename: string,
    onDownloadProgress?: (progressEvent: any) => void,
  ) => {
    return this.request({
      url,
      method: "GET",
      onDownloadProgress,
      responseType: "blob",
    }, {})
      .then((res) => fileDownload(res, filename))
      .catch((err) => guiNotifier().handleError(err))
  }

  upload = (
    url: string,
    formData: FormData,
    onUploadProgress?: (progressEvent: any) => void,
    reqConfig: IRequestConfig = {}
  ) => this.request({
    url,
    data: formData,
    method: "POST",
    onUploadProgress,
  }, reqConfig, ApiHeaderModel.getFileUploadHeaders())

  unmount() {
    this.isMounted = false;
    this.requestTokens.forEach(({ cancelToken }) => {
      try {
        cancelToken.cancel(COMPONENT_UNMOUNTED);
      } catch (err) { }
    });
  }
}
