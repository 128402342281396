import { useUser } from '@guibil/app';
import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { GuiAuthRoute } from './auth/GuiAuthRoute';
import GuiDocumentTitleSync from './breadcrumb/GuiDocumentTitleSync';
import { GuiRouteParamsContext } from './contexts/GuiRouteParamsContext';
import { NothingFound } from './error-pages/404';
import { GuiGuestRoute } from './guest/GuiGuestRoute';
import { IGuiAppRouter } from './types';
import { convertGroupIntoOneLevel } from './utils/local-helpers';

export const GuiAppRouter: React.FC<IGuiAppRouter> = (props) => {
  const { config, documentTitleConfig } = props;
  const { isAuth, role } = useUser();
  const routerConfig = isAuth ? config.auth : config.guest;
  const RouteComponent = isAuth ? GuiAuthRoute : GuiGuestRoute;

  const routesList = useMemo(() => convertGroupIntoOneLevel(routerConfig, '/')
    .filter((item) => item.component), [routerConfig]);

  return (
    <>
      {documentTitleConfig && <GuiDocumentTitleSync {...props} />}

      <Switch>
        {
          routesList.map((route, key) => {

            if (route.forbiddenRoles) {
              if (role !== "GUEST" && route.forbiddenRoles.includes(role)) {
                return null;
              }
            };

            if (route.params) {
              // in order to pass params, I need to render Context.Provider
              return (
                <RouteComponent key={key} exact {...route} component={undefined}>
                  <GuiRouteParamsContext.Provider value={route.params}>
                    {/* @ts-ignore */}
                    <route.component />
                  </GuiRouteParamsContext.Provider>
                </RouteComponent>
              );
            }
            return <RouteComponent key={key} exact {...route} />;
          })
        }

        {props.children && props.children}

        <Route component={NothingFound} />
      </Switch>
    </>
  );
};

GuiAppRouter.defaultProps = {
  hideBreadcrumb: false,
};
