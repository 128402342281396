export const app_store_artist_details_en = {
  title: "Artist Details",
  last_release_date: "Last Release Date",
  rate: "Rate",
  tag: "Tags",
  comment: "Comments",
  executive: "Executive",
  executives: "Executives",

  executivesDialog: {
    title: "Executives",
    createButton: "Add",
    deleteConfirmMessage: "Are you sure you want to delete this executive?",
    deleteSuccessMessage: "Executive deleted successfully",
    name: "Executive name",
    web_site: "Executive web site",
    actions: " ",
    create: {
      title: "Add Executive",
      name: "New Executive Name",
      web_site: "New Executive Web Site",
    },
  },

  artistGames: {
    title: "Artist Games",
    object_order: "Index",
    logo_image_url: "Logo",
    name: "Name",
    artist: "Artist",
    rate: "Rate",
    store_rate: "Store Rate",
    release_date: "Release Date",
    last_release_date: "Last Release Date",
    follow: "Follow",
  }
};
