import { useEffect } from 'react';
import { useGuiFormPropsContext } from '../../../contexts/GuiFormPropsContext';
import { useGuiFormStateContext } from '../../../contexts/GuiFormStateContext';

const GuiFormStateSubscribers = () => {
  const formPropsContext = useGuiFormPropsContext();
  const { formState } = useGuiFormStateContext();

  useEffect(() => {
    formPropsContext.formStateListener?.(formState);
  }, [formState]);

  return null;
};

export { GuiFormStateSubscribers };
