import { isNullOrUndefined, isNotNullOrUndefined, convertToArray } from '@guibil/helpers';

type FieldForActionType = {
  causedFieldName: string,
  causedFieldValue: any,
}

export interface IChainedFieldsToHide {
  [key: string]: Array<FieldForActionType>
}
export interface IChainedFieldsToDisable {
  [key: string]: Array<FieldForActionType>
}

export interface IGuiChainedFields {
  targetField: string,
  onValue: any | Array<any>,
  fieldsToHide?: Array<string>,
  fieldsToDisable?: Array<string>,
}

function performChainedFieldsGenericAction(chainedFields: IGuiChainedFields, newValue: any, _finalActionFields: IChainedFieldsToHide | IChainedFieldsToDisable, actionField: | 'fieldsToHide' | 'fieldsToDisable') {
  const onValueAr = convertToArray(chainedFields.onValue);
  const fieldsForAction = convertToArray(chainedFields[actionField]);
  const targetFieldAr = convertToArray(chainedFields.targetField);

  targetFieldAr.forEach((actionCauseField: string) => {
    fieldsForAction.forEach((field: string) => {
      onValueAr.forEach((onValue: any) => {
        const fieldForActionInstance: FieldForActionType = {
          causedFieldName: actionCauseField,
          causedFieldValue: onValue,
        };
        if (isNullOrUndefined(_finalActionFields[field])) { _finalActionFields[field] = []; }

        let itemExistsInArray = false;
        if (isNotNullOrUndefined(_finalActionFields[field]) && Array.isArray(_finalActionFields[field])) {
          _finalActionFields[field] = _finalActionFields[field].filter((tmp) => {
            if (tmp.causedFieldName !== actionCauseField) {
              return true;
            }

            if (tmp.causedFieldValue === newValue) {
              itemExistsInArray = true;
              return true;
            }
            return false;
          });
        }

        if ((newValue === onValue || (typeof newValue === "number" && newValue.toString() === onValue)) && !itemExistsInArray) {
          _finalActionFields[field].push(fieldForActionInstance);
        }
      });
    });
  });

  return _finalActionFields;
}

export function performChainedFieldsHide(chainedFields: IGuiChainedFields, newValue: any, _finalHiddenFields: IChainedFieldsToHide) {
  return performChainedFieldsGenericAction(chainedFields, newValue, _finalHiddenFields, 'fieldsToHide');
}

export function performChainedFieldsDisable(chainedFields: IGuiChainedFields, newValue: any, _finalDisabledFields: IChainedFieldsToDisable) {
  return performChainedFieldsGenericAction(chainedFields, newValue, _finalDisabledFields, 'fieldsToDisable');
}
