import { GuiGrid } from '@guibil/grid'
import GameLogo from 'pages/components/GameLogo';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import Datetime from 'pages/components/Datetime';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const Installations = () => {
	const classes = useStyles();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<GuiGrid
					fetchData="installations/installations"
					title="gameTracker.development.installations.installedGames.title"
					labelPath="gameTracker.development.installations.installedGames"
					columnExtensions={[
						{ columnName: "game.logo_image_url", width: 76 },
						{ columnName: "game.name", wordWrapEnabled: true },
						{ columnName: "play_detail.player_count", width: 100 },
						{ columnName: "play_detail.total_playtime_in_ms", wordWrapEnabled: true },
						{ columnName: "play_detail.average_playtimes_in_ms", wordWrapEnabled: true },
						{ columnName: "register_date", width: 150 },
						{ columnName: "game.last_release_date", width: 150 },
						{ columnName: "follow", width: 130 },
					]}
					columns={[
						"game.logo_image_url",
						"game.name",
						"play_detail.player_count",
						"play_detail.total_playtime_in_ms",
						"play_detail.average_playtimes_in_ms",
						"register_date",
						"game.last_release_date",
						"follow",
					]}
					dateTimeFields={[
						{ targetField: "game.last_release_date" },
					]}
					hiddenSortFields={[
						"game.logo_image_url",
						"follow",
					]}
					getCellValues={[
						{
							targetFields: ["play_detail.total_playtime_formatted"],
							function: (row: any) => row.row?.play_detail?.total_playtime_in_ms
						},
						{
							targetFields: ["play_detail.average_playtimes_formatted"],
							function: (row: any) => row.row?.play_detail?.average_playtimes_in_ms
						},
					]}
					customCells={[
						{
							targetFields: ["register_date"],
							component: (row: any) => <Datetime datetimeString={row?.row?.register_date} timeDisabled />
						},
						{
							targetFields: ["play_detail.total_playtime_in_ms"],
							component: (row: any) => <span>{row.row?.play_detail?.total_playtime_formatted}</span>
						},
						{
							targetFields: ["play_detail.average_playtimes_in_ms"],
							component: (row: any) => <span>{row.row?.play_detail?.average_playtimes_formatted}</span>
						},
						{
							targetFields: ["game.name"], component: (row: any) => {
								if (row.row?.game) {
									return <div>
										{row?.row?.is_test && <div className={classes.isTest}>Test</div>}
										<Name
											linkTo={`/development/installations/installation/${row?.row?.id}`}
											name={row.row?.game?.name}
											url={row.row?.game?.url}
										/>
									</div>
								} else {
									return <div>
										{row?.row?.is_test && <div className={classes.isTest}>Test</div>}
										<Name
											linkTo={`/development/installations/installation/${row?.row?.id}`}
											name={row.row?.game_store_id}
											url={"https://apps.apple.com/us/app/id" + row.row?.game_store_id}
										/>
									</div>
								}
							}
						},
						{
							targetFields: ["game.logo_image_url"], component: (row: any) =>
								<GameLogo logo_image_url={row.row?.game?.logo_image_url} />
						},
						{
							targetFields: ["follow"], component: (row: any) => {
								if (row.row?.game) {
									return <FollowingStatus
										key={row.row?.game?.id}
										isFollowing={row.row?.game?.following}
										followUrl={`games/follow_game/${row.row?.game?.id}`}
									/>
								} else {
									return <span>-</span>
								}
							}
						},
					]}
				>
				</GuiGrid>
			</Grid>
			<Grid item xs={12} />
		</Grid >
	)
}

const useStyles = makeStyles({
	isTest: {
		fontWeight: 600,
		fontSize: "14px",
		color: "var(--primarySuccess)",
	}
});

export default Installations
