import { app_store_games_en } from "../app-store/locales/en_US";
import { play_store_games_en } from "../play-store/locales/en_US";


export const games_en = {
  breadcrumbTitle: "All Games",
  pageTitle: "All Games",
  title: "All Games",
  menuTitle: "All Games",

  appStore: app_store_games_en,
  playStore: play_store_games_en,
};
