import { useRequest } from '@guibil/api';
import { logError } from '@guibil/app';
import { guiNotifier } from '@guibil/components';
import { DEFAULT_SUBMIT_HANDLER } from '@guibil/form/constants';
import { GUI_FORM_STATE_EVENT } from '@guibil/form/constants/gui-form-state';
import { IGuiFormResponse, IGuiFormResponseValidationError, IGuiFormSubmitType } from '@guibil/form/types';
import {
  checkPropertyExists, forEachArrayOrObject, getFieldByName, isNotNullOrUndefined, isNullOrUndefined,
} from '@guibil/helpers';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { useGuiFormPropsContext } from '../../contexts/GuiFormPropsContext';
import { useGuiFormStateContext } from '../../contexts/GuiFormStateContext';
import { preprocessYupValidationErr } from '../../includes/FormHelpers';
import { omit, set } from 'lodash';

export const SHOW_SINGLE_API_VALIDATION_ERROR = true;

interface IProps { }

const GuiFormikContainer: React.FC<IProps> = (props) => {
  const request = useRequest();
  const propsContext = useGuiFormPropsContext();
  const stateContext = useGuiFormStateContext();
  const { data, guiEventSystem, fieldsToDismiss } = stateContext;
  const { confirmOnSubmit, submitHandler, fetchData } = propsContext;
  let submitType: IGuiFormSubmitType = "put";

  const defaultSubmitHandlerInner = useCallback(async (values: any, formikProps: FormikHelpers<any>) => {
    const { submitHandler, beforeSubmit, config } = propsContext;

    let submitValues = { ...values };

    fieldsToDismiss.forEach((field) => {
      const _field = getFieldByName(submitValues, field);
      const paths = _field.split(".");

      if (paths.length) { // which means we have a nested object
        submitValues = omit(submitValues, _field);
      } else {
        delete submitValues[_field];
      }

    });

    delete submitValues["_links"];

    let requestResult = null;
    if (beforeSubmit) submitValues = beforeSubmit(submitValues);

    // Form submit is handled by the User, in component page
    if (submitHandler) {
      try {
        requestResult = await submitHandler(submitValues, formikProps);
      }
      catch (err) {
        logError(err, "Please catch error and return api response");
        throw err;
      }
    } else { // Submit is done by configurations
      let targetLink = window.location.pathname;
      if (config?.submitDataApi) targetLink = config?.submitDataApi;
      if (typeof fetchData === "string") targetLink = fetchData;

      submitType = config?.submitType || (propsContext.type === "new-form" ? "post" : "put")
      requestResult = submitType === "put" ? await request.put(targetLink, submitValues, { returnFullResponse: true }) : await request.post(targetLink, submitValues, { returnFullResponse: true });
    }

    return requestResult;
  }, [fieldsToDismiss, submitHandler]);

  const formSubmitFailed = (err: any) => {
    guiEventSystem.emit(GUI_FORM_STATE_EVENT.FORM_FAILED_TO_SAVE, err);
  }

  const mapFormValidationMessagesFromResponse = (value: any, formikProps: FormikHelpers<any>, validationErrors: {
    [key: string]: string[]
  }) => {
    const transformApiValidationErrors = () => {
      let errors: { [key: string]: string } = {};

      Object.keys(validationErrors).forEach(key => {
        const errorMessage = SHOW_SINGLE_API_VALIDATION_ERROR
          ? validationErrors[key][0]
          : validationErrors[key].join(", ");

        if (typeof (key) !== "string") { return; }

        if (key.length === 0) {
          if (Object.keys(value).length >= 1) {
            errors[Object.keys(value)[0]] = errorMessage;
          }
        }
        else {
          const errorKey = key[0].toLowerCase() + key.substr(1);
          errors[errorKey] = errorMessage;
        }
      })
      return errors;
    }

    formikProps.setErrors(transformApiValidationErrors());
  }

  const defaultSubmitHandler = useCallback(async (values: any, formikProps: FormikHelpers<any>) => {
    const { removeRequestError, onRequestError, setIsSubmitting } = stateContext;
    const { config } = propsContext;

    removeRequestError(DEFAULT_SUBMIT_HANDLER);
    setIsSubmitting(true);

    try {
      const requestResult: IGuiFormResponse = await defaultSubmitHandlerInner(values, formikProps);
      if (requestResult) {
        if (requestResult.status >= 200 && requestResult.status < 300) {

          guiEventSystem.emit(GUI_FORM_STATE_EVENT.FORM_SUCCESSFULLY_SAVED, requestResult, submitType);
          if (config?.onSubmitSuccess) config?.onSubmitSuccess();
        }
      }

    } catch (err) {
      const response = err?.response?.data;

      if (response?.is_error) {
        const validationError = (response as IGuiFormResponseValidationError)

        if (validationError?.exception_messages) {
          mapFormValidationMessagesFromResponse(values, formikProps, validationError.exception_messages);
          formSubmitFailed(err);
        } else if (response?.exception_message) {
          formSubmitFailed(err);
        }
      } else {
        onRequestError(DEFAULT_SUBMIT_HANDLER, err, formikProps.submitForm);
      }

    } finally {
      setIsSubmitting(false);
    }
  }, [fieldsToDismiss, submitHandler]);

  const handleValidate = async (values: any) => {
    const { validationHandlerExtension, validationSchema } = propsContext;
    const { fieldsToDismiss } = stateContext;
    const resultErrors: any = {};


    // STEP 1
    if (validationSchema) {
      try {
        await validationSchema().validate(values, { abortEarly: false });
      } catch (validationErrs) {
        const preprocessedErrors = preprocessYupValidationErr(validationErrs);
        forEachArrayOrObject(preprocessedErrors, (message, path) => {
          if (checkPropertyExists(path, values) && isNullOrUndefined(resultErrors[path]) && fieldsToDismiss.includes(path) === false) {
            const paths = path.split(".");
            if (paths.length >= 2) {
              set(resultErrors, path, message);
            } else {
              set(resultErrors, path, message);
            }
          }
        });
      }
    }

    // STEP 2
    /** Start of validationHandlerExtension */
    if (typeof (validationHandlerExtension) === 'function') {
      try {
        const customValidationResults = await validationHandlerExtension(values);
        // STEP 2-1
        if (Object.keys(customValidationResults).length > 0) {
          forEachArrayOrObject(customValidationResults, (message, path) => {
            if (checkPropertyExists(path, values) && isNullOrUndefined(resultErrors[path]) && fieldsToDismiss.includes(path) === false) {
              set(resultErrors, path, message);
            }
          });
        }
      }
      // STEP 2-2
      catch (extendedValidationSchema) {
        try {
          try {
            await extendedValidationSchema().validate(values, { abortEarly: false });
          } catch (err) {
            const preprocessedErrors = preprocessYupValidationErr(err);
            forEachArrayOrObject(preprocessedErrors, (message, path) => {
              if (isNotNullOrUndefined(values[path]) && isNullOrUndefined(resultErrors[path]) && fieldsToDismiss.includes(path) === false) {
                set(resultErrors, path, message);
              }
            });
          }
        } catch (err) { /** Possibly extendedValidationSchema is not a Yup validator, but simple error */ }
      }
    }
    /** End of validationHandlerExtension */

    return resultErrors;
  };

  const onKeyDown = async (keyEvent: React.KeyboardEvent, formikProps: FormikProps<any>) => { // disable submit with enter
    if (propsContext.disableSubmitTriggeredByEnter) {
      if (keyEvent.key == 'Enter') {
        keyEvent.preventDefault();
      }
    } else {
      if (keyEvent.key == 'Enter') {
        if (!formikProps?.isValid || !formikProps?.dirty) {
          keyEvent.preventDefault();
        } else {
          await formikProps?.submitForm();
        }
      }
    }
  }

  const onSubmit = async (values: any, formikProps: FormikHelpers<any>) => {
    if (confirmOnSubmit) {
      const description = typeof (confirmOnSubmit) === 'string' ? confirmOnSubmit : 'guibil:form.confirmation.submit';

      guiNotifier().confirm({ title: 'guibil:form.confirmation.title', description }, async () => {
        await defaultSubmitHandler(values, formikProps);
      });
    } else {
      await defaultSubmitHandler(values, formikProps);
    }
  }

  return (
    <Formik
      initialValues={data}
      validate={handleValidate}
      validateOnChange
      enableReinitialize
      validateOnMount={propsContext.type === "writer"}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <form onKeyDown={(e: any) => onKeyDown(e, formikProps)}>
          {props.children}
        </form>
      )}
    </Formik>
  );
};

export { GuiFormikContainer };
