import { useUser } from '@guibil/app';
import { GuiButtonsRenderer, IGuiButtonsRenderer } from '@guibil/components';
import { getUserPermissionForAction } from '@guibil/helpers';
import React from 'react';
import { useGuiGridPropsContext } from '../../contexts/GuiGridPropsContext';
import { useGuiGridStateContext } from '../../contexts/GuiGridStateContext';

/**
 * Reason of GuiGridButtonsRenderer to be seperate component,
 * is that other components in Grid also use this component
 */
interface IProps {
  buttons?: IGuiButtonsRenderer,
}
export const GuiGridButtonsRenderer: React.FC<IProps> = (props) => {
  const { buttons } = props;
  const { loadingState } = useGuiGridStateContext();
  const { roles } = useGuiGridPropsContext();
  const user = useUser();

  const buttonClickDisabled = loadingState === 'refreshing';

  return (
    <GuiButtonsRenderer
      buttons={buttons}
      disableAll={buttonClickDisabled}
      shouldRender={(button) => !button.name || getUserPermissionForAction(user.role, roles, button.name)}
    />
  );
};
