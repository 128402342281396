export const installations_en = {
  breadcrumbTitle: "Published Games",
  pageTitle: "Published Games",
  title: "Published Games",
  menuTitle: "Published Games",

  installedGames: {
    title: "Published Games",
    follow: "Follow",
    register_date: "Register Date",
    game: {
      logo_image_url: "Logo",
      name: "Name",
      artist: "Artist",
      last_release_date: "Last Release Date",
    },

    play_detail: {
      player_count: "Player Count",
      total_playtime_in_ms: "Play Time",
      average_playtimes_in_ms: "Average Play Time"
    },
  },
};
