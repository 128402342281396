import { makeStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { StoreType, useStore } from './StoreProvider';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useHistory } from 'react-router-dom';
import PlayStoreLogo from 'images/play_store_logo.png'
import AppStoreLogo from 'images/app_store_logo.png'
import React from 'react';


const Store = () => {
  const store = useStore();
  const classes = useStyles();
  const history = useHistory();

  const [selectedValue, setSelectedValue] = React.useState(store.store);

  const handleChange = (event: any) => {
    var selectedStore = event.target.value as StoreType;
    store.changeStore(selectedStore);
    setSelectedValue(selectedStore);
    history.push("/");
  };



  return (
    <RadioGroup row>

      <div className={classes.container}>
        <Radio checked={selectedValue === StoreType.AppStore} value={StoreType.AppStore} onChange={handleChange} />
        <img width="36px" height="36px" src={AppStoreLogo} />
      </div>

      <div className={classes.container}>
        <Radio checked={selectedValue === StoreType.PlayStore} value={StoreType.PlayStore} onChange={handleChange} />
        <img width="36px" height="36px" src={PlayStoreLogo} />
      </div>
    </RadioGroup>
  )
}

const useStyles = makeStyles({
  container: {
    fontWeight: 800,
    marginTop: "18px",
    marginLeft: "9px",
    display: "flex",
    alignItems: "center",
    "& .MuiButtonBase-root": {
      color: "var(--titleColor)"
    }
  }
});

export default Store
