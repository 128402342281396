import { GuiRequestError } from '@guibil/components';
import React from 'react';
import { useGuiFormRequestErrors } from '../hooks/useGuiFormRequestErrors';

const GuiFormSaveRequestError = () => {
  const { saveRequestError } = useGuiFormRequestErrors();

  return (
    <GuiRequestError error={saveRequestError} />
  );
}

export default GuiFormSaveRequestError;
