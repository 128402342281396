import { ILang } from '../contexts/LanguageContext';
import { logError } from '../logger/log-error';

type IEnumKey = string | boolean | number;
export type IEnumBuilder<T extends | string> = {
	[key in T]: IEnumKey[]
}

export type IEnumLangBuilder<T extends | string> = {
	[key in T]: {
		[transKey: string]: string,
	}
}

export type IEnumCollection = IEnumBuilder<any>;

class GuiEnums {
	private _enumCollection?: IEnumCollection;
	private _lang?: ILang;

	private cached: {
		[key in string]: any
	} = {};

	private set enumCollection(_enumCollection: IEnumCollection) {
		// throws Exception, to prevent accidental overwrite
		// if (this._enumCollection) { throw new Error("Enums already initialized"); }
		this._enumCollection = _enumCollection;
	}

	private get enumCollection() {
		if (!this._enumCollection) { throw new Error('Enums must be initialized'); }
		return this._enumCollection;
	}

	private set lang(_lang: ILang) {
		this._lang = _lang;
		this.clearCache();
	}

	private get lang() {
		if (!this._lang) { throw new Error('GuiEnums lang not found'); }
		return this._lang;
	}

	get locale() {
		return this.lang;
	}

	initEnums(enumCollection: IEnumCollection, lang: ILang) {
		this.enumCollection = enumCollection;
		this.lang = lang;
	}

	get(targetEnum: IGuiApp.GuiAvailableEnums, targetEnumKey?: string) {
		try {
			const enumObj = this.cacheOrGetCached(targetEnum);
			if (typeof (targetEnumKey) !== 'undefined') {
				return this.getEnumValue(targetEnum, targetEnumKey);
			}
			return enumObj;
		} catch (err) {
			logError(err, `No '${targetEnum}' enum found in collection`);
		}
		return [];
	}

	getEnumValue(targetEnum: IGuiApp.GuiAvailableEnums, targetEnumKey: string) {
		try {
			const enumObj = this.cacheOrGetCached(targetEnum);
			if (typeof (targetEnumKey) !== 'undefined' && enumObj) {
				const value = enumObj[targetEnumKey];
				return typeof (value) === 'undefined' ? targetEnumKey : value;
			}
		} catch (err) {
			logError(err, `No '${targetEnum}' enum found with '${targetEnumKey}' key found`);
		}
		return targetEnumKey;
	}

	private cacheOrGetCached(targetEnum: IGuiApp.GuiAvailableEnums) {
		if (typeof (this.cached[targetEnum]) !== 'undefined') {
			return this.cached[targetEnum];
		}

		const result: any = {};
		const tmpEnum = getProperty(targetEnum, this.enumCollection);

		tmpEnum.forEach((item: IEnumKey) => {
			if (typeof (item) === 'number' || typeof (item) === "boolean") {
				item = item.toString()
			}
			result[item] = this.lang(`enum:${targetEnum}.${item}`);
		});

		this.cached[targetEnum] = result; // Store result of enum
		return result;
	}

	private clearCache() {
		this.cached = {};
	}
}

const guiEnumsInstance = new GuiEnums();
export { guiEnumsInstance as GuiEnums };

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 */
function getProperty(propertyName: string, object: any) {
	try {
		const parts = propertyName.split('.');
		let property = object;

		for (let i = 0; i < parts.length; i++) {
			property = property[parts[i]];
		}

		return property;
	} catch (error) {
		return undefined;
	}
}
