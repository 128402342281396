import React from 'react';
import {
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { classNames } from '@guibil/components';
import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';
import { SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const CellComponent = (props: Table.DataCellProps) => {
  const classes = useStyles();
  const { actionFields, columnExtensions } = useGuiGridPropsContext();
  const { column } = props;
  const { name } = column;

  const curActionField = (actionFields || []).filter(({ targetField }) => targetField === name)[0];
  const extension: any = columnExtensions ? columnExtensions.filter((ext) => ext.columnName === name)[0] : {};

  const DragHandle = SortableHandle(({ style, ...props }: any) => (
    <Table.Cell {...props} className={classes.dragCell} style={{ ...style, ...{ cursor: "move" } }}><DragIndicatorIcon /></Table.Cell>
  ));

  // Field used for action purpose
  if (curActionField) {
    return (
      <Table.Cell
        {...props}
        className={classNames(classes.cellComponent, classes.actionCell)}
      />
    );
  }

  if (column.name === "drag") {
    return <DragHandle />
  }
  return (
    <Table.Cell
      {...props}
      className={classNames(classes.cellComponent, extension && (extension?.align === "center" && classes.center))}
    />
  );
};

const useStyles = makeStyles(() => ({
  cellComponent: {
    fontSize: "13px !important",
    fontWeight: 600,
    padding: "2px !important",
    backgroundColor: "var(--bgPrimary)",
    color: "var(--defaultTextColor) !important",
    minHeight: 35,
  },
  actionCell: {
    textAlign: "center"
  },
  dragCell: {
    position: "relative",
    "& > svg": {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)"
    }
  },
  center: {
    textAlign: "center"
  }
}));

export { CellComponent };
