/* eslint-disable unused-imports/no-unused-vars */
import { GuiEnums } from '@guibil/app';
import { classNames } from '@guibil/components';
import { isNotNullOrUndefined, mapArrayOrObject } from '@guibil/helpers';
import { getKeyByValue } from '@guibil/router/utils/local-helpers';
import { commonCSS } from '@guibil/styles';
import { Chip } from '@material-ui/core';
import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { IDefaultFormElementProps } from '../../hocs/withGuiFormItem';
import { IGuiDropdownProps } from '../GuiDropdown';

type IProps = IGuiDropdownProps & IDefaultFormElementProps;

export const GuiDropdownMultiple: React.FC<IProps> = (props) => {
  const {
    disabled, multipleSelection, className, showClearOption, fieldValue, fieldRequired,
    fieldEditMode, fieldError, fieldOnChange, field, measurementLabel, label,
    tooltip, controlled, selections, selectionsEnum, autocomplete, keys, ...otherProps
  } = props;

  // const handleFieldChange = useCallback((e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
  //   fieldOnChange(e.target.value as string);
  // }, [fieldOnChange]);

  const [selection, setSelection] = React.useState<string[]>([]);
  const [selectionValues, setSelectionValues] = React.useState<any[]>([]);

  const handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: any;
  }>,) => {

    if (keys) {
      let selectionKeys = event.target.value.map((val: any) => {
        const index = getKeyByValue(selections, val);
        if (index) return keys[parseInt(index)];
      })

      setSelection(selectionKeys);
      setSelectionValues(event.target.value)
      fieldOnChange(selectionKeys);
    }
    setSelection(event.target.value as string[]);
    fieldOnChange(event.target.value as string[]);

  };

  // useEffect(() => {
  //   fieldOnChange(selection);
  // }, [selection])

  useEffect(() => {
    if (keys) {
      let selectionKeys = fieldValue.map((val: any) => {
        const index = getKeyByValue(selections, val);
        if (index) return keys[parseInt(index)];
      })

      setSelection(selectionKeys);
      setSelectionValues(fieldValue)
      return;
    }
    setSelection(fieldValue as string[]);
  }, [fieldValue])


  let dropdownOptionsContainer = null;
  if (fieldEditMode) {
    dropdownOptionsContainer = mapArrayOrObject(selections, (optionValue, key, type) => {
      let value = key;

      if (type === 'array') { value = optionValue; }
      if (typeof (selectionsEnum) !== 'undefined') { optionValue = GuiEnums.get(selectionsEnum, value); }
      if (typeof (selections) === "object") { }

      let isSelected = selection.includes(value);
      if (keys) {
        isSelected = selectionValues.includes(value)
      }

      return (
        <MenuItem key={value} value={value}>
          <Checkbox checked={isSelected} />
          {optionValue}
        </MenuItem>
      );
    });
  }

  return (
    <FormControl
      // variant="outlined"
      margin="normal"
      className={classNames(commonCSS.guiDropDown, commonCSS.guiDropDownMultiselect, disabled && commonCSS.guiDropDownDisabled, className)}
      error={isNotNullOrUndefined(fieldError)}
    >
      <InputLabel className={classNames(commonCSS.guiDropDownLabel, fieldRequired && [commonCSS.requiredLabel, commonCSS.requiredDropdownLabel])} shrink>{label}</InputLabel>

      {/* <Select
        variant="outlined"
        className={className}
        multiple
        fullWidth
        disabled={fieldEditMode === false ? true : disabled}
        input={<OutlinedInput labelWidth={100} name={field} />}
        onChange={handleFieldChange}
        value={fieldArValue}
        renderValue={(selected: any) => (
          <div className={commonCSS.guiDropDownChips}>
            {selected.map((value: any) => (
              <Chip key={value} label={Array.isArray(selections) ? value : selections![value]} className={commonCSS.guiDropDownChip} />
            ))}
          </div>
        )}
        {...otherProps}
      >
        {dropdownOptionsContainer}
      </Select> */}

      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        variant="outlined"
        className={classNames(className)}
        classes={{
          selectMenu: commonCSS.multipleSelectDropdown,
        }}
        disabled={fieldEditMode === false ? true : disabled}
        fullWidth
        multiple
        value={keys ? selectionValues : selection}
        onChange={handleChange}
        input={<OutlinedInput labelWidth={100} name={field} />}
        // renderValue={(selected: any) => {

        //   return 
        // }}
        renderValue={(selected: any) => (
          <div className={commonCSS.guiDropDownChips}>
            {selected.map((value: any) => (
              // <Chip key={value} label={Array.isArray(selections) ? value : selections![value]} className={commonCSS.guiDropDownChip} />
              <Chip
                key={value}
                variant="outlined"
                size="small"
                label={Array.isArray(selections) ? value : selections![value]}
                color="primary"
                className={commonCSS.dropdownChip}
              />
            ))}
          </div>
        )}

        MenuProps={{
          getContentAnchorEl: null,
        }}

        {...otherProps}
      >
        {dropdownOptionsContainer}
      </Select>
      <FormHelperText className={commonCSS.formHelper}>{fieldError}</FormHelperText>
    </FormControl>
  );
}

