export const gid_display_tr = {

  pdf: "Pdf",
  printGid: "Yazdır",

  sections: {
    summary: "Özet",
    general: "Bilgiler",
    dynamic: "Dinamikler",
    mechanic: "Mekanikler",
    design: "Tasarım",
  },

  summary: {
    created_by: "Oluşturan",
    created_datetime: "Oluşturma Tarihi",
    updated_by: "Son Güncelleyen",
    updated_datetime: "Son Güncelleme Tarihi",
    rate: "Puan",
    status: "Durum",
  },

  general: {
    title: "İsim",
    mantra: "Mantra",
    popularity: "Popülerlik",
    art_difficulty: "Tasarım Zorluğu",
    dev_difficulty: "Geliştirme Zorluğu",
    core_loops: "Ana döngüler",
    planned_developers: "Planlanan Geliştiriciler",
    planned_designers: "Planlanan Tasarımcılar",
  },

  dynamic: {
    genre: "Kategori",
    progress: "Süreç",
    goal: "Hedef",
    challenge: "Zorluk",
  },

  mechanic: {
    cores: "Ana Mekanikler",
    subs: "Alt Mekanikler",
  },

  design: {
    description: "Açıklamalar",
    notes: "Notlar",
    b64encode_images: "Tasarım Görselleri",
    files: "Tasarım Dosyaları",
  },

  history: {
    title: "Geçmişi",
    updated_by: "Güncelleyen",
  },
};
