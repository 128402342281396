// import { createMuiTheme } from "@material-ui/core/styles";
import { createGuiTheme } from '@guibil/styles';

const theme = createGuiTheme({
  typography: {
    fontFamily: [
      'KantumruyPro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,

    allVariants: {
      color: 'var(--defaultTextColor)',
    },
    body1: {
      fontWeight: 500,
      color: 'var(--titleColor) !important',
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 500,
      color: 'var(--defaultTextColor)',
      fontSize: '.85rem !important',
    },
    h3: {
      margin: '1rem .2rem',
      color: '#000',
    },
    h4: {
      marginBottom: 15,
    },
    h6: {
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#025159',
      light: '#048C7F',
      dark: '#23a88c',
      contrastText: '#1D8771',
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      light: '#d9d9d9',
      main: '#192734',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#e0dccc',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    info: {
      main: '#6c63ff',
    },
    warning: {
      main: '#a67458',
    },
    error: {
      main: '#e32a2a',
      dark: '#c22121',
    },
    success: {
      main: '#4BB543',
    },
  },
});

export { theme };
