import { GuiEnums } from '@guibil/app';
import { guiIsEqual } from '@guibil/helpers';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { IDefaultFormElementProps, IFormItemHelper, IGuiFormElementCommonProps, withGuiFormItem } from '../hocs/withGuiFormItem';
import { GuiDropdownAutocomplete } from './dropdown/GuiDropdownAutocomplete.dropdown';
import { GuiDropdownDefault } from './dropdown/GuiDropdownDefault.dropdown';
import { GuiDropdownMultiple } from './dropdown/GuiDropdownMultiple.dropdown';
import { GuiText, MaskNormalizerType } from './GuiText';

export type ISelection = Array<string | number | boolean> | { [key: string]: string | number | boolean };
export interface IGuiDropdownProps {
  /**
   * First type is array of strings, where it will be simply rendered.
   * Second type is object, where it expects { "Key1": "value1", "Key2": "value2"}
   */
  selections?: ISelection,
  isObjectSelections?: boolean,
  selectionsEnum?: IGuiApp.GuiAvailableEnums,
  showClearOption?: boolean,
  multipleSelection?: boolean,
  measurementLabel?: string,
  keys?: string[],
  autocomplete?: {
    freesolo?: boolean
  },
  className?: string,
  /**
  * Array of additional field Messages to display
  */
  helperMessages?: IFormItemHelper[];
  disabledValues?: any[];
  maskNormalizer?: MaskNormalizerType,
}

// since HOC transmits extra data, I need to extend it, in order get access to all props
type IProps = IGuiDropdownProps & IDefaultFormElementProps;

const GuiDropdown: FunctionComponent<IProps> = (props) => {
  const {
    multipleSelection, fieldValue, label,
    fieldEditMode, fieldOnChange, field, measurementLabel, className,
    selections: _selections, selectionsEnum, autocomplete, isObjectSelections, helperMessages,
  } = props;

  const selections = useMemo(() => {
    return _selections || (selectionsEnum && GuiEnums.get(selectionsEnum)) || [];
  }, [_selections, selectionsEnum]);

  useEffect(() => {
    if (multipleSelection) return;
    if (fieldValue) {
      const isFieldValueInSelection =
        (Array.isArray(selections) && selections.includes(fieldValue))
        || (Array.isArray(selections) === false && typeof (selections[fieldValue]) !== "undefined");

      if (!isFieldValueInSelection && !autocomplete?.freesolo) {
        fieldOnChange("");
      }
    }
  }, [selections, fieldValue]);



  let textValue = fieldValue;
  // In case when _selection is an object, textValue must be "Value" of {Key: "Value"}, not "Key"

  if (multipleSelection) {
    if (isObjectSelections) {
      let values = Object.values(selections);
      let keys = Object.keys(selections);
      return <GuiDropdownMultiple {...props} selections={values as ISelection} keys={keys} />
    }
    if (selectionsEnum) { textValue = GuiEnums.get(selectionsEnum, fieldValue); }

    return <GuiDropdownMultiple {...props} selections={selections} />
  }

  if (fieldEditMode) {
    if (autocomplete) {
      return <GuiDropdownAutocomplete {...props} selections={selections} />
    }
    return <GuiDropdownDefault {...props} selections={selections} />
  }

  // In case when _selection is an object, textValue must be "Value" of {Key: "Value"}, not "Key"
  if (typeof (selections) === "object" && !Array.isArray(selections)) { textValue = selections[fieldValue] }
  if (selectionsEnum) { textValue = GuiEnums.get(selectionsEnum, fieldValue); }

  return (
    <GuiText
      controlled
      disabled
      field={field}
      measurementLabel={measurementLabel}
      label={label}
      value={textValue || ""}
      editMode={false}
      className={className}
      helperMessages={helperMessages}
    />
  );
};

const GuiDropdownComponent = withGuiFormItem<IGuiDropdownProps & IGuiFormElementCommonProps>(
  React.memo(GuiDropdown, (prevProps, nextProps) => guiIsEqual(prevProps, nextProps)),
);

export { GuiDropdownComponent as GuiDropdown };

