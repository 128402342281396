export const guibil_components_en = {
  close: "Close",
  guiRefreshButton: {
    text: "Refresh",
  },
  confirmation: {
    title: "Confirmation",
    confirm: "OK",
    cancel: "Cancel",
  },
  genericDialog: {
    loading: "Loading...",
    close: "Close",
  },
  loadingDialog: {
    title: "Loading",
    description: "Please Wait...",
  },
  locationPicker: {
    title: "Pick Location",
    getCurrentLocation: "Find My Approximate Location",
    pickLocation: "Pick",
    province: "Province",
    suburb: "Neighborhood",
    district: "District",
    center: "Center",
    region: "Region",
    longitude: "Longitude",
    latitude: "Latitude",
    warning:
      "Warning: This feature does not indicate your actual location.It is just approximation.",
  },
  requestError: {
    title: "Request Error",
    button: "Retry",
  },
  timeInterval: {
    title: "Refresh Interval (sec)",
    second: "Second",
  },
  loading: {
    dots: "Loading...",
    actionPerforming: "Please Wait...",
  },
  switchText: {
    active: "Active",
    passive: "Passive",
    enabled: "Enabled",
    disabled: "Disabled",
    on: "On",
    off: "Off",
    yes: "Yes",
    no: "No",
    published: "Published",
    draft: "Draft",
    invalid: "Invalid",
    exists: "Exists",
    notExists: "Not Exists",
    registered: "Registered",
    notRegistered: "Not Registered",
    defined: "Defined",
    notDefined: "Not Defined"
  },
};
