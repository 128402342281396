import React, { useContext } from 'react';
import { IGuiFormProps } from '../types';

export const GuiFormPropsContext = React.createContext<IGuiFormProps | undefined>(undefined);

/**
 * if formElement is controlled, then it must not require any form context
 */
export const useGuiFormPropsContext = () => {
  const context = useContext(GuiFormPropsContext);
  if (typeof (context) === 'undefined') {
    throw new Error('Please useGuiFormPropsContext inside GuiForm');
  }

  return context;
};
