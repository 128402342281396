import { app_store_dashboard_en } from "../app-store/locales/en_US";
import { play_store_dashboard_en } from "../play-store/locales/en_US";


export const dashboard_en = {
  breadcrumbTitle: "Dashboard",
  pageTitle: "Dashboard",
  title: "Dashboard",
  menuTitle: "Dashboard",

  appStore: app_store_dashboard_en,
  playStore: play_store_dashboard_en,
};
