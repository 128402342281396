import React, { useContext } from 'react';

interface IGuiBrowserRouterContext {
  modulePermitted?: (module: any) => boolean,
}

export const GuiBrowserRouterContext = React.createContext<IGuiBrowserRouterContext | undefined>(undefined);

export const useGuiBrowserRouterContext = () => {
  const context = useContext(GuiBrowserRouterContext);
  if (typeof (context) === 'undefined') {
    throw new Error('use Gui routes inside GuiBrowserRouter');
  }

  return context;
};
