import { useParams } from 'react-router-dom';
import { useRequest } from "@guibil/api"
import { GuiPage, useGuiPageData } from "@guibil/page"
import { Grid } from '@material-ui/core';
import PageItem from 'pages/components/PageItem';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import GameLogo from 'pages/components/GameLogo';
import Datetime from 'pages/components/Datetime';
import { useLang } from '@guibil/app';
import ComponentContainer from 'pages/components/ComponentContainer';
import ComponentItem from 'pages/components/ComponentItem';
import { classNames, GuiButton, useWindowSize } from '@guibil/components';
import { useEffect, useState } from 'react';
import RetentionDialog from './retention-dialog/RetentionDialog';
import { makeStyles } from '@material-ui/styles';


const Detail = () => {
  const { id } = useParams<{ id: string }>();
  const { width } = useWindowSize();
  const classes = useStyles();
  const lang = useLang();
  const req = useRequest();
  const [data, setData] = useGuiPageData();
  const [retentionDialogProps, setRetentionDialogProps] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => { width !== 0 && setIsMobile(width > 666 ? false : true); }, [width])

  const fetchData = async () => {
    return await req.get(`installations/installation_details/${id}`);
  }

  return (
    <GuiPage
      fetchData={fetchData}
      setData={setData}
      title="gameTracker.development.installationDetails.detail.title"
      headerComponent={
        < ComponentContainer >
          <ComponentItem
            item={
              data?.game === null ? null : <FollowingStatus
                isFollowing={data?.game?.following}
                followUrl={`games/follow_game/${data?.game?.id}`}
              />
            }
          />
          <ComponentItem
            item={
              <GuiButton
                onClick={() => setRetentionDialogProps({
                  id: id,
                  register_date: data?.register_date,
                })}
                text="gameTracker.development.installationDetails.detail.retentionDialog.retention"
                variant="success"
                icon="table"
              />
            }
          />
        </ComponentContainer >
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classNames(classes.gameHeader, isMobile ? classes.gameHeaderMobile : null)}>
            <div>
              {
                data?.game === null ? null : <GameLogo
                  logo_image_url={data?.game?.logo_image_url}
                  width={180}
                  height={180}
                />
              }
            </div>
            <div style={{ width: "100%" }}>
              <div className={classNames(data?.game !== null ? classes.gameName : classes.gameNameNull, isMobile ? classes.gameNameMobile : null)}>
                <Name
                  linkTo={`/makers/maker/${data?.game?.artist?.id}`}
                  name={data?.game !== null ? data?.game?.artist?.name : data?.game_store_id}
                  url={data?.game !== null ? data?.game?.artist?.url : "https://apps.apple.com/us/app/id" + data?.game_store_id}
                  isUrl={data?.game !== null ? true : false}
                  fontSize="22px"
                  fontWeight={800}
                />
              </div>
              <div className={classNames(data?.game !== null ? classes.gameName : classes.gameNameNull, isMobile ? classes.gameNameMobile : null)}>
                {data?.is_test && <div className={classes.isTest}>Test</div>}
                <Name
                  linkTo={`/games/game/${data?.game?.id}`}
                  name={data?.game !== null ? data?.game?.name : data?.game_store_id}
                  url={data?.game !== null ? data?.game?.url : "https://apps.apple.com/us/app/id" + data?.game_store_id}
                  isUrl={data?.game !== null ? true : false}
                  fontSize="28px"
                  fontWeight={800}
                />
              </div>

              <div className={classNames(data?.game !== null ? classes.gameAvaragePlay : null, isMobile ? classes.gameAvaragePlayMobile : null)}>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12} md={6} lg={4}>
                    <PageItem
                      label={lang("gameTracker.development.installationDetails.detail.register_date")}
                      component={() => <Datetime datetimeString={data?.register_date} timeDisabled />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <PageItem
                      label={lang("gameTracker.development.installationDetails.detail.release_date")}
                      component={() => <Datetime datetimeString={data?.game?.release_date} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <PageItem
                      label={lang("gameTracker.development.installationDetails.detail.last_release_date")}
                      component={() => <Datetime datetimeString={data?.game?.last_release_date} />}
                    />
                  </Grid>
                </Grid>
              </div>

              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} md={6} lg={4}>
                  <div className={classNames(data?.game !== null ? classes.gameAvaragePlay : null, isMobile ? classes.gameAvaragePlayMobile : null)}>
                    <PageItem
                      label={lang("gameTracker.development.installationDetails.detail.player_count")}
                      component={() => {
                        return (
                          <div style={{ display: "flex" }}>
                            <div>
                              <div className={classes.title}>{lang("gameTracker.development.installationDetails.detail.total")}</div>
                              < div className={classes.title} > {
                                lang("gameTracker.development.installationDetails.detail.ios")}</div>
                              <div className={classes.title
                              } > {lang("gameTracker.development.installationDetails.detail.android")}</div>
                            </div>
                            <div style={{ marginLeft: "14px" }}>
                              <span style={{ fontSize: "12px" }}>{data?.play_detail?.player_count}</span><p />
                              <span style={{ fontSize: "12px" }}>{data?.ios_play_detail?.player_count}</span><p />
                              <span style={{ fontSize: "12px" }}>{data?.android_play_detail?.player_count}</span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <div className={classNames(data?.game !== null ? classes.gameAvaragePlay : null, isMobile ? classes.gameAvaragePlayMobile : null)}>
                    <PageItem
                      label={lang("gameTracker.development.installationDetails.detail.total_playtime_formatted")}
                      component={() => {
                        return (
                          <div style={{ display: "flex" }}>
                            <div>
                              <div className={classes.title}>{lang("gameTracker.development.installationDetails.detail.total")}</div>
                              < div className={classes.title} > {
                                lang("gameTracker.development.installationDetails.detail.ios")}</div>
                              <div className={classes.title
                              } > {lang("gameTracker.development.installationDetails.detail.android")}</div>
                            </div>
                            <div style={{ marginLeft: "14px" }}>
                              <span style={{ fontSize: "12px" }}>{data?.play_detail?.total_playtime_formatted}</span><p />
                              <span style={{ fontSize: "12px" }}>{data?.ios_play_detail?.total_playtime_formatted}</span><p />
                              <span style={{ fontSize: "12px" }}>{data?.android_play_detail?.total_playtime_formatted}</span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <div className={classNames(data?.game !== null ? classes.gameAvaragePlay : null, isMobile ? classes.gameAvaragePlayMobile : null)}>
                    <PageItem
                      label={lang("gameTracker.development.installationDetails.detail.average_playtimes_formatted")}
                      component={() => {
                        return (
                          <div style={{ display: "flex" }}>
                            <div>
                              <div className={classes.title}>{lang("gameTracker.development.installationDetails.detail.total")}</div>
                              < div className={classes.title} > {
                                lang("gameTracker.development.installationDetails.detail.ios")}</div>
                              <div className={classes.title
                              } > {lang("gameTracker.development.installationDetails.detail.android")}</div>
                            </div>
                            <div style={{ marginLeft: "14px" }}>
                              <span style={{ fontSize: "12px" }}>{data?.play_detail?.average_playtimes_formatted}</span><p />
                              <span style={{ fontSize: "12px" }}>{data?.ios_play_detail?.average_playtimes_formatted}</span><p />
                              <span style={{ fontSize: "12px" }}>{data?.android_play_detail?.average_playtimes_formatted}</span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

            </div>
          </div>
        </Grid>

      </Grid>
      {
        retentionDialogProps &&
        <RetentionDialog
          id={retentionDialogProps?.id}
          register_date={retentionDialogProps?.register_date}
          onClose={() => setRetentionDialogProps(null)} />
      }
    </GuiPage >
  )
}

const useStyles = makeStyles({
  gameHeader: {
    display: "flex",
    paddingTop: 16,
  },
  gameHeaderMobile: {
    display: "inherit",
  },
  gameName: {
    marginLeft: 15,
    paddingBottom: 10,
  },
  gameNameNull: {
    paddingBottom: 10
  },
  gameNameMobile: {
    marginLeft: 0
  },
  gameAvaragePlay: {
    marginLeft: 14
  },
  gameAvaragePlayMobile: {
    marginLeft: 0
  },
  title: {
    opacity: 0.8,
    fontWeight: 400,
  },
  isTest: {
    fontWeight: 600,
    fontSize: "16px",
    color: "var(--primarySuccess)",
  }
});

export default Detail
