import React, { useContext } from 'react';
import { IPagingState } from '../types';

interface IProps {
  performFetch: (queryString?: string, pagingData?: IPagingState | undefined) => Promise<void>,
  performRefresh: () => void,
  exporter: {
    exportRef: React.MutableRefObject<any>;
    performExcelExport: () => void,
    performPdfExport: () => void,
  }
}

export const GuiGridActionsContext = React.createContext<IProps | undefined>(undefined);

/**
 * if formElement is controlled, then it must not require any form context
 *
 * @param controlled
 */
export const useGuiGridActionsContext = () => {
  const context = useContext(GuiGridActionsContext);
  if (typeof (context) === 'undefined') {
    throw new Error('Please useGuiGridActionsContext inside GuiForm');
  }

  return context;
};
