import React, { useMemo } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import InputMask from 'inputmask';

export const GuiGridPhoneNumberCell: React.FC<DataTypeProvider.ValueFormatterProps> = (props) => {
  const { value } = props;

  const maskedValue = useMemo(() => {
    try {
      return InputMask.format(value, { mask: '+\e9\e0 (999) 999 99 99', escapeChar: "\e" })
    } catch (err) {
      return value;
    }
  }, [value])

  return (
    <a href={`tel:${maskedValue}`}>
      {maskedValue}
    </a>
  );
};
