import React, { useContext } from 'react';

export interface IGuiFormActionsContext {
  performFetch: () => Promise<void>,
  performFetchDefault: () => Promise<void>,
}

export const GuiFormActionsContext = React.createContext<IGuiFormActionsContext | undefined>(undefined);

export const useGuiFormActionsContext = () => {
  const context = useContext(GuiFormActionsContext);
  if (typeof (context) === 'undefined') {
    throw new Error('Please useGuiFormActionsContext inside GuiForm');
  }

  return context;
};
