export const installation_details_tr = {
  breadcrumbTitle: "Oynanma Detayları",
  pageTitle: "Oynanma Detayları",
  title: "Oynanma Detayları",
  menuTitle: "Oynanma Detayları",

  detail: {
    title: "Oynanma Detayları",
    register_date: "Kayıt Tarihi",
    release_date: "Yayın Tarihi",
    last_release_date: "Son Yayın Tarihi",

    total: "Toplam",
    ios: "IOS",
    android: "Android",
    player_count: "Oyuncu Sayısı",
    total_playtime_formatted: "Süre",
    average_playtimes_formatted: "Ortalama",

    retentionDialog: {
      title: "Retention",
      retention: "Retention",
      date: "Tarih",
      player_count: "Oyuncu Sayısı",

      filter: {
        filter: "Filtrele",
        os_type: "Os Tipi",
        start_date: "Başlangıç Tarihi",
        end_date: "Bitiş Tarihi"
      }
    },
  },

  playTimeChart: {
    title: "Oynanma Süresi",
    count: "Kullanıcı Sayısı",
  },

  players: {
    title: "Oyuncu Oynama Süreleri",
    download: "İndir",
    version: "Ver",
    os: "Os",

    install_datetime: "Yükleme Zamanı",
    play_count: "Toplam",
    playtimes_in_ms: "Süre",
    average_playtimes_in_ms: "Ortalama",
    level: "Seviye",

    details: " ",
    detailsButton: "Detaylar",
    events: " ",
    eventsButton: "Olaylar",

    playerPlayDetailsDialog: {
      playTimes: {
        title: "Oynama Süreleri",
        playtime_in_ms: "Oynama Süresi",
        created_datetime: "Oynama Zamanı",
      },

      dailyPlayTimes: {
        title: "Günlük Oynama Süreleri",
        day: "Gün",
        playtimes_in_ms: "Günlük Oynama Süresi",
        created_datetime: "Oynadığı Gün",
      }
    },

    playerLevelEvents: {
      title: "Kullanıcı Olayları",
      refresh: "Yenile",
      level: "Seviye",
      play_time: "Oynama Zamanı",

      oneTimeEvents: {
        title: "Tek Seferlik Olaylar",
        eventDateTime: "Olay Zamanı",
        name: "Adı",
        value: "Değeri",
        localEventDateTime: "Olay Zamanı (Yerel)",
      },

      spanningEvents: {
        title: "Süreli Olaylar",
        eventDateTime: "Olay Zamanı",
        name: "Adı",
        startValue: "Başlangıç Değeri",
        startDescription: "Başlangıç Açıklaması",
        finishValue: "Bitiş Değeri",
        finishDescription: "Bitiş Açıklaması",
        eventLocalStartFinishDatetime: "Başlangıç Bitiş Zamanı (Yerel)",
        span: "Süre",
      }
    },
  },
};
