import { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useRequest } from "@guibil/api"
import { GuiEnums, useLang } from "@guibil/app";
import { Grid } from '@material-ui/core';
import PageItem from 'pages/components/PageItem';
import Name from 'pages/components/Name';
import FollowingStatus from 'pages/components/FollowingStatus';
import Rate from 'pages/components/Rate';
import Tagger, { TagProps } from 'pages/components/Tagger';
import { useState } from 'react';
import { classNames, guiNotifier, useWindowSize } from '@guibil/components';
import Comments from 'pages/components/comments/Comments';
import { makeStyles } from '@material-ui/styles';
import GameLogo from 'pages/components/GameLogo';
import { GuiDateTimePicker, GuiForm, GuiSwitch, GuiText, useGuiFormData, useGuiFormHook } from '@guibil/form';
import ComponentContainer from 'pages/components/ComponentContainer';
import ComponentItem from 'pages/components/ComponentItem';
import GameVersions from '../components/game-versions/GameVersions';
import GameScreenshots from '../components/game-screenshots/GameScreenshots';
import TopChart from '../components/top-chart/TopChart';


const GameDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { width } = useWindowSize();
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();
  const [data, setData] = useGuiFormData();
  const [hook, setHook] = useGuiFormHook();
  const [selectedTags, setSelectedTags] = useState<TagProps[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => { width !== 0 && setIsMobile(width > 666 ? false : true); }, [width])

  const fetchData = async () => {
    const res = await req.get(`games/game/${id}`);
    setSelectedTags(res?.tags?.tags)
    return { ...res }
  }

  const handleSingleSelectedTag = async (tag: TagProps) => {
    try {
      await req.post(`games/tag_game/${id}`, { slug: tag.slug })
    } catch (err: any) {
      guiNotifier().handleError(err)
    }
  }

  const handleSelectedTags = (tags: TagProps[]) => setSelectedTags(tags);

  const formatBytes = (bytes: string, decimals = 2) => {
    var byte = parseInt(bytes);
    if (byte === 0) return '0Byte';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(byte) / Math.log(k));
    return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  return (
    <GuiForm
      type='reader'
      fetchData={fetchData}
      setData={setData}
      setHook={setHook}
      title="gameTracker.gameDetails.appStore.title"
      headerComponent={
        <ComponentContainer>
          <ComponentItem
            item={
              <FollowingStatus
                isFollowing={data?.values?.following}
                followUrl={`games/follow_game/${data?.values?.id}`}
              />
            }
          />
        </ComponentContainer>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classNames(classes.gameHeader, isMobile ? classes.gameHeaderMobile : null)}>
            <div>
              <GameLogo
                logo_image_url={data?.values?.logo_image_url}
                width={200}
                height={200}
              />
            </div>
            <div>
              <div className={classNames(classes.gameName, isMobile ? classes.gameNameMobile : null)}>
                <Name
                  linkTo={`/makers/maker/${data?.values?.artist?.id}`}
                  name={data?.values?.artist?.name}
                  url={data?.values?.artist?.url}
                  fontSize="22px"
                />
              </div>
              <div className={classNames(classes.gameName, isMobile ? classes.gameNameMobile : null)}>
                <Name
                  linkTo={`/games/game/${data?.values?.id}`}
                  name={data?.values?.name}
                  url={data?.values?.url}
                  isUrl={false}
                  fontSize="26px"
                  fontWeight={800}
                />
              </div>
              <div className={classNames(classes.gameName, isMobile ? classes.gameNameMobile : null)}>
                <span className={classes.genreTitle}>{lang("gameTracker.gameDetails.appStore.genre")}: </span>
                <span className={classes.genre}>{data?.values?.genres?.length > 0 ? data?.values?.genres?.map((genre: string) => GuiEnums.get("AppStoreGenre", genre)).join(", ") : "-"}</span>
              </div>
              <div className={classNames(classes.gameQr, isMobile ? classes.gameQrMobile : null)}>
                <GameLogo
                  logo_image_url={data?.values?.qr_image_url}
                  width={104}
                  height={104}
                  border_radius="0px"
                />
              </div>
            </div>
          </div>
        </Grid>

        {
          data?.values?.artist_history &&
          <Grid item xs={12} md={12} lg={12}>
            <PageItem
              label={lang("gameTracker.gameDetails.appStore.artist_history")}
              component={
                data?.values?.artist_history.map((history: any, index: number) => {
                  return (
                    <div key={index}>
                      <span style={{ display: "flex" }}>
                        <Name
                          linkTo={`/makers/maker/${history?.old_artist?.id}`}
                          name={history?.old_artist?.name}
                          url={history?.old_artist?.url}
                          fontSize="15px"
                        />
                        <span style={{ padding: "0px 16px" }}>{'>'}</span>
                        <Name
                          linkTo={`/makers/maker/${history?.new_artist?.id}`}
                          name={history?.new_artist?.name}
                          url={history?.new_artist?.url}
                          fontSize="15px"
                        />
                      </span>
                    </div>
                  );
                })
              }
            />
          </Grid>
        }

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>

            <Grid item container>
              <Grid xs={12}>
                <GuiText
                  field=""
                  label="gameTracker.gameDetails.appStore.current_version"
                  value={data?.values?.current_version ? data?.values?.current_version : "-"}
                />
              </Grid>

              <Grid xs={12}>
                <GuiDateTimePicker
                  field="release_date"
                  label="gameTracker.gameDetails.appStore.release_date"
                />
              </Grid>

              <Grid xs={12}>
                <GuiDateTimePicker
                  field="last_release_date"
                  label="gameTracker.gameDetails.appStore.last_release_date"
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={12} md={6} lg={3}>
            <Grid item container>
              <Grid xs={12}>
                <GuiText
                  field=""
                  label="gameTracker.gameDetails.appStore.total_number_of_reviews"
                  value={data?.values?.total_number_of_reviews ? data?.values?.total_number_of_reviews : "-"}
                />
              </Grid>

              <Grid xs={12}>
                <GuiText
                  field=""
                  label="gameTracker.gameDetails.appStore.bundle"
                  value={data?.values?.bundle_id ? data?.values?.bundle_id : "-"}
                />
              </Grid>

              <Grid xs={12}>
                <GuiText
                  field=""
                  label="gameTracker.gameDetails.appStore.size"
                  value={data?.values?.size ? formatBytes(data?.values?.size) : "-"}
                />
              </Grid>

              <Grid xs={12}>
                <GuiText
                  field=""
                  label="gameTracker.gameDetails.appStore.minimum_os_version"
                  value={data?.values?.minimum_os_version ? data?.values?.minimum_os_version : "-"}
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={12} md={6} lg={3}>
            <Grid item container>
              <Grid xs={12}>
                <Rate
                  label="gameTracker.gameDetails.appStore.rate"
                  rate={data?.values?.rate}
                  rateUrl={`games/rate_game/${data?.values?.id}`}
                />
              </Grid>

              <Grid xs={12}>
                <GuiSwitch
                  field="is_free"
                  label="gameTracker.gameDetails.appStore.is_free"
                  switchText="yes-no"
                />
              </Grid>

              {
                data?.values?.is_free ?
                  null :
                  <Grid xs={12}>
                    <GuiText
                      field="price"
                      label="gameTracker.gameDetails.appStore.price"
                      value={data?.values?.price}
                    />
                  </Grid>
              }

              <Grid xs={12}>
                <GuiSwitch
                  field="editor_is_choice"
                  label="gameTracker.gameDetails.appStore.editor_is_choice"
                  switchText="yes-no"
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={12} md={6} lg={3}>
            <Grid xs={12}>
              <Rate
                label={data?.values?.store_rate_count != 0 ? `${lang("gameTracker.gameDetails.appStore.store_rate")} (${data?.values?.store_rate_count ? data?.values?.store_rate_count : "-"})` : lang("gameTracker.gameDetails.appStore.store_rate")}
                rate={data?.values?.store_rate}
                withoutBg
                isStoreRate
              />
            </Grid>
            <Grid xs={12}>
              <div>
                <Rate
                  rate={5}
                  withoutBg
                  isFixedRate
                  fixedRate={data?.values?.rating_counts ? data?.values?.rating_counts?.five : 0}
                  isStoreRate
                />
                <Rate
                  rate={4}
                  withoutBg
                  isFixedRate
                  fixedRate={data?.values?.rating_counts ? data?.values?.rating_counts?.four : 0}
                  isStoreRate
                />
                <Rate
                  rate={3}
                  withoutBg
                  isFixedRate
                  fixedRate={data?.values?.rating_counts ? data?.values?.rating_counts?.three : 0}
                  isStoreRate
                />
                <Rate
                  rate={2}
                  withoutBg
                  isFixedRate
                  fixedRate={data?.values?.rating_counts ? data?.values?.rating_counts?.two : 0}
                  isStoreRate
                />
                <Rate
                  rate={1}
                  withoutBg
                  isFixedRate
                  fixedRate={data?.values?.rating_counts ? data?.values?.rating_counts?.one : 0}
                  isStoreRate
                />
              </div>
            </Grid>
          </Grid>


          <Grid item xs={12} md={6} lg={6}>
            <Tagger
              label="gameTracker.gameDetails.appStore.tag"
              selecteds={selectedTags}
              availables={data?.values?.tags?.available_tags}
              handleSelectedTags={handleSelectedTags}
              handleSingleSelectedTag={handleSingleSelectedTag}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Comments
              label="gameTracker.gameDetails.appStore.comment"
              onSuccess={() => hook?.fetch()}
              postUrl={`games/comment_game/${data?.values?.id}`}
              updateUrl={`games/comment_game/${data?.values?.id}`}
              comments={data?.values?.comments}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TopChart game_id={id} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <PageItem
              label={lang("gameTracker.gameDetails.appStore.description")}
              component={
                <div className={classes.description} dangerouslySetInnerHTML={{ __html: data?.values?.description_html ? data?.values?.description_html : "-" }} />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <GameScreenshots screenshots={data?.values?.screenshots} showVideos={true} />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <PageItem
            label={lang("gameTracker.gameDetails.appStore.version_history")}
            component={
              data?.values?.versions?.length > 0 ?
                <GameVersions versions={data?.values?.versions} />
                :
                <span>-</span>
            }
          />
        </Grid>
      </Grid >
      <Grid item xs={12} />
    </GuiForm>
  )
}

const useStyles = makeStyles({
  description: {
    borderRadius: "2px",
    marginTop: "8px",
    padding: "10px 16px",
    fontSize: "14px",
    fontWeight: 500,
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: 300,
    "&::-webkit-scrollbar-track": {
      backgroundColor: "var(--primaryGridBorderColor2)",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar": {
      width: 10,
      height: 10,
      borderRadius: "2px",
      backgroundColor: "var(--primaryGridBorderColor2)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#8e8e8e",
    }
  },
  gameHeader: {
    display: "flex",
    paddingTop: 16
  },
  gameHeaderMobile: {
    display: "inherit",
  },
  gameName: {
    marginLeft: 15
  },
  gameNameMobile: {
    marginLeft: 0
  },
  gameQr: {
    marginLeft: 15,
    marginTop: 6
  },
  gameQrMobile: {
    marginLeft: 0
  },
  genreTitle: {
    fontSize: "14px",
    color: "var(--defaultTextColor)",
    fontWeight: 600
  },
  genre: {
    fontSize: "14px",
    color: "var(--defaultTextColor)",
    fontWeight: 400
  },
});

export default GameDetails
