import { IEnumLangBuilder } from '@guibil/app';
import { AppEnumTypes } from '../app-enum';
import { app_active_psasive_en } from '../general/active-passive/locales/en_US';
import { app_store_chart_en } from '../general/app-store-chart/locales/en_US';
import { date_filter_en } from '../general/date-filter/locales/en_US';
import { app_enabled_disabled_en } from '../general/enabled-disabled/locales/en_US';
import { app_exists_not_exists_en } from '../general/exist-nonexists/locales/en_US';
import { app_store_genre_en } from '../general/app-store-genre/locales/en_US';
import { app_language_en } from '../general/language/locales/en_US';
import { notification_type_en } from '../general/notification-type/locales/en_US';
import { app_on_off_en } from '../general/on-off/locales/en_US';
import { play_store_chart_en } from '../general/play-store-chart/locales/en_US';
import { play_store_genre_en } from '../general/play-store-genre/locales/en_US';
import { app_registered_not_registered_en } from '../general/registered-not-registered/locales/en_US';
import { app_yes_no_en } from '../general/yes-no/locales/en_US';
import { os_type_en } from '../general/os-type/locales/en_US';
import { gid_status_en } from '../general/gid-status/locales/en_US';

export const app_enum_en: IEnumLangBuilder<AppEnumTypes> = {
  AppLanguage: app_language_en,
  AppYesNo: app_yes_no_en,
  AppOnOff: app_on_off_en,
  AppActivePassive: app_active_psasive_en,
  AppEnabledDisabled: app_enabled_disabled_en,
  AppExistsNotExists: app_exists_not_exists_en,
  AppRegisteredNotRegistered: app_registered_not_registered_en,
  NotificationType: notification_type_en,
  DateFilter: date_filter_en,
  OsType: os_type_en,
  PlayStoreChart: play_store_chart_en,
  PlayStoreGenre: play_store_genre_en,
  AppStoreChart: app_store_chart_en,
  AppStoreGenre: app_store_genre_en,
  GidStatus: gid_status_en,
};
