import React from 'react';
import { useLang } from '@guibil/app';
import { GuiGenericDialog } from './GuiGenericDialog';

export interface IGuiConfirmationDialogConfig {
  title?: string,
  description?: string,
  confirmText?: string,
  cancelText?: string,
  size?: | 'xs' | 'sm' | 'md' | 'lg' | 'xl',
}

interface IProps extends IGuiConfirmationDialogConfig {
  onCancel?: Function,
  onConfirm?: Function,
  isOpen?: boolean,
}

export const GuiConfirmationDialog: React.FC<IProps> = (props) => {
  const lang = useLang();
  const {
    size, title, confirmText, description, cancelText, onConfirm, onCancel, isOpen,
  } = props;

  return (
    <GuiGenericDialog
      isOpen={isOpen}
      dismissible={false}
      drag
      description={description}
      hideCloseButton
      onClose={onCancel}
      size={size || 'sm'}
      title={title || 'guibil:components.confirmation.title'}
      buttons={[
        {
          text: confirmText || lang('guibil:components.confirmation.confirm'), icon: 'check-circle', variant: 'success', onClick: () => { if (onConfirm) onConfirm(); },
        },
        {
          text: cancelText || lang('guibil:components.confirmation.cancel'), icon: 'times-circle', variant: 'cancel', onClick: () => { if (onCancel) onCancel(); },
        },
      ]}
    />
  );
};

GuiConfirmationDialog.defaultProps = {
  isOpen: true,
};
