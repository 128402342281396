import { useState } from "react";
import { useRequest } from "@guibil/api";
import Rate from "pages/components/Rate";
import Tagger from "pages/components/Tagger";
import { useParams } from "react-router-dom";
import GidToPdf from "./components/GidToPdf";
import { useGuiPageData } from "@guibil/page";
import { GuiEnums, useLang } from "@guibil/app";
import GameImages from "./components/GameImages";
import { Grid, makeStyles } from '@material-ui/core';
import WithLastUpdate from "./components/WithLastUpdate";
import ValuesList from "pages/components/valuesList/ValuesList";
import FilesUpload from "pages/components/filesUpload/FilesUpload";
import { GuiRichText } from "@guibil/form/form-elements/elements/GuiRichText";
import { GuiDateTimePicker, GuiDropdown, GuiForm, GuiText } from "@guibil/form";

const GidDisplay = () => {
  const lang = useLang();
  const req = useRequest();
  const classes = useStyles();
  const [data, setData] = useGuiPageData();
  const { id } = useParams<{ id: string }>();
  const [printOpen, setPrintOpen] = useState<boolean>(false);

  const fetchData = async () => {
    const response = await req.get("gids/gid/display/" + id);
    response.histories.status = response?.histories?.status.map((history: any) => ({ ...history, value: GuiEnums.get("GidStatus", history.value) }))
    return response;
  }

  return <div className={classes.root}>
    <GuiForm
      type="reader"
      fetchData={fetchData}
      setData={setData}
      title={data?.values?.title}
      labelPath="gameTracker.development.gids"
      extraButtons={[{
        onClick: () => setPrintOpen(true),
        text: "gameTracker.development.gidDisplay.printGid",
        variant: "success",
        icon: "print"
      }]}
    >
      {/* Summary */}
      <div>
        <div className={classes.title}>
          {lang("gameTracker.development.gidDisplay.sections.summary")}
        </div>
        <div className={classes.container}>
          <Grid container spacing={2} >

            <Grid item xs={12} md={4}>
              <GuiText
                field="created_by"
                label="gameTracker.development.gidDisplay.summary.created_by"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GuiText
                field="updated_by"
                label="gameTracker.development.gidDisplay.summary.updated_by"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Rate
                label="gameTracker.development.gidDisplay.summary.rate"
                rate={data?.values?.rate}
                rateUrl={"gids/gid/rate/" + id}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GuiDateTimePicker
                field="created_datetime"
                label="gameTracker.development.gidDisplay.summary.created_datetime"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GuiDateTimePicker
                field="updated_datetime"
                label="gameTracker.development.gidDisplay.summary.updated_datetime"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.summary.status"
                histories={data?.values?.histories?.status}
              >
                <GuiDropdown
                  field="status"
                  selectionsEnum="GidStatus"
                  label="gameTracker.development.gidDisplay.summary.status"
                />
              </WithLastUpdate>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* General */}
      <div>
        <div className={classes.title}>
          {lang("gameTracker.development.gidDisplay.sections.general")}
        </div>
        <div className={classes.container}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.general.title"
                histories={data?.values?.histories?.title}
              >
                <GuiText
                  field="title"
                  label="gameTracker.development.gidDisplay.general.title"
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.general.mantra"
                histories={data?.values?.histories?.mantra}
              >
                <GuiText
                  field="mantra"
                  multiline
                  label="gameTracker.development.gidDisplay.general.mantra"
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.general.popularity"
                histories={data?.values?.histories?.popularity}
              >
                <GuiDropdown
                  field="popularity"
                  label="gameTracker.development.gidDisplay.general.popularity"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.general.art_difficulty"
                histories={data?.values?.histories?.art_difficulty}
              >
                <GuiDropdown
                  field="art_difficulty"
                  label="gameTracker.development.gidDisplay.general.art_difficulty"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.general.dev_difficulty"
                histories={data?.values?.histories?.dev_difficulty}
              >
                <GuiDropdown
                  field="dev_difficulty"
                  label="gameTracker.development.gidDisplay.general.dev_difficulty"
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.general.core_loops"
                histories={data?.values?.histories?.core_loops}
              >
                <ValuesList
                  disabled
                  label={lang("gameTracker.development.gidDisplay.general.core_loops")}
                  values={data?.values?.core_loops}
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12} md={6}>
              <Tagger
                label="gameTracker.development.gidDisplay.general.planned_developers"
                availables={[]}
                disabled
                selecteds={data?.values?.planned_developers}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Tagger
                label="gameTracker.development.gidDisplay.general.planned_designers"
                availables={[]}
                disabled
                selecteds={data?.values?.planned_designers}
              />
            </Grid>

          </Grid>
        </div>
      </div>

      {/* Dynamics */}
      <div>
        <div className={classes.title}>
          {lang("gameTracker.development.gidDisplay.sections.dynamic")}
        </div>
        <div className={classes.container}>
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <GuiText
                field="dynamic.genre"
              />
            </Grid>
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.dynamic.progress"
                richText
                fullWidth
                histories={data?.values?.histories?.dynamic?.progress}
              >
                <GuiRichText
                  field="dynamic.progress"
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.dynamic.goal"
                richText
                fullWidth
                histories={data?.values?.histories?.dynamic?.goal}
              >
                <GuiRichText
                  field="dynamic.goal"
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.dynamic.challenge"
                richText
                fullWidth
                histories={data?.values?.histories?.dynamic?.challenge}
              >
                <GuiRichText
                  field="dynamic.challenge"
                />
              </WithLastUpdate>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Mechanics */}
      <div>
        <div className={classes.title}>
          {lang("gameTracker.development.gidDisplay.sections.mechanic")}
        </div>
        <div className={classes.container}>
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.mechanic.cores"
                histories={data?.values?.histories?.mechanic?.cores}
              >
                <ValuesList
                  disabled
                  label={lang("gameTracker.development.gidDisplay.mechanic.cores")}
                  values={data?.values?.mechanic?.cores}
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.mechanic.subs"
                histories={data?.values?.histories?.mechanic?.subs}
              >
                <ValuesList
                  disabled
                  label={lang("gameTracker.development.gidDisplay.mechanic.subs")}
                  values={data?.values?.mechanic?.subs}
                />
              </WithLastUpdate>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Design */}
      <div>
        <div className={classes.title}>
          {lang("gameTracker.development.gidDisplay.sections.design")}
        </div>
        <div className={classes.container}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.design.description"
                richText
                fullWidth
                histories={data?.values?.histories?.design?.description}
              >
                <GuiRichText
                  field="design.description"
                />
              </WithLastUpdate>
            </Grid>

            <Grid item xs={12}>
              <WithLastUpdate
                title="gameTracker.development.gidDisplay.design.notes"
                richText
                fullWidth
                histories={data?.values?.histories?.design?.notes}
              >
                <GuiRichText
                  field="design.notes"
                />
              </WithLastUpdate>
            </Grid>

            <Grid item xs={12}>
              <WithLastUpdate
                fullWidth
                title="gameTracker.development.gidDisplay.design.b64encode_images"
                histories={data?.values?.histories?.design?.b64encode_images}
              >
                <GameImages
                  label="gameTracker.development.gidDisplay.design.b64encode_images"
                  disabled
                  b64encode_images={data?.values?.design?.b64encode_images}
                />
              </WithLastUpdate>
            </Grid>
            <Grid item xs={12}>
              <WithLastUpdate
                fullWidth
                title="gameTracker.development.gidDisplay.design.files"
                histories={data?.values?.histories?.design?.files}
              >
                <FilesUpload
                  label="gameTracker.development.gids.design.files"
                  disabled
                  files={data?.values?.design?.files}
                />
              </WithLastUpdate>
            </Grid>
          </Grid>
        </div>
      </div>

      {printOpen && <GidToPdf data={data?.values} onClose={() => setPrintOpen(false)} />}
    </GuiForm>
  </div>
}

const useStyles = makeStyles(() => ({
  root: {
    "& h1": {
      fontWeight: 800,
      fontSize: "22px",
    },
  },
  container: {
    paddingBottom: "30px",
  },
  title: {
    paddingTop: "20px",
    fontWeight: 800,
    fontSize: "20px",
    color: "var(--titleColor)"
  },
}));

export default GidDisplay
