import * as Yup from 'yup';
import {
  isEqual, cloneDeep, isEmpty, get,
} from 'lodash';
import moment from 'moment';

export function forgetLocalStorage(name: string): void {
  window.localStorage.removeItem(name);
}

export function isNotNullOrUndefined(obj: any): boolean {
  if (typeof obj !== 'undefined' && obj !== null) return true;
  return false;
}

export function isNullOrUndefined(obj: any): boolean {
  if (typeof obj === 'undefined' || obj === null) return true;
  return false;
}

export function getLocalStorageData(key: string, defaultValue: any = null): null | any {
  if (window.localStorage.getItem(key) !== null && window.localStorage.getItem(key) !== 'undefined') {
    return window.localStorage.getItem(key);
  }
  if (defaultValue !== null) {
    setLocalStorageData(key, defaultValue);
    return defaultValue;
  }
  return null;
}

export function setLocalStorageData(key: string, value: any): void {
  if (typeof (value) !== 'string') {
    value = JSON.stringify(value);
  }
  window.localStorage.setItem(key, value);
}

export function isIPValid(ip: string, emptyable = false): boolean {
  if (emptyable === true && ip === '') { return true; }
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip);
}
export function isHexValid(value: string, emptyable = false): boolean {
  if (emptyable === true && value === '') { return true; }
  return /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/.test(value);
}

export function isSubnetMaskValid(ip: string, emptyable = false): boolean {
  if (emptyable === true && ip === '') { return true; }
  return /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/.test(ip);
}

export function isIPV6Valid(ip: string, emptyable = false): boolean {
  if (emptyable === true && ip === '') { return true; }
  return /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/.test(ip);
}
export function isMACValid(mac: string, emptyable = false): boolean {
  if (emptyable === true && mac === '') { return true; }
  return /^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/.test(mac);
}
export function isFrequencyValid(frequency: any): boolean {
  frequency = parseFloat(frequency);
  return (frequency % 0.3125) === 0;
}
export function isUrlValid(url: any, emptyable = false): boolean {
  if (emptyable === true && url === '') { return true; }
  // Regex from https://stackoverflow.com/a/163684/6307070 by RegexBuddy for Windows OS
  return /^(\b(https|http):\/\/)[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]$/.test(url);
}
export function isSipNumber(sipNumber: string, emptyable: boolean = false): boolean {
  if (emptyable === true && sipNumber === "") { return true; }
  return /^(\S+[a-zA-Z0-9]{3,30})\@(((\w+)(.\w+)?.([a-zA-Z]{2,3})$)|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$))/.test(sipNumber)
}
export const LONGITUDE_VALIDATION = () => Yup.object().shape({
  degree: Yup.number().required().min(0).max(179),
  minute: Yup.number().required().min(0).max(59),
  second: Yup.number().required().min(0).max(59.99),
});
export const LATITUDE_VALIDATION = () => Yup.object().shape({
  degree: Yup.number().required().min(0).max(89),
  minute: Yup.number().required().min(0).max(59),
  second: Yup.number().required().min(0).max(59.99),
});

/**
 * Used to get field's Name from the Object
 * field may tolerate first letter to be Capitalized or vice-versa
 *
 * @export
 * @param {*} row
 * @param {string} field
 * @returns {string}
 */
export function getFieldByName(row: any, field: string, strictMode = false): string {
  try {
    if (typeof (getProperty(field, row)) !== 'undefined') {
      return field;
    }
    if (field.length > 0) {
      const fstChar = field.charAt(0);
      const newField = ((fstChar.toLowerCase() === fstChar) ? fstChar.toUpperCase() : fstChar.toLowerCase()) + field.substring(1);

      if (typeof (getProperty(newField, row)) !== 'undefined') {
        return newField;
      }
    }

    return strictMode ? '' : field;
  } catch (error) {
    return '';
  }
}

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 */
export function getProperty(propertyName: string, object: any) {
  try {
    return get(object, propertyName, undefined);
  } catch (error) {
    return undefined;
  }
}
export { set as guiSetProperty } from 'lodash';

export function checkPropertyExists(propertyName: string, object: any) {
  try {
    return typeof (getProperty(propertyName, object)) !== "undefined"
  } catch (error) {
    return false;
  }
}

declare global {
  interface Window { global: any; }
}
window.global = window.global || {};

export function setGlobalVariable(type = 'api', variable: string, value: any) {
  if (isNullOrUndefined(window.global[type])) {
    window.global[type] = {};
  }
  if (isNullOrUndefined(window.global[type][variable])) {
    window.global[type][variable] = [value];
  } else if (Array.isArray(window.global[type][variable])) {
    window.global[type][variable].push(value);
  }
}

export function removeGlobalVariable(type = 'api', variable: string) {
  if (isNullOrUndefined(window.global[type])) {
    window.global[type] = {};
  }
  if (isNotNullOrUndefined(window.global[type][variable])) {
    if (Array.isArray(window.global[type][variable])) {
      window.global[type][variable].shift();
    } else {
      delete window.global[type][variable];
    }
  }
}

export function getGlobalVariable(type = 'api', variable: string) {
  if (isNullOrUndefined(window.global[type])) {
    window.global[type] = {};
  }
  if (isNotNullOrUndefined(window.global[type][variable])) {
    if (Array.isArray(window.global[type][variable])) {
      const size = window.global[type][variable].length;
      return window.global[type][variable][size - 1];
    }

    return window.global[type][variable];
  }

  return null;
}

// I get it from stackoverflow
// Search google for `most efficient method to groupby on a array of objects`
export function groupBy(xs: any, key: any) {
  return xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function fileDownload(data: any, filename: string) {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const blobUrl = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobUrl;
  tempLink.setAttribute('download', filename);
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobUrl);
}

export function generateRandomString(): string {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

type ArrayOrObjectCallbackType = | 'array' | 'object';
type ArrayOrObjectCallbackParamsType = (item: any, key: any, type: ArrayOrObjectCallbackType) => any;
/**
 * It is same as:
 * target.foreach() for an Array, and
 * Object.keys(target).foreach(key=>{
 *  let option=target[key]
 * })
 *
 * It expects Callback function as a second parameter, which passes (Item, Key, Type: |"array"|"object")
*/
export function forEachArrayOrObject(target: Array<any> | any, callback: ArrayOrObjectCallbackParamsType): void {
  if (Array.isArray(target)) {
    target.forEach((item: any, key: any) => {
      callback(item, key, 'array');
    });
  }
  else if (typeof (target) === 'object') {
    Object.keys(target).forEach((key: any) => {
      const item = target[key];
      callback(item, key, 'object');
    });
  }
}

/**
 * It is same as:
 * target.foreach() for an Array, and
 * Object.keys(target).foreach(key=>{
 *  let option=target[key]
 * })
 *
 * It expects Callback function as a second parameter, which passes (Item, Key, Type: |"array"|"object")
*/
export function forEachArrayOrObjectNoLinks(target: Array<any> | any, callback: ArrayOrObjectCallbackParamsType): void {
  if (Array.isArray(target)) {
    target.forEach((item: any, key: any) => {
      callback(item, key, 'array');
    });
  }
  else if (typeof (target) === 'object') {
    Object.keys(target).forEach((key: any) => {
      if (key !== "_links") {
        const item = target[key];
        callback(item, key, 'object');
      }
    });
  }
}

/**
 * It is same as:
 * target.map() for an Array, and
 * Object.keys(target).map(key=>{
 *  let option=target[key]
 * })
 *
 * It expects Callback function as a second parameter, which passes (Item, Key, Type: |"array"|"object")
*/
export function mapArrayOrObject(target: Array<any> | any, callback: ArrayOrObjectCallbackParamsType): Array<any> {
  if (Array.isArray(target)) {
    return target.map((item: any, key: any) => callback(item, key, 'array'));
  }
  if (typeof (target) === 'object') {
    return Object.keys(target).map((key: any) => {
      const item = target[key];
      return callback(item, key, 'object');
    });
  }
  return [];
}

/**
 * It is same as:
 * target.map() for an Array, and
 * Object.keys(target).map(key=>{
 *  let option=target[key]
 * })
 *
 * It expects Callback function as a second parameter, which passes (Item, Key, Type: |"array"|"object")
*/
export function mapArrayOrObjectNoLinks(target: Array<any> | any, callback: ArrayOrObjectCallbackParamsType): Array<any> {
  if (Array.isArray(target)) {
    return target.map((item: any, key: any) => callback(item, key, 'array'));
  }
  if (typeof (target) === 'object') {
    return Object.keys(target).map((key: any) => {
      if (key !== "_links") {
        const item = target[key];
        return callback(item, key, 'object');
      }
    });
  }
  return [];
}

export function filterObjectKeysWithArrayList(targetObject: any, targetArray: Array<any>) {
  try {
    const filtered = Object.keys(targetObject)
      .filter((key) => targetArray.includes(key)).reduce((obj: any, key: any) => {
        obj[key] = targetObject[key];
        return obj;
      }, {});
    return filtered;
  } catch (error) {
    return {};
  }
}

export function convertToArray(target: any): Array<any> {
  if (Array.isArray(target)) {
    return target;
  }

  if (typeof (target) === 'undefined') {
    return [];
  }
  return [target];
}

export function guiCloneDeep<T>(target: T): T {
  return cloneDeep(target);
}

export function guiIsEqual(a: any, b: any) {
  return isEqual(a, b);
}

export function guiIsEmpty(target: any) {
  return isEmpty(target);
}

export function humanizeDateTime(datetime: any) {
  if (!moment(datetime).isValid()) { return datetime; }

  const minutes = moment(datetime).diff(moment(), 'minutes');
  return moment.duration(minutes, 'minutes').humanize(true);
}

/**
 * ex: target = 5.2, precision = 3 => 5.2
*/
export function getInPrecision(target: number, precision = 2): number {
  const mult = precision >= 0 ? Math.pow(10, precision) : 1;
  return Math.round(target * mult) / mult;
}

/**
 * precision is strictly as defined, filled with 0's at the end
 * ex: target = 5.2, precision = 3 => 5.200
 * returns string, since number eliminates final 0's. It can be used to show number as text
*/
export function getInFullPrecision(target: number, precision = 2): string {
  precision = precision >= 0 ? precision : 0;
  return getInPrecision(target, precision).toFixed(precision);
}

export function capitalize(target: string): any {
  return target[0].toUpperCase() + target.slice(1).toLowerCase();
}

export function mapAlarmsToTerminals(alarms: any, terminals: any) {
  terminals = guiCloneDeep(terminals);

  forEachArrayOrObject(terminals, (terminal, key) => {
    for (let it = 0; it < alarms.length; it++) {
      const alarm = alarms[it];
      if (alarm.terminalId === terminal.id) {
        terminals[key].alarms = guiCloneDeep(alarm);
        break;
      }
    }

    if (isNullOrUndefined(terminals[key].alarms)) {
      terminals[key].alarms = {};
    }
  });

  return terminals;
}

export function getObjValueByKey(targetObj: any, objKey: string) {
  try {
    if (typeof (targetObj[objKey]) !== 'undefined') {
      return targetObj[objKey];
    }

    if (typeof (objKey) !== 'string') { objKey = JSON.stringify(objKey).toLowerCase(); }

    const arrayOfKeys = Object.keys(targetObj);
    for (let it = 0; it < arrayOfKeys.length; it++) {
      if (arrayOfKeys[it].toLowerCase() === objKey.toLowerCase()) {
        return targetObj[arrayOfKeys[it]];
      }
    }
  } catch (err) { }

  return undefined;
}

export function fillWithCharacters(target: any, targetLength: number, character: string, isPrefix = true) {
  target = JSON.stringify(target);
  while (target.length < targetLength) {
    if (isPrefix) { target = character + target; } else { target += character; }
  }
  return target;
}

export function guiIsNumber(target: any): target is number {
  try {
    return isNaN(parseFloat(target)) === false;
  } catch (err) {
    return false;
  }
}

export function groupDevicesListForNavigation(devices: any): Array<{ subtype: any, deviceGroup: any }> {
  const groupedDevices = groupBy(devices, 'subtype');
  // if (groupedDevices.AselsanModem) {
  //   const aselsanModems = groupBy(groupedDevices.AselsanModem, "parentId")
  //   // delete groupedDevices.AselsanModem;

  //   // if (aselsanModems[PAMA_PARENT_ID]) { groupedDevices["AselsanModemPama"] = aselsanModems[PAMA_PARENT_ID]; }
  // }

  const orderedResult: Array<{ subtype: any, deviceGroup: any }> = [];
  forEachArrayOrObject(groupedDevices, (deviceGroup, key) => {
    const tmp = {
      deviceGroup,
      subtype: key,
    };
    orderedResult.push(tmp);
  });
  orderedResult.sort((a, b) => (a.subtype <= b.subtype ? -1 : 1));

  return orderedResult;
}

export function toLowerCase(value: string): string {
  return String(value).toLowerCase();
}

export function stringPredicate(value: string, searchText: string) {
  return searchText === '' || toLowerCase(value).startsWith(toLowerCase(searchText));
}

/**
 * This is function which will parseFloat, without cleaning right most 0's in decimal, ex: 5.02000
 *
 * @export
 * @param {(string|number)} number
 * @returns
 */
export function guiParseFloat(number: string | number) {
  if (guiIsNumber(number)) {
    let toFixedLength = 0;
    let str = String(number);
    ['.', ','].forEach((seperator) => {
      const arr = str.split(seperator);
      if (arr.length === 2) {
        toFixedLength = arr[1].length;
        // since parseFloat, does not accept ',' as decimal seperator, replace it with '.'
        if (seperator === ',' && !str.includes('.')) {
          str = str.replace(',', '.');
        }
      }
    });

    // If there is no precision, then using parseFloat has it's limits in BigInt
    if (toFixedLength === 0) {
      return parseInt(str);
    }
    return parseFloat(str).toFixed(toFixedLength);
  }
  return number;
}

export function getRequestErrorResponseData(error: any): undefined | { message: string, title: string } {
  if (isNotNullOrUndefined(error) && isNotNullOrUndefined(error.response) && isNotNullOrUndefined(error.response.data)) {
    return {
      message: error.response.data.message || error.response.data.Message,
      title: error.response.data.title || error.response.data.Title,
    };
  }

  return undefined;
}

export function getRequestErrorResponseMessage(error: any): undefined | string {
  const data = getRequestErrorResponseData(error);
  if (data) {
    return data.message;
  }

  return undefined;
}

export function getHiddenText(target: any, hideChar = '*') {
  const { length } = String(target);
  let result = '';

  while (result.length < length) result += hideChar;
  return result;
}

export function replaceCharAt(target: string, position: number, replaceStr: string) {
  return target.slice(0, position) + replaceStr + target.slice(position + 1);
}

export function stringLimit(str: string, limit = 20) {
  if (typeof (str) !== 'string') { return ''; }

  if (str.length <= limit) { return str; }
  return `${str.substring(0, limit)}...`;
}

/**
 * Returns:
 * true - if button should be visible
 * false - if button should be hidden
 */
export function getUserPermissionForAction(_authUserRole: any, _formRoles: any, buttonKey: string, formValues?: any): boolean {
  let permission = 'V';
  if (buttonKey === 'update' && formValues && formValues.areAllFieldsReadOnly === true) {
    return false;
  }
  if (_formRoles && _formRoles.action) {
    try {
      const _actionRoles = _formRoles.action;
      if (_actionRoles[buttonKey] && _actionRoles[buttonKey][_authUserRole] && (_actionRoles[buttonKey][_authUserRole] === 'V' || _actionRoles[buttonKey][_authUserRole] === 'I')) {
        permission = _actionRoles[buttonKey][_authUserRole];
      } else {
        permission = 'V';
      }
    } catch (error) {
    }
  }

  return permission === 'V';
}
