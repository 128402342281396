import { COMPONENT_UNMOUNTED } from "@guibil/api/pack/gui-request-pack";

export const guiNotifier = () => {
  const guiNotification = window.guiNotification || {};

  const handleError = (err: any) => {
    if (err.message === COMPONENT_UNMOUNTED) return;

    if (err?.response?.data?.exception_message && typeof (err?.response?.data?.exception_message) === "string") {
      guiNotifier().error(err?.response?.data?.exception_message);
    } else {
      guiNotifier().error("guibil:form.errorMessage");
    }
    throw (err);
  }

  return {
    toast: guiNotification.toast,
    success: guiNotification.success,
    error: guiNotification.error,
    warning: guiNotification.warning,
    info: guiNotification.info,
    confirm: guiNotification.confirm,
    loader: guiNotification.loader,
    infiniteLoader: guiNotification.infiniteLoader,
    handleError
  };
};
