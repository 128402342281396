export const app_store_game_details_tr = {
  title: "Oyun Detayları",
  release_date: "Yayın Tarihi",
  last_release_date: "Son Yayın Tarihi",
  current_version: "Versiyon",
  rate: "Takım Puanı",
  bundle: "Bundle Id",
  size: "Boyut",
  minimum_os_version: "Min OS Versiyon",
  is_free: "Ücretsiz",
  price: "Ücret",
  editor_is_choice: "Editörün Seçimi",
  total_number_of_reviews: "İnceleme Sayısı",
  store_rate: "Mağaza Puanı",
  tag: "Etikeler",
  comment: "Yorumlar",
  description: "Açıklama",
  version_history: "Versiyon Geçmişi",
  screenshots: "Ekran Görüntüleri",
  version: "Versiyon",
  genre: "Kategoriler",
  artist_history: "Yapımcı Değişiklik Geçmişi",

  top_games_chart: "Popüler Oyunlarda Günlük Değişimi",
  order_change: "Sıra Değişimi",
  order: "Sıra",
  daily_max_order: "Günlük maksimum sıra",
  daily_min_order: "Günlük minimum sıra",
  date_selection: "Tarih",
  chart_type: "Tip",
};
