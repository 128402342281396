export const user_management_tr = {
  breadcrumbTitle: "Kullanıcı Yönetimi",
  pageTitle: "Kullanıcı Yönetimi",
  title: "Kullanıcı Yönetimi",
  menuTitle: "Kullanıcı Yönetimi",

  addUser: "Ekle",

  deleteConfirmMessage: "Seçili kullanıcıyı silmek istediğinizden emin misiniz?",
  deleteSuccessMessage: "Kullanıcı başarılı bir şekilde silindi",

  username: "Kullanıcı Adı",
  role: "Rolü",
  email: "E-mail",
  first_name: "İsim",
  last_name: "Soyisim",
  is_active: "Aktiflik Durumu",
  actions: " ",

  create: {
    title: "Kullanıcı Ekle",
    username: "Kullanıcı Adı",
    email: "E-mail",
    role: "Rolü",
    first_name: "İsim",
    last_name: "Soyisim",
    is_active: "Aktiflik Durumu",
    password: "Şifre",
    password_confirm: "Şifre Tekrar",
    successMessage: "Kullanıcı başarılı bir şekilde oluşturuldu",
  },

  update: {
    title: "Kullanıcı Güncelle",
    username: "Kullanıcı Adı",
    email: "E-mail",
    role: "Rolü",
    first_name: "İsim",
    last_name: "Soyisim",
    is_active: "Aktiflik Durumu",
    password: "Şifre",
    password_confirm: "Şifre Tekrar",
    successMessage: "Kullanıcı başarılı bir şekilde güncellendi",
  },

  updatePassword: {
    title: "Kullanıcı Şifresi Güncelle",
    username: "Kullanıcı Adı",
    password: "Şifre",
    password_confirm: "Şifre Tekrar",
    successMessage: "Kullanıcı şifresi başarılı bir şekilde güncellendi",
  },
};
