import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { authRouterConfig } from 'app/routes/auth-router-config';
import { IModuleRoutes, IModuleRouteItem } from '@guibil/router/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useUser } from '@guibil/app';
import { guestRouterConfig } from 'app/routes';
import { useLang } from '@guibil/app';
import { classNames, useWindowSize } from '@guibil/components';
import { makeStyles } from '@material-ui/core';
import SettingsItem from './SettingsItem';
import AccountItem from './AccountItem';
import Store from './store/Store';
import Brand from './Brand';


const GuiMenu = (props: { setOpenDrawer: Function }) => {
  const { setOpenDrawer } = props;
  const lang = useLang();
  const { isAuth, role } = useUser();
  const classes = useStyles();
  const location = useLocation();
  const { width } = useWindowSize();

  const combineUrls = (main: string, target: string) => `${main}/${target}`.replace(/\/+/, '/');

  const MenuRenderer = (routes: IModuleRoutes, parentUrl: string, routeLevel: number) => {
    let menu = null;
    routeLevel = routeLevel || 0;

    menu = routes.map((route) => {
      const { url, title, children, icon, hidden, forbiddenRoles } = route;

      if (hidden) return null;
      if (forbiddenRoles) {
        if (role !== "GUEST" && forbiddenRoles.includes(role)) {
          return null;
        }
      };

      const combinedUrl = combineUrls(parentUrl, url);
      const iconContainer = typeof (icon) === 'string'
        ? <FontAwesomeIcon icon={icon as IconProp} color="inherit" />
        : icon?.type?.render();

      if (!children?.length) {
        return (
          <NavLink key={route.title} onClick={() => { if (width < 666) setOpenDrawer(false); }} activeClassName={classes.activeLink} to={combinedUrl} exact={route.exact}>
            <ListItem button classes={{ root: classes.linkButton }}>
              <ListItemIcon classes={{ root: classes.icon }} style={{ marginLeft: 14 * routeLevel }}>
                {iconContainer}
              </ListItemIcon>
              <ListItemText className={classes.linkItemText} style={{ marginLeft: 3 * routeLevel }} primary={lang(title || "")} />
            </ListItem>
          </NavLink>
        );
      }
      return <MenuRouteItemRenderer routeLevel={routeLevel} route={route} parentUrl={parentUrl} key={route.title} icon={iconContainer} />;
    });

    return menu;
  };

  const MenuRouteItemRenderer = (props: { route: IModuleRouteItem, parentUrl: string, routeLevel: number, icon: any }) => {
    const { route, parentUrl, routeLevel, icon } = props;
    const combinedUrl = combineUrls(parentUrl, route.url);
    const [open, setOpen] = React.useState(location.pathname.startsWith(combinedUrl));
    const handleClick = () => { setOpen(!open); };


    return (
      <div key={route.title}>
        <ListItem disableRipple disableTouchRipple button classes={{ root: classes.linkButton }} onClick={handleClick}>
          <ListItemIcon style={{ marginLeft: 9 * routeLevel }} classes={{ root: classes.icon }}>
            {icon}
          </ListItemIcon>
          <ListItemText className={classes.linkItemText} style={{ marginLeft: 9 * routeLevel }} primary={lang(route.title || "")} />
          {open ? <ExpandLess classes={{ root: classNames(classes.icon, classes.expandIcon) }} /> : <ExpandMore classes={{ root: classNames(classes.icon, classes.expandIcon) }} />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {
              route.children && MenuRenderer(route.children!, combineUrls(parentUrl, route.url), routeLevel + 1)
            }
          </List>
        </Collapse>
      </div>
    );
  };

  return (
    <List
      component="nav"
      subheader={(
        <>
          <Link to="/" onClick={() => { if (width < 666) setOpenDrawer(false); }}  >
            <ListSubheader className={classes.navTitle}>
              <div className={classes.brand}>
                <Brand displayLogo />
              </div>
            </ListSubheader>
          </Link>
          <div className={classes.appbarBottom}>
            <SettingsItem />
            <AccountItem setOpenDrawer={setOpenDrawer} />
            <Store />
          </div>
        </>
      )}
      className={classes.root}
    >
      <>
        {MenuRenderer(isAuth ? authRouterConfig : guestRouterConfig, '/', 0)}
      </>
    </List >
  );
}


const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 380,
  },
  divider: {
    width: 240,
    height: 1,
    background: "var(--titleColor) !important",
    opacity: "0.125"
  },
  brand: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    marginLeft: 8,
  },
  appbarBottom: {
    paddingTop: 0,
    paddingBottom: 20,
  },
  icon: {
    color: 'inherit',
    minWidth: 30,
    fontSize: '1.6rem',
    '& svg': {
      width: '24px !important',
    },
  },
  expandIcon: {
    color: "var(--titleColor) !important",
    marginRight: "12px !important",
  },
  linkItemText: {
    marginTop: "0px !important",
    marginBottom: "0px !important",
    "& span": {
      fontSize: "15px !important",
    }
  },
  activeLink: {
    '& > div': {
      borderRadius: "2px",
      backgroundColor: "var(--backdrop) !important",
    },

  },
  linkButton: {
    padding: "8px 10px",
    paddingRight: 5,
    color: "var(--titleColor)",
    '&:hover': {
      backgroundColor: "var(--backdrop)",
    },
  },
  navTitle: {
    padding: 0,
    paddingBottom: 20
  },
});

export default GuiMenu;
