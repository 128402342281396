type ICallback = (...args: any[]) => void;
type ISubscriber = {
  name: string,
  callback: ICallback,
  id: Symbol,
};
interface IConfig {
  enableLogging: boolean,
}

/**
 * GuiEventSystem - is can be used as basic 
 * subscribe/unsubscribe method, to emit events
 * 
 * 
 * Can replace fat context states in grid/form,
 * and thus improve Performance
 */
export class GuiEventSystem {
  private subsribers: ISubscriber[];

  constructor(private config?: IConfig) {
    this.subsribers = [];
  }

  subscribe = (name: string, callback: ICallback) => {
    const id = Symbol("SubscribeId");
    this.subsribers.push({ name, callback, id });

    this.logEvent(`Subscribing to ${name}`);
    return () => {
      this.unsubscribe(id);
    }
  }

  emit = (name: string, ...args: any[]) => {
    this.subsribers.forEach((subsriber) => {
      if (subsriber.name === name) {
        subsriber.callback(...args);
      }
    })
    this.logEvent(`Emitting '${name}'`, ...args)
  }

  private unsubscribe = (subscribeId: Symbol) => {
    this.logEvent(`Unsubscribing from ${this.subsribers.find(({ id }) => id === subscribeId)?.name}`)
    this.subsribers = this.subsribers.filter(({ id }) => id !== subscribeId);
  }

  private logEvent = (event: string, ...args: any[]) => {
    if (process.env.NODE_ENV === "development" && this.config?.enableLogging) {
      // eslint-disable-next-line no-console
      const logger = console.debug || console.log;
      logger(event, args);
    }
  }
}
