import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";

const exportPdf = (head: RowInput[], body: RowInput[], filename: string) => {
  const doc = new jsPDF({ format: "a4" });

  autoTable(doc, {
    head,
    body,
  });

  doc.save(filename + ".pdf");
};

export { exportPdf };
