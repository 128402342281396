import { guiNotifier } from '@guibil/components';
import { GUI_FORM_STATE_EVENT } from '@guibil/form/constants/gui-form-state';
import { useGuiFormStateContext } from '@guibil/form/contexts/GuiFormStateContext';
import { IGuiFormResponse } from '@guibil/form/types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGuiFormPropsContext } from '../../../contexts/GuiFormPropsContext';

export const GuiFormUserConfigsListener = () => {
  const { config, successListener, errorListener } = useGuiFormPropsContext();
  const { guiEventSystem } = useGuiFormStateContext();
  const history = useHistory();

  useEffect(() => {
    const unsubscribe = guiEventSystem.subscribe(GUI_FORM_STATE_EVENT.FORM_SUCCESSFULLY_SAVED, (response: IGuiFormResponse) => {
      successListener && successListener(response);
      if (config?.successMessage) guiNotifier().success(config.successMessage);
      else guiNotifier().success("guibil:form.successMessage");

      config?.redirectOnSuccess && history.push(config.redirectOnSuccess)
    })

    return unsubscribe;
  }, []);


  useEffect(() => {
    const unsubscribe = guiEventSystem.subscribe(GUI_FORM_STATE_EVENT.FORM_FAILED_TO_SAVE, (err: any) => {
      errorListener && errorListener(err);
      if (config?.errorMessage) guiNotifier().error(config?.errorMessage);
      else if (err?.response?.data?.exception_message) guiNotifier().error(err?.response?.data?.exception_message)
      else guiNotifier().error("guibil:form.errorMessage")
    })

    return unsubscribe;
  }, []);

  return null;
};
