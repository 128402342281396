export const theme_en = {
  title: "Theme",
  pageTitle: "Theme Settings",
  breadcrumbTitle: "Theme",

  theme: "Theme",
  dark: "Dark",
  light: "Light",

  use: "Use",
  using: "Using"
};
