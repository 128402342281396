import { useRequest } from "@guibil/api"
import { useLang } from "@guibil/app";
import { classNames, useWindowSize } from "@guibil/components";
import { GuiPage, useGuiPageData } from "@guibil/page";
import { Grid, makeStyles } from "@material-ui/core";
import Datetime from "pages/components/Datetime";
import GameLogo from "pages/components/GameLogo";
import Name from "pages/components/Name";
import PageItem from "pages/components/PageItem";
import GameScreenshots from "pages/games/game-details/app-store/components/game-screenshots/GameScreenshots";
import { useEffect, useState } from "react";


const RandomGames = () => {
  const lang = useLang();
  const { width } = useWindowSize();

  const classes = useStyles();
  const req = useRequest();
  const [data, setData] = useGuiPageData();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => { width !== 0 && setIsMobile(width > 666 ? false : true); }, [width])

  const fetchData = async () => {
    const res = await req.get("games/random_two_games");
    return { ...res }
  }

  const Game = (props: any) => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className={classNames(classes.gameHeader, isMobile ? classes.gameHeaderMobile : null)}>
            <div>
              <GameLogo
                logo_image_url={props?.logo_image_url}
                width={180}
                height={180}
              />
            </div>
            <div>
              <div className={classNames(classes.gameName, isMobile ? classes.gameNameMobile : null)}>
                <Name
                  linkTo={`/makers/maker/${props?.artist?.id}`}
                  name={props?.artist?.name}
                  url={props?.artist?.url}
                  fontSize="16px"
                  fontWeight={800}
                />
              </div>
              <div className={classNames(classes.gameName, isMobile ? classes.gameNameMobile : null)}>
                <Name
                  linkTo={`/games/game/${props?.id}`}
                  name={props?.name}
                  url={props?.url}
                  fontSize="22px"
                  fontWeight={800}
                />
              </div>
              <div className={classNames(classes.gameQr, isMobile ? classes.gameQrMobile : null)}>
                <GameLogo
                  logo_image_url={props?.qr_image_url}
                  width={126}
                  height={126}
                  border_radius="0px"
                />
              </div>
            </div>
          </div>

        </Grid>
        <Grid item xs={12}>
          <PageItem
            label={lang("gameTracker.randomGames.appStore.release_date")}
            component={() => <Datetime datetimeString={props?.release_date} />}
          />
        </Grid>
        <Grid item xs={12}>
          <PageItem
            label={lang("gameTracker.randomGames.appStore.last_release_date")}
            component={() => <Datetime datetimeString={props?.last_release_date} />}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <GameScreenshots screenshots={props?.screenshots} showVideos={false} />
          </Grid>
        </Grid>
      </Grid>

    )
  }

  return (
    <GuiPage
      fetchData={fetchData}
      setData={setData}
      title="gameTracker.randomGames.appStore.randomTwoGame"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          {
            data?.first !== null ?
              <Game {...data?.first} /> :
              <span></span>
          }
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {
            data?.second !== null ?
              <Game {...data?.second} /> :
              <span></span>
          }
        </Grid>

      </Grid>
    </GuiPage>
  )
}

const useStyles = makeStyles({
  gameHeader: {
    display: "flex",
    paddingTop: 16
  },
  gameHeaderMobile: {
    display: "inherit",
  },
  gameName: {
    marginLeft: 15
  },
  gameNameMobile: {
    marginLeft: 0,
  },
  gameQr: {
    marginLeft: 15
  },
  gameQrMobile: {
    marginLeft: 0
  }
});

export default RandomGames
