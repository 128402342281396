import { useRequest } from '@guibil/api';
import React, { useEffect, useState } from 'react';
import { UserContext, IUserContext } from '../contexts/UserContext';
import { GuiApp } from '../GuiApp';
import jwt_decode from "jwt-decode";
import { AppLoading } from '@guibil/components';
import { useHistory } from 'react-router-dom';

interface IProps { }

export const UserProvider: React.FC<IProps> = ({ children }) => {
  const req = useRequest();
  const history = useHistory();
  const [accessToken, setAccessToken] = useState(GuiApp.getAccessToken());
  const [refreshToken, setRefreshToken] = useState(GuiApp.getRefreshToken());
  const [userContext, setUserContext] = useState<IUserContext | undefined>(undefined);
  const [isLoading, setisLoading] = useState(false);

  const checkAuth = async () => {
    if (refreshToken) {
      try {
        setisLoading(true);
        const res = await req.post("users/refresh_token", { "refresh": GuiApp.getRefreshToken() });
        GuiApp.setAccessToken(res.data.access);
        setAccessToken(res.data.access);
      } catch (err) {
        logout();
        history.push("/");
      } finally {
        setisLoading(false);
      }
    }
  }

  useEffect(() => { checkAuth(); }, [])

  useEffect(() => {
    if (accessToken) {
      try {
        var decoded: any = jwt_decode(accessToken);
        setUserContext({ isAuth: true, role: decoded?.userrole, detail: { username: decoded?.username, fullname: decoded?.fullname } } as IUserContext);
      } catch (error) {
        setUserContext({ isAuth: false, role: 'GUEST' } as IUserContext);
      }
    } else {
      setUserContext({ isAuth: false, role: 'GUEST' } as IUserContext);
    }
  }, [accessToken]);

  const login = (accessToken: string, refreshToken: string) => {
    GuiApp.setAccessToken(accessToken);
    GuiApp.setRefreshToken(refreshToken);
    setAccessToken(accessToken);
  };

  const logout = () => {
    GuiApp.setAccessToken('');
    GuiApp.setRefreshToken('');
    setAccessToken('');
    setRefreshToken('');
  };

  if (isLoading) return <AppLoading withBrand />;
  if (!userContext) return <AppLoading withBrand />;

  const contextValue = {
    login,
    logout,
    checkAuth,
    ...userContext,
  };


  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};
