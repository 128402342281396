import { IEnumBuilder } from '@guibil/app';
import { auth_user_gender_enum } from './user/gender/enum';
import { auth_user_role_enum } from './user/role/enum';

export type AuthEnumTypes =
  | 'AuthUserGender'
  | 'AuthUserRole';

export const auth_enum: IEnumBuilder<AuthEnumTypes> = {
  AuthUserGender: auth_user_gender_enum,
  AuthUserRole: auth_user_role_enum,
};
