import React, { useEffect } from 'react';
import { ILang, LanguageContext } from '../contexts/LanguageContext';
import { GuiEnums, IEnumCollection } from '../enums/GuiEnums';
import { UserProvider } from './UserProvider';

interface IProps {
  lang: ILang,
  enumsCollection?: IEnumCollection,
}
export const GuiAppProvider: React.FC<IProps> = (props) => {
  const { lang, enumsCollection, children } = props;

  useEffect(() => {
    if (enumsCollection) {
      GuiEnums.initEnums(enumsCollection, lang);
    }
  }, [enumsCollection, lang]);

  return (
    <LanguageContext.Provider value={lang}>
      <UserProvider>
        {children}
      </UserProvider>
    </LanguageContext.Provider>
  );
};
