/* eslint-disable no-console */
import { guiNotifier } from "@guibil/components";

export function logError(error: Error, message?: string) {
  if (process.env.NODE_ENV === "development") {
    const messageStr = "(DEV) " + [message, error.message]
      .filter(message => message)
      .join(" | ");

    console.warn('__ERROR_LOG__', messageStr, error);
    guiNotifier().warning(messageStr, { anchorOrigin: { horizontal: "right", vertical: "top" } });
  }
}
