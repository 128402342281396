import { classNames, GuiButton } from '@guibil/components';
import { makeStyles } from '@material-ui/core';
import React from 'react';

interface IProps {
  type: "delete" | "update" | "refresh" | "detail";
  onClick: () => void;
  disabled?: boolean;
  hide?: boolean;
}

const GuiGridRowButton: React.FC<IProps> = (props) => {
  const classes = useClasses();

  if (props.hide) return null;

  if (props.type === "delete") {
    return <GuiButton
      nativeProps={{ classes: { root: classNames(classes.button, classes.danger) } }}
      variant="cancel" onClick={props.onClick}
      icon="trash"
      disabled={!!props.disabled}
    />
  }

  if (props.type === "refresh") {
    return <GuiButton
      nativeProps={{ classes: { root: classNames(classes.button, classes.refresh) } }}
      variant="cancel" onClick={props.onClick}
      icon="sync-alt"
      disabled={!!props.disabled}
    />
  }

  if (props.type === "detail") {
    return <GuiButton
      nativeProps={{ classes: { root: classNames(classes.button, classes.refresh) } }}
      variant="cancel" onClick={props.onClick}
      icon="search"
      disabled={!!props.disabled}
    />
  }

  return (
    <GuiButton
      nativeProps={{ classes: { root: classes.button } }}
      variant="cancel" onClick={props.onClick}
      icon="pen"
      disabled={!!props.disabled}
    />
  )
}

const useClasses = makeStyles({
  button: {
    minWidth: "unset",
    margin: "4px",
    width: "32px !important",
    height: "32px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "7px !important",
    float: "left",
    marginRight: "5px !important",
    "& span": {
      margin: 0,
    },
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& svg": {
      position: "static !important",
      width: "14px !important",
      height: "14px !important",
    }
  },
  refresh: {
    "& .MuiCircularProgress-root": {
      left: "6.5px !important"
    },
    "& svg": {
      color: "var(--primarySuccess) !important",
      position: "static !important",
    }
  },
  danger: {
    "& .MuiCircularProgress-root": {
      left: "6.5px !important"
    },
    "& svg": {
      color: "var(--primaryErrorText) !important"
    }
  }
})

export default GuiGridRowButton
