import { classNames, useWindowSize } from '@guibil/components';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'

const ComponentItem = (props: { item: any }) => {
  const { item } = props;
  const classes = useStyles();
  const { width } = useWindowSize();
  const [vertically, setVertically] = useState<boolean>(false);

  useEffect(() => { width !== 0 && setVertically(width < 666 ? true : false); }, [width])

  return (
    <div className={classNames(classes.item, vertically ? classes.vertically : null)}>
      {item}
    </div>
  )
}

const useStyles = makeStyles({
  item: {
    paddingRight: 4,
  },
  vertically: {
    paddingBottom: 5,
  },
});

export default ComponentItem
