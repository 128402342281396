import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { guibil_form_en, guibil_form_tr } from "@guibil/form";
import { guibil_grid_en, guibil_grid_tr } from "@guibil/grid";
import { guibil_components_en, guibil_components_tr } from "@guibil/components";

import { login_en } from "pages/login/locales/en_US";
import { login_tr } from "pages/login/locales/tr_TR";
import { guibil_api_en, guibil_api_tr } from "@guibil/api";
import { enum_tr } from "app/enums/locales/tr_TR";
import { enum_en } from "app/enums/locales/en_US";
import { GuiApp } from "@guibil/app";
import { routes_tr } from "../routes/locales/tr_TR";
import { routes_en } from "../routes/locales/en_US";
import { app_tr } from "app/locales/tr_TR";
import { app_en } from "app/locales/en_US";
import { game_tracker_en } from "pages/locales/en_US";
import { game_tracker_tr } from "pages/locales/tr_TR";

export const i18n_resources = {
  en: {
    guibil: {
      form: guibil_form_en,
      grid: guibil_grid_en,
      components: guibil_components_en,
      api: guibil_api_en,
    },
    enum: enum_en,
    general: {
      app: app_en,
      routes: routes_en,
      gameTracker: game_tracker_en,
      login: login_en,
    },
  },
  tr: {
    guibil: {
      form: guibil_form_tr,
      grid: guibil_grid_tr,
      components: guibil_components_tr,
      api: guibil_api_tr,
    },
    enum: enum_tr,
    general: {
      app: app_tr,
      routes: routes_tr,
      gameTracker: game_tracker_tr,
      login: login_tr,
    },
  },
};

i18n.use(initReactI18next).init({
  lng: GuiApp.getLanguage(),
  ns: ["guibil", "enum", "general"],
  defaultNS: "general",
  resources: i18n_resources,
});
