import React, { useMemo, useState } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { GuiButton, GuiFileViewer, GuiGenericDialog } from '@guibil/components';
import { useGuiGridPropsContext } from '@guibil/grid/contexts/GuiGridPropsContext';

export const GuiGridFileCell: React.FC<DataTypeProvider.ValueFormatterProps> = (props) => {
  const [showDetail, setShowDetail] = useState(false);
  const { fileFields } = useGuiGridPropsContext();
  const { value, column } = props;

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  const fileConfig = useMemo(() => (fileFields || []).find((field) => field.targetField === column.name),
    [fileFields, column.name]);

  if (!fileConfig) { return null; }

  if (fileConfig.displayRaw) {
    return (
      <GuiFileViewer
        category={fileConfig.category}
        viewApi={fileConfig.viewApi}
        filePath={value}
      />
    );
  }
  return (
    <>
      <GuiButton onClick={toggleShowDetail} icon="eye" />
      {showDetail
        && (
          <GuiGenericDialog
            isOpen
            onClose={toggleShowDetail}
            title="guibil:grid.cells.file.title"
          >
            <GuiFileViewer
              category={fileConfig.category}
              viewApi={fileConfig.viewApi}
              filePath={value}
            />
          </GuiGenericDialog>
        )}
    </>
  );
};
