export const play_store_new_in_top_games_tr = {
  title: "Popülerlerde Yeni Oyun",

  filter: {
    filter: "Filtrele",
    name: "Ad",
    chart_type: "Tip",
    chart_genre: "Kategori",
    previous_datetime: "Popüler oyunlara girme zamanı",
  },

  chart_genre: "Kategori",
  game: {
    name: "Adı",
    logo_image_url: "Logo",
    last_release_date: "Son Yayın Tarihi",
    following: "Takip",
  },
};
